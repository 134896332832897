@switch (config().type) {
  @case (columnTypes().String) {
    {{ value() }}
  }
  @case (columnTypes().Number) {
    {{ value() }}
  }
  @case (columnTypes().Date) {
    {{ value() | formatDate : 'dd MMM yyyy' }}
  }
  @case (columnTypes().DateTime) {
    {{ value() | formatDate : 'dd MMM yyyy HH:mm a' }}
  }
  @case (columnTypes().Status) {
    <div class="d-flex justify-content-between align-items-center">
      <div class="w-fit-content h-fit-content h6 align-content-center"
        [ngClass]="{'ready': status().READY === value(), 'failed': status().FAILED === value(), 'in-progress': status().IN_PROGRESS === value()}">
        {{ value() }}
      </div>
      @if (status().READY === value()) {
        <mat-icon class="download-icon item-center cursor-pointer"
          (click)="utils.downloadFile(row().downloadUrl)">download</mat-icon>
      }
    </div>
  }
  @case (columnTypes().Custom) {
    <ng-container [ngTemplateOutlet]="config().custom"></ng-container>
  }
}
