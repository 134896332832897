<div class="skill-taxonomy-con">
  <div class="h3 black ">Skill Taxonomy Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div
          [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display':smsOption.showControl&&renderSkillProficiencyRelationNode(smsOption.key)?'block':'none'}">
          @if (smsOption.showControl) {
            <div>
              @if (smsOption.type=='text') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [required]="smsOption.required" autocomplete="off"
                      maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='textarea') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                      cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='number') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                      [min]="smsOption.min||0" />
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='select') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    @if (smsOption.icon) {
                      <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    }
                    <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      (selectionChange)="onSMSOptionSelected(smsOption, $event)"
                      [multiple]="smsOption.isMulti" [compareWith]="compareSelect">
                      @for (option of smsOption.options; track option) {
                        <mat-option
                          [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                        </mat-option>
                      }
                    </mat-select>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='date') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>date_range</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                      (focus)="picker.open()" />
                    <mat-datepicker #picker></mat-datepicker>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
  <div class="d-flex mt-4 flex-row">
    @if (showSkillMasterTags) {
      <div class="d-flex flex-half flex-column gap-3 pt-3">
        @if (showHrmsTags || showCustomTags) {
          <div class="h3 black ">Skill Master Tags</div>
        }
        <form [formGroup]="skillMasterTagsSettingsFormGroup">
          <main class="admin-form-con">
            @if (showHrmsTags) {
              <section class="admin-input-con-half">
                <app-data-ordering-control formControlName="hrmsTags" [input]="hrmsTagsConfig"
                (onSave)="saveSkillMasterTags('hrmsTags', 'hrmsFieldsChosenForMaster', 'skillMasterHrmsTags')"></app-data-ordering-control>
              </section>
            }
            @if (showCustomTags) {
              <section class="admin-input-con-half">
                <app-data-ordering-control formControlName="customTags" [input]="customTagsConfig"
                (onSave)="saveSkillMasterTags('customTags', 'fieldsForCustomTags', 'skillMasterCustomTags')"></app-data-ordering-control>
              </section>
            }
          </main>
        </form>
      </div>
    }
  </div>
</div>