import { Component, EventEmitter, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CsvMasterUploadsDownloadsService } from 'src/app/services/csv-master-uploads-downloads.service';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { ITemplateConfig, ITemplatePayload, ITemplateResponse, IUploadConfig } from './bulk-upload.model';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-bulk-upload-modal',
    templateUrl: './bulk-upload-modal.component.html',
    styleUrls: ['./bulk-upload-modal.component.scss'],
    standalone: false
})
export class BulkUploadModalComponent {
  @Output() onEvent = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fileName') fileName: ElementRef;
  uploadSub: Subscription = new Subscription();

  uploadConfig: IUploadConfig;
  templateConfig: ITemplateConfig;

  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private utils: UtilitiesService, private csvMasterUploadsDownloadsUtil: CsvMasterUploadsDownloadsService) { }

  onFileClick(event) {
    this.fileInput.nativeElement.click();
  }

  onFileSelect(event) {
    let file = this.fileInput.nativeElement.files[0];
    if (file) this.fileName.nativeElement.innerHTML = file.name;
  }

  onCloseClick(){
    this.onEvent.emit({ status: 'Close' });
  }

  generateTemplate() {
    if (!this.templateConfig?.payload && !this.templateConfig?.downloadTemplateUrl) return;

    const payload: ITemplatePayload = this.templateConfig.payload ?? {};
    this.ds.searchData(this.templateConfig.downloadTemplateUrl, payload).pipe(take(1)).subscribe((res: ITemplateResponse) => {
      if (res.status === 'Success') {
        this.utils.downloadFile(res.path);
      } else {
        this.weds.showDialog({ type: 'generic', code: 0 });
      }
    }, err => {
      this.weds.showDialog({ type: 'generic', code: 0 });
    })
  }

  async onUpload(event) {
    let file = this.fileInput.nativeElement.files[0];
    if (!file) { this.weds.showDialog({ type: 'generic', title: 'Select File', message: 'Please select a .csv file to upload', btnTitle: 'OK' }); return; }
    let dialogData = { type: 'wait', title: 'Uploading', message: 'Please wait while the file is uploaded', showProgress: true, progress: 0 };
    this.weds.showDialog(dialogData);
    if(this.uploadConfig?.saveIntegration) {
      this.uploadBasedOnUploadConfig(file, dialogData);
    } else if (this.uploadConfig?.functionName) {
      this.uploadFile(file);
    }

  }

  async uploadBasedOnUploadConfig(file, dialogData) {
    const csvUploadConfigURL = await this.csvMasterUploadsDownloadsUtil.getUploadConfig(
      this.uploadConfig.payload.clientId,
      this.uploadConfig.payload.integrationType.replace(" ", "")
    );

    if (csvUploadConfigURL['type'] === 'S3') {
      const s3PreSignedURL = csvUploadConfigURL['s3PreSignedURL'];
      const fileName = csvUploadConfigURL['fileName'];
      this.csvMasterUploadsDownloadsUtil.uploadFileToS3ForIntegrationsV2(
        this.uploadConfig.payload,
        s3PreSignedURL,
        file,
        fileName
      ).pipe(take(1)).subscribe();
      return;
    }
    this.uploadSub = this.ds.uploadFiles(file, this.uploadConfig.payload.clientId, ['application/csv'], 500).subscribe((rs: any) => {
      if (rs.type == 1) {
        dialogData.progress = Math.round(rs.loaded * 100 / rs.total);
      }
      else if (rs.status == 200 && rs.body) {
        rs = rs.body;
        this.uploadSub.unsubscribe();
        this.weds.closeDialog();
        if (rs.status == 'Success') {
          this.uploadConfig.payload.file = rs.files[0];
          this.weds.showDialog({ type: 'wait', code: -5 });
          this.ds.saveData('admin/saveIntegrations', this.uploadConfig.payload).pipe(take(1)).subscribe((rs: any) => {
            this.weds.closeDialog();
            if (rs.status == 'Success') {
              this.weds.showDialog({ type: 'generic', code: -1 })
            } else {
              this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
            }
          });
        } else {
          this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
        }
      } else if (rs.type !== 0 && !rs.type && rs.status != 200) {
        this.weds.closeDialog();
        this.weds.showDialog({ type: 'generic', code: 0 });
      }
    }, (err) => {
      this.uploadSub.unsubscribe();
    });
  }

  uploadFile(file) {
    this.uploadSub = this.ds[this.uploadConfig.functionName](`${this.uploadConfig.URL}`, this.uploadConfig.payload).subscribe((response: any) => {
      if (response.ok && response.preSignedUrl) {
        this.uploadSub.unsubscribe();
        this.weds.closeDialog();
        this.weds.showDialog({ type: 'wait', code: -5 });
        this.ds.putFileToS3PreSignedUrl(response.preSignedUrl, file).pipe(take(1)).subscribe((res: any) => {
          this.weds.closeDialog();
          this.weds.showDialog({ type: 'generic', code: -1 })
        });
      } else {
        this.weds.closeDialog();
        this.weds.showDialog({ type: 'generic', code: 0 });
      }
    }, (err) => {
      this.uploadSub.unsubscribe();
    });
  }
}
