<div class="container-new-growth-plan">
  <div class="header d-flex justify-content-between">
    <div class="left-sec item-center">
      <span class="icon">
        <button class="btn" (click)="navigateBack()"><img src="assets/icons/chevron-left.svg" alt="left icon"></button>
      </span>
      <span class="header-text h3">Goal Details</span>
    </div>
    @if (!checkIfTeamGoals && !(isArchived || goal?.isArchived)) {
      <div class="right-sec d-flex gap-3">
        <button class="btn-3-dots" [matMenuTriggerFor]="menu" #menuTrigger>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu class="idp" #menu="matMenu">
          <app-goal-actions [goalActions]="goalActionData" (onGoalAction)="onGoalActionClick($event, element)"></app-goal-actions>
        </mat-menu>
        <button class="btn-check-in item-center" (click)="onCheckInClick()">
          <mat-icon>pin_drop</mat-icon>
          CHECK-IN
        </button>
      </div>
    }
    @if (isArchived || goal?.isArchived) {
      <div class="right-sec d-flex">
        <button class="btn-unarchive primary-button item-center" (click)="unArchive()">
          <mat-icon class="mr-1">unarchive</mat-icon>
          UNARCHIVE
        </button>
      </div>
    }
  </div>

  @if (goal) {
    <div class="main-body gap-3 d-flex">
      <div class="detail-sec w-100">
        <!-- Name and status section -->
        <div class="mt-3 data-con title d-flex flex-column mb-4">
          <!-- info message for archived goal -->
          @if (isArchived) {
            <div class="info-archived d-flex justify-content-between align-items-center mb-12">
              <div class="ls ml-12">
                <app-svg-icon fill="var(--color-info-blue)" src="icons/info.svg"></app-svg-icon>
                This is an archived goal
              </div>
              <button (click)="unArchive()" class="btn rs mr-12">
                UNARCHIVE
              </button>
            </div>
          }
          <div class="icon-with-title d-flex align-items-center gap-3 mb-12">
            <div class="icon">
              <button class="btn item-center" [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.progressStatus), 'color': getColorForGoalStatus(goal.progressStatus)}">
                <mat-icon>{{goal.icon}}</mat-icon>
              </button>
            </div>
            <div class="title-text h3">{{goal.title}}</div>
          </div>
          <div class="status d-flex justify-content-between align-items-center mb-12">
            <div class="status-label d-flex gap-12 item-center">
              <div class="h4">Status</div>
              @if (goal.progressStatus) {
                <div class="btn-status h6 item-center" [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.progressStatus), 'color': getColorForGoalStatus(goal.progressStatus)}">{{PROGRESS_STATUS[goal.progressStatus]}}</div>
              }
              <!-- <span class="updatedBy" *ngIf="goal.updatedBy">by {{goal.updatedBy}}</span> -->
            </div>
            <div class="type label d-flex gray">
              @if (goal?.type === 'SKILL') {
                <mat-icon class="ic-16 mt-2">star</mat-icon>
              }
              {{getStatus(goal)}}
            </div>
          </div>
          <!-- progress bar -->
          <div class="item-center w-100">
            <div class="progress-bar" [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.progressStatus)}">
              <div class="progress" [ngStyle]="{ width: 70 + '%', 'background-color': getColorForGoalStatus(goal.progressStatus) }"></div>
            </div>
          </div>
          @if (goal.rejectionReason) {
            <div class="reject-reason mt-4">
              <label class="h5 d-flex">Rejection Reason:</label>
              {{goal.rejectionReason}}
            </div>
          }
          @if (goal.description) {
            <div class="description mt-4">
              <label class="h5 d-flex">Description</label>
              {{goal.description}}
            </div>
          }
          <div class="mt-2 d-flex">
            <mat-icon class="basic-info-icon ic-16 gray pr-1">info</mat-icon>
            <span class="label gray fw-300">{{ infoTips[goal.type] }}</span>
          </div>
        </div>
        <!-- Entity section -->
        @if (goal.type !== 'CUSTOM') {
          <app-goal-entity [goal]="goal"></app-goal-entity>
        }
        <!-- Activity feed section -->
        <div class="mt-4 activity-feed-sec">
          <div class="label">Activity Feed</div>
          <div class="data-con activity-feed mt-2">
            <!-- Input box for adding a new comment -->
            <div class="comment item-center gap-2 mb-3">
              <div class="icon-sec item">
                @if (profileImageUrl) {
                  <img [src]="profileImageUrl" alt="Profile" height="40px" width="40px" />
                }
                @if (!profileImageUrl) {
                  <button class="btn item-center">{{ getShortName(userName) }}</button>
                }
              </div>
              <div class="text-box w-100">
                <input
                  [(ngModel)]="newComment"
                  (keyup.enter)="addComment()"
                  class="text-input"
                  type="text"
                  placeholder="Add a comment..."
                  />
              </div>
            </div>
            <div class="tabs">
              <mat-tab-group mat-align-tabs="start">
                <mat-tab label="All"></mat-tab>
                <mat-tab label="Comments"></mat-tab>
              </mat-tab-group>
            </div>
            <!-- Comments Section -->
            <div class="comments-section">
              @for (comment of comments; track comment) {
                <div class="comment-item mb-3">
                  <div class="comment-content item-center">
                    <div class="profile-icon">
                      @if (getProfileInfo(comment.userId)?.profileImg) {
                        <img
                          [src]="getProfileInfo(comment.userId)?.profileImg"
                          alt="Profile"
                          class="profile-circle">
                      }
                      @if (!getProfileInfo(comment.userId)?.profileImg) {
                        <div class="profile-circle">
                          {{ getProfileInfo(comment.userId)?.initials }}
                        </div>
                      }
                    </div>
                    <div class="content-details">
                      <div class="comment-header">
                        <strong>{{ getProfileInfo(comment.userId)?.initials }}</strong> {{comment.content}}
                      </div>
                      <div class="comment-footer">
                        <a href="javascript:void(0)" (click)="selectCommentForReply(comment)">Reply</a>
                      </div>
                    </div>
                  </div>
                  <!-- Show replies -->
                  @if (comment.replies.length) {
                    <div class="replies mt-4">
                      @for (reply of comment.replies; track reply) {
                        <div class="reply-item d-flex gap-3">
                          <div class="profile-icon reply-indent">
                            @if (getProfileInfo(reply.userId)?.profileImg) {
                              <img
                                [src]="getProfileInfo(reply.userId)?.profileImg"
                                alt="Profile"
                                class="profile-circle">
                            }
                            @if (!getProfileInfo(reply.userId)?.profileImg) {
                              <div class="profile-circle">
                                {{ getProfileInfo(reply.userId)?.initials }}
                              </div>
                            }
                          </div>
                          <div class="reply-content">
                            <div class="reply-header">
                              <strong>{{ getProfileInfo(reply.userId)?.initials }}</strong>Replied
                            </div>
                            <div class="reply-text">{{ reply.content }}</div>
                          </div>
                        </div>
                      }
                    </div>
                  }
                  <!-- Reply input box -->
                  @if (selectedCommentForReply === comment) {
                    <div class="reply-input-container">
                      <div class="profile-icon reply-indent">
                        <div class="profile-circle">{{ getProfileInfo(userId)?.initials }}</div>
                      </div>
                      <input
                        [(ngModel)]="replyText"
                        (keyup.enter)="replyToComment(comment)"
                        class="text-input reply-box"
                        type="text"
                        placeholder="Write a reply..."
                        />
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <!-- About Section -->
      <div class="side-bar-sec d-flex flex-column gap-2">
        @if (checkIfTeamGoals &&
          (!(['APPROVED', 'REJECTED', 'REVISED'].includes(goal.approvalStatus) || goal?.isRevisedAccepted) || !(isArchived || goal?.isArchived))
          ) {
          <div class="data-con about-sec mt-3 p-3 d-flex flex-column">
            @if (!(['APPROVED', 'REJECTED', 'REVISED'].includes(goal.approvalStatus) || goal.isRevisedAccepted)) {
              <button mat-stroked-button class="btn btn-success" (click)="approveGoal()">
                <mat-icon>check_circle</mat-icon>
                APPROVE GOAL
              </button>
              <button mat-stroked-button class="btn btn-danger" (click)="rejectGoal()">
                <mat-icon>do_not_disturb_on</mat-icon>
                REJECT GOAL
              </button>
              <hr>
              }
              @if (!(isArchived || goal?.isArchived)) {
                <button mat-stroked-button class="btn btn-outline" (click)="editGoal()">
                  <mat-icon>edit</mat-icon>
                  EDIT
                </button>
                <button mat-stroked-button class="btn btn-outline" (click)="archiveGoal()">
                  <mat-icon>inventory_2</mat-icon>
                  ARCHIVE
                </button>
              }
            </div>
          }
          <div class="data-con about-sec mt-3 p-3">
            <label class="h4">About this goal</label>
            <div class="mb-20 mt-20">
              <div class="name mb-1">GOAL TYPE</div>
              <div class="value">{{ goal.type + ' Goal' }}</div>
            </div>
            <div class="mb-20">
              <div class="name mb-1">GOAL CYCLE</div>
              <div class="value">
                {{goal?.developmentPlanDetails?.timePeriod?.startDate | date: 'd MMMM yyyy'}} - {{goal?.developmentPlanDetails?.timePeriod?.endDate | date: 'd MMMM yyyy'}}
              </div>
            </div>
            <div>
              <div class="name mb-1">DUE DATE</div>
              <div class="value">{{ goal.goalDeadline | date: 'd MMM yyyy' }}</div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
