import { Component, Input, OnInit } from '@angular/core';
import { IGoalStatus } from '.';

@Component({
    selector: 'app-my-goal-cards',
    templateUrl: './my-goal-cards.component.html',
    styleUrls: ['./my-goal-cards.component.scss'],
    standalone: false
})
export class MyGoalCardsComponent implements OnInit {
  @Input() goalsCardData: IGoalStatus[] = [];
  @Input() label: string = 'My Learning Goals';

  constructor() { }

  ngOnInit(): void {
  }

}
