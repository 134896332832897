import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges } from '@angular/core';
import "@sgratzl/chartjs-chart-boxplot";
import { BoxAndWiskers, BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';
import { CategoryScale, Chart, ChartData, LinearScale } from 'chart.js';
import { ThemeService } from 'src/app/services/theme.service';

Chart.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale);

@Component({
    selector: 'app-box-plot-chart',
    templateUrl: './box-plot-chart.component.html',
    styleUrls: ['./box-plot-chart.component.scss'],
    standalone: false
})
export class BoxPlotChartComponent implements OnInit {

  constructor(private readonly elementRef: ElementRef, private themeService: ThemeService, private readonly ngZone: NgZone) { }

  private chart: Chart;
  @Input() boxPlotData: any;
  @Input() showLegend: boolean = false;
  @Input() legendDBLClick: boolean = true;
  @Input() barThickness: number = 30;
  @Output() onChartClick = new EventEmitter();
  originalLabelArr: any[] = [];
  ngOnInit() {

  }

  ngOnChanges(changes: any): void {
    (this.boxPlotData.datasets || []).map(d => {
      d.medianColor = this.themeService.getColorValue('--color-white');
      d.meanRadius = 0;
      d.borderRadius = 5;
      d.borderWidth = 1.5;
      d.boxBorder = 0;
      d.padding = 10;
      d.maxBarThickness = 30;
      d.barThickness = this.barThickness;
    });
    this.options.options.scales.y.title.text = this.boxPlotData.yAxesLabel
    this.options.data = this.boxPlotData;
    this.options.options.plugins.legend.display = this.showLegend;
    if (this.boxPlotData.labels) {
      this.originalLabelArr = JSON.parse(JSON.stringify(this.boxPlotData.labels));
      for (let i = 0; i < this.boxPlotData.labels.length; i++) {
      let label = this.boxPlotData.labels[i] || '';
      if (label.length < 16) continue;
      label = label.split(' ');
      if (label.length == 1) { this.boxPlotData.labels[i] = label.join(' ').slice(0, 18) + '...'; continue; }
      let newLabel = [];
      label.map((s) => {
        if (!newLabel.length) { newLabel.push(s); return; }
        if ((newLabel[newLabel.length - 1] + ' ' + s).length > 16) newLabel.push(s);
        else newLabel[newLabel.length - 1] = newLabel[newLabel.length - 1] + ' ' + s;
      });
      this.boxPlotData.labels[i] = newLabel;
      }
    }
    this.build();
  }

  options: any = {
    type: 'boxplot',
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          displayColors: false,
          callbacks: {
            title: function (context) {
              let title = context[0].dataset.label + ": " + context[0].label;
              return title;
            },
            label: function (context) {
              let data = context.formattedValue, labels = [];
              ['min', 'max', 'median', 'mean'].map(key => {
                labels.push(key + ': $ ' + data[key])
              })
              return labels;
            }
          }
        },
        legend: {
          display: false
        }
      },
      scales: {
        x: { grid: { display: false, drawTicks: false }, ticks: { minRotation: 0, labelOffset: -8, color:'#0E0D0F'} },
        y: {
          display: true,
          title: {
            text: '',
            display: true,
            padding: 10
          },
          ticks:{
            color:'#0E0D0F'
          }
        }
      },
    },
    plugins: [
      {
        id: 'chartAreaBorder',
        beforeDraw(chart, args, options) {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          ctx.save();
          ctx.strokeStyle = '#e6e6eb';
          ctx.lineWidth = 1;
          ctx.strokeRect(left, top, width, height);
          ctx.restore();
        },
    },
  ]
  }

  onDblClick(event) {
    const activePoints = this.chart.getElementsAtEventForMode(event, 'point', { intersect: true }, true);
       if (activePoints) {
        const [chartElem] = activePoints as any;
        if (chartElem){
        this.onChartClick.emit({
          type: 'singleClick',
          xLabel: typeof this.originalLabelArr[chartElem.index] == 'string' ? this.originalLabelArr[chartElem.index] : this.originalLabelArr[chartElem.index].join(' '),
          data: chartElem.element.items
        })
        this.chart.destroy();
        }
      }
  }

  private build() {
    this.ngZone.runOutsideAngular(() => {
      const node: HTMLElement = this.elementRef.nativeElement;
      if (this.chart) this.chart.destroy()
      this.chart = new Chart(node.querySelector('canvas'), this.options);
    });
  } 
}
