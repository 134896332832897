
<div class="group-card rev-level-{{maxNestingLevel}}" [formGroup]="groupForm">
  <div class="group-buttons d-flex mb-3 gap-2">
    @if (maxNestingLevel > 1) {
      <button mat-stroked-button class="btn teritiary-button fs-12" (click)="addCondition()"><mat-icon class="ic-16">add</mat-icon>ADD CONDITION</button>
    }
    @if (maxNestingLevel > 1) {
      <button mat-stroked-button class="btn teritiary-button fs-12" (click)="addSubGroup()"><mat-icon class="ic-16">add</mat-icon>ADD SUB-GROUP</button>
    }

    @if (conditions && conditions.length > 1) {
      <button mat-icon-button  class="btn ml-auto" (click)="removeGroup(currentParentIndex)">
        <mat-icon class="ic-16">close</mat-icon>
      </button>
    }
  </div>

  <!-- Conditions and nested groups -->
  <div formArrayName="conditions">
    @for (subgroup of conditions.controls; track subgroup; let j = $index) {
      @if (subgroup.get('type').value === 'condition') {
        <ng-container *ngTemplateOutlet="conditionTemplate; context: { group: subgroup, index: j }"></ng-container>
      }
      @if (subgroup.get('type').value === 'group') {
        <search-group
          [groupForm]="subgroup"
          [tagsConfigMap]="tagsConfigMap"
          [tagValuesAPIResposne]="tagValuesAPIResposne"
          [maxNestingLevel]="maxNestingLevel - 1"
          [parentArray]="conditions"
          [currentParentIndex]="j">
        </search-group>
      }
      <!-- Logic Operator Toggle Button between conditions/groups -->
      @if (j < conditions.length - 1) {
        <div class="logic-operator my-3">
          <button mat-stroked-button  class="btn primary-button" (click)="toggleAndOr(groupForm)">
            <mat-icon>repeat</mat-icon>
            {{ groupForm?.get('logicOperator')?.value || 'AND' }}
          </button>
        </div>
      }
    }
  </div>
</div>

<!-- Condition Template -->
<ng-template #conditionTemplate let-group="group" let-index="index">
  <div class="condition-card data-con flex-wrap">
    <ng-select class="tag-select-input-box min-width-150" (change)="onTagSelectChange($event, group)" [clearable]="false" [formControl]="group.get('tag')" [items]="designationOptions"></ng-select>

    @if (group.get('tag').value) {
      @if (isAllocationType(group.get('tag')?.value)) {
        <ng-select [formControl]="group.get('poolCode')" [clearable]="false" [items]="['Pool code']"></ng-select>
      }
      <ng-select [formControl]="group.get('operator')" [clearable]="false" [items]="operatorOptions"></ng-select>
      <ng-template [ngTemplateOutlet]="ngSelectTemplate" [ngTemplateOutletContext]="{ group: group }"></ng-template>

      @if (isAllocationType(group.get('tag')?.value)) {
        <span class="mx-2 label">AND</span>
        <ng-select [formControl]="group.get('allocationPercentageDisabled')" [clearable]="false" [items]="['Allocation percentage(%)']"></ng-select>
        <ng-select [formControl]="group.get('conditionType')" [clearable]="false" [items]="conditionTypes"></ng-select>
        <mat-form-field class="adv-search-text" appearance="outline">
          <input matInput [formControl]="group.get('allocationPercentage')" placeholder="Any">
        </mat-form-field>
        @if (group.get('conditionType').value === 'in between') {
          <span class="mx-2 label">AND</span>
          <mat-form-field class="adv-search-text" appearance="outline">
            <input matInput [formControl]="group.get('allocationPercentage2')" placeholder="Any">
          </mat-form-field>
        }
      }

      @if (isSkillTag(group.get('tag')?.value)) {
        <span class="mx-2 label">AND</span>
        <ng-select [formControl]="group.get('experienceOptions')" [clearable]="false" [items]="experienceOptions"></ng-select>
        <ng-select [formControl]="group.get('conditionType')" [clearable]="false" [items]="conditionTypes"></ng-select>
        <ng-select [formControl]="group.get('proficiencyLevel')" [clearable]="false" [items]="proficiencyLevels"></ng-select>
        @if (group.get('conditionType').value === 'in between') {
          <span class="mx-2 label">AND</span>
          <ng-select [formControl]="group.get('proficiencyLevel2')" [clearable]="false" [items]="proficiencyLevels"></ng-select>
        }
      }
      
      @if (isAllocationTag(group.get('tag').value)) {
        <span class="mx-2 label">AND</span>
        <mat-form-field appearance="outline" class="input-form-field">
          <mat-icon matPrefix class="gray mb-10 fs-16">date_range</mat-icon>
          <mat-date-range-input [rangePicker]="picker">
            <input (focus)="onFocus(picker)" matStartDate placeholder="Start date" [formControl]="group.get('startDate')">
            <input (focus)="onFocus(picker)" matEndDate placeholder="End date" [formControl]="group.get('endDate')">
          </mat-date-range-input>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      }      
    }

    <button mat-icon-button class="btn teritiary-button ml-auto" (click)="removeConditionOrGroup(index)">
      <mat-icon class="ic-16">close</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #ngSelectTemplate let-group="group">
  <ng-select
    class="flex-fill"
    style="min-width: 300px;"
    [formControl]="group.get('searchValue')"
    [items]="getItemsForTag(group.get('tag')?.value)"
    bindLabel="element"
    bindValue="element"
    [multiple]="group.get('operator').value === 'contains'"
    [typeahead]="search$"
    [trackByFn]="trackByFn"
    placeholder="{{ isAllocationType(group.get('tag')?.value) ? 'Any' : 'Choose from below options' }}"
    [loading]="isLoading(group.get('tag')?.value)"
    [closeOnSelect]="group.get('operator').value !== 'contains'"
    (search)="onSearch($event, group)"
    >
  </ng-select>
</ng-template>
