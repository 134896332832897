<table mat-table [dataSource]="datasource()" class="mat-table">
  @for (column of columns(); track column.key) {
    <ng-container [matColumnDef]="column.key">
      <th mat-header-cell *matHeaderCellDef>
        <div class="d-flex align-items-center">
          <span>{{ column.name }}</span>
          @if (column.sort) {
            <mat-icon class="cursor-pointer ic-16" (click)="onSortClick(column.key)">
              {{ getSortIcon(column.key) }}
            </mat-icon>
          }
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <app-cell [config]="column" [row]="element"></app-cell>
      </td>
    </ng-container>
  }
  <tr mat-header-row [sticky]="true" *matHeaderRowDef="displayedColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
</table>
@if (api.isLoading()) {
  <div class="h-100 w-100 m-auto"></div>
} @else if (datasource().length) {
  <div class="px-20 pb-20">
    <app-paginator #paginator [totalCount]="api.value()?.totalCount ?? 0"></app-paginator>
  </div>
} @else {
  <div class="h-100 w-100 m-auto">
    <app-no-data-box [data]="noData()"></app-no-data-box>
  </div>
}
