<form [formGroup]="listItemsFormGroup" (ngSubmit)="save()">
  <div class="d-flex flex-column gap-3">
    @if (input.title) {
      <div class="h3 black ">{{input.title}}</div>
    }
    <div class="d-flex flex-column gap-2">
      @if (input.subTitle) {
        <div class="h4 black">{{input.subTitle}}</div>
      }
      <!-- Multi select drop down -->
      @if (input.addFromDropDown) {
        <mat-form-field class="drop-down font-weight-normal" appearance="outline">
          <mat-label>{{ input.dropDownPlaceholder || 'Choose from below options' }}</mat-label>
          <mat-select multiple formControlName="listItemInput"
            (selectionChange)="listItemsChangedByDropDown($event)">
            @for (option of input.dropDownOptions; track option) {
              <mat-option [value]="option">
                {{option}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }

      <!-- Drag and drop -->
      @if (selectedItems.length) {
        <div class="d-flex flex-column list-items border-radius-4" cdkDropList
          (cdkDropListDropped)="changeOrder($event)">
          @for (value of selectedItems; track value; let i = $index) {
            <div class="list-item d-flex flex-row justify-content-between align-items-center gap-2 p-2"
              cdkDrag [cdkDragDisabled]="!input.canOrder || input.disabled">
              @if (!input.addFromTextBox) {
                <span>{{value}}</span>
              }
              @if (input.addFromTextBox) {
                <mat-form-field appearance="outline" class="text-box admin-input-form-field">
                  <input type="text" matInput [value]="value"
                    [placeholder]="input.textBoxPlaceHolder"
                    (focusout)="onTextBoxBlur($event, i)" [disabled]="input.disabled"/>
                </mat-form-field>
              }
              @if (input.canRemoveItem && !input.disabled) {
                <mat-icon (click)="removeItemFromList(i)"
                        class="d-flex flex-row justify-content-center align-items-center opacity-2 opacity-hover-9 cursor-pointer
                        ">clear</mat-icon>
              }
            </div>
          }
        </div>
      }

      @if (!input.disabled) {
        <div class="admin-save-button-con">
          @if (input.addFromTextBox) {
            <button mat-stroked-button (click)="addTempItemFromTextBox()" class="secondary-button" type="button">Add</button>
          }
          @if (input.useSaveButton) {
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
          }
        </div>
      }
    </div>
  </div>
</form>
