import { TemplateRef } from "@angular/core";

export interface IDialog {
  icon?: string;
  title: string;
  subtitle?: string;
  datatable?: IDatatable;
}

export interface IDatatable {
  title: string;
  api: {
    endpoint: string;
    payload: any;
    clientId?: boolean;
  };
  parseResponse?: (response: IPaginatedResponse) => unknown;
  parseResponseKey?: string;
  columns: Array<IColumn>;
}

export interface IColumn {
  name: string;
  key: string;
  type: ColumnType;
  sort?: boolean;
  valueFunction?: (config: IColumn, row: unknown) => unknown;
  custom?: TemplateRef<unknown>;
}

export enum ColumnType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  DateTime = 'dateTime',
  Status = 'status',
  User = 'user',
  Custom = 'custom'
}

export interface IPaginatedResponse {
  limit: number;
  offset: number;
  totalCount: number;
  results?: unknown[];
  userData?: unknown[];
  masterData?: unknown[];
}

export interface INoData {
  title: string;
  message: string;
}
