<div class="p2p-settings-con">
  <div class="h3 black ">Gamification Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; let i = $index; track smsOption.key) {
        @if (smsOption?.type === 'sub-title' && smsOption.showControl) {
          <div class="admin-input-con-full my-20">
            <div class="h4 black sub-title">
              {{smsOption.title}}
            </div>
          </div>
        } @else {
          <div class="admin-input-con-half m-0">
            <div class="admin-input-con-half m-0"
              [ngStyle]="{'display': smsOption.showControl ? 'block' : 'none'}" class="align-self-center">
              @if (smsOption.showControl) {
                <div>
                  @if (smsOption.type === 'select') {
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                      <mat-label>{{smsOption.title}}</mat-label>
                      @if (smsOption.icon) {
                      <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                      }
                      <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                        [multiple]="smsOption.multiple" [compareWith]="compareSelect" (selectionChange)="onSelectionChange(smsOption, $event)">
                        @for (option of smsOption.options; track option) {
                        <mat-option [value]="option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                        </mat-option>
                        }
                      </mat-select>
                      @if (editForm.get(smsOption.key)?.invalid) {
                      <mat-error>{{smsOption.errorText || 'This input is mandatory'}}</mat-error>
                      }
                    </mat-form-field>
                  }
                  @if (smsOption.type === 'checkbox') {
                    <div class="d-flex">
                      <mat-label class="mb-1">{{smsOption.title}}</mat-label>
                      <mat-icon
                        matTooltip="Choose leaderboard timeframes: 'All TIme' for cumulative scores, 'Week' and 'Month' for period-specific rankings. This will adjust the leaderboard view for the users."
                        matTooltipPosition="right" class="d-flex align-items-center ic-16 gray ml-1">
                        info</mat-icon>
                    </div>
                    <div class="gap-2 admin-input-con-full d-flex align-items-center mb-0">
                      <div class="d-flex align-items-center justify-content-start custom-input-height gap-6 w-100">
                        @for (checkBoxOption of smsOption.options; track checkBoxOption) {
                          <mat-checkbox color="primary" [checked]="editForm.get(smsOption.key)?.value?.includes(checkBoxOption)"
                            (change)="toggleCheckboxSelection($event, checkBoxOption)">
                            {{ checkBoxOption === 'alltime' ? 'All Time' : (checkBoxOption | titlecase) }}
                          </mat-checkbox>
                        }
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      }
      @if (canEdit) {
        <div class="admin-save-button-con">
          <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
      }
    </div>
  </form>
</div>