<div>
  <div class="filter-sec item-between pb-12">
    <div class="item-center gap-3">
      @if (!isSkillActivity) {
        <div class="item-center gap-2">
          <span class="h5">Recent Activity</span>
        @if (activities.length) {
          <div class="px-2 bg-color-gray-75 border-radius-20 h-20px w-8px fs-12">
            {{activities.length}}
          </div>
          }
        </div>
      }
      @if (sources.length) {
        <ng-select [(ngModel)]="selectedSource" [closeOnSelect]="false" [multiple]="true"
          [appendTo]="'body'" [clearable]="false" (ngModelChange)="onSourcesChange()" class="filter-button">
          @for (source of sources; track source) {
            <ng-option [value]="source.id">
              {{source.label}}
            </ng-option>
          }
        </ng-select>
      }
    </div>
    <div class="color-color-gray-1000 d-flex justify-content-end align-items-center gap-1 clickable fs-12"
      (click)="toggleSortOrder()">
      <mat-icon class="fs-16 w-16px h-16px">sort</mat-icon>
      {{sortOrder === 0 ? 'BY NEWEST FIRST' : 'BY OLDEST FIRST'}}
    </div>
  </div>
  @if (isLoading) {
    <div class="fw-500 mt-2">
      Loading...
    </div>
  }
  @if (!isLoading) {
    @for (group of groupedActivities; track group; let i = $index) {
      <div>
        <div class="timeline p-3 position-relative">
          <div class="timeline-bar outer" [ngClass]="{'first': i === 0}">
          </div>
          <div class="timeline-background-bar"></div>
          @for (activity of group.activities; track activity; let j = $index; let last = $last) {
            <div class="timeline-item">
              @if (j === 0 || group.showAll) {
                <div class="timeline-entry d-flex"
                  [ngClass]="{'increase-height': group.activities.length > 1 && j < group.activities.length - 1 }"
                  >
                  <div class="time">
                    <div class="time-sec">
                      {{ activity.timestamp | date: 'hh:mm a' }}<br />
                    </div>
                    <div class="date-sec">
                      {{ activity.timestamp | date: 'dd MMM yyyy' }}
                    </div>
                  </div>
                  <div class="timeline-bar">
                    @if (activity) {
                      <div class="circle"></div>
                    }
                  </div>
                  <div class="icon item-center mx-3">
                    @if ((activity?.event === 'Endorsement' && activity?.endorsements?.type !== 'approve')) {
                      <div
                        >
                        <app-svg-icon height="20" width="20" class="custom-svg"
                        [src]="endorsementIconsMap[activity?.endorsements?.type]" fill="var(--color-black)"></app-svg-icon>
                      </div>
                    } @else {
                      <mat-icon class="fs-20 mt-1 ml-1">{{iconMap[activity?.event]}}</mat-icon>
                    }
                  </div>
                  <div class="content">
                    <span class="event">
                      <span class="event-name">{{ getDisplayText(activity) }}</span>
                      @if (activity.event === 'Endorsement') {
                        <span>
                          by {{ activity.endorsements.actionByName }}
                        </span>
                      }
                      @if (activity.event !== 'Endorsement') {
                        <span>
                          by {{ activity.label}}
                        </span>
                      }
                    </span>
                    @if (!activity.endorsements) {
                      <span>
                        {{ getDisplaySupportText(activity) }} {{ activity.label }}
                      </span>
                    }
                    <p class="rating mt-1 d-flex align-items-center mb-1">
                      <span class="overall-text">Overall:</span>
                      @if (!checkIfL1Endorsement(activity)) {
                        <span class="star mx-2">★</span>
                        <span class="mr-1">{{ activity.overallRating?.fromValue || 0 }}</span>
                        @if (activity.overallRating?.toValue !== activity.overallRating?.fromValue) {
                          <span class="mr-1">
                            → {{ activity.overallRating?.toValue || 0}}
                          </span>
                        }
                      } @else {
                        <p class="rating mb-0 ml-1">
                          Final endorsement pending.
                          <app-svg-icon fill="var(--color-info-blue)" src="icons/info.svg" width="12" height="12"
                            class="mt-1 ml-1"
                            matTooltip="Updated ratings are not committed to your skill profile until final endorsement is done.">
                          </app-svg-icon>
                        </p>
                      }
                      @if (activity.delta?.length) {
                        <mat-icon class="expand-icon ml-2 cursor-pointer"
                          (click)="toggleDeltaVisibility(activity)" [class.rotated]="activity['showDelta']">
                          expand_more
                        </mat-icon>
                      }
                    </p>
                    @if (activity.delta?.length && activity['showDelta']) {
                      <div class="delta-accordion" [@expandCollapse]>
                        <div class="delta-content mb-4">
                          @for (item of activity.delta; track item) {
                            <div class="delta-item">
                              @if (ratingKeyMap[item.key]) {
                                <span class="delta-label">{{ ratingKeyMap[item.key] }}:</span>
                                <span class="star mx-2">★</span>
                                <span class="delta-value">
                                  {{ item.fromValue || 0 }}
                                  @if (item.toValue !== item.fromValue) {
                                    → {{ item.toValue || 0 }}
                                  }
                                </span>
                              }
                            </div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </div>
              }
              <div>
              </div>
              @if (group.activities.length > 1 && !group.showAll && j === 1) {
                <div class="show-more">
                  <button mat-button (click)="toggleGroupVisibility(i)">
                    {{ 'Show ' + (group.activities.length - 1) + ' more ' + (group.activities.length - 1 === 1 ? 'activity' : 'activities') }}
                  </button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
    @if (getAllGroupedActivities().length > (currentPage * itemsPerPage)) {
      <div class="pagination">
        <button mat-button (click)="loadMore()">Show More</button>
      </div>
    }
  }
</div>
