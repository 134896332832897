import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-editable-table',
    templateUrl: './editable-table.component.html',
    styleUrls: ['./editable-table.component.scss'],
    standalone: false
})
export class EditableTableComponent implements OnInit, OnDestroy {
  editForm: FormGroup;
  num_controls = 0;
  tableColumns: any[] = [];
  @Input() addButtonText: string;
  @Input() blockNegativeInputs: boolean = false;
  @Input() addButtonPosition: string = 'bottom';
  @Input() enableDelete = false;
  @Input() title: string;
  @Input() footNotes: string;
  @Input() enableDrag = false;
  @Input() saveButtonText: string;
  @Input() canEdit = false;
  @Input() generateIdFromIndex = '';
  @Input() conditionalValidators: any = {};
  @Input() maxRows = 0;
  @Input() endorseLevelSettings = false;
  @Output() onSave = new EventEmitter();
  @Output() onAddRow = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Output() onDrop = new EventEmitter();
  @Output() iconClicked = new EventEmitter();
  @Output() formValidityChange = new EventEmitter<boolean>();
  @ViewChildren('autoComplete') autoCompletes: QueryList<any>;
  private destroy$ = new Subject<void>();
  private _columns: any[] = [];
  private _dataSource: any[] = [];

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.editForm) this.editForm = this.fb.group({});
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  @Input()
  set columns(cols: any[]) {
    this._columns = [...cols];
    if (this.enableDelete && this.canEdit) this._columns.push({ key: 'delete', type: 'delete' });
    this.tableColumns = this._columns.map((col) => { return col.key });
  }
  get columns() {
    return this._columns;
  }
  @Input()
  set dataSource(data: any[]) {
    this._dataSource = data || [];

    this.createControls();
  }
  get dataSource() {
    return this._dataSource;
  }
  createControls() {
    this.editForm = this.fb.group({}); 
  
    for (let i = 0; i < this._dataSource.length; i++) {
      if (!this._dataSource[i].tblId) {
        this._dataSource[i].tblId = Math.round(Math.random() * 100000000);
      }
  
      this._columns.map((col) => {
        const controlKey = col.key + '_' + this._dataSource[i].tblId;
        
        if (!this.editForm.get(controlKey) && col.key !== 'delete') {
          const control = new FormControl(
            { value: this._dataSource[i][col.key], disabled: col.disabled || !this.canEdit || this.dataSource[i]['disable' + col.key] }, 
            col.validators
          );
  
          control.statusChanges.subscribe(() => {
            this.emitFormValidity(); 
          });
  
          this.editForm.addControl(controlKey, control);
          this.checkIfInputDisabled(col, this._dataSource[i]);
        }
      });
  
      if (this.endorseLevelSettings) {
        const customApproverControlKey = 'customApprover_' + this._dataSource[i].tblId;
        const approvalManager = this.dataSource[i].approvalManager;
  
        if (approvalManager === 'Custom Approvers' && this.editForm.controls[customApproverControlKey]) {
          this.editForm.controls[customApproverControlKey].setValidators([Validators.required]);
          this.editForm.controls[customApproverControlKey].enable();
        } else {
          this.editForm.controls[customApproverControlKey].setValue('');
          this.editForm.controls[customApproverControlKey].setValidators([]);
          this.editForm.controls[customApproverControlKey].disable();
        }
      }
  
      // Apply min and max validation logic to the controls
      this.useMinAndMaxValidation(i);
    }
  
    this.emitFormValidity();
  }

  emitFormValidity() {
    this.formValidityChange.emit(this.editForm.valid); 
  }
    
  getDataFromForm(): any[] {
    let d = this.editForm.value;
    let data = [];
    for (let i = 0; i < this._dataSource.length; i++) {
      let o: any = {};
      let colHash = {};
      this._columns.map((col) => {
        if (col.type == 'auto-complete') {
          let arr = this.autoCompletes.toArray();
          for (let j = 0; j < arr.length; j++) {
            if (arr[j].input.key != col.key || arr[j].data.tblId != this._dataSource[i].tblId) continue;
            let v = arr[j].getValue();

            if (!v && col.validators && col.validators.length) break;
            o[col.key] = v;
          }
        } else if (col.key != 'delete') {
          o[col.key] = this.editForm.controls[col.key + '_' + this._dataSource[i].tblId].value;
        }
        colHash[col.key] = 1;
      });
      for (let field in this._dataSource[i]) if (['delete', 'tblId'].indexOf(field) < 0 && !colHash[field]) o[field] = this._dataSource[i][field];
      if (this._dataSource[i].dataKey) o.dataKey = this._dataSource[i].dataKey;
      data.push(o);
    }
    return data;
  }
  save() {
    if (this.editForm.invalid) {
      return;  
    }
    let data = this.getDataFromForm();
    this.onSave.emit(data);
  }
  
  addRow() {
    if (this.maxRows && this.maxRows <= this.dataSource.length) return;
    this._dataSource = this.getDataFromForm();
    let o = {};
    if (this.generateIdFromIndex)
      o[this.generateIdFromIndex] = this._dataSource.length + 1;
    this._dataSource.push(o);
    this.createControls();
    this._dataSource = [...this._dataSource];
    this.onAddRow.emit(this._dataSource);
  }
  deleteRow(tblId) {
    let deleted: any = {}, deletedIndex: any;
    for (let i = 0; i < this._dataSource.length; i++) {
      if (this._dataSource[i].tblId == tblId) { deleted = this._dataSource[i]; deletedIndex = i, this._dataSource.splice(i--, 1); break; }
    }
    this._columns.map((col) => {
      if (col.key != 'delete') this.editForm.removeControl(col.key + '_' + deleted.tblId);
    })
    this._dataSource = [...this._dataSource]
    this.onDelete.emit({ data: this._dataSource, deleted: deleted, deletedIndex });
  }
  drop(event) {
    moveItemInArray(this._dataSource, event.previousIndex, event.currentIndex);
    this._dataSource = [...this._dataSource];
    this.onDrop.emit(this._dataSource);
  }

  selectionChanged(key, i) {
    const tableId = key.split('_')[1];
    const index = this._dataSource.findIndex(data => data.tblId === Number(tableId))
    if (index >= 0) {
      this._dataSource[index].modifiedByClient = true
    }
  }

  onKeyChange(event: KeyboardEvent, columnType: string) {
    // Prevent negative or exponential inputs. 
    if (event.key === '-' || event.key === 'e' && columnType === 'number') {
      event.preventDefault();
    }
  }

  checkIfInputDisabled(col, element) {
    if ((element[col.key] === null || element[col.key] === undefined) && col.disableIfEmpty) {
      this.editForm.controls[col.key + '_' + element.tblId].disable();
    }
    return false;
  }

  useMinAndMaxValidation(index: number) {
    const minControlName = `min_${this.dataSource[index].tblId}`;
    const maxControlName = `max_${this.dataSource[index].tblId}`;

    const minControl = this.editForm.get(minControlName);
    const maxControl = this.editForm.get(maxControlName);

    if (minControl && maxControl) {
      this.setupMinValidation(minControl, maxControl);
      this.setupMaxValidation(minControl, maxControl);
    }
  }

  selectionChangeEvent(event, key, i) {
    if (this.endorseLevelSettings && i == 0) {
      const tableId = key.split('_')[1];
      if (event.value == 'Custom Approvers' && this.editForm.controls['customApprover_' + tableId]) {
        this.editForm.controls['customApprover_' + tableId].setValidators([Validators.required]);
        this.editForm.controls['customApprover_' + tableId].enable();
      } else {
        this.editForm.controls['customApprover_' + tableId].setValue('');
        this.editForm.controls['customApprover_' + tableId].setValidators([]);
        this.editForm.controls['customApprover_' + tableId].disable();
      }
    } else {
      this.editForm.patchValue({
        [key]: event.selectedOption || event.value,
      })
    }
  }

  onIconClick(tblId, key) {
    this.iconClicked.emit({ data: this.dataSource.filter((item) => item.tblId == tblId)[0], key });
  }

// Private method to handle min validation logic
private setupMinValidation(minControl, maxControl) {
  minControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    )
    .subscribe((minValue) => {
      minValue = +minValue;
      const maxValue = +maxControl.value;

      if (minValue >= 0) {
        maxControl.setValidators([Validators.min(minValue)]); 
      } else {
        maxControl.clearValidators();
      }

      if (minValue === maxValue) {
        minControl.setValidators([Validators.min(0), Validators.max(maxValue)]); 
      } else {
        minControl.setValidators([Validators.min(0)]);
      }

      maxControl.updateValueAndValidity(); 
    });
}

// Private method to handle max validation logic
private setupMaxValidation(minControl, maxControl) {
  maxControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    )
    .subscribe((maxValue) => {
      maxValue = +maxValue;
      const minValue = +minControl.value;

      if (maxValue >= minValue) {
        minControl.setValidators([Validators.min(0), Validators.max(maxValue)]); 
      } else {
        minControl.clearValidators(); 
      }

      // Handle case where min equals max
      if (maxValue === minValue) {
        maxControl.setValidators([Validators.min(minValue)]); 
      } else {
        maxControl.setValidators([Validators.min(minValue + 1)]); 
      }

      minControl.updateValueAndValidity(); 
    });
}

}
