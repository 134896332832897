import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { provideNativeDateAdapter } from '@angular/material/core';
import { DATE_FORMATS } from '../../sms-settings/sms-settings.component';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { take } from 'rxjs/operators';
import { BUTTON_EVENTS, DIALOG_TYPES } from 'src/app/constants';
import { IDialogConfig } from 'src/app/modules/team-skill-approvals/team-skill-approvals-v2/team-skill-approvals.model';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { SearchUserGroupComponent } from '../search-user-group/search-user-group.component';

@Component({
    selector: 'app-create-user-group',
    templateUrl: './create-user-group.component.html',
    styleUrls: ['./create-user-group.component.scss'],
    providers: [
      provideNativeDateAdapter(DATE_FORMATS)
    ],
    imports: [ SharedModule, SearchUserGroupComponent ]
})

export class CreateUserGroupComponent implements OnInit {

  @Input() notificationData: any;
  @Input() config: any;
  @Output() onEvent = new EventEmitter();

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private ds: DataService,
    private weds: WaitErrorDialogsService) { }

  //------------------------------------------------------------------
  // Public Variables
  //------------------------------------------------------------------

  public editForm: FormGroup;
  public totalUsersCnt: number = 0;
  public loadingFilter: boolean = false;
  public searchForm: FormGroup;
  public tagsList: any = [];

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.totalUsersCnt = this.notificationData?.numberOfEmployees ? this.notificationData?.numberOfEmployees : 0;
    this.editForm = this.fb.group({});
    this.searchForm = this.fb.group({
      conditions: this.fb.array([]),
      logicOperator: ['AND']
    });
    this.editForm.addControl('groupName', new FormControl({ value: '', disabled: false }));
    this.editForm.addControl('description', new FormControl({ value: '', disabled: false }));
    if (this.config?.enableEditUserGroup) {
      this.processData(this.notificationData?.filters);
    }
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public onSave(deleteUserGroup?): any {
    let endpoint = '';
    const formVal = this.searchForm.value;
    const queryObj = this.mapGroupFilters(formVal);
    const payloadObj = {
      title: this.editForm.controls['groupName'].value,
      description: this.editForm.controls['description'].value,
      filters: queryObj.filters,
      condition: this.searchForm.controls['logicOperator'].value === 'AND' ? 'and' : 'or',
      userGroupId: this.notificationData.id
    };
    if (deleteUserGroup) {
      return this.confirmArchive(this.notificationData.id);
    } else if (this.config?.enableEditUserGroup) {
      endpoint = API_ENDPOINT.UPDATE_USER_GROUP(this.ds.currentAdminClientId);
    } else {
      endpoint = API_ENDPOINT.CREATE_USER_GROUP(this.ds.currentAdminClientId, this.ds.currentUserData?.userId || this.ds.user.userId);
    }
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    this.ds.saveData(endpoint, { clientId: this.ds.currentAdminClientId, data: payloadObj }).pipe(take(1)).subscribe((response: any) => {
      if (response.ok) {
        this.onEvent.emit({ type: 'REFRESH' });
      } else {
        this.showDialog({ type: DIALOG_TYPES.GENERIC, code: response.error ? response.error.code : 99 });
      }
      this.weds.closeDialog(dialogRef);
    });
  }

  public mapGroupFilters(group: any): any {
    const groupOperator = group.logicOperator === 'AND' ? 'and' : 'or';
    const groupOutput: any = {
      condition: groupOperator,
      filters: group.conditions.map((condition: any) => {
        if (condition.type === 'condition') {
          return this.mapConditionValues(condition);
        } else if (condition.type === 'group') {
          return this.mapGroupFilters(condition);
        }
      }).flat()
    };
    return groupOutput;
  }

  public mapConditionValues(condition: any): any[] {
    const tag = condition.tag.toLowerCase();
    return [
      {
        key: tag,
        value: Array.isArray(condition.searchValue) ? condition.searchValue : [condition.searchValue]
      },
    ];
  }

  public populateForm(queryObject: any): void {
    this.searchForm.patchValue({
      logicOperator: this.notificationData?.condition,
    });
    const conditionsArray = this.searchForm.get('conditions') as FormArray;
    this.populateConditions(conditionsArray, queryObject);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private showDialog(data: any) {
    let dialogRef = this.dialog.open(SharedDialogComponent, {
      panelClass: 'shared-dialog',
    });
    dialogRef.componentInstance.data = data;
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (data.event = 'BTN_CLICK') dialogRef.close();
    })
  }

  private confirmArchive(id: string) {
    const dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    let dialogData: IDialogConfig = {
      title: 'Delete user group?',
      message: `Are you sure you want to delete the user group?`,
      btnTitle: 'Delete',
      btn2Title: 'Cancel',
      message2: 'This action cannot be undone.',
      warnButton: true,
      noGrow: true
    }
    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      ...dialogData
    };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
        const clientId = this.ds.currentAdminClientId;
        this.ds.careerPrismDataDeleteApi(API_ENDPOINT.DELETE_USER_GROUP(clientId, id)).pipe(take(1)).subscribe((response) => {
          if (response.ok) {
            this.onEvent.emit({ type: 'REFRESH' });
          } else {
            this.showDialog({ type: 'generic', code: response.error ? response.error.code : 99 });
          }
          this.weds.closeDialog(dialogRef);
        });
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private processData(filters: any) {
    this.editForm.get('groupName').setValue(this.notificationData.title);
    this.editForm.get('description').setValue(this.notificationData.description);
    this.populateForm(filters);
  }

  private populateConditions(conditionsArray: FormArray, conditions: any[]): void {
    conditions?.forEach((condition: any) => {
      if (condition.type === 'group') {
        const subGroup = this.fb.group({
          type: 'group',
          logicOperator: this.notificationData?.condition,
          conditions: this.fb.array([]),
        });
        this.populateConditions(subGroup.get('conditions') as FormArray, condition.conditions);
        conditionsArray.push(subGroup);
      } else {
        const conditionGroup = this.createConditionGroup(condition);
        conditionsArray.push(conditionGroup);
      }
      this.tagsList.push(condition.key);
    });
  }

  private createConditionGroup(condition: any): FormGroup {
    const conditionGroup: FormGroup = this.fb.group({
      type: 'condition',
      tag: condition.key,
      searchValue: condition.value
    });
    return conditionGroup;
  }
}