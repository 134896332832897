import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { StarRatingComponent } from '../star-rating/star-rating.component';
import { StarRatingsDialogComponent } from '../star-ratings-dialog/star-ratings-dialog.component';
import { ProficiencyComponent } from '../../admin/auto-proficiency/proficiency/proficiency.component';
import { ProficiencySliderOverlayComponent } from '../proficiency-slider-overlay/proficiency-slider-overlay.component';


export interface DialogData {
  value: number;
  key: string;
};

@Component({
    selector: 'app-single-star',
    templateUrl: './single-star.component.html',
    styleUrls: ['./single-star.component.scss'],
    standalone: false
})
export class SingleStarComponent implements OnInit {
  @Input() client: Client;
  @Input() matIconClass: string; //Input for icon classes
  @Input() key: any;
  @Input() color:string = 'var(--primary-color)';
  @Input() skillItem: any;
  @Input() editable: boolean;
  @Input() hideRatingValue = false;
  @Input() showRequiredProficiency: boolean = false;
  @Output() onSetValue = new EventEmitter();

  ratingOpts = {
    ratings: ['overallRating']
  }

  @ViewChild('starButton') starButton: ElementRef;
  dialogRef;

  maxValue = 5;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.client && this.client.smsSettings.skillRating == 'Stars 1-4') this.maxValue = 4;
    if (this.showRequiredProficiency && !this.skillItem.requiredRating) this.showRequiredProficiency = false;
  }

  onStarClick(event) {
    this.skillItem[this.key] = event.data.value;
    this.onSetValue.emit(event.data);
  }

  getButtonClasses() {
    return {
      'editable': this.editable,
      'not-editable': !this.editable,
      [this.matIconClass]: !!this.matIconClass
    };
  }
}
