import { Validators } from "@angular/forms";
import { INPUT_TYPES } from ".";
import { BadgeType, TriggerType } from "../modules/admin/global-gamification-triggers/gamification.model";

export const CREATE_TRIGGER_OPTIONS =  [
  { title: 'Trigger Type Name', key: 'name', placeholder: 'Enter trigger type name', type: INPUT_TYPES.TEXT, icon: 'settings_suggest', validators: [Validators.required], required: true },
  { title: 'Trigger Type Description', key: 'description', placeholder: 'Enter descriptive text', type: INPUT_TYPES.TEXT, icon: 'settings_suggest', validators: [Validators.required], required: true },
  { title: 'Notification Prompt', key: 'notificationPrompt', placeholder: 'Select notification prompt', type: INPUT_TYPES.SELECT, validators: [Validators.required], required: true,
    options: [
      { key: 'immediate', value: 'Immediate' },
      { key: 'delayed', value: 'Delayed' }
    ]
  },
  { title: 'What would you like to assign?', key: 'rewardDefinitionType', type: INPUT_TYPES.CHECKBOX, validators: [Validators.required], required: true,
    options: [
      { key: TriggerType.BADGES, value: 'badges', checked: true  },
      { key: TriggerType.POINTS, value: 'points', checked: false }
    ],
    defaultValue: ['badges']
  },
  { title: 'Badge Type', key: 'type', type: INPUT_TYPES.RADIO, validators: [Validators.required], required: true,
    options: [
      { key: BadgeType.ONE_TIME, value: 'One-time'  },
      { key: BadgeType.REPEATABLE, value: 'Repeatable' },
      { key: BadgeType.LEVELS, value: 'Levels' }
    ],
    defaultValue: BadgeType.LEVELS
  }
];

export const MULTIPLE_BADGE_LABEL = 'Head over to the badges section of your profile to check out all the unlocked badges!';
