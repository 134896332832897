import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { MultiSelectChip } from './multi-select-chip.model';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
    selector: 'app-multi-select-chip',
    templateUrl: './multi-select-chip.component.html',
    styleUrls: ['./multi-select-chip.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: MultiSelectChipComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: MultiSelectChipComponent
        }
    ],
    standalone: false
})
export class MultiSelectChipComponent implements OnInit, ControlValueAccessor, Validator {
	
	@Input() input: MultiSelectChip
	@Output() onEnter: EventEmitter<any> = new EventEmitter();
	
	onChange = (item: Array<any>) => { };
	onTouched = () => { };
	touched = false;
	disabled = false;
	
	chipEditForm: FormGroup;
	selectedItems = [];
	constructor(private fb: FormBuilder, private util: UtilitiesService) { }

	ngOnInit(): void {
		if (this.input.value.length > 0) {
			this.selectedItems.push(...this.input.value);
		}
		this.chipEditForm = this.fb.group({
			chipInput: new FormControl({ value: '', disabled: this.input.disabled }, this.input.validators || [])
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.input && !changes.input.firstChange && this.input.value?.length) {
			this.selectedItems.push(...this.input.value);
		}
	}

	onAdd(event: any): void {
		if(this.chipEditForm.invalid) {
			this.chipEditForm.markAllAsTouched();
			return;
		}
		this.markAsTouched();
		if (!this.disabled) {
			const value = (event.target.value || '').trim();
			if (value) {
				this.util.pushUniqueValue(this.selectedItems, value);
				this.onChange(this.selectedItems);
			}
		}
	}

	onRemove(item: any): void {
		this.markAsTouched();
		if (!this.disabled) {
			this.selectedItems = this.selectedItems.filter((value) => {
				return item !== value;
			});
			this.onChange(this.selectedItems);
		}
	}

	onBlur() {
		if(!this.chipEditForm.controls['chipInput'].value) {
			this.chipEditForm.controls['chipInput'].setErrors(null);
		}
	}

	writeValue(item: any) {
		if (!item) return
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
	}

	validate(control: AbstractControl<any, any>): ValidationErrors | null {
		if(this.isFormEmpty()){
			return { required: true };
		}
		this.chipEditForm.reset();
		return this.chipEditForm.errors;
	}

	isFormEmpty(): boolean {
		if(this.chipEditForm.controls['chipInput'].hasValidator(Validators.required) 
			&& this.selectedItems.length <= 0){
				this.chipEditForm.markAllAsTouched();
				return true;
		}
		return false;
	}
}
