import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS } from 'src/app/constants';

@Injectable({
    providedIn: 'root'
})
export class SkillUtilitiesService {
    constructor(private ds: DataService) {}

    get checkIsHighDemandSkillTaggingEnabled(): boolean {
        return this.ds.client.smsSettings?.useHighDemandSkillTagging === SMS_OPTIONS.YES;
    }

    isPrimarySkillTypeEnabledForSelectedCategory(tab: any): boolean {
        const treatmentMap = JSON.parse(localStorage.getItem("treatmentMap"));
        const allowedTreatments = ['C', 'T1'];
        const selectedCategory = this.ds.client.smsSettings.categoryWiseSettings.find(
          category => category.categoryId === tab.categoryId
        );
    
        if (!selectedCategory) return false; // In case selectedCategory is undefined
    
        const hasNonEmptyMinMax = selectedCategory.skillTypes.some(
          skillType => skillType.min || skillType.max
        );
    
        return (
          selectedCategory.enablePrimarySkill === SMS_OPTIONS.YES &&
          allowedTreatments.includes(treatmentMap.getMySkills) &&
          hasNonEmptyMinMax
        );
    }
}
