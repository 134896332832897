<div class="hrms-masters">
  <div class="h3 black ">HRMS Masters</div>
  <main class="d-flex flex-column gap-3 mt-4">
    <section class="d-flex flex-column gap-3">
      <div class="d-flex flex-column gap-3">

        <!-- HRMS Fields available to select in HRMS Master -->
        <mat-form-field class="admin-input-con-full font-weight-normal" appearance="outline">
          <mat-label>Choose from available HRMS fields</mat-label>
          <mat-select multiple [formControl]="hrmsMasterAvailableFieldsFormControl"
            (selectionChange)="hrmsFieldsChosenForMasterChanged($event)">
            @for (field of hrmsMasterFields; track field) {
              <mat-option [value]="field.field">
                {{field.field}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        <!-- Selected HRMS Fields in HRMS Master -->
        <form [formGroup]="hrmsFieldsChosenForMasterFormGroup" (ngSubmit)="saveHrmsMasters()">
          <div class="d-flex flex-column hrms-tags border-radius-4">
            <div
              class="d-flex hrms-tag flex-row align-items-center col-gap-2 pt-2 pr-4 pb-2 pl-4 h4 black font-weight-bold">
              <span class="flex-grow-1">Tag</span>
              <div class="d-flex flex-row align-items-center col-gap-3">
                <span class="w-200">Mandatory/Optional</span>
                <span class="w-200">Input Type</span>
                <span class="w-200 pr-5">Additional Fields</span>
                <mat-icon class="white">clear</mat-icon>
              </div>
            </div>
            @for (field of hrmsFieldsChosenForMaster; track field; let i = $index) {
              <div class="d-flex hrms-tag flex-row align-items-center col-gap-3 pt-1 pr-4 pb-1 pl-4"
                >
                <span class="flex-grow-1">{{ field.field }}</span>
                <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>Choose option</mat-label>
                    <mat-select [formControlName]="field.dataKey + 'isMandatory'">
                      @for (option of requiredOptions; track option) {
                        <mat-option [value]="option">{{option}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </span>
                <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>Choose option</mat-label>
                    <mat-select [formControlName]="field.dataKey + 'isMultiSelect'">
                      @for (option of inputTypeOptions; track option) {
                        <mat-option [value]="option">{{option}}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </span>
                <span class="d-flex flex-row align-items-center col-gap-3 w-200 pr-5">
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>Choose options</mat-label>
                    <mat-select [formControlName]="field.dataKey + 'additionalFields'" multiple>
                      <!-- Preconfigured Additional fields -->
                      @for (addtionalField of preconfiguredAddtionalField; track addtionalField) {
                        <mat-option [value]="addtionalField">{{addtionalField}}
                        </mat-option>
                      }
                      <!-- Additional fields coming from BE -->
                      @for (addtionalField of field.addtionalFields; track addtionalField) {
                        <mat-option [value]="addtionalField">{{addtionalField}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </span>
                @if (canEdit) {
                  <mat-icon (click)="removeFieldFromHrmsMasters(field.dataKey)" class="opacity-2 opacity-hover-7 cursor-pointer">clear</mat-icon>
                }
              </div>
            }
          </div>
        </form>
      </div>
      @if (canEdit) {
        <div class="admin-save-button-con">
          <button type="submit" (click)="saveHrmsMasters()" mat-stroked-button class="primary-button">Save</button>
        </div>
      }
    </section>
    <!-- <section class="d-flex flex-column gap-3 admin-input-con-half">
    <app-drag-and-drop [input]="DataOrderComponentConfig"></app-drag-and-drop>
    <div class="admin-save-button-con">
      <button type="submit" (click)="saveHrmsMasters()" mat-stroked-button class="primary-button">Save</button>
    </div>
  </section> -->
</main>
</div>