import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from '../constants';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';
import { map, take, tap } from 'rxjs/operators';
import { WaitErrorDialogsService } from './wait-error-dialogs.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { StaffingRoleModalComponent } from '../modules/base/staffing-role-modal/staffing-role-modal.component';

const STAFFING_ROLE = 'staffingRole';
const STAFFING_ROLE_MODAL_SHOWN = 'isStaffingRoleModalShown';
const TREATMENT_MAP_GET_MY_SKILLS_V3 = 'T2';

@Injectable({
  providedIn: 'root'
})
export class StaffingRoleService {

  public uploadedFileLoc: string = null;
  public selectedUserCustomTags = this.ds.client?.smsSettings?.selectedUserCustomTags || [];

  constructor(
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private dialog: MatDialog,
  ) { }

  public showStaffingRoleModal() {
    if(!this.selectedUserCustomTags?.length || JSON.parse(localStorage.getItem('isStaffingRoleModalShown') || 'false')) return;
    this.fetchUserCustomTags().pipe(take(1)).subscribe((customTags) => {
      const staffingRoles = customTags?.find(tag => tag.attributeType === STAFFING_ROLE)?.values ?? [];

      if (!staffingRoles?.length || staffingRoles?.length > this.ds?.client?.smsSettings?.maximumStaffingRoles) {
        this.openStaffingRoleModal();
        if (this.ds?.client?.smsSettings?.isStaffingRoleMandatory !== SMS_OPTIONS.YES) localStorage.setItem(STAFFING_ROLE_MODAL_SHOWN, 'true');
      }
    });
  }

  public extractStaffingRolesFromResume() {
    const treatmentMap = JSON.parse(localStorage.getItem("treatmentMap"));
    const isExtractStaffingRoleWithAI = this.ds.client?.smsSettings?.extractStaffingRoleWithAI === SMS_OPTIONS.YES;

    if( treatmentMap?.getMySkills !== TREATMENT_MAP_GET_MY_SKILLS_V3 || !isExtractStaffingRoleWithAI || !this.uploadedFileLoc) {
      return;
    }
    const payload = {
      clientId: this.ds.client?.clientId,
      userId: this.currentUserId,
      loc: this.uploadedFileLoc,
    }
    this.ds.saveData(API_ENDPOINT.EXTRACT_STAFFING_ROLES_FROM_RESUME, payload).pipe(take(1)).subscribe();
    this.uploadedFileLoc = null;
  }

  public fetchUserCustomTags(showDialog: boolean = true): Observable<any[]> {
    const loader = showDialog ? this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true }) : null;
    const payload = {
      userId: this.currentUserId,
      tags: []
    };

    return this.ds.saveData(API_ENDPOINT.GET_USER_CUSTOM_TAGS, payload).pipe(
      take(1),
      tap(() => { if(showDialog) this.weds.closeDialog(loader) }),
      map((rs: any) => {
        if(rs.status === RESPONSE_STATUS.SUCCESS) {
          return rs.data?.map(tagData => ({
            attributeType: tagData?.attributeType,
            isMandatory: tagData?.isMandatory,
            isMultiSelect: tagData?.isMultiSelect,
            values: tagData?.values?.map(tag => ({
              ...tag,
              _id: tag.id
            })) ?? []
          })) ?? [];
        } else {
          if(showDialog) this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 99 });
        }
      })
    );
  }

  public getUserStaffingRolesAndPreferences(showDialog: boolean = true): Observable<{ staffingRole: any[], otherPreferences: any[] }> {
    return this.fetchUserCustomTags(showDialog).pipe(
      map((customTags: any[]) => {
        const { staffingRole, otherPreferences } = (customTags || [])?.reduce((acc, tag) => {
            if (tag.attributeType === 'staffingRole') {
              acc.staffingRole = tag.values ?? [];
            } else {
              acc.otherPreferences.push(tag);
            }
            return acc;
          },
          { staffingRole: [], otherPreferences: [] }
        );

        return { staffingRole, otherPreferences };
      })
    );
  }

  public get isStaffingRoleEnabled(): boolean {
    const { selectedUserCustomTags = [], fieldsForCustomTags = [] } = this.ds.client?.smsSettings || {};

    const isStaffingRoleInSelectedUserCustomTags = selectedUserCustomTags?.includes('staffingRole');
    const isStaffingRoleInFieldsForCustomTags = fieldsForCustomTags?.some(field => field?.dataKey === 'staffingRole');

    return isStaffingRoleInSelectedUserCustomTags && isStaffingRoleInFieldsForCustomTags;
  }

  public get currentUserId(): string {
    return this.ds.currentUserData?.userId ?? this.ds.user?.userId;
  }

  public get selectedPreferences() {
    return this.selectedUserCustomTags?.filter(tag => tag !== "staffingRole") || [];
  }

  private openStaffingRoleModal() {
    const dialogWidth = window.innerWidth < 900 ? '90vw' : '600px';
    const dialogHeight = window.innerWidth < 900 ? '90vh' : '640px';

    let dialogRef = this.dialog.open(StaffingRoleModalComponent, { width: dialogWidth, height: dialogHeight, panelClass: 'profile-modal', disableClose: true });
    dialogRef.componentInstance.data = {
      selectedStaffingRole: [],
      dontCloseProgrammatically: true,
    }
    const onEvent = dialogRef.componentInstance.onEvent.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe(() => { onEvent.unsubscribe(); });
  }

}
