import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { GoalService } from 'src/app/services/goal.service';
import { take } from 'rxjs/operators';

import { DataService } from 'src/app/services/data.service';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { GoalStatus } from '../goals-track-list/goals-track-list.component';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { AccomplishmentType } from 'src/app/modules/admin/admin-goal-management/components/add-goal/add-goal.component';
import { ISkillDetailsResponse, IMasterCourseDataResponse, ICertificationMasterData, ICertification, ICourse, IMasterCourseData } from 'src/app/modules/admin/admin-goal-management/components/add-goal';
import { IGoal } from 'src/app/modules/admin/admin-goal-management/components/goals';

export interface IGoalEntityTypeRole {
  title: string;
  mandatorySkills: ISkill[];
  optionalSkills: ISkill[];
  totalCurrentRating: number;
  totalExpectedRating: number;
}

export interface ISkill {
  skillName: string;
  currentRating: number;
  expectedRating: number;
}

@Component({
    selector: 'app-goal-entity',
    templateUrl: './goal-entity.component.html',
    styleUrls: ['./goal-entity.component.scss'],
    standalone: false
})
export class GoalEntityComponent implements OnInit, OnChanges {

  @Input() public goal: IGoal;

  public courseData: Partial<ICourse>;
  public certificationData: Partial<ICertification>;
  public skills = ['Angular', 'Node.js', 'Python', 'Java', 'C#'];
  public displayedColumns: string[] = ['skill', 'current', 'expected'];
  public goalStatusColors: { [key in GoalStatus]: string } = this._goalService.goalStatusColors;
  public goalStatusBGColors: { [key in GoalStatus]: string } = this._goalService.goalStatusBGColors;

  public dataSourceMandatory: MatTableDataSource<any>;
  public dataSourceOptional: MatTableDataSource<any>;
  public dataSourceCondtionalMandatory: MatTableDataSource<any>;
  clickedRows = new Set();

  public masterGoalData: any = null;
  public dynamicBadgeText: string = 'Any 1 skill';

  private readonly MANDATORY = 'mandatory';
  private readonly OPTIONAL = 'optional';

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(
    public _goalService: GoalService,
    private ds: DataService,
    private weds: WaitErrorDialogsService
  ) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.goal && this.goal) {
      this.getExtraDetails(this.goal.typeIdentifier, this.goal.type.toLowerCase() as AccomplishmentType);
    }
  }

  ngOnInit(): void {
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public getColorForGoalStatus(goalStatus: string) {
    return this.goalStatusColors[goalStatus];
  }

  public getBGColorForGoalStatus(goalStatus: string) {
    return this.goalStatusBGColors[goalStatus];
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private getExtraDetails(id: string, type: AccomplishmentType) {
    // const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const client = this.ds.currentAdminClientId;
    const requestPayload = {
      clientId: client,
      type: type,
      id: id,
    };

    this.ds.careerPrismDataPostApi(API_ENDPOINT.GET_EXTRA_DETAILS_MYGOAL, requestPayload).pipe(take(1)).subscribe((res: ISkillDetailsResponse | IMasterCourseDataResponse | ICertificationMasterData | any) => {
        // this.weds.closeDialog(dialogRef);
        this.masterGoalData = { ...res?.data?.masterGoalData ?? {} };

      switch (type) {
        case AccomplishmentType.Course:
          this.courseData = this.mapMasterGoalDataToCourse(this.masterGoalData);
          break;
        case AccomplishmentType.Skill:
          break;
        case AccomplishmentType.Certification:
          this.certificationData = this.mapMasterGoalDataToCertification(this.masterGoalData);
          break;
        case AccomplishmentType.Specialization:
        case AccomplishmentType.Role:
          this.handleDetailResponseForRoleAndSpecialization();
          break;
      }
    });
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCourse(masterGoalData: IMasterCourseData): Partial<ICourse> {
    return {
      _id: masterGoalData.courseId,
      clientId: this.ds.currentAdminClientId,
      description: masterGoalData.courseDescription,
      pk: masterGoalData.courseId,
      skills: masterGoalData.skillItemIds,
      status: masterGoalData.status?.toUpperCase(),
      title: masterGoalData.courseName,
      type: AccomplishmentType.Course?.toUpperCase(),
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.courseId,
    };
  }

  // TODO: Move to service after all merged
  private mapMasterGoalDataToCertification(masterGoalData: ICertificationMasterData): Partial<ICertification> {
    return {
      _id: masterGoalData.certificationId,
      clientId: this.ds.currentAdminClientId,
      createdAt: masterGoalData.createddt,
      description: masterGoalData.data.certificationDescription,
      originCreator: masterGoalData.data.certificationIssuedBy,
      pk: masterGoalData.certificationId,
      status: masterGoalData.data.status?.toUpperCase(),
      title: masterGoalData.data.certificationName,
      type: AccomplishmentType.Certification?.toUpperCase(),
      updatedAt: masterGoalData.modifieddt,
      enabled: masterGoalData.enabled,
      learningElementPk: masterGoalData.certificationId,
    };
  }

  private handleDetailResponseForRoleAndSpecialization() {
    const mappedData = this.tranformDataForTable();
    this.dataSourceMandatory = new MatTableDataSource(mappedData.mandatory);
    this.dataSourceOptional = new MatTableDataSource(mappedData.optional);
    this.dataSourceCondtionalMandatory = new MatTableDataSource(mappedData.mandatoryoptional);
  }

  private tranformDataForTable() {
    const mappedData = {
      title: this.masterGoalData?.name,
      specializationId: this.masterGoalData?.specializationId,
      mandatory: [],
      optional: [],
      mandatoryoptional: [],
    };

    this.masterGoalData?.skillItems?.forEach(item => {
      const mappedItem = {
        skillName: item.skillName,
        expectedRating: item.exitRating,
        currentRating: 'NA'
      };

      if (item.category.toLowerCase() === this.MANDATORY) {
        mappedData.mandatory.push(mappedItem);
      } else if (item.category?.toLowerCase() === this.OPTIONAL) {
        mappedData.optional.push(mappedItem);
      } else {
        mappedData.mandatoryoptional.push(mappedItem);
      }
    });
    return mappedData;
  }

}
