<div class="projects-settings-con">
  <div class="h3 black ">Profile & Resume Builder Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div
          [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'">
          @if (smsOption.type=='title') {
            <div class="h3 black " style="margin:10px 0;">
            {{smsOption.title}}</div>
          }
          @if (smsOption.type=='sub-title') {
            <div class="h4 black" style="margin:10px 0;">
            {{smsOption.title}}</div>
          }
          @if (smsOption.type=='text') {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                  [required]="smsOption.required" autocomplete="off"
                  maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                @if (editForm.get(smsOption.key).invalid) {
                  <mat-error>
                    {{smsOption.errorText||'This input is mandatory'}}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type=='select') {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                @if (smsOption.icon) {
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                }
                <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required" [multiple]='smsOption.isMulti'>
                  @for (option of smsOption.options; track option) {
                    <mat-option
                      [value]="option?.key || option">
                      {{option?.value || option}}
                    </mat-option>
                  }
                </mat-select>
                @if (editForm.get(smsOption?.key)?.invalid) {
                  <mat-error>
                    {{smsOption.errorText||'This input is mandatory'}}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
</div>
