import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { DIALOG_TYPES, RESPONSE_STATUS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-upload-widget',
  templateUrl: './upload-widget.component.html',
  styleUrls: ['./upload-widget.component.scss'],
  standalone: false
})
export class UploadWidgetComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() allowedTypes: string[] = [];
  @Input() maxFileSizeInMB: number = 0;
  @Input() supportedFileFormatsForUpload: string = '';
  @Input() S3PreSignedUrl: string = '';
  @Output() uploadedFileData = new EventEmitter();

  public isDragging = false;
  public isUploading = false;
  public file: File | null = null;

  constructor(
    private utilities: UtilitiesService,
    private weds: WaitErrorDialogsService,
    private ds: DataService
  ) { }

  public onFileDropped(files: NgxFileDropEntry[]): void {
    this.isDragging = false;
    const droppedFile = files[0];
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.file = file;
        this.uploadFile(file);
      });
    }
  }

  public triggerFileInput(): void {
    this.fileInput.nativeElement?.click();
  }

  public onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.file = input.files[0];
      this.uploadFile(this.file);
    }
  }

  public onDragOver(): void {
    this.isDragging = true;
  }

  public onDragLeave(): void {
    this.isDragging = false;
  }

  public cancelUpload(event: any): void {
    event.stopPropagation();
    this.isUploading = false;
    this.file = null;
    this.uploadedFileData.emit(null);
  }

  public removeFile(event: any): void {
    event.stopPropagation();
    this.file = null;
    this.uploadedFileData.emit(null);
  }

  public changeFile(event: any): void {
    event.stopPropagation();
    this.file = null;
    this.triggerFileInput();
  }

  private uploadFile(file: File): void {
    this.isUploading = true;
    if(this.maxFileSizeInMB && this.utilities.isFileSizeExceedingMaxLimit([file], this.maxFileSizeInMB)){
      this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 481 ,dontCloseAllDialogs: true, fileSize : this.maxFileSizeInMB});
      this.isUploading = false;
      this.file = null;
      this.fileInput.nativeElement.value = '';
      return;
    }

    if(this.S3PreSignedUrl){
       const uploadSubscription: any = this.ds.putFileToS3PreSignedUrl(this.S3PreSignedUrl, file[0]).subscribe((res: any) => {
        //  Emit the response on success from the API.
            this.uploadedFileData.emit();
       });
     return;
    }

    const uploadSubscription: any = this.ds.uploadFiles([file], this.ds.client.clientId, this.allowedTypes, this.maxFileSizeInMB).subscribe((res: any) => {
        if (res.status === 200 && res.body) {
        res = res.body;
        this.isUploading = false;
        if (res.status === RESPONSE_STATUS.SUCCESS) {
          uploadSubscription.unsubscribe();
          this.uploadedFileData.emit(res.files[0]);
        } else {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: res.error ? res.error.code : 99 });
        }
      }else if (res.type !== 0 && !res.type && res.status != 200) {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: res.error ? res.error.code : 99 });
      }
    }, (err) => {
      uploadSubscription.unsubscribe();
      this.isUploading = false;
    });
}


}
