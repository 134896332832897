<div>
  <!-- Badge Image -->
  <div class="badge-img position-relative d-flex justify-content-center align-items-center"
    [ngClass]="{'badge-locked': isLocked}" id="badgeImage">
    @if (isImmediate) {
      <span class="badge-img-con-header position-absolute h6 fw-500">Badges Unlocked!</span>
    }
    @if (!multipleBadgesCount) {
      <div class="d-flex flex-column align-items-center">
        <div class="badge-img-con rounded-circle position-relative">
          <img class="object-cover rounded-circle h-100 w-100" [src]="getBadgeImageUrl()">
        </div>
        @if (!isLocked && (data.badgeType === eBadgeType.MULTI_LEVEL || data.isRepeatable)) {
          <div
            class="badge-img-con-info position-absolute h5 fw-500 d-flex justify-content-center elevation-3">
            @if (data.badgeType === eBadgeType.MULTI_LEVEL) {
              <span>Level {{data.currentUserBadgeInfo?.level}}</span>
            }
            @if (data.isRepeatable) {
              <span>x{{data.currentUserBadgeInfo?.badgeCount}}</span>
            }
          </div>
        }
      </div>
    }
    @if (multipleBadgesCount) {
      <div class="d-flex flex-column align-items-center">
        <div class="badge-img-multiple d-flex align-items-center">
          @for (image of [1,2,3]; track image; let i = $index) {
            <div class="badge-img-con rounded-circle">
              <img class="object-cover rounded-circle h-100 w-100" [src]="'assets/badges/badge'+ (i+1) +'.png'">
            </div>
          }
        </div>
      </div>
    }
    <div (click)="close()"
      class="badge-img-con-close position-absolute t-3 r-3 rounded-circle w-32px h-32px item-center cursor-pointer">
      <mat-icon class="gray w-16px h-16px ic-16">close</mat-icon>
    </div>
  </div>

  <!-- Badge Name & Description -->
  <div class="px-20 pt-3" [ngClass]="{'mb-32' : isLocked}">
    <div class="d-flex flex-column gap-2 align-items-center mb-12">
      <span
      class="h4">{{multipleBadgesCount ? 'You unlocked '+multipleBadgesCount+' badges!' : data.currentUserBadgeInfo?.name}}</span>
      <span
      class="caption gray text-center">{{multipleBadgesCount ? multipleBadgeLabel : data.currentUserBadgeInfo?.description}}</span>
    </div>

    <!-- Badge List -->
    @if (showBadgeInfo()) {
      @if (!showBadgeList) {
        <ng-container *ngTemplateOutlet="badgeDescription; context: { type: this.data.badgeType }"></ng-container>
      }
      @if (showBadgeList) {
        <ng-container *ngTemplateOutlet="badgeList; context: { type: this.data.badgeType }"></ng-container>
      }
    }
  </div>

  <!-- Badge Footer -->
  @if (!isLocked) {
    <div class="badge-footer d-flex justify-content-between p-20 gap-3 elevation-1">
      @if (isImmediate) {
        <button (click)="navigateToBadges()" mat-stroked-button
        class="badge-footer-btn secondary-button">See All Badges</button>
      }
      <button (click)="close()" mat-stroked-button class="badge-footer-btn primary-button flex-1">Okay</button>
    </div>
  }
</div>

<ng-template #badgeDescription let-type="type">
  @if (type === eBadgeType.MULTI_LEVEL) {
    @if (data.badges.length > data.currentUserBadgeInfo?.level) {
      <div
        class="badge-info mt-12 mb-20 py-12 px-3 d-flex flex-column gap-2 elevation-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-1">
            <mat-icon class="w-16px h-16px ic-16 gray">lock</mat-icon>
            <span class="label">Level {{nextLevel?.level}}: {{nextLevel?.badgeLevelName}}</span>
          </div>
          <span class="caption gray">{{nextLevel?.badgeCount}}/{{nextLevel?.maxCount}} skills</span>
        </div>
        <mat-progress-bar mode="determinate" [value]="getProgressBarValue(nextLevel)"></mat-progress-bar>
      </div>
    }
  }
  @if (type === eBadgeType.ONE_TIME && data.badges[0]?.value) {
    <div class="badge-info mt-12 mb-20 py-12 px-3 d-flex flex-column gap-1 align-items-center elevation-3">
      <span class="h6 fw-500 text-uppercase">Awarded For:</span>
      <span class="h5">{{data.badges[0]?.value}}</span>
    </div>
  }
</ng-template>

<ng-template #badgeList let-type="type">
  <div class="badge-list mt-12 pt-12 d-flex flex-column gap-1">
    @for (badge of data.badges; track badge; let i = $index) {
      <div>
        <div class="d-flex gap-2">
          @if (data.badgeType === eBadgeType.MULTI_LEVEL) {
            <mat-icon class="w-16px h-16px ic-16 gray">
            {{badge?.locked ? 'lock' : 'emoji_events'}}</mat-icon>
          }
          @if (data.badgeType !== eBadgeType.MULTI_LEVEL) {
            <div
              class="badge-list-repeatable h-16px border-box py-2px px-1 fs-12 border-radius-20 item-center">
              <span>x1</span></div>
            }
            <span class="label h-20px">{{getBadgeLabel(badge)}}</span>
          </div>
          <div class="d-flex gap-2">
            <div class="w-16px h-24px d-flex justify-content-center">
              <div class="badge-list-line w-4px border-radius-8 h-100"
              [ngClass]="{invisible : i == data.badges.length - 1}"></div>
            </div>
            @if (!badge?.locked && badge?.assignDate !== undefined) {
              <span class="gray caption">on
              {{badge?.assignDate | date:'dd MMM yyyy'}}</span>
            }
            @if (badge?.locked && badge?.badgeCount !== undefined) {
              <div class="w-70 d-flex gap-1 align-items-center">
                <mat-progress-bar mode="determinate" [value]="getProgressBarValue(badge)"></mat-progress-bar>
                <span class="caption gray">{{badge?.badgeCount}}/{{badge?.maxCount}}</span>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </ng-template>
