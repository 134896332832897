import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss'],
  imports: [
    MatIconModule,
    CommonModule
  ]
})
export class ProfilePicComponent implements OnInit {
  @Input() user: any;
  @Input() canEdit = false;
  @Input() fontSize;
  @Input() fontColor: string;
  @Output() onProfileClick = new EventEmitter();
  @Output() openFileInput = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  onClick() {
    this.onProfileClick.emit();
  }
  onFileClick() {
    this.openFileInput.emit();
  }
}
