import { Component, input } from '@angular/core';
import { INoData } from '../../shared.model';

@Component({
  selector: 'app-no-data-box',
  templateUrl: './no-data-box.component.html',
  styleUrls: ['./no-data-box.component.scss']
})
export class NoDataBoxComponent {
  data = input<INoData>(null);
}
