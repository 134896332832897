<div>
  <div class="h3 black ">Skill Accuracy Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
      @if (smsOption.order === 1) {
      <div class="h4 black admin-input-con-full my-10">{{smsOption.subGroup}} Skill Accuracy Settings</div>
      }
      <div [ngClass]="smsOption.type ==='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
        [ngStyle]="{'display': smsOption.showControl?'block':'none'}">
        @if (smsOption.type === 'textarea' && smsOption.showControl) {
        <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
          <mat-label>{{smsOption.title}}</mat-label>
          <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
          <textarea #textAreaRef matInput (input)="updateCharacterCount()" [placeholder]="'Enter ' + smsOption.title"
            formControlName="{{smsOption.key}}" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
          <div class="character-counter">{{ characterCount }}/{{maxCharacterCount}}</div>
        </mat-form-field>
        }
        @if (smsOption.type === 'select' && smsOption.showControl) {
        <div [matTooltip]="smsOption?.tooltip">
          <mat-form-field appearance="outline" class="admin-input-form-field">
            <mat-label>{{smsOption.title}}</mat-label>
            @if (smsOption.icon) {
            <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
            }
            <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
              (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
              [compareWith]="compareSelect">
              @for (option of smsOption.options; track option) {
              <mat-option
                [value]="option.skillTagId || option.skillTypeId || option.value ? option.skillTagId || option.skillTypeId || option.value: option">
                {{smsOption.displayKey ? option[smsOption.displayKey] : option}}
              </mat-option>
              }
            </mat-select>
            @if (editForm.get(smsOption.key).invalid) {
            <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
            }
          </mat-form-field>
        </div>
        }
      </div>
      }
    </div>
    @if (canEdit) {
    <div class="admin-save-button-con">
      <button type="submit" mat-stroked-button class="primary-button">Save</button>
    </div>
    }
  </form>
</div>
