import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-goal-actions',
    templateUrl: './goal-actions.component.html',
    styleUrls: ['./goal-actions.component.scss'],
    standalone: false
})
export class GoalActionsComponent {

  @Input() goalActions: Array<{
    icon: string;
    name: string;
  }>

  @Output() onGoalAction = new EventEmitter();

  public onGoalActionClick(action: any) {
    this.onGoalAction.emit(action);
  }

}
