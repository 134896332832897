import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS } from 'src/app/constants';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { cloneDeep } from 'lodash';
import { TREATMENT_MAP } from 'src/app/constants/treatmentmap.constants';
@Injectable({
  providedIn: 'root'
})

export class GlobalSkillTypeValidationService {

  public deletedSkills: any[] = [];

  constructor(
    private ds: DataService,
    private utils: UtilitiesService
  ) { }

  private additionalGlobalSkillTypeDetails = [
    { icon: 'stars', label: 'Skills integral to my work.' },
    { icon: 'group_work', label: 'Supporting skills I actively use at work.' },
    { icon: 'all_out', label: 'Skills I have but don’t actively use at work.' }
  ];

  public isGlobalSkillTypeEnabled(): boolean {
    return this.utils.getTreatmentMap(TREATMENT_MAP.GET_MY_SKILLS) === TREATMENT_MAP.V3 && this.ds.client?.smsSettings?.enableGlobalSkillType === SMS_OPTIONS.YES;
  }

  public get globalSkillTypes() {
    return this.ds.client?.smsSettings?.globalSkillTypes;
  }

  public transformGlobalSkillTypePayload(data) {
    data.forEach(skillType => {
      skillType.name = skillType.name.trim();
      skillType.min = Number(skillType.min);
      skillType.max = Number(skillType.max);
    });
    return data;
  }

  public setGlobalSkillTypes(globalSkillTypes) {
    globalSkillTypes.forEach(skillType => {
      skillType.skills.forEach(skill => {
        skill.skillTypeId = skillType.skillTypeId;
      });
    });
    return globalSkillTypes;
  }

  public validateSkills(globalSkillTypes) {
    this.setGlobalSkillTypes(globalSkillTypes);
    globalSkillTypes.forEach(skillType => {
      // Reset errors
      skillType.error = null;

      if (skillType.min && skillType.skills.length < skillType.min) {
        skillType.error = `Min. ${skillType.min} ${skillType.name} skill(s) required.`;
      }
      if (skillType.max && skillType.skills.length > skillType.max) {
        skillType.error = `Max. ${skillType.max} ${skillType.name} skill(s) allowed.`;
      }
    });
  }

  public transformGlobalSkillType() {
    const globalSkillTypes = this.globalSkillTypes.map((skillType, index) => {
      return {
        ...skillType,
        ...this.additionalGlobalSkillTypeDetails[index],
        skills: [],
        ...(skillType.info === undefined && { info: this.getSkillTypeInfo(skillType) }),
      }
    });
    return globalSkillTypes;
  }

  public classifySkillItems(skillItems: any[]) {
    const globalSkillTypesHash = {};
    const globalSkillClassificationData = this.transformGlobalSkillType();
    const unClassifiedSkills = this.unClassifiedSkills;
    globalSkillClassificationData.forEach((skillType: any) => {
      if (!globalSkillTypesHash[skillType.skillTypeId]) {
        globalSkillTypesHash[skillType.skillTypeId] = [];
      }
    });
    skillItems.forEach((skill: any) => {
      const skillType = skill.skillTypeId;
      if (skillType && globalSkillTypesHash[skillType]) {
        globalSkillTypesHash[skillType].push(skill);
      } else {
        unClassifiedSkills.skills.push(skill);
      }
    });
    globalSkillClassificationData.forEach((skillType: any) => {
      skillType.skills = globalSkillTypesHash[skillType.skillTypeId]
    });
    return { globalSkillClassificationData, unClassifiedSkills };
  }

  private getSkillTypeInfo(skillType) {
    let message = '';
    if (skillType.min && skillType.max) {
      message = `Min. ${skillType.min}, Max. ${skillType.max} skills allowed`;
    } else if (skillType.min) {
      message = `Min. ${skillType.min} skills allowed`;
    } else if (skillType.max) {
      message = `Max. ${skillType.max} skills allowed`;
    } else {
      message = 'Unlimited skills allowed';
    }
    return message;
  }

  public processExistingSkillsForGlobalSkillType(existingSkillItems?: any[]) {
    existingSkillItems = existingSkillItems || cloneDeep(this.ds.currentUserData?.userSkillItems);
    existingSkillItems = existingSkillItems.map((skill: any) => {
      const existingValues = {
        incomingRating: skill.incomingRating || '1',
        aspirationalRating: skill.aspirationalRating || '1',
        lastUsed: skill.lastUsed,
        experience: skill.experience,
        skillTypeId: skill.skillTypeId
      };

      return {
        ...skill,
        isExistingSkill: true,
        skillName: skill.name,
        skillId: skill.skillItemId,
        selected: true,
        isClientSkill: true,
        existingValues,
        recommendedValues: {
          ...existingValues,
          ...(skill.recommendedValues || {})
        }
      }
    });
    return existingSkillItems;
  }

  public getGlobalSkillTypeLabel(skillTypeId) {
    const skillType = this.transformGlobalSkillType().find(skillType => skillType.skillTypeId === skillTypeId);
    if (!skillType) {
      return this.unClassifiedSkills.label;
    }
    return skillType.label;
  }

  get unClassifiedSkills() {
    return {
      skillTypeId: '',
      icon: 'help',
      name: 'Unclassified',
      label: 'Skills pending classification.',
      info: 'Leftover skills will be auto-classified as ‘Other’',
      skills: []
    }
  }
}
