<div class="my-growth-card d-flex flex-fill flex-column">
  @if (!hideHeader) {
    <div class="card-header d-flex align-items-center mt-2" [ngClass]="{'active': myGrowthPlanObj.status === 'ACTIVE', 'in-review': myGrowthPlanObj.status !== 'ACTIVE','primary': this.myGrowthPlanObj?.status === 'GOAL_SETTING' }">
      <div class="text">
        <div class="main-text">
          {{ myGrowthPlanObj.status === 'ACTIVE'
          ? teamGoalFlag ? GoalStatusHeader.GOAL_ACTIVE_TEAM_TITLE : GoalStatusHeader.GOAL_ACTIVE_TITLE
          : myGrowthPlanObj.status === 'GOAL SETTING'
          ? totalGoalsValue + (totalGoalsValue > 1 ? ' Goal(s) ' : ' Goal ') + GoalStatusHeader.GOAL_SET_TITLE
          : GoalStatusHeader.DEFAULT_GOAL_TITLE }}
        </div>
        <div class="sub-text">
          {{ myGrowthPlanObj.status === 'ACTIVE'
          ? teamGoalFlag ? GoalStatusHeader.GOAL_ACTIVE_TEAM_DESC : GoalStatusHeader.GOAL_ACTIVE_DESC
          : myGrowthPlanObj.status === 'GOAL SETTING'
          ? GoalStatusHeader.GOAL_SET_DESC
          : GoalStatusHeader.DEFAULT_GOAL_DESC }}
        </div>
      </div>
      <div class="nav-icon clickable">
        <mat-icon>chevron_right</mat-icon>
      </div>
    </div>
  }
  <div class="" [ngClass]="{'data-con-wrapper': isPastGoalCardTeamGoals}">
    <div class="data-con card-body mt-0 border-box" [ngClass]="{'no-box-shadow': isPastGoalCardTeamGoals}">
      <div class="icon mt-3">
        <button [ngClass]="{activeProgressIcon: myGrowthPlanObj.status === 'ACTIVE'}" class="btn-explore item-center">
          <div>
            <mat-icon class="active-icon" [ngClass]="{activeExploreIcon: myGrowthPlanObj.status === 'ACTIVE'}" class="d-flex item-center">explore</mat-icon>
          </div>
        </button>
      </div>
      <div class="goal-slider slider-container pt-0">
        <div class="button-label" [ngClass]="{'mt-3 mb-1': myGrowthPlanObj?.status === 'COMPLETED'}">
          <div class="right-label">
            {{myGrowthPlanObj.name}}
          </div>
          <div class="border-radius-4" [ngClass]="{'active-status': myGrowthPlanObj.status === 'ACTIVE','complete-status': myGrowthPlanObj.status !== 'ACTIVE'}">
            <span class="h6 text-uppercase">{{myGrowthPlanObj.status}}</span>
          </div>
        </div>

        <div class="slider mt-2 item-center w-100">
          <div class="progress-bar">
            <div class="progress" [ngClass]="{activeProgress: myGrowthPlanObj.status === 'ACTIVE'}" [ngStyle]="{ width: calculateActivePhasePosition() + '%' }"></div>
            <div class="thumb" [ngStyle]="{ left: calculateActivePhasePosition() + '%' }"></div>
          </div>
        </div>

        <div class="info icon-sec d-flex justify-content-between">
          <div class="goal-indicator d-flex">
            @if (myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] >= 0) {
              <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'], icon: 'album', color: getColor('goalsSet'), showBar: hasNextGoal('GOAL_SET') }"></ng-container>
            }
            @if (myGrowthPlanObj.goalsProgressStatusAndCount['IN_REVIEW'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['IN_REVIEW'] >= 0) {
              <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['IN_REVIEW'], icon: 'pending', color: getColor('inReview'), showBar: hasNextGoal('IN_REVIEW') }"></ng-container>
            }
            @if (myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] >= 0) {
              <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'], icon: 'offline_pin', color: getColor('completed'), showBar: hasNextGoal('COMPLETED') }"></ng-container>
            }
            @if (myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] >= 0) {
              <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'], icon: 'watch_later', color: getColor('delayed'), showBar: false }"></ng-container>
            }
            @if (myGrowthPlanObj.userWithoutGoals) {
              @if (hasValue()) {
                <div class="vl"></div>
              }
              @if (bannerDec) {
                <div class="item-center val"><mat-icon class="warning mr-1">warning</mat-icon> <span class="text-no-goal mb-1 label">{{myGrowthPlanObj.userWithoutGoals}} team members without goals</span></div>
              } @else {
                <span class="text-no-goal mb-1">
                  ended on {{myGrowthPlanObj?.planEndDate | date :'dd MMMM yyyy'}}
                </span>
              }
            }
          </div>
          <div class="days-left">
            {{myGrowthPlanObj.daysLeft}} <span class="text">days left</span>
          </div>
        </div>

      </div>
    </div>

    @if (isPastGoalCardTeamGoals) {
      <div class="item-between px-20 py-2">
        <div class="primary d-flex align-items-center clickable" (click)="onViewPlan()">
          VIEW PLAN
          <mat-icon>arrow_right_alt</mat-icon>
        </div>
        <div class="goal-indicator d-flex">
          @if (myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] >= 0) {
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['GOAL_SET'] || 0, icon: 'album', color: getColor('goalsSet'), showBar: hasNextGoal('GOAL_SET') }"></ng-container>
          }
          @if (myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] >= 0) {
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.goalsProgressStatusAndCount['COMPLETED'] || 0, icon: 'offline_pin', color: getColor('completed'), showBar: hasNextGoal('COMPLETED') }"></ng-container>
          }
          @if (myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] !== null && myGrowthPlanObj.goalsProgressStatusAndCount['DELAYED'] >= 0) {
            <ng-container *ngTemplateOutlet="goalIndicator; context: { value: myGrowthPlanObj.delayed || 0, icon: 'watch_later', color: getColor('delayed'), showBar: false }"></ng-container>
          }
        </div>
      </div>
    }
  </div>
</div>

<ng-template #goalIndicator let-value="value" let-icon="icon" let-color="color" let-showBar="showBar">
  <div class="d-flex">
    <mat-icon class="mr-1" [ngStyle]="{'color': color }">{{ icon }}</mat-icon>
    <span class="val">{{ value }}</span>
  </div>
  @if (showBar) {
    <div class="vl"></div>
  }
</ng-template>
