<div class="container">
  <div>
    @if (config?.title) {
      <label class="h4 black">{{config.title}}</label>
    }
    @if (config?.subTitle) {
      <p class="body gray">{{config.subTitle}}</p>
    }
  </div>

  <div class="d-flex flex-column align-items-start align-self-stretch p-bottom-0">
    @for (item of selectedData; track item; let i = $index) {
      <div class="skill-row">
        <div class="skill-label">{{item?.displayName}}</div>
        <div class="d-flex">
          <app-single-star [key]="'value'" [client]="client" class="skill-field-star" [editable]="canEdit"
            [skillItem]="item || {}" [value]="item?.value || 0" (onSetValue)="onSetRating($event)">
          </app-single-star>
          <mat-icon class="skill-delete-icon" (click)="deleteItem(item)"
          matTooltip="Delete the skill">close</mat-icon>
        </div>
      </div>
    }
  </div>

  @if (!showTextbox) {
    <div class="d-flex align-self-start">
      <button
        class="add-btn d-flex p-2 justify-content-center align-items-center gap-1 border-radius-4 h6 primary fw-500"
        (click)="showTextboxOnClick()">
        <app-svg-icon fill="var(--primary-color-light)" src="icons/add.svg"></app-svg-icon>
        Add Skill
      </button>
    </div>
  }

  <div class="search-con">
    @if (showTextbox) {
      <form>
        <mat-form-field appearance="outline">
          <input class="d-flex p-2 align-items-center gap-2 align-self-stretch border-radius-4" type="text"
            placeholder="Search skill..." aria-label="Skills" matInput #skillInput [formControl]="searchControl"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addItem($event.option.value)">
            @for (option of availableItemsToDisplay | async; track option) {
              <mat-option class="search-result-con"
                [value]="option">
                {{option.name}}
              </mat-option>
            }
          </mat-autocomplete>
          @if (searchControl.invalid && searchControl.touched && config?.required) {
            <mat-error>Please provide skills associated with
            above Certification name.</mat-error>
          }
        </mat-form-field>
      </form>
    }
  </div>
</div>