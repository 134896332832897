<form [formGroup]="editForm">
  <mat-form-field appearance="outline" class="w-100">
    @if (input.title) {
      <mat-label>{{input.title}}
        @if (input.required) {
          <span>*</span>
        }
      </mat-label>
    }
    @if (input.icon) {
      <mat-icon matPrefix>{{input.icon}}</mat-icon>
    }
    <mat-select #select [compareWith]="compareObjects" [disabled]="input?.disableSelection" disableOptionCentering [panelClass]="customPanelClass || 'myPanelClass'" [formControl]="tags" multiple (selectionChange)="onSelect($event)" (openedChange)="focusOnSearchBar()">
      <mat-select-trigger>
        {{tags.value.length ? (tags.value[0][input.nameField] || tags.value[0].name || tags.value[0]): input.placeholder}}
        @if (tags.value.length > 1) {
          <span class="additional-selection">
            (+{{tags.value.length - 1}} {{tags.value.length === 2 ? 'other' : 'others'}})
          </span>
        }
      </mat-select-trigger>
      <div class="tags ml-3" [ngClass]="{'hide-tags-display':!allComplete}">
      </div>
      @if (!disabled) {
        <div class="tags ml-3" [ngClass]="{'hide-tags-display':allComplete}">
          @for (item of tags.value; track item) {
            <div class="tag d-flex h-100 align-items-baseline mt-2 mb-1 ml-1">
              <span class="body black">{{item.name || item[input.nameField] || item}}</span>
              <mat-icon class="ic-18" (click)="removeRole(item)">cancel</mat-icon>
            </div>
          }
        </div>
      }
      @if (!disabled) {
        <input #searchBar matInput class="input-style mt-0 mr-0 mb-0 ml-2 pl-2" placeholder="{{input.placeholder}}" formControlName="input" [required]="input.required" (keydown.space)="$event.stopPropagation()"/>
      }
      @if (options.size > 0) {
        @if (!disabled && !input.hideSelectAllCheckbox) {
          <div class="mat-mdc-option mdc-list-item mat-mdc-option-multiple">
            <mat-checkbox class="select-all-box" [color]="'primary'" [checked]="allComplete" [indeterminate]="someComplete" #allSelected (change)="toggleSelection($event)">Select All</mat-checkbox>
          </div>
        }
        @for (option of options; track option) {
          <mat-option [disabled]="disabled" class="option-checkbox" [value]="option">
            {{option[input.nameField] || option.name || option}}
          </mat-option>
        }
      } @else {
        <mat-option class="hide-check-box lh-3" disabled>
          <span> No options available</span>
          @if (input?.actionableLinkText && !tags.value.length) {
            <div>
              <span class="primary link lh-20 cursor-pointer" (click)="onLinkClick()">{{input?.actionableLinkText}}</span>
            </div>
          }
        </mat-option>
      }
    </mat-select>
    @if (input.suffixIcon) {
      <mat-icon matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
    }
  </mat-form-field>
</form>
