import { Component, computed, inject, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ColumnType, IColumn } from '../../shared.model';
import { FormatDatePipe } from '../../../../pipes/format-date.pipe';
import { Status } from 'src/app/constants';

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
  imports: [
    MatIconModule,
    FormatDatePipe
  ]
})
export class CellComponent {
  public utils = inject(UtilitiesService);

  public config = input.required<IColumn>();
  public row = input.required<unknown>();

  public columnTypes = signal(ColumnType);
  public status = signal(Status);

  public value = computed(() => {
    const config = this.config();
    const row = this.row();

    return config.valueFunction ? config.valueFunction(config, row) : row[config.key];
  })
}
