<div class="d-flex flex-column gap-2">
  <div class="h3 black mb-2">Skill Updated Source Settings</div>
  <form [formGroup]="skillUpdatedSource">
    <div class="d-flex justify-content-between">
      <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
        <mat-label>Show skill history</mat-label>
        <mat-icon matPrefix>settings_suggest</mat-icon>
        <mat-select formControlName="showSkillHistory">
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Skill Sources Alias Table -->
    @if (showSkillSourcesAliasTable) {
      <table mat-table [dataSource]="skillSourcesAliasControls.controls" class="table-container w-100 my-20" formArrayName="skillSourcesAlias">
        <!-- Source Label Column -->
        <ng-container matColumnDef="sourceLabel">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell"> Source Label </th>
          <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
            {{ row?.value?.label }}
          </td>
        </ng-container>
        <!-- Alias Column -->
        <ng-container matColumnDef="alias">
          <th mat-header-cell *matHeaderCellDef class="mat-header-cell pl-2"> Alias </th>
          <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
            <mat-form-field appearance="outline">
              <mat-label>{{row?.value?.default}}</mat-label>
              <input matInput [placeholder]="row?.value?.default" formControlName="alias" />
            </mat-form-field>
          </td>
        </ng-container>
        <!-- Table Header -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    }

    <div class="admin-save-button-con">
      <button type="submit" (click)="saveData()" mat-stroked-button class="primary-button">Save</button>
    </div>
  </form>
</div>
