import { Component, computed, inject, input, Signal, signal, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { HttpResourceRef, HttpResourceRequest } from '@angular/common/http';
import { IPaginatedResponse, ColumnType, IDatatable, INoData } from './../shared.model';
import { CellComponent } from './cell/cell.component';
import { DataService } from 'src/app/services/data.service';
import { NoDataBoxComponent } from './no-data-box/no-data-box.component';
import { PaginatorComponent } from './paginator/paginator.component';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
  imports: [
    MatIconModule,
    MatTableModule,
    PaginatorComponent,
    NoDataBoxComponent,
    CellComponent
  ]
})
export class DatatableComponent {
  private ds = inject(DataService);

  public paginator = viewChild<PaginatorComponent>('paginator');
  public config = input.required<IDatatable>();

  public columnTypes = signal(ColumnType);

  public columns = computed(() => this.config().columns);
  public displayedColumns = computed(() => this.columns().map(column => column.key));
  public datasource = computed(() => {
    const value = this.api.value();
    const config = this.config();
    return value?.results ?? value[config.parseResponseKey] ?? [];
  });
  public noData: Signal<INoData> = computed(() => {
    const title = this.config().title;
    return {
      title: `No ${title} available`,
      message: `Sorry, there are no ${title} available at this moment`
    }
  });

  public api: HttpResourceRef<IPaginatedResponse> = this.ds.postApiSignal(() => this.payload());

  private payload(): HttpResourceRequest {
    const config = this.config();
    const limit = this.paginator().limit();
    const offset = (this.paginator().pageNumber() - 1) * limit;

    let body = config.api.payload;
    body.limit = limit;
    body.offset = offset;
    body.operation = 'GET';

    return {
      url: config.api.endpoint,
      body: body
    }
  }
}
