import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ValidateString } from 'src/app/services/validation.service';
import { CUSTOM_MESSAGES } from 'src/app/constants';
import { LearningElementCertificationsSettingsComponent } from '../learning-element-certifications-settings/learning-element-certifications-settings.component';
import { LearningTypeDataTableComponent } from '../learning-type-data-table/learning-type-data-table.component';
import { TREATMENT_MAP } from 'src/app/modules/my-learnings/my-learning.model';
import { DEFAULT_LEARNING_TYPES, ELeFieldsKeys, ILearningElementActionsFormData, IMyLearningTreatmentV3Field } from './learning-settings.model';
import { IConfig } from '../learning-type-wise-settings/learning-type-wise-settings.model';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';

const myLearningTreatmentV3Control: IMyLearningTreatmentV3Field[] = [
  {
    title: 'LE assignment mechanism',
    key: 'myLearningModuleType',
    type: 'select',
    options: [{title: 'Learning Element based', value: 'LEARNING_ELEMENT'}, {title: 'Specialization based', value: 'COURSE'}],
    defaultValue: '',
    displayKey: 'title',
    validators: [Validators.required],
    icon: 'settings_suggest',
    hoverText: 'Learning element (LE) assignment mechanism decides how LEs will be treated. i.e if they will be processed based on learning paths configured in Skillprism OR simply act as a master record for inbound APIs'
  },
  { 
    title: 'Specialization based LE assignment settings',
    type: 'sub-title',
    checkValue: [{ key: 'myLearningModuleType', values: ['COURSE'], isArray: false }]
  }
];

@Component({
    selector: 'app-learning-settings',
    templateUrl: './learning-settings.component.html',
    standalone: false
})
export class LearningSettingsComponent implements OnInit {
  @ViewChild(LearningElementCertificationsSettingsComponent) certComponent: LearningElementCertificationsSettingsComponent;
  @ViewChild(LearningTypeDataTableComponent) leTypeTableComponent: LearningTypeDataTableComponent;
  //   @ViewChild('skillCategories') skillCategories: EditableTableComponent;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();


  constructor(
    public ds: DataService,
    private weds: WaitErrorDialogsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private util: UtilitiesService,
  ) {
    this.editForm = fb.group({});
  }
  public TREATMENT_MAP = TREATMENT_MAP;
  public myLearningTreatment: string = this.util.getTreatmentMap(TREATMENT_MAP.MY_LEARNINGS);
  public isMyLearningTreatmentV3: boolean = this.myLearningTreatment === TREATMENT_MAP.V3;
  public config: IConfig;
  public searchLoading: boolean = false;
  public searchResults: User[] = [];
  public search = new Subject<string>();

  public skillCategoriesColumns: any[];
  public editForm: FormGroup;
  public smsOptions: any[] = [
    ...this.getLearningModuleField(),
      {
      title: 'Skill proficiency based on Endorsement Status - Endorsed',
      key: 'skillProfEndorsStatusEndorsed',
      type: 'select',
      options: [{title: 'N', value: 'N'}],
      defaultValue: 'N',
      // disabled: true,
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Skill proficiency based on Endorsement Status - Pending',
      key: 'skillProfEndorsStatusPending',
      type: 'select',
      options: [{title: '0', value: '0'}, {title: 'Previous endorsed state', value: 'previous_endorsed_state'}, {title: 'N', value: 'N'}],
      defaultValue: 'N',
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Skill proficiency based on Endorsement Status - Skipped',
      key: 'skillProfEndorsStatusSkipped',
      type: 'select',
      options: [{title: '0', value: '0'}, {title: 'Previous endorsed state', value: 'previous_endorsed_state'}, {title: 'N', value: 'N'}],
      defaultValue: 'N',
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Skill proficiency based on Endorsement Status - Not Required',
      key: 'skillProfEndorsStatusNotRequired',
      type: 'select',
      options: [{title: 'N', value: 'N'}],
      defaultValue: 'N',
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Skill proficiency based on Endorsement Status - Rejected',
      key: 'skillProfEndorsStatusRejected',
      type: 'select',
      options: [{title: '0', value: '0'}, {title: 'N', value: 'N'}],
      defaultValue: '0',
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    // Commented settings are for phase 2
    // { title: 'Method to assign the LCs', key: 'methodToAssignLCs', type: 'select', options: [{title: 'Assign all LCs for a skill together to a user, open higher LC after lower LC is completed', value: 'HIGHER_LC_AFTER_LOWER_LC_COMPLETED'}, {title: 'Assign all LCs for a skill together to a user, and allow user to take them in any order', value: 'LC_CAN_BE_COMPLETED_IN_ANY_ORDER'}], validators: [Validators.required], icon: 'settings_suggest' },
    ...this.getSubTitleFieldWithDivider(),
    {
      title: 'Use Finishing Learning Element',
      key: 'useFinishingLearningElement',
      type: 'select',
      options: [
        { title: 'Yes', value: 'YES' },
        { title: 'No', value: 'NO' },
      ],
      displayKey: 'title',
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Show Finishing Learning Element to user',
      key: 'showFinishingLearningElementToUser',
      type: 'select',
      options: [
        { title: 'Yes', value: 'YES' },
        { title: 'No', value: 'NO' },
      ],
      displayKey: 'title',
      validators: [],
      icon: 'settings_suggest',
      checkValue: [{ key: 'useFinishingLearningElement', values: ['YES'], isArray: false }]
    },
    {
      title: 'After completion of LC',
      key: 'afterCompletionOfLC',
      type: 'select',
      validators: [],
      options: [
        { title: 'Update the user skill proficiency', value: 'UPDATE_USER_SKILL_PROFICIENCY' },
        { title: 'Mark it as endorsed', value: 'MARK_ENDORSED' },
      ],
      displayKey: 'title',
      isMulti: true,
      icon: 'settings_suggest',
    }, // needs clarification
    {
      title: 'Choose skill rating for update after LC completion',
      key: 'skillRatingTypeForLCCompletionUpdate',
      type: 'select',
      options: [
        { title: 'Incoming Rating', value: 'INCOMING_RATING' },
        { title: 'Project Rating', value: 'PROJECT_RATING' },
        { title: 'Training Rating', value: 'TRAINING_RATING' },
        { title: 'Self Acquired Rating', value: 'SELF_ACQUIRED_RATING' },
      ],
      validators: [],
      icon: 'settings_suggest',
      isMulti: false,
      displayKey: 'title',
      // checkValue: [{ key: 'afterCompletionOfLC', values: ['UPDATE_USER_SKILL_PROFICIENCY'] }]
    },
    {
      title: 'When LMS sends user course progress as 100%',
      key: 'userLearningElementProgress',
      type: 'select',
      options: [
        { title: 'Add/Update the skill & rating', value: 'ADD_OR_UPDATE_RATING_AND_SKILL' },
        { title: 'Mark the skill endorsed', value: 'MARK_SKILL_ENDORSED' },
        { title: 'Ignore', value: 'IGNORE' }
      ],
      validators: [],
      icon: 'settings_suggest',
      isMulti: true,
      displayKey: 'title',
    },
    {
      title: 'Approver of skill from LXP',
      key: 'approverOfSkillFromLXP',
      type: 'select',
      options: [
        { title: 'Current Endorsers', value: 'CURRENT_ENDORSERS' },
        { title: 'Other static endorser', value: 'OTHER_STATIC_ENDORSER' },
      ],
      validators: [],
      icon: 'settings_suggest',
      isMulti: false,
      displayKey: 'title',
    },
    {
      title: 'Select Approver of skill from LXP',
      key: 'staticApproverOfSkillFromLXP',
      type: 'searchText',
      options: [],
      icon: 'settings_suggest',
      isMulti: false,
      displayKey: 'title',
      validators: [Validators.required],
      bindLabel: 'empId',
      bindValue: 'userId',
      checkValue: [{ key: 'approverOfSkillFromLXP', values: [ 'OTHER_STATIC_ENDORSER'], isArray: true }]
    },
    {
      title: 'Override even if the user has a higher endorsed skill rating',
      key: 'endorseHigherSkillRating',
      type: 'select',
      options: [
        { title: 'Yes', value: 'YES' },
        { title: 'No', value: 'NO' },
      ],
      validators: [],
      icon: 'settings_suggest',
      isMulti: false,
      displayKey: 'title',
      checkValue: [{ key: 'userLearningElementProgress', values: ['ADD_OR_UPDATE_RATING_AND_SKILL', 'MARK_SKILL_ENDORSED'], isArray: true }]
    },
    {
      title: 'Message for missing learning path of a skill',
      key: 'messageForMissingLearningPathOfSkill',
      type: 'text',
      defaultValue: CUSTOM_MESSAGES.MISSING_LEARNING_PATH,
      displayKey: 'title',
      validators: [],
      maxLength: 150,
      icon: 'settings_suggest',
    },
    // { title: 'show LC / specialization completion %', key: 'showLCorSpecializationCompletionPercentage', type: 'select', options: ['No', 'Yes - based on number of LEs', 'Yes - based on duration of LEs'], validators: [Validators.required], icon: 'settings_suggest' }, //in phase 2
    // { title: 'Define LCs for', key: 'defineLCsFor', type: 'select',  validators: [], options: ['Skill', 'Proficiency'], isMulti: true, icon: 'settings_suggest',}, //  options: ['Skill Cluster', 'Practice'], will add in phase 2
    // { title: 'Set timeline for completion', key: 'setTimelineForCompletion', type: 'select', options: [{title: 'No', value: 'NO'}, {title:'Yes, only for mandatory LEs', value: 'YES_ONLY_MANDATORY_LE'}, {title:'Yes, for all LEs', value: 'YES_ALL_LE'}], validators: [Validators.required], icon: 'settings_suggest' },
  ];
 
  private _client: Client;

  ngOnInit(): void {
    this.myLearningTreatment = this.util.getTreatmentMap(TREATMENT_MAP.MY_LEARNINGS);
    this.skillCategoriesColumns = [
      {
        title: 'Learning Elements Types',
        key: 'name',
        type: 'text',
        disabled: !this.canEdit,
        required: true,
        validators: [Validators.required, ValidateString(2)],
        placeholder: 'Eg: Courses, Assessments, Certifications, Videos, Projects, etc',
      },
    ];
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.updateConfig(this.client?.smsSettings?.myLearningModuleType, this.config?.isSaveDisabled);
    this.buildForms();
    this.initUserSearch();
  }

  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.forEach((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.checkValue || []).map((item) => {
        const getFormValue = this.editForm.get(item.key)?.value;
        const validValue = item.values.indexOf( getFormValue ? getFormValue : this.client.smsSettings[item.key]) < 0;
        const dependentValues = !item.values.some((val)=> getFormValue?.includes(val));
        if (!item.isArray && validValue) { 
          s.showControl = false;
        }
        else if (item.isArray && dependentValues) {
          s.showControl = false;
        }
      }
      );
      
      if (s.showControl && this.shouldHideControl(s.key)) {
        s.showControl = false;
      }

      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }

  public leTypeDataTableFormValueChanges(): void {
    this.config.isSaveDisabled = true;
  }

  onLearningElementsTypesSave(data) {
    if (!data.length) {
      this.weds.showDialog({ type: 'generic', code: 133 });
      return;
    }
    let d = { type: 'typesOfLearningElements', typesOfLearningElements: data };
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = {
      type: 'generic',
      title: 'Confirm Save',
      message: 'This is a dangerous setting, are you absolutely sure you want to save these settings?',
      btnTitle: 'Yes',
      btn2Title: 'Cancel',
    };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event == 'BTN_CLICK') {
        this.weds.showDialog({ type: 'wait', title: 'Deleting', message: 'Please wait while your data is deleted' });
        // this.saveData(d);
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    let payload = { ...this.editForm.value, type: 'learningPath' };
    if (this.myLearningTreatment !== this.TREATMENT_MAP.V1) {
      payload = {
        ...payload,
        ...this.certComponent?.editForm?.value,
        learningElementTypes: this.leTypeTableComponent?.data?.filter(d => d.name)
      }
    }
    this.onSave.emit(payload);
    this.config.isSaveDisabled = false;
  }

  saveLearningElementActionsData(formData: ILearningElementActionsFormData) {
    this.onSave.emit(formData);
  }

  onSMSOptionSelected(smsOption, event) {
    this.buildForms();
    if(smsOption.key === ELeFieldsKeys.MY_LEARNING_MODULE_TYPE) {
      this.updateConfig(event?.value, true);
    }
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }
  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  private updateConfig(leAssignmentMechanism: string, isSaveDisabled: boolean = false): void {
    this.config = {
        tabs: this.client?.smsSettings?.learningElementTypes?.length 
            ? this.client.smsSettings.learningElementTypes 
            : [...DEFAULT_LEARNING_TYPES],
        leAssignmentMechanism,
        isSaveDisabled
    };
  }

  private shouldHideControl(controlKey: ELeFieldsKeys): boolean {
    if (!this.isMyLearningTreatmentV3) return false;
    
    const keysToAlwaysHide = new Set([ELeFieldsKeys.USER_LEARNING_ELEMENT_PROGRESS, ELeFieldsKeys.ENDORSE_HIGHER_SKILL_RATING]);
    const isLearningElementType = this.editForm.get(ELeFieldsKeys.MY_LEARNING_MODULE_TYPE)?.value === 'LEARNING_ELEMENT';
    
    return keysToAlwaysHide.has(controlKey) || (controlKey !== ELeFieldsKeys.MY_LEARNING_MODULE_TYPE && isLearningElementType);
  }

  private getLearningModuleField(): IMyLearningTreatmentV3Field[] {
    return this.isMyLearningTreatmentV3 ? myLearningTreatmentV3Control : [
      {
        title: 'Select Learning Element',
        key: ELeFieldsKeys.MY_LEARNING_MODULE_TYPE,
        type: 'select',
        options: [
          { title: 'Learning Module', value: 'LEARNING_ELEMENT' },
          { title: 'Course Module', value: 'COURSE' }
        ],
        defaultValue: '',
        displayKey: 'title',
        validators: [Validators.required],
        icon: 'settings_suggest',
      }
    ];
  }

  private getSubTitleFieldWithDivider() {
    return this.isMyLearningTreatmentV3
      ? [{ 
          title: '', 
          type: 'sub-title', 
          dividerRequired: true, 
          checkValue: [{ key: 'myLearningModuleType', values: ['COURSE'], isArray: false }] 
        }]
      : [];
  }
  
  getUserForEnteredEmpId(empId: string) {
    return this.ds.searchData(API_ENDPOINT.GET_USER, { 
      clientId: this.ds.currentAdminClientId, 
      getClient: !this.client, 
      search: empId || null, 
      offset: 0, 
      limit: 5, 
      status: 'Active', 
      getRoles: false, 
      getCustomApproverUnits: false 
    }).pipe(
      take(1),
      map((data: {status: string, searchData: User[]}) => {
        return data.searchData;
      })
    );
  }

  private initUserSearch() {
    this.search.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        if (!this.searchResults?.length || !this.editForm.get('staticApproverOfSkillFromLXP')?.value) {
          this.searchLoading = true;
        }
      }),
      switchMap(val => { return this.getUserForEnteredEmpId(val) }),
      map((data: User[]) => {
        const approverDetails = { 
          empId: this.client.smsSettings?.staticApproverOfSkillFromLXPEmpId, 
          userId: this.client.smsSettings?.staticApproverOfSkillFromLXP 
        };
        const index = data.findIndex(result => 
          result.empId === approverDetails.empId || result.userId === approverDetails.userId
        );
        return index === -1 ? [...data, approverDetails] : data;
      })
    ).subscribe(data => {
      this.searchResults = data as User[];
      const approverDetails = { empId: this.client.smsSettings.staticApproverOfSkillFromLXPEmpId, userId: this.client.smsSettings.staticApproverOfSkillFromLXP } as User;
      const existingEmpId = this.searchResults.find(result => result.empId === approverDetails.empId);
      const existingUserId = this.searchResults.find(result => result.userId === approverDetails.userId);

      if (!existingEmpId && !existingUserId) {
        this.searchResults.push(approverDetails);
      }
      this.searchLoading = false;
    });
  }
}
