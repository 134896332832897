<div class="avatar-preview-main-con">
  <div class="avatar-preview-title-con mb-3">
    <div class="h4">Edit Image</div>
    <mat-icon class="cursor-pointer close gray" mat-dialog-close>close</mat-icon>
  </div>

  <div class="image-cropper-con">
    <div class="ellipse item-center">
      <image-cropper
        style="width: auto;"
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [hideResizeSquares]="true"
        [imageURL]="imageURL"
        [imageQuality]="100"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="200"
        [transform]="{scale: sliderValue / 50}"
        [roundCropper]="true"
        format="png"
        (imageCropped)="imageCropped($event)"
        (loadImageFailed)="loadImageFailed($event)">
      </image-cropper>
    </div>
  </div>

  <div class="slider-container">
    <button mat-icon-button (click)="decrease()">
      <mat-icon>zoom_out</mat-icon>
    </button>

    <mat-slider><input matSliderThumb [(ngModel)]="sliderValue" /></mat-slider>

    <button mat-icon-button (click)="increase()">
      <mat-icon>zoom_in</mat-icon>
    </button>
  </div>

  @if (!isClientUser) {
    <hr class="mt-3 mb-3">
  }

  @if (!isClientUser) {
    <div class="d-flex align-items-center mb-2">
      <label class="mr-2">Gender</label>
      <mat-radio-group [(ngModel)]="selectedGender" class="d-flex ml-auto gap-6 mr-4">
        <mat-radio-button value="male" class="mr-3">Male</mat-radio-button>
        <mat-radio-button value="female">Female</mat-radio-button>
      </mat-radio-group>
    </div>
  }

  @if (!isClientUser) {
    <hr class="mt-3 mb-3">
  }

  <div class="d-flex justify-content-between mt-2">
    @if (!isClientUser && showAvatarDeleteBtn) {
      <button mat-stroked-button color="warn" (click)="delete()">
        <mat-icon class="mr-1">delete</mat-icon>DELETE
      </button>
    }
    @if (isClientUser) {
      <button mat-stroked-button class="secondary-button" mat-dialog-close>
        CANCEL
      </button>
    }

    @if (selectedAvatar && !isClientUser) {
      <div class="item-center">
        @if (!selectedAvatar.isEnabled) {
          <button mat-stroked-button class="mr-2 secondary-button" (click)="enableDisableToggle()">Enable</button>
        }
        @if (selectedAvatar.isEnabled) {
          <button mat-stroked-button class="mr-2 secondary-button" (click)="enableDisableToggle()">Disable</button>
        }
        <button mat-stroked-button class="primary-button" (click)="update()">Update</button>
      </div>
    }
    @if (!selectedAvatar && !isClientUser) {
      <button mat-stroked-button class="primary-button" (click)="add()">ADD AVATAR</button>
    }
    @if (!selectedAvatar && isClientUser) {
      <button mat-stroked-button class="primary-button" (click)="apply()">APPLY</button>
    }
  </div>
</div>
