<div class="skill-ratings-con">
  <app-skill-ratings-settings [canEdit]="canEdit" [category]="category" [client]="client"
  (onSave)="onSaveDataEvent($event)"></app-skill-ratings-settings>
</div>
@if (client) {
  <div class="types-tags-con">
    @if (!isGlobalSkillTypeEnabled) {
      <app-skill-types-settings class="types-tags" [canEdit]="canEdit" [category]="category" [client]="client"
      (onSave)="onSaveDataEvent($event)">
    </app-skill-types-settings>
    }
    <app-skill-tags-settings class="types-tags" [canEdit]="canEdit" [category]="category" [client]="client"
      (onSave)="onSaveDataEvent($event)">
    </app-skill-tags-settings>
  </div>
}
<div style="width:100%" class="category-wise-settings-con">
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div
          [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
          @if (smsOption.type=='title') {
            <div class="h3 black " style="margin:10px 0;">
            {{smsOption.title}}</div>
          }
          @if (smsOption.type=='sub-title') {
            <div class="h4 black" style="margin:10px 0;">
            {{smsOption.title}}</div>
          }
          @if (smsOption.showControl) {
            <div>
              @if (smsOption.type=='text') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [required]="smsOption.required" autocomplete="off"
                      maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='textarea') {
                <div>
                  <mat-form-field appearance="outline"
                    class="admin-input-form-field admin-text-area-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}" cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
                    class="config-tooltip h5 black"></textarea>
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='select') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    @if (smsOption.icon) {
                      <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    }
                    <mat-select [matTooltip]="smsOption.tooltip" formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                      [compareWith]="compareSelect">
                      @for (option of smsOption.options; track option) {
                        <mat-option
                          [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                        </mat-option>
                      }
                    </mat-select>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='date') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>date_range</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                      (focus)="picker.open()" />
                    <mat-datepicker #picker></mat-datepicker>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
    @if (allowMultilevelApprover) {
      <div>
        <div class="multi-level-endorsement-title-con">
          <div class="h4 black">Multilevel Endorsements</div>
          <button type="button" mat-stroked-button class="secondary-button" (click)="syncEndorsement()">{{syncButtonText}}</button>
        </div>
        @if (client) {
          <div class="multi-level-endorsement-table">
            <app-editable-table [endorseLevelSettings]="true" #multilevelEndorsements [canEdit]="canEdit" [enableDelete]="true" [columns]="approvalLevelColumns" [maxRows]="maxSkillApprovalLevelRow"  [footNotes]="'You need a minimum of 1 approver for endorsements'" [saveButtonText]="'Save'" (onSave)="onMultilevelEndorsementsSave($event)" [addButtonText]="'Add'" [dataSource]="this.settings.levelWiseApprovalSettings"  [enableDrag]="false"></app-editable-table>
          </div>
        }
      </div>
    }
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="button" mat-stroked-button class="tertiary-button"
        (click)="applySameSettingsToAllCategories()">Apply same settings to all categories</button>
      </div>
    }
  </form>
</div>
