<div class="p2p-settings-con">
  <div class="h3 black ">Homepage Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
          @if (smsOption.showControl) {
            <div>
              @if (smsOption.type=='select') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    @if (smsOption.icon) {
                      <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    }
                    <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      [compareWith]="compareSelect">
                      @for (option of smsOption.options; track option) {
                        <mat-option
                          [value]="option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                        </mat-option>
                      }
                    </mat-select>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
</div>
