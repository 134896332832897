import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ACCESS_LEVELS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';
import { DataOrderComponentConfig } from 'src/app/modules/common-components/data-ordering-control/data-ordering-control.model';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-user-custom-tags',
    templateUrl: './user-custom-tags.component.html',
    standalone: false
})
export class UserCustomTagsComponent implements OnInit {

  @Input() set client(client: Client) {
    this.updateConfigFromClient(client);
  }
  @Output() onSave = new EventEmitter();

  public userCustomTagsSettingsFormGroup = new FormGroup({
    userCustomTag: new FormControl({ value: [], disabled: false }),
  });
  public userCustomTagsConfig: DataOrderComponentConfig = {
    key: 'userCustomTag',
    subTitle: 'User Custom Tags',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose User Custom Tags',
    maxCount: 10,
    canOrder: false,
    useSaveButton: true,
    disabled: false,
    dropDownOptions: [],
    values: [],
  };

  constructor(private ds: DataService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  public saveUserCustomTags(): void {
    const selectedTags = this.userCustomTagsSettingsFormGroup.get('userCustomTag')?.value || [];
    const fieldsForCustomTags = this.ds.client?.smsSettings?.fieldsForCustomTags || [];

    const selectedUserCustomTags = selectedTags
      .map((tag) => fieldsForCustomTags.find((customTag) => customTag?.field === tag)?.dataKey)
      .filter(Boolean);

    this.onSave.emit({
      type: 'userCustomTag',
      selectedUserCustomTags,
    });
  }

  private initializeForm(): void {
    const control = this.userCustomTagsSettingsFormGroup.get('userCustomTag');
    if (control) {
      control.setValue(this.userCustomTagsConfig.values);
    }
  }

  private updateConfigFromClient(client: Client): void {
    const features = client?.features?.admin?.customTags ?? ACCESS_LEVELS.NO_ACCESS;
    const fieldsForCustomTags = client?.smsSettings?.fieldsForCustomTags ?? [];
    const selectedUserCustomTags = client?.smsSettings?.selectedUserCustomTags ?? [];

    this.userCustomTagsConfig = {
      ...this.userCustomTagsConfig,
      disabled: features !== ACCESS_LEVELS.EDIT,
      dropDownOptions: fieldsForCustomTags.map(({ field }) => field),
      values: fieldsForCustomTags
        .filter(({ dataKey }) => selectedUserCustomTags?.includes(dataKey))
        .map(({ field }) => field),
    };

    const control = this.userCustomTagsSettingsFormGroup?.get('userCustomTag');
    if (control) {
      control.setValue(this.userCustomTagsConfig.values);
    }
  }
}
