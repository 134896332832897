import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Observable } from 'rxjs';
import { startWith, debounceTime, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { SEARCH_BUTTON_EVENTS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: false
})
export class SearchComponent implements OnInit {
  @Input() opts;
  @Input() secondaryOpts: any[];
  @Output() buttonClick = new EventEmitter();
  @Output() onSearchResult = new EventEmitter();
  @Output() onSearchEnter = new EventEmitter();
  @Output() selectedValuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() searchInput = new EventEmitter();
  @ViewChild('search') search: ElementRef;
  @ViewChild(MatSelect) matSelect: MatSelect;
  searchResults: Observable<any>;
  searchControl = new FormControl();
  ignoreFirstEmptySearch = true;
  selectedValues: string[] = [];
  constructor(
    private ds: DataService,
    private utilityService: UtilitiesService
  ) { }
  @Input() disabled: boolean = false;

  ngOnInit(): void {
    this.ds.limitChanged.subscribe(() => {
      this.search.nativeElement.value = ''
    })
    if (this.disabled) this.searchControl.disable();
    this.searchResults = this.searchControl.valueChanges.pipe(startWith(''), debounceTime(400), distinctUntilChanged(), switchMap(val => {
      if (this.ignoreFirstEmptySearch && !val) { this.ignoreFirstEmptySearch = false; return []; }
      if (this.opts?.realTimeSearch) {
        this.searchInput.emit(val);
        return [];
      } else {
        let request = Object.assign(
          {
            search: val,
            limit: this.opts.limit || 10,
            forAutoComplete: true,
            type: this.opts.type,
            filters: this.opts.filters || [],
            clientId: this.opts.clientId,
            ...(this.opts.extraPayload && { ...this.opts.extraPayload })
          }, this.opts.additionalSearchPayloud || {});
        return this.ds.searchData(this.opts.searchEndpoint, request).pipe(map(response => response[this.opts.responseDataKey] || response['searchData']));
      }
    }));
  }
  onEnter(event) {
    this.onSearchEnter.emit({ val: this.search.nativeElement.value });
    if (this.opts.clearOnSelect) this.search.nativeElement.value = '';
  }
  onSearchResultSelected(event) {
    this.search.nativeElement.value = event.option.value.searchDisplayText || event.option.value[this.opts.searchDisplayField];
    this.onSearchResult.emit({ value: event.option.value });
    if (this.opts.clearOnSelect) this.search.nativeElement.value = '';
  }
  onLeftClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.LEFT_BUTTON });
  }
  onRightClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.RIGHT_BUTTON });
  }
  onFilterClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.FILTER_BUTTON });
  }
  onAddClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.ADD_BUTTON });
  }
  onSecondaryButtonClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.SECONDARY_BUTTON });
  }
  onDownloadClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.DOWNLOAD });
  }
  onAdditionalDownloadClick(type: string) {
    this.buttonClick.emit({ type });
  }
  onUploadClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.UPLOAD });
  }
  uploadAdjacentSkill() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.UPLOAD_ADJ_SKILLS });
  }
  downloadAdjacentSkill() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.DOWNLOAD_ADJ_SKILLS });
  }
  onUploadSpecializationClick() {
    this.buttonClick.emit({ type: SEARCH_BUTTON_EVENTS.UPLOAD_SPECIALIZATIONS });
  }
  onDropdownItemClick(option: any) {
    this.buttonClick.emit({ type: option.event });
  }
  onSelectionChange() {
    this.selectedValuesChange.emit(this.selectedValues);
    if (this.matSelect.panelOpen) {
      this.matSelect.close();
    }
  }

  isObject(val) {
    return this.utilityService.isObject(val);
  }
}
