export interface SkillData {
  skillName: string,
  skillId: string,
  isStaffingSkill: string,
  name: string,
  skillGroup: string,
  clientId: string,
  id: string,
  skillHierarchyId: string,
  hierarchy: string,
  skillHierarchy: SkillHierarchy[],
  skillCategoryId: string,
  skillCategory: {
    categoryId: string,
    name: string
  },
  headline: any
  remarks: any,
  proficiency: any,
  skillItemId: string,
  existingSkill: boolean,
  overallRating: number | null,
  recommendedValues: {
    incomingRating: number,
    aspirationalRating: number,
    lastUsed: number | null,
    experience: number | null,
    skillTypeId: string | null
  }
}

export interface SkillHierarchy {
  name: string,
  order: number,
  hierarchyName: string
}

export interface SpecData {
  specializationId: string,
  specialization: string,
  specializationName: string,
  description: string,
  skillCluster: string,
  skills: SpecSkillItem[],
  clientId: string,
  id: string,
  tags: any,
  headline: {
    specialization: string[]
  },
  skillClusterId: string
}
export interface SpecSkillItem {
  skillItemId: string,
  exitRating: number,
  skill: string,
  category: string
}

export interface ProfileData {
  designation: string
  fullName: string
  headline: any,
  id: string,
  isSelected?: boolean
  location: string
  profileImageUrl: string,
  bg: string
}

export interface ProjectData {
  projectId: string;
  project: string;
  projectName: string;
  projectProcessedKey?: string;  
  description: string | null;     
  location: string;
  skill: string;
  clientId: string;
  id: string;
  headline: {
    skill?: string[];
    project?: string[];
  };
  startDt: string;
  endDt?: string;
  skillItems: ProjectSkillItem[];
  projectCustomerName: string;
  projectLocation?: string; 
  projectEmployeeRole: string;
}

export interface ProjectSkillItem {
  name: string;                 
  search: string;
  skillId: string;
  priority?: string;
  skillIds: string[];
  createdBy: string;
  hideSkill: string;
  updatedBy?: string;
  skillLevel: string;
  isStaffingSkill: string;
  skillCategoryId: string;
  skillHierarchyIds: string[];
}

export interface FilterOption {
  name: string;
  key: string;
  routeName?: string;
  count?: number;
  loaded?: boolean;
}

export enum FilterKeys {
  PEOPLE = 'people',
  SKILLS = 'skillMaster',
  SPECIALIZATIONS = 'specMaster',
  COURSES = 'courseMaster',
  CERTIFICATIONS = 'certMaster',
  PROJECTS = 'projMaster'
}

export interface IAllocationDetails {
  operator: string;
  value?: number;
  secondaryValue?: number;
}

export interface IPrismSearchPayload {
  payload: any,
  isAdvancedSearch: boolean,
  formattedQuery: string
}

export interface ITagsConfigMap {
  allocation: string[];
}

export interface ISuggestion {
  operands?: ISuggestion[],
  element?: string,
  tag?: string,
  operator?: string
}

export interface IParsedQuery {
  string: string,
  highlight?: string
}

export interface IParsedSuggestion {
  query: IParsedQuery[],
  simpleQuery: string
}

export interface IPiiSuggestion {
  name: string,
  designationName: string,
  profileImg?: string
}

export interface IUserData {
  id: string;
  element: string;
  tag: string;
  tagType: string;
}

export interface IPrismSearchResponse {
  userData: IUserData[];
  totalCount: number;
}

export interface HighLight{
  [item: string]: string[];
}
