export enum TYPE {
  COURSE = 'COURSE',
  ASSIGNMENT = 'ASSIGNMENT',
  CERTIFICATION = 'CERTIFICATION',
}

export enum ASSIGNED_TYPE {
  LMS = 'LMS',
  AUTO_ASSIGNED = 'AA',
}

export enum STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  PASSED = 'PASSED',
  FAILED = 'FAILED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export enum COLORS {
  IN_PROGRESS = 'var(--color-orange-dark)',
  COMPLETED = 'var(--color-green-dark)',
}

export enum PROGRESS_TYPE {
  PERCENT = 'PERCENT',
  STATUS = 'STATUS'
}

export interface IProgressCard {
  leId: string;
  clientId: string;
  completionTimeline?: string;
  contentUrl: string;
  description: string;
  duration?: any;
  originCreator?: string;
  pk: string;
  skills: any[];
  source?: string;
  status: string;
  subType?: string;
  thumbnailUrl?: string;
  title: string;
  type: string;
  enabled: number;
  learningElementPk: string;
  userProgress: IUserProgress[];
  assignedBy: string;
  assigneeInitials?: string;
  assigneeName?: string;
  progress: number;
  userStatus: string;
  isFinished?: boolean;
  specialization: string;
  resultStatus?: string;
  durationInTime?: string;
  referenceUrl?: string;
  referenceFile?: string;
  issuingOrganization?: string;
  currentState?: string;
  startDate?: string;
  endDate?: string;
  skillItemIdVsName?: any;
  skillItemIds: string[];
  uniqueIdForResumeGeneration?: string;
  certificationSource?: string;
  newlyCreatedItemId?: string;
  selectedSkills?: any[];
}

export interface IUserProgress {
  type: string;
  value: string;
}