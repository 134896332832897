<button #starButton class="star-container" [ngClass]="getButtonClasses()" [matMenuTriggerFor]="menu"
  [disabled]="!editable && !showRequiredProficiency"
  [matTooltip]="hideRatingValue ? 'Rating: ' + (skillItem[key] || 0) : (editable ? 'Click to edit' : '')"
  matTooltipPosition="above">

  @if ((skillItem && skillItem[key] || 0) == 0) {
    <mat-icon [ngStyle]="{'color': 'gray'}">
      star_border
    </mat-icon>
  }

  @if ((skillItem && skillItem[key] || 0) == maxValue) {
    <mat-icon [ngStyle]="{'color': color || 'gray'}">
      star
    </mat-icon>
  }

  @if ((skillItem && skillItem[key] || 0) > 0 && (skillItem[key] || 0) < maxValue) {
    <mat-icon
      [ngStyle]="{'color': color || 'gray'}">
      star_half
    </mat-icon>
  }

  @if (skillItem && !hideRatingValue) {
    <div
      [ngClass]="skillItem[key] ? 'star-value caption black-text' : 'star-value caption gray'">
      {{ (skillItem[key] || 0) }}
    </div>
  }

  @if (showRequiredProficiency && skillItem.requiredRating && skillItem.requiredRating > skillItem[key] && !skillItem.changes) {
    <app-svg-icon
      class="warning-icon" src="icons/warning.svg" fill="var(--color-hot-pink)">
    </app-svg-icon>
  }
</button>
<mat-menu #menu class="star-menu" yPosition="above" xPosition="before">
  @if (showRequiredProficiency) {
    <div>
      <div style="margin-bottom: 6px;">Expected Proficiency</div>
      <div>
        <app-star-rating key="requiredRating" [editable]="false" noTooltip="true" [skillItem]="skillItem||{}"
          [client]="client" color="#00A2ED">
        </app-star-rating>
      </div>
      <div class="proficiency-title-con">
        Proficiency
        @if (skillItem.requiredRating>skillItem.overallRating&&!skillItem.changes) {
          <app-svg-icon class="prefix"
          src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
        }
      </div>
    </div>
  }
  <app-star-rating [key]="key" [editable]="editable" [skillItem]="skillItem||{}" [client]="client"
    [noTooltip]="!editable" (onSetValue)="onStarClick($event)">
  </app-star-rating>
</mat-menu>
