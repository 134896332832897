import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, take, tap } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { ISelectedAccomplishmentResponse, ISkillResponseData } from '../../admin-goal-management/components/add-goal';
import { AccomplishmentType, IPaginationVirtual } from '../../admin-goal-management/components/add-goal/add-goal.component';
import { HttpParams } from '@angular/common/http';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { IUserData } from '../notification-triggers.model';

@Component({
    selector: 'app-search-user-group',
    templateUrl: './search-user-group.component.html',
    styleUrls: ['./search-user-group.component.scss'],
    imports: [ SharedModule ]
})

export class SearchUserGroupComponent implements OnInit, OnChanges {
  @Input() groupForm: FormGroup;
  @Input() maxNestingLevel: number;
  @Input() parentArray!: FormArray;
  @Input() currentParentIndex: number = 0;
  @Input() filterDropDownData: any = null;
  @Input() tagsList: any = [];

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private fb: FormBuilder,
    private ds: DataService,
    private utilitiesService: UtilitiesService) { }

  //------------------------------------------------------------------
  // Public Variables
  //------------------------------------------------------------------

  public search$ = new Subject<string>();
  public itemsMap: { [key: string]: any[] } = {};
  public loadingMap: { [key: string]: boolean } = {};
  public selectedAccomplismentApiResponse: ISkillResponseData | ISelectedAccomplishmentResponse;
  public accomplishments = [
    { id: '1', type: AccomplishmentType.Skill, label: 'Skill' },
    { id: '2', type: AccomplishmentType.Specialization, label: 'Specialization' },
    { id: '3', type: AccomplishmentType.Certification, label: 'Certification' },
    { id: '4', type: AccomplishmentType.Course, label: 'Course' },
    { id: '5', type: AccomplishmentType.Designation, label: 'Designation' },
  ];

  //------------------------------------------------------------------
  // Private Variables
  //------------------------------------------------------------------

  private searchText: string;
  private ENDPOINT_GOAL_DATA = API_ENDPOINT.GET_GOAL_DATA_LIST_IDP;
  private apiMethod: (endpoint: string, payload: any) => Observable<any>;

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.groupForm && this.groupForm) {
      this.apiMethod = this.ds.postApi.bind(this.ds);
      this.tagsList?.forEach((tag: any) => {
        this.fetchDataForTags(tag, {}, '');
      });
    }
  }

  ngOnInit(): void {
    this.ENDPOINT_GOAL_DATA = API_ENDPOINT.GET_USER_GROUP_GOAL_DATA_LIST;
    this.apiMethod = this.ds.postApi.bind(this.ds);
    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((searchObj: any) => searchObj?.term?.trim()),
        switchMap(async (searchObj) => {
          this.itemsMap[searchObj?.tag] = [];
          this.searchText = searchObj?.term;
          this.fetchDataForTags(searchObj?.tag, {}, searchObj?.term)
        })
      )
      .subscribe(res => {
      });
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public onSearch(searchObj: any, group: FormGroup) {
    const tag = group.get('tag')?.value;
    if (!tag) {
      return;
    }
    searchObj.group = group.value;
    searchObj.tag = tag;
    this.search$.next(searchObj);
  }

  public getItemsForTag(tag: string): any[] {
    return this.itemsMap[tag] || [];
  }

  public isLoading(tag: string): boolean {
    return this.loadingMap[tag] || false;
  }

  public get conditions(): FormArray {
    return this.groupForm.get('conditions') as FormArray;
  }

  public trackByFn(item: IUserData) {
    return item.element;
  }

  public addCondition() {
    const condition = this.fb.group({
      type: 'condition',
      tag: [null],
      operator: ['is'],
      searchValue: [null],
    }
    );
    this.conditions.push(condition);
  }

  public addSubGroup() {
    if (this.maxNestingLevel > 1) {
      const subGroup = this.fb.group({
        type: 'group',
        logicOperator: ['AND'],
        conditions: this.fb.array([]),
      });
      (subGroup.get('conditions') as FormArray)?.push(this.createCondition());
      (subGroup.get('conditions') as FormArray)?.push(this.createCondition());
      this.conditions.push(subGroup);
    }
  }

  public removeCondition(index: number) {
    this.conditions.removeAt(index);
  }

  public removeGroup(index: number) {
    this.parentArray.removeAt(index);
  }

  public removeConditionOrGroup(index: number) {
    this.conditions.removeAt(index);
  }

  public onTagSelectChange(event: any, group: FormGroup) {
    group.get('searchValue')?.reset();
    this.selectedAccomplismentApiResponse = null;
    this.fetchDataForTags(event.type, {}, '');
  }

  public toggleAndOr(group: FormGroup) {
    const currentOperator = group.get('logicOperator')?.value;
    const newOperator = currentOperator === 'AND' ? 'OR' : 'AND';
    group.get('logicOperator')?.setValue(newOperator);
  }

  public onScrollToEndAccomplishments(accomplishmentType: string) {
    const type: any = accomplishmentType.toLowerCase();
    const paginationData: IPaginationVirtual = this.generatePaginationData(type);
    if (paginationData?.offset) {
      this.fetchDataForTags(type, paginationData, this.searchText || '');
    }
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private setLoadingState(tag: string, state: boolean): void {
    this.loadingMap[tag] = state;
  }

  private fetchDataForTags(filterType: AccomplishmentType, pagination: any = {}, searchStr) {
    let requestPayload = this.buildRequestPayloadAdmin(filterType, searchStr);
    requestPayload = { ...requestPayload, ...pagination };
    this.setLoadingState(filterType, true);
    if (filterType === AccomplishmentType.Skill) {
      this.getSkills(pagination, searchStr).pipe(take(1), finalize(() => { })).subscribe();
    } else {
      this.apiMethod(this.ENDPOINT_GOAL_DATA, requestPayload).pipe(take(1)).subscribe((responseData: ISelectedAccomplishmentResponse) => {
        this.setLoadingState(filterType, false);
        this.selectedAccomplismentApiResponse = { ...responseData };
        this.handleResponseForTags(responseData, filterType, searchStr);
      });
    }
  }

  private getSkills(pagination: IPaginationVirtual = {}, searchText: string = '') {
    const clientId = this.ds.currentAdminClientId;
    const requestPayload = this.buildRequestPayloadAdmin(AccomplishmentType.Skill, searchText);
    const queryParams = new HttpParams()
      .set('limit', (pagination.limit || 10).toString())
      .set('page', (pagination.offset || 0).toString());
    this.setLoadingState(AccomplishmentType.Skill, true);
    const baseURL = `${API_ENDPOINT.SEARCH_SKILLS(clientId)}${searchText}`;
    return this.ds.postApi(`${baseURL}&${queryParams.toString()}`, requestPayload).pipe(
      take(1),
      tap((responseData: ISkillResponseData) => {
        this.setLoadingState(AccomplishmentType.Skill, false);
        this.selectedAccomplismentApiResponse = { ...responseData };
        this.handleResponseForTags(responseData, AccomplishmentType.Skill, '');
      })
    );
  }

  private generatePaginationData(type: any) {
    let paginationData: IPaginationVirtual = {};
    
    if (type === AccomplishmentType.Skill) {
      paginationData = {
        offset: +(this.selectedAccomplismentApiResponse as ISkillResponseData).next
      };
    } else {
      const paginationStr = (this.selectedAccomplismentApiResponse as ISelectedAccomplishmentResponse).data?.paginationStr;
      const { currentEnd, totalResults } = this.utilitiesService.parsePaginationStr(paginationStr);
      if (currentEnd < totalResults) {
        paginationData = {
          offset: +(this.selectedAccomplismentApiResponse as ISkillResponseData).next || currentEnd
        };
      }
    }
    return paginationData;
  }

  private buildRequestPayloadAdmin(type: AccomplishmentType, searchStr): any {
    if(type === AccomplishmentType.Skill) {
      return {
        search: '',
        forAutoComplete: true,
        userId: this.ds.user.userId,
        type: type,
        clientId: this.ds.currentAdminClientId,
        maxModifiedDt: '',
        limit: 10
      };
    } else {
      return {
        clientId: this.ds.currentAdminClientId,
        type: type,
        id: this.ds.user.userId,
        search: searchStr ? searchStr : '',
        limit: 10
      };
    }
  }

  private handleResponseForTags(res: any, tag: string, searchText: string) {
    let resposeMapTag = '';
    switch (tag) {
      case AccomplishmentType.Course:
        resposeMapTag = 'coursename';
        break;
      case AccomplishmentType.Skill:
        resposeMapTag = 'title';
        break;
      case AccomplishmentType.Specialization:
        resposeMapTag = 'specializationname';
        break;
      case AccomplishmentType.Certification:
        resposeMapTag = 'certificationname';
        break;
      case AccomplishmentType.Designation:
        resposeMapTag = 'designationname';
        break;
    }
    this.handleResponse(res, tag, resposeMapTag, searchText)
  }

  private handleResponse(responseData: any, tag: string, resposeMapTag: string, searchText: string) {
    const resultData = responseData.data?.searchData || responseData.data;
    let currentResponse = resultData?.map(item => ({ title: item[resposeMapTag], id: item.id || item.pk })) ?? [];
    if (this.itemsMap[tag]) {
      this.itemsMap[tag] = [
        ...this.itemsMap[tag],
        ...currentResponse,
      ];
    } else {
      this.itemsMap[tag] = [
        ...currentResponse,
      ];
    }
  }

  private createCondition(): FormGroup {
    return this.fb.group({
      type: 'condition',
      tag: [null],
      operator: ['is'],
      searchValue: [null],
    },
    );
  }
}