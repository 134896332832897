import { DIALOG_TYPES } from 'src/app/constants';

const BASE_DIALOG_CONFIG = {
    type: DIALOG_TYPES.GENERIC,
    warnButton: false,
    noGrow: true
};

export const SINGLE_SKILL_CAUTION_DIALOG = {
    ...BASE_DIALOG_CONFIG,
    title: 'Caution!',
    message: 'Double-check this before approving:',
    btnTitle: 'Approve',
    btn2Title: 'Cancel'
};

export const ALL_SKILLS_CAUTION_DIALOG = {
    ...BASE_DIALOG_CONFIG,
    message: 'Please approve them individually, to ensure accuracy.',
    btnTitle: 'Okay'
};

export const PARTIAL_SKILLS_CAUTION_DIALOG = {
    ...BASE_DIALOG_CONFIG,
    message: 'Please approve them individually, to ensure accuracy.',
    btn2Title: 'Cancel'
};
export const USER_SKILLS_CAUTION_DIALOG = {
    ...BASE_DIALOG_CONFIG,
    message: `Please review them carefully before saving, to ensure accuracy.`,
    btnTitle: 'Save Anyway',
    btn2Title: 'Cancel',
    warnButton: false,
    noGrow: true
};
