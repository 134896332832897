import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EditableTableComponent } from 'src/app/modules/common-components/editable-table/editable-table.component';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalSkillTypeValidationService } from 'src/app/modules/my-skills/services/global-skill-type-validation.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { ValidateString } from 'src/app/services/validation.service';
import { ACCESS_LEVELS, SMS_OPTIONS, DEFAULT_GLOBAL_SKILL_TYPES } from "../../../../constants";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-skill-management-settings',
    templateUrl: './skill-management-settings.component.html',
    standalone: false
})
export class SkillManagementSettingsComponent implements OnInit, OnDestroy {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  @Output() onGlobalSkillType = new EventEmitter();
  editForm: FormGroup;

  smsOptions: any[] = [
    {
      title: 'Skill Rating Mechanism',
      key: 'skillRating',
      type: 'select',
      options: [
        'Stars 1-5',
        'Stars 1-4'
      ],
      validators: [Validators.required],
      icon: 'settings_suggest',
      defaultValue: 'Stars 1-5',
      required: true,
    },
    {
      title: 'Show Interview Feedback Form',
      key: 'manageInterviewFeedbackForm',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Show Carousel on My skill page',
      key: 'showCarouselOnMySkills',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
    },
    {
      title: 'Repeat my skills info carousel after? (Days)',
      key: 'repeatCarouselMySkills',
      type: 'number',
      validators: [Validators.required, Validators.min(0)],
      defaultValue: 0,
      checkValue: [{ key: 'showCarouselOnMySkills', values: [SMS_OPTIONS.YES] }],
      icon: 'settings_suggest',
    },
    {
      title: 'Enable Onboarding',
      key: 'enableOnboarding',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.YES
    },
  ];

  skillRequestOptionsFormGroup: FormGroup;
  skillTypeSettingsForm: FormGroup;
  mySkillsSummaryFormGroup: FormGroup;
  showSkillAdditionRequests: boolean = false;
  isGlobalAdmin: boolean = false;

  skillRequestOptions: any[] = [
    {
      title: 'Allow Skill Requests by Employees',
      key: 'allowSkillRequests',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: "settings_suggest",
      defaultValue: SMS_OPTIONS.NO,
      required: true
    }
  ]
  skillTypeSettingOptions: any[] = [
    {
      title: 'Enable Global Skill Type',
      key: 'enableGlobalSkillType',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: "settings_suggest",
      defaultValue: SMS_OPTIONS.NO,
      required: true
    }
  ]
  mySkillsSummaryOptions: any[] = [
    {
      title: 'Show Summary View',
      key: 'showSummaryView',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [Validators.required],
      icon: "settings_suggest",
      defaultValue: SMS_OPTIONS.NO,
      required: true
    },
    {
      title: 'Manage Skill Description',
      key: 'manageSkillsDescription',
      type: 'textarea',
      validators: [Validators.maxLength(1000)],
      icon: 'settings_suggest'
    },
  ];
  globalSkillTypes: any[] = DEFAULT_GLOBAL_SKILL_TYPES;
  subscription: Subscription;
  skillTypesColumns: any[];
  skillTypesFormValid: boolean = true;
  @ViewChild('skillTypes') skillTypesTable: EditableTableComponent;

  constructor(
    private ds: DataService,
    private fb: FormBuilder,
    private weds: WaitErrorDialogsService,
    private globalSkillTypeValidationService: GlobalSkillTypeValidationService
  ) {
    this.editForm = this.fb.group({});
    this.skillTypeSettingsForm = this.fb.group({});
    this.skillRequestOptionsFormGroup = this.fb.group({});
    this.mySkillsSummaryFormGroup = this.fb.group({});
  }

  ngOnInit(): void {
    this.globalSkillTypes = this.client.smsSettings.globalSkillTypes || this.globalSkillTypes;
    this.skillTypesColumns = [
      { title: 'Skill Types', key: 'name', type: 'text', disabled: !this.canEdit, required: true, validators: [Validators.required, ValidateString(2)], placeholder: 'E.g.: Primary, Secondary, Others' },
      { title: 'Minimum', key: 'min', type: 'number', disabled: !this.canEdit, required: false, validators: [], disableIfEmpty: true },
      { title: 'Maximum', key: 'max', type: 'number', disabled: !this.canEdit, required: false, validators: [], disableIfEmpty: true },
    ];
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  @Input()
  set client(client: Client) {
    this._client = client;
    this.setAccessValues();
    this.buildForms();
    this.buildSkillTypeSettingForm();
    this.buildSkillRequestOptionsForm();
    this.buildMySkillsSummaryForm();
  }
  get client() {
    return this._client;
  }

  setAccessValues() {
    this.isGlobalAdmin = this.ds.user.role.globalAdmin;
    this.showSkillAdditionRequests = this.isGlobalAdmin || this.ds.user.role.admin?.skillAdditionRequests === ACCESS_LEVELS.EDIT;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value =
        currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => {
        if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) == ACCESS_LEVELS.NO_ACCESS)
          s.showControl = false;
      });
      (s.checkValue || []).map((item) => {
        if (
          item.values.indexOf(
            this.editForm.contains(item.key)
              ? this.editForm.get(item.key).value
              : this.client.smsSettings[item.key]
          ) < 0
        )
          s.showControl = false;
      });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)
        this.editForm.addControl(
          s.key,
          new FormControl(
            { value: value, disabled: !this.canEdit },
            s.validators
          )
        );
    });
  }

  buildSkillTypeSettingForm() {
    this.skillTypeSettingOptions.forEach(option => {
      let currentValue = this.skillTypeSettingsForm.value;
      let value = currentValue[option.key] || this.client.smsSettings[option.key];

      this.skillTypeSettingsForm.addControl(
        option.key,
        new FormControl(
          { value: value, disabled: !this.canEdit },
          option.validators
        )
      );
      if (option.key === 'enableGlobalSkillType') {
          if (value === SMS_OPTIONS.YES) {
            this.onGlobalSkillType.emit(true);
          } else {
            this.onGlobalSkillType.emit(false);
          }
      }
    })
  }

  buildSkillRequestOptionsForm() {
    this.skillRequestOptions.forEach(option => {
      let currentValue = this.skillRequestOptionsFormGroup.value;
      let value = currentValue[option.key] || this.client.smsSettings[option.key];

      this.skillRequestOptionsFormGroup.addControl(
        option.key,
        new FormControl(
          { value: value, disabled: !this.canEdit },
          option.validators
        )
      );
    })
  }

  buildMySkillsSummaryForm() {
    this.mySkillsSummaryOptions.forEach(option => {
      let currentValue = this.mySkillsSummaryFormGroup.value;
      let value = currentValue[option.key] || this.client.smsSettings[option.key];

      this.mySkillsSummaryFormGroup.addControl(
        option.key,
        new FormControl(
          { value: value, disabled: !this.canEdit },
          option.validators
        )
      );
    })
  }

  onSkillTypesValidityChange(isValid: boolean) {
    this.skillTypesFormValid = isValid;
  }

  saveData() {
    if (this.editForm.invalid) return;
    this.validateProficiencyTooltipStatus();
    let d = this.editForm.value;
    d.type = 'skillManagementGeneralSettings';
    this.onSave.emit(d);
  }

  saveTooltipData(event) {
    this.onSave.emit(event);
  }

  saveSkillRequestData() {
    if (this.skillRequestOptionsFormGroup.invalid) return;
    let d = this.skillRequestOptionsFormGroup.value;
    const payload = {
      allowSkillRequests: d.allowSkillRequests,
      type: 'skillAdditionRequests'
    };
    this.onSave.emit(payload);
  }

  saveSkillTypeData() {
    if (this.skillTypeSettingsForm.invalid || !this.skillTypesFormValid) return;
    let d = this.skillTypeSettingsForm.value;
    d.type = 'globalSkillType';
    if (d.enableGlobalSkillType === SMS_OPTIONS.YES) {
      let data = this.skillTypesTable.getDataFromForm();
      d.globalSkillTypes = this.globalSkillTypeValidationService.transformGlobalSkillTypePayload(data);
      if (!data.length) { this.weds.showDialog({ type: 'generic', code: 180 }); return; }
    }
    this.onSave.emit(d);
  }

  saveMySkillsSummaryData() {
    if (this.mySkillsSummaryFormGroup.invalid) return;
    let d = this.mySkillsSummaryFormGroup.value;
    const payload = {
      showSummaryView: d.showSummaryView,
      manageSkillsDescription: d.manageSkillsDescription ? d.manageSkillsDescription : '',
      type: 'mySkillsSummary'
    };
    this.onSave.emit(payload);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, { value: selectedValue }) {
    [
      'manageInterviewFeedbackForm',
      'showCarouselOnMySkills',
    ].includes(smsOption.key) && this.buildForms();
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }

  validateProficiencyTooltipStatus() {
    if (this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.NO) {
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
      this.editForm.controls?.proficiencyOptions?.setValue([]);
    }
    if (this.editForm?.value?.proficiencyOptions?.indexOf('Description') === -1)
      this.editForm.controls?.useProficiencyTooltip?.setValue(SMS_OPTIONS.NO);
  }

  checkProficiencyMasterEnabled(): boolean {
    return this.editForm?.value?.useSkillProficiency === SMS_OPTIONS.YES;
  }

  renderSkillProficiencyRelationNode(key: string): boolean {
    return /useProficiencyTooltip|proficiencyOptions/.test(key)
      ? this.checkProficiencyMasterEnabled()
      : true;
  }
}
