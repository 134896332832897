import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, ReactiveFormsModule } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { STORAGE_CONSTANTS } from 'src/app/constants/storage.constants';
import { ITagsConfigMap } from '../../prism-search.model';
import { SearchGroupComponent } from './search-group/search-group.component';

@Component({
  selector: 'app-advanced-search-form',
  templateUrl: './advanced-search-form.component.html',
  styleUrls: ['./advanced-search-form.component.scss'],
  imports: [
    ReactiveFormsModule,
    SearchGroupComponent
  ]
})
export class AdvancedSearchFormComponent implements OnInit {
  @Input() tagsConfigMap: ITagsConfigMap;
  public searchForm: FormGroup;
  public tagValuesAPIResposne: any = null;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private fb: FormBuilder, private ds: DataService, private weds: WaitErrorDialogsService) {
    this.searchForm = this.fb.group({
      conditions: this.fb.array([]),
      logicOperator: ['AND']
    });
  }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.getSearchFiltersWithAllowedOperators();
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private getSearchFiltersWithAllowedOperators(): void {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = {
      clientId: this.ds.client.clientId,
    }

    this.ds.careerPrismDataPostApi(API_ENDPOINT.ADVANCED_PRISM_SEARCH_FILTERS, payload).pipe(take(1)).subscribe(res => {
      dialogRef.close();
      this.tagValuesAPIResposne = res;

      const query = JSON.parse(localStorage.getItem(STORAGE_CONSTANTS.ADVANCED_SEARCH_FORMATTED_QUERY));
      if(query) {
        this.populateForm(query);
      }
    });
  }

  public populateForm(queryObject: any): void {
    this.searchForm.patchValue({
      logicOperator: queryObject.logicOperator,
    });

    const conditionsArray = this.searchForm.get('conditions') as FormArray;
    this.populateConditions(conditionsArray, queryObject.conditions);
  }

  private populateConditions(conditionsArray: FormArray, conditions: any[]): void {
    conditions.forEach((condition: any) => {
      if (condition.type === 'group') {
        const subGroup = this.fb.group({
          type: 'group',
          logicOperator: condition.logicOperator,
          conditions: this.fb.array([]),
        });
        this.populateConditions(subGroup.get('conditions') as FormArray, condition.conditions);
        conditionsArray.push(subGroup);
      } else {
        const conditionGroup = this.createConditionGroup(condition);
        conditionsArray.push(conditionGroup);
      }
    });
  }

  private createConditionGroup(condition: any): FormGroup {
    const normalizedSearchValue =
      condition.operator === 'contains' && !Array.isArray(condition.searchValue)
        ? [condition.searchValue]
        : condition.searchValue;

    const conditionGroup: FormGroup = this.fb.group({
      type: condition.type,
      tag: condition.tag,
      operator: condition.operator,
      experienceOptions: condition.experienceOptions,
      alphanumeric: condition.alphanumeric,
      startDate: condition.startDate,
      endDate: condition.endDate,
      conditionType: condition.conditionType,
      proficiencyLevel: condition.proficiencyLevel,
      proficiencyLevel2: condition.proficiencyLevel2,
      poolCode: condition.poolCode,
      allocationPercentage: condition.allocationPercentage,
      allocationPercentage2: condition.allocationPercentage2,
      allocationPercentageDisabled: condition.allocationPercentageDisabled,
    });

    if(condition.tag === 'skill') {
      conditionGroup.get('experienceOptions').patchValue('Proficiency');
      conditionGroup.get('experienceOptions').disable();
    }
    // allocation
    if(condition.tag === 'allocation') {
      conditionGroup.get('poolCode').patchValue('Pool code');
      conditionGroup.get('poolCode').disable();
      conditionGroup.get('allocationPercentageDisabled').patchValue('Allocation percentage(%)');
      conditionGroup.get('allocationPercentageDisabled').disable();
    }

    conditionGroup.addControl('searchValue', this.fb.control(normalizedSearchValue));

    return conditionGroup;
  }

}
