<form [formGroup]="editForm">
  <mat-form-field appearance="outline" class="input-form-field">
    @if (input.title&&!input.noAutoCompleteTitle) {
      <mat-label>{{input.title}}</mat-label>
    }
    @if (input.icon) {
      <mat-icon matPrefix>{{input.icon}}</mat-icon>
    }
    <input matInput placeholder="{{placeHolder}}" formControlName="inputControl" [required]="!input.notRequired"
      [matAutocomplete]="auto" (focus)="doInitialLoad()"/>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDisplayName"
      (optionSelected)="onSelectionChange($event)" (opened)="autocompleteScroll()">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{getDisplayOptions(option)}}
        </mat-option>
      }
    </mat-autocomplete>
    @if (input.suffixIcon) {
      <mat-icon matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
    }
    <mat-error>
      @for (validation of validation_msgs; track validation.message) {
        @if (hasError(validation.type)) {
          {{ validation.message }}
        }
      }
    </mat-error>
  </mat-form-field>
</form>
