<ngx-file-drop dropZoneClassName="upload-widget-dropzone" contentClassName="upload-widget-content"
  (onFileDrop)="onFileDropped($event)" [accept]="'application/pdf'" [multiple]="false">
  <ng-template ngx-file-drop-content-tmp>
    <div [ngClass]="{ 'drop-zone': true, 'dragging': isDragging }" (dragover)="onDragOver()"
      (dragleave)="onDragLeave()" (click)="triggerFileInput()">
      <input type="file" #fileInput [accept]="'application/' + supportedFileFormatsForUpload" (change)="onFileSelected($event)" style="display: none;" />
      @if (!file) {
        @if (!isUploading) {
          <div class="upload-initial">
            <app-svg-icon height="24px" width="24px" src="icons/description.svg" viewBox="0 0 24 24" class="upload-icon"></app-svg-icon>
            <div class="upload-initial-text">
              <app-svg-icon class="mt-1" height="16px" width="16px" src="icons/plus-icon.svg" viewBox="0 0 20 20"></app-svg-icon>
              UPLOAD FILE
            </div>
            <div class="upload-initial-label">
              Choose a file or drag it here. Only .{{supportedFileFormatsForUpload}} format are supported. @if (maxFileSizeInMB) {
              <span>Maximum filesize is {{maxFileSizeInMB}} MB.</span>
            }
          </div>
        </div>
      }
    }
    @if (file) {
      @if (isUploading) {
        <div class="uploading">
          <app-svg-icon height="24px" width="24px" src="icons/description.svg" viewBox="0 0 24 24" class="upload-icon"></app-svg-icon>
          <div class="uploading-label black">Uploading file...</div>
          <a class="uploading-button" (click)="cancelUpload($event)">CANCEL</a>
        </div>
      }
      @if (!isUploading) {
        <div class="uploaded">
          <app-svg-icon height="24px" width="24px" src="icons/description.svg" viewBox="0 0 24 24" class="upload-icon"></app-svg-icon>
          <div class="uploaded-label black">
            {{ file.name }}
            <a class="uploaded-label-button" (click)="removeFile($event)">✕</a>
          </div>
          <a class="uploaded-button" (click)="changeFile($event)">CHANGE FILE</a>
        </div>
      }
    }
  </div>
</ng-template>
</ngx-file-drop>
