<div class="container h-100 overflow-hidden">
  <div class="top">
    <div class="top-inner">
      <div class="body-large white">
        <div class="h4">Preview</div>
      </div>
      <mat-icon class="close-icon" mat-dialog-close >close</mat-icon>
    </div>
  </div>

  <div class="container-body d-flex flex-column gap-3">
    @if (data.showRedirectTo && data.url) {
      <div class="content d-flex gap-2 bg-white align-items-center">
        <span class="h5">{{data.linkTitle}}</span>
        <mat-icon class="ic-16 gray h-16px cursor-pointer" (click)="openLink()">open_in_new</mat-icon>
      </div>
    }

    <div class="content preview-box bg-white" [ngClass]="[data.showRedirectTo && data.url ? 'height-with-url' : 'h-100']">
      @if (data?.presignedUrl) {
        <div class="item-center h-100 ">
          @if (isImage) {
            <img [src]="data?.presignedUrl" alt="Preview Image" />
          }
          @if (isPDF) {
            <iframe [src]="data?.presignedUrl" class="w-100 h-100"></iframe>
          }
          @if (!isImage && !isPDF) {
            <div class="h4 gray item-center h-100">Unsupported file format</div>
          }
        </div>
      } @else {
        <div class="h4 gray item-center h-100">Show file preview here</div>
      }

    </div>

  </div>
</div>
