import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ACCESS_LEVELS, SMS_OPTIONS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';

interface ISMSOption {
  title: string;
  key: string;
  type: 'select' | 'textarea' | 'text' | 'date';
  options?: string[];
  validators: any[];
  icon: string;
  defaultValue?: any;
  subGroup: string;
  order: number;
  required?: boolean;
  showControl?: boolean;
  checkValue?: Array<{ key: string, values: string[] }>;
  isMulti?: boolean;
  allowIf?: Array<string>;
  tooltip?: string;
}

@Component({
  selector: 'app-skill-accuracy',
  templateUrl: './skill-accuracy.component.html',
  styleUrls: ['./skill-accuracy.component.scss'],
  standalone: false
})
export class SkillAccuracyComponent implements AfterViewInit {
  @ViewChild('textAreaRef') textArea!: ElementRef<HTMLTextAreaElement>;
  private _client: Client;
  @Input() canEdit = false;
  @Output() onSave = new EventEmitter<{ type: string, [key: string]: any }>();
  editForm: FormGroup;
  characterCount = 0;
  maxCharacterCount = 150;
  smsOptions: ISMSOption[] = [
    {
      title: 'Enable User Cautions',
      key: 'enableUserCaution',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.NO,
      subGroup: 'User',
      order: 1
    },
    {
      title: 'Collect User Evidence',
      key: 'captureSkillProofFromUser',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.NO,
      subGroup: 'User',
      order: 2
    },
    {
      title: 'Text for Caution',
      key: 'userCautionText',
      type: 'textarea',
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,
      checkValue: [{ key: 'enableUserCaution', values: [SMS_OPTIONS.YES] }],
      subGroup: 'User',
      order: 3
    },
    {
      title: 'Enable Endorser Cautions',
      key: 'enableEndorserCaution',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.NO,
      subGroup: 'Endorser',
      order: 1
    },
    {
      title: 'Enable Endorser Aids',
      key: 'enableAidForEndorser',
      type: 'select',
      options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
      validators: [],
      icon: 'settings_suggest',
      defaultValue: SMS_OPTIONS.NO,
      subGroup: 'Endorser',
      order: 2
    },
  ];
  constructor(private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  ngAfterViewInit(): void {
    this.updateCharacterCount();
  }

  public buildForms(): void {
    this.smsOptions.forEach((option) => {
      const currentValue = this.editForm.value;
      const value = this.getOptionValue(option, currentValue);
      option.showControl = this.shouldShowControl(option);

      this.updateFormControl(option, value);
    });
  }

  public saveData(): void {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'skillAccuracySettings';
    this.onSave.emit(d);
  }

  public compareSelect(o1: any, o2: any): boolean {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  public onSMSOptionSelected(smsOption: ISMSOption, event: { value: any }): void {
    if (smsOption.key === 'enableUserCaution') {
      this.buildForms();
    }
  }

  public evaluateEnterKey(event: any): void {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

  public updateCharacterCount(): void {
    let textarea = this.textArea.nativeElement;
    if (textarea.value.length > this.maxCharacterCount) {
      textarea.value = textarea.value.substring(0, this.maxCharacterCount);
    }
    this.characterCount = textarea.value.length;
  }

  private getOptionValue(option: ISMSOption, currentValue: any): any {
    return currentValue[option.key] ||
      this.client?.smsSettings?.skillAccuracySettings?.[option.key] ||
      option.defaultValue;
  }

  private shouldShowControl(option: ISMSOption): boolean {
    let show = true;

    if (option.allowIf?.length) {
      show = !option.allowIf.some(key =>
        (this.client?.features?.admin[key] || ACCESS_LEVELS.NO_ACCESS) === ACCESS_LEVELS.NO_ACCESS
      );
    }

    if (show && option.checkValue?.length) {
      show = !option.checkValue.some(item => {
        const value = this.editForm.get(item.key)?.value ||
          this.client?.smsSettings?.skillAccuracySettings?.[item.key];
        return !item.values.includes(value);
      });
    }

    return show;
  }

  private updateFormControl(option: ISMSOption, value: any): void {
    if (this.editForm.contains(option.key)) {
      this.editForm.removeControl(option.key);
    }

    if (option.showControl) {
      this.editForm.addControl(
        option.key,
        new FormControl(
          { value, disabled: !this.canEdit },
          option.validators
        )
      );
    }
  }
}
