import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ACCESS_LEVELS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { ALIASES_MASTER_LIST } from './aliases-config';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-aliases',
    templateUrl: './aliases.component.html',
    standalone: false
})
export class AliasesComponent {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  aliasForm: FormGroup;
  aliasTotalList: any[];

  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.aliasForm = this.fb.group({});
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  
  get client() {
    return this._client;
  }

  buildForms() {
    const allowedLevelOfSpecialization = Number(this.client.smsSettings.levelOfSpecializationCount);

    const dynamicLevelOfSpecializationAliases = [...new Array(allowedLevelOfSpecialization + 1 || 0)]
      .map((_, index) => ({
        title: `Level Of Specialization Alias ${index}`,
        key: `levelOfSpecialization${index}`,
        type: 'text',
        validators: [],
        icon: 'settings_suggest',
      }));

    this.aliasTotalList = cloneDeep(ALIASES_MASTER_LIST);
    if(allowedLevelOfSpecialization) {
      const specCategoryAlias = this.aliasTotalList.find(obj => obj.category === "My Specialization");
      specCategoryAlias.aliasesCategoryList.push(...dynamicLevelOfSpecializationAliases);
    }

    this.aliasTotalList.forEach((feature) => {
      feature.aliasesCategoryList?.forEach((aliasItem) => {
        aliasItem.showControl = true;
        (aliasItem.allowIf || []).forEach((key) => {
          if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) === ACCESS_LEVELS.NO_ACCESS) aliasItem.showControl = false;
        });
    
        (aliasItem.checkValue || []).forEach((item) => {
          const formValue = this.aliasForm.contains(item.key) ? this.aliasForm.get(item.key).value : this.client.smsSettings[item.key];
    
          if (!item.values.includes(formValue)) {
            aliasItem.showControl = false;
          }
        });

        if (this.aliasForm.contains(aliasItem.key)) {
          this.aliasForm.removeControl(aliasItem.key);
        }
        if (aliasItem.showControl) {
          this.aliasForm.addControl(aliasItem.key, new FormControl(
            { value: this.client.smsSettings[aliasItem.key], disabled: !this.canEdit }, 
            aliasItem.validators
          ));
        }
      });
    });
  }

  saveAliases() {
    if (this.aliasForm.invalid) return;
    let aliasFormValue = this.aliasForm.value;
    aliasFormValue.type = 'aliases';
    this.onSave.emit(aliasFormValue);
  }
}
