<div class="d-flex">
  <div>
    <label class="material-checkbox">
      <input type="checkbox" [disabled]="disabled" [checked]="checked"
        (change)="onCheckboxSelected($event.target.checked)" />
      <span></span>
    </label>
  </div>
  <div class="d-flex flex-column gap-1">
    <span class="body">{{label}}</span>
    @if (description) {
      <span class="caption gray">{{description}}</span>
    }
  </div>
</div>
