import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { IAvatar, IAvatarResponse, IFile } from './avatar.model';
import { ToastService } from 'src/app/modules/common-components/toast/toast.service';
import { ISearchData, ISearchPayload } from '../../../admin-goal-management/layouts/admin-goal-management-layout';
import { ISettingsOption } from '../../../sms-settings/settings/settings.model';
import { Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { AvatarService } from '../../services/avatar.service';
import { IAvatarOutputResponse } from '../../components/avatar-list/avatar-list.component';

@Component({
    selector: 'app-avatar-layout',
    templateUrl: './avatar-layout.component.html',
    styleUrls: ['./avatar-layout.component.scss'],
    standalone: false
})
export class AvatarLayoutComponent implements OnChanges {

  @Input() client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();

  public avatarsEnabledResponse: IAvatarResponse;
  public avatarsDisabledResponse: IAvatarResponse;

  public settingsOptionTitle = '3D avatar settings';
  public avatarSettingsOptions: ISettingsOption[] = [
    {
      title: 'Allow user to select a 3D Avatar from enabled settings',
      key: 'allow3dAvatars',
      type: 'select',
      options: ['Yes', 'No'],
      validators: [Validators.required],
      icon: 'settings_suggest',
      settingsType: 'avatarSettings'
    },
  ];

  private defaultLimit: number = 10;

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private weds: WaitErrorDialogsService, private ds: DataService, private toast: ToastService, private avatarService: AvatarService) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.client && this.client && this.hasAccessAvatar) {
      this.fetchAllAvatarsData();
    }
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public onAddAvatarEvent(data: { file: IFile, gender: string }) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { file: data.file, gender: data.gender, clientId: this.client.clientId };
    this.ds.postApi<IAvatarResponse>(API_ENDPOINT.ADD_AVATAR_ADMIN, payload).pipe(take(1)).subscribe((res: any) => {
      this.weds.closeDialog(dialogRef);
      const msg = 'Avatar added successfully';
      this.toast.showToast({ type: 'success', msg: msg });
      if(res.status) {
        this.fetchAvatars();
      }
    });
  }

  public onDeleteEvent(data: IAvatarOutputResponse) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { avatarId: data.avatar.avatarId, clientId: this.client.clientId };
    this.ds.postApi<IAvatarResponse>(API_ENDPOINT.DELETE_AVATAR_ADMIN, payload).pipe(take(1)).subscribe((res: any) => {
      this.weds.closeDialog(dialogRef);
      const msg = 'Avatar deleted successfully';
      this.toast.showToast({ type: 'success', msg: msg });
      if(res.status) {
        this.fetchAllAvatarsData(data.pagination);
      }
    });
  }

  public onUpdateEvent(data: { file: IFile, avatar: IAvatar, gender: string, pagination: any }) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { avatarId: data.avatar.avatarId, gender: data.gender, file: data.file, clientId: this.client.clientId };
    this.ds.postApi<IAvatarResponse>(API_ENDPOINT.UPDATE_AVATAR_ADMIN, payload).pipe(take(1)).subscribe((res: any) => {
      this.weds.closeDialog(dialogRef);
      const msg = 'Avatar updated successfully';
      this.toast.showToast({ type: 'success', msg: msg });
      if(res.status) {
        this.fetchAvatars({ pageNo: data.pagination.enabled }, data.avatar.isEnabled ? 'active' : 'inactive');
      }
    });
  }

  public onEnableDisableEvent(data: IAvatarOutputResponse) {
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { avatarId: data.avatar.avatarId, clientId: this.client.clientId };
    const ENDPOINT = data.avatar.isEnabled ? API_ENDPOINT.ENABLE_AVATAR_ADMIN : API_ENDPOINT.DISABLE_AVATAR_ADMIN;
    this.ds.postApi<IAvatarResponse>(ENDPOINT, payload).pipe(take(1)).subscribe((res: any) => {
      this.weds.closeDialog(dialogRef);
      const msg = `Avatar ${data.avatar.isEnabled ? 'Enable' : 'Disable'} successfully`;
      this.toast.showToast({ type: 'success', msg: msg });
      if(res.status) {
        this.fetchAllAvatarsData(data.pagination);
      }
    });
  }

  public onPaginationEvent(data: ISearchPayload) {
    this.fetchAvatars(data);
  }

  public onPaginationDisbaledEvent(data: ISearchPayload) {
    this.fetchAvatars(data, 'inactive');
  }

  public handleSave(data: any) {
    this.onSave.emit(data);
  }

  get hasAccessAvatar(): boolean {
    return this.avatarService.hasAccessAvatar(this.client);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private fetchAvatars(payload?: ISearchData, type: 'active' | 'inactive' = 'active' ): void {
    this.defaultLimit = payload?.limit || this.defaultLimit;
    const dialogRef = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const defaultPayload = {
      pageNo: 1,
      limit: this.defaultLimit,
      getTotalCount: true,
      type: type,
      clientId: this.client.clientId
    };
    const finalPayload = { ...defaultPayload, ...payload };
    this.ds.postApi<IAvatarResponse>(API_ENDPOINT.GET_LIST_OF_AVATAR_ADMIN, finalPayload).pipe(take(1)).subscribe((res: IAvatarResponse) => {
      this.weds.closeDialog(dialogRef);

      if(type === 'active') {
        this.avatarsEnabledResponse = {...res};
      } else {
        this.avatarsDisabledResponse = {...res};
      }
    });
  }

  private fetchAllAvatarsData(pagination?: any) {
    this.fetchAvatars({ pageNo: pagination?.enabled || 1 });
    this.fetchAvatars({ pageNo: pagination?.disabled || 1 }, 'inactive',);
  }

}
