import { Component, OnInit } from '@angular/core';
import { Toast } from './toast.model';
import { ToastService } from './toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: false
})
export class ToastComponent implements OnInit {

  constructor(public toastService: ToastService) { }

  toasts: Array<Toast> = [];

  ngOnInit(): void {
    this.toastService.initializeToast(this);
  }
}
