@if (data?.totalDataCnt && data?.totalDataCnt != 0) {
  <div class=" bottom-con caption gray">
    <div class="limit-con">
      <mat-select [value]="limit" [disabled]="disableLimit" (selectionChange)="onLimitChange($event)">
        @for (num of numPageOptions; track num) {
          <mat-option [value]="num">{{num}}
          </mat-option>
        }
      </mat-select>
      <div>rows per page</div>
    </div>
    @if (limit < data.totalDataCnt) {
      <div class="offset-con">
        <span style="margin-right:8px;">Showing {{startOfRange}} to
        {{endOfRange}} of {{data.totalDataCnt}} {{itemTitle}}</span>
        @if (pageNumber>1) {
          <mat-icon (click)="onNextPrev('first')">arrow_left</mat-icon>
        }
        @if (pageNumber>1) {
          <mat-icon (click)="onNextPrev('prev')">keyboard_arrow_left</mat-icon>
        }
        @for (num of numPages; track num) {
          <div class="page-number-button"
            [ngClass]="{'page-number-button-selected':num==pageNumber}" (click)="onPageNumberClick(num)">{{num}}
          </div>
        }
        @if (pageNumber*limit<data.totalDataCnt) {
          <mat-icon (click)="onNextPrev('next')">
            keyboard_arrow_right
          </mat-icon>
        }
        @if (pageNumber*limit<data.totalDataCnt) {
          <mat-icon (click)="onNextPrev('last')">arrow_right
          </mat-icon>
        }
      </div>
    }
  </div>
}