
@if (!allAssignedGoals?.length && !allInReviews.length) {
  <div class="data-con mt-4 mb-4">
    <div class="no-data-sec item-center">
      <img height="64px" width="64px" src="assets/nodata.png" />
      <div class="h5">No goals to show</div>
      <button mat-stroked-button class="primary-button mt-4" (click)="onAddClick()">
        <mat-icon class="add-icon ic-16">add</mat-icon>
        ADD GOAL
      </button>
    </div>
  </div>
}

<div class="data-section mb-20">
  <!-- In Review Section -->
  @if (allInReviews?.length) {
    <div class="goal-in-review-sec data-con mt-4 mb-4">
      <div class="header d-flex justify-content-between gap-1">
        <div class="lhs d-flex item-center gap-2">
          <div class="label-info gap-1 d-flex align-items-center">
            <mat-icon>pending</mat-icon>
            In review ({{allInReviews?.length}})
          </div>
          <mat-checkbox [checked]="allInReviewSelected" (change)="onSelectAllChange($event)">
            <span class="body font-weight-normal">Select All</span>
          </mat-checkbox>
        </div>
        @if (isSelected || allInReviewSelected) {
          <div class="rhs d-flex gap2">
            <div class="archived-goals mr-3">
              <button mat-stroked-button class="secondary-button fs-12" (click)="archiveSelected()">
                <mat-icon class="archived-icon ic-16">inventory_2</mat-icon>
                ARCHIVE SELECTED GOALS
              </button>
            </div>
            <div class="add-goals">
              <button mat-stroked-button class="success-button fs-12" (click)="acceptRevised()">
                <mat-icon class="check-icon ic-16">check_circle</mat-icon>
                ACCEPT REVISED GOALS
              </button>
            </div>
          </div>
        }
      </div>
      <div class="body-container">
        @for (goal of allInReviews; track goal; let i = $index) {
          <div class="in-review-list data-con">
            <div class="checkbox-container mr-3">
              <input class="clickable" width="20px" height="20px" type="checkbox" (click)="onSelectInReviewGoal(goal)" [checked]="goal.selected" name="select-{{i}}" [id]="i">
            </div>
            <div class="progress d-flex">
              <circle-progress
                class="circle-wi"
                percent="10"
                [innerStrokeWidth]="4"
                [outerStrokeWidth]="4"
                [backgroundPadding]="-6"
                [space]="-4"
                [radius]="25"
                [maxPercent]="100"
                [showImage]="true"
                [innerStrokeColor]="getBGColorForGoalStatus(goal.approvalStatus)"
                [imageSrc]="getSVGPath(goal.type)"
                [outerStrokeColor]="getBGColorForGoalStatus(goal.approvalStatus)"
                imageHeight="24"
                [backgroundColor]="getBGColorForGoalStatus(goal.approvalStatus)"
                imageWidth="24"
                [showZeroOuterStroke]="false"
                [startFromZero]="false"
                skeletonNew>
              </circle-progress>
            </div>
            <div class="details d-flex flex-column w-100">
              <div class="top-part d-flex justify-content-between">
                <div class="title h5">{{goal.title}}</div>
                <div class="d-flex align-items-center gap-12">
                  <div class="goalStatus btn h6 item-center p-1"
                    [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.approvalStatus), 'color': getColorForGoalStatus(goal.approvalStatus)}"
                    >
                    {{goal.approvalStatus}}
                  </div>
                  @if (goal.approvalStatus === 'REVISED') {
                    <div class="goal-icons-con d-flex align-items-center gap-12" (click)="$event.stopPropagation()">
                      <mat-icon class="clickable goal-icon ic-16" (click)="archiveSelectedInreviewGoal(goal)">inventory_2</mat-icon>
                      <div class="vl"></div>
                      <button mat-stroked-button class="secondary-button fs-12 accept-sec item-center" (click)="acceptRevisedGoal(goal)">
                        <mat-icon class="clickable check-icon ic-16 mr-1">check_circle</mat-icon>
                        ACCEPT
                      </button>
                    </div>
                  }
                  @if (goal.approvalStatus !== 'REVISED') {
                    <div class="goal-icons-con d-flex align-items-center gap-12" (click)="$event.stopPropagation()">
                      <mat-icon class="clickable goal-icon ic-16" (click)="editSelectedInreviewGoal(goal)">edit</mat-icon>
                      <mat-icon class="clickable goal-icon ic-16" (click)="archiveSelectedInreviewGoal(goal)">inventory_2</mat-icon>
                    </div>
                  }
                </div>
              </div>
              <div><hr></div>
              <div class="bottom-part d-flex gap-4 align-items-center">
                <div class="type label d-flex">
                  @if (goal?.type === 'SKILL') {
                    <mat-icon class="ic-16">star</mat-icon>
                  }
                  {{getStatus(goal)}}
                </div>
                <div class="date-sec label d-flex gap-4">
                  <div class="item-center">
                    <mat-icon class="mr-1">explore</mat-icon>
                    {{goal?.developmentPlanDetails?.name}}
                  </div>
                  <div class="item-center">
                    <mat-icon class="mr-1">event</mat-icon>
                    Due {{goal.goalDeadline | date: 'd MMMM yyyy'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        @if (allInReviews.length < countAllInReview) {
          <div class="text-center mt-3 d-flex">
            <button class="show-more item-center" (click)="loadMoreInReview()">
              <mat-icon class="small-icon">keyboard_arrow_down</mat-icon>
              Show More
            </button>
          </div>
        }
      </div>
    </div>
  }

  <!-- Active Section -->

  @for (goal of allAssignedGoals; track goal; let i = $index) {
    <div class="active-list data-con clickable" (click)="gotoDetailsSection(goal)">
      <div class="progress d-flex">
        <circle-progress
          class="circle-wi"
          percent="10"
          innerStrokeWidth="4"
          outerStrokeWidth="4"
          backgroundPadding="-6"
          space="-4"
          radius="25"
          maxPercent="100"
          showImage="true"
          [innerStrokeColor]="getBGColorForGoalStatus(goal.progressStatus)"
          [imageSrc]="getSVGPath(goal.type)"
          [outerStrokeColor]="getBGColorForGoalStatus(goal.progressStatus)"
          imageHeight="24"
          [backgroundColor]="getBGColorForGoalStatus(goal.progressStatus)"
          imageWidth="24"
          [showZeroOuterStroke]="false"
          [startFromZero]="false"
          skeletonNew>
        </circle-progress>
      </div>
      <div class="details d-flex flex-column w-100">
        <div class="top-part d-flex justify-content-between">
          <div class="title h5">{{goal.title}}</div>
          <div class="d-flex align-items-center gap-12">
            <div class="details-status-con border-radius-4 h6 item-center p-1"
              [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.progressStatus), 'color': getColorForGoalStatus(goal.progressStatus)}"
              >
              {{PROGRESS_STATUS[goal.progressStatus]}}
            </div>
            <div class="goal-icons-con d-flex align-items-center gap-12" (click)="$event.stopPropagation()">
              @if (goal.progressStatus !== 'COMPLETED') {
                <mat-icon class="goal-icon ic-16" title="Edit" (click)="editSelectedInreviewGoal(goal)">edit</mat-icon>
              }
              <mat-icon class="goal-icon ic-16" title="archive" (click)="archiveSelectedActiveGoal(goal)">inventory_2</mat-icon>
              @if (!isTeamGoal) {
                <div class="goal-check-in-icon p-1 border-radius-4 item-center" title="Check in" (click)="onCheckInClick(goal)">
                  <mat-icon class="goal-icon ic-16 white">pin_drop</mat-icon>
                </div>
              }
            </div>
          </div>
        </div>
        <div><hr></div>
        <div class="bottom-part d-flex gap-4 align-items-center">
          <div class="type label d-flex">
            @if (goal?.type === 'SKILL') {
              <mat-icon class="ic-16">star</mat-icon>
            }
            {{getStatus(goal)}}
          </div>
          <div class="date-sec label d-flex gap-4 w-100">
            @if (goal?.developmentPlanId) {
              <div class="item-center">
                <mat-icon class="mr-1">explore</mat-icon>
                {{goal?.developmentPlanDetails?.name}}
              </div>
              <div class="item-center">
                <mat-icon class="mr-1">event</mat-icon>
                Due {{goal.goalDeadline | date: 'd MMMM yyyy'}}
              </div>
            }
            @if (isTeamGoal) {
              <div class="profile-img ml-auto">
                <app-user-avatar [height]="24" [width]="24" [userImageUrl]="getProfileInfo(goal)?.profileImg" [userInitials]="util.getShortName(getProfileInfo(goal)?.name)"></app-user-avatar>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (allAssignedGoals?.length < countAllAssigned) {
    <div class="text-center mt-3 d-flex">
      <button class="show-more item-center" (click)="loadMore()">
        <mat-icon class="small-icon">keyboard_arrow_down</mat-icon>
        Show More
      </button>
    </div>
  }
</div>
