.rating-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.not-editable.on-endorsement:after {
    visibility: hidden !important;
}
.on-endorsement:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
.on-endorsement:after {
    content: attr(data-title);
    position: absolute;
    background-color: #4d4d4d;
    top: 3em;
    left: 100%;
    max-width: 400px;
    width: max-content;
    padding: 4px;
    color: #fff;
    word-wrap: break-word;
    border-radius: 3px;
    opacity: 1;
    z-index: 99999;
    visibility: hidden;
    font-size: smaller;
    white-space: pre-line;
}
.on-endorsement {
    position: relative;
}
.editable {
    cursor: pointer;
}
.editable:hover {
    .mat-icon:hover {
        color: rgba(31, 64, 203, 0.7);
    }
}
.not-editable {
    cursor: auto;
}
.greyed-out {
    .mat-icon {
        color: var(--color-gray);
    }
}
:host .mat-icon {
  font-size: 24px;
  display: inline;
}
.mat-mdc-tooltip {
  margin-left: -25px !important;
}
.star-container {
    display: flex;
    align-items: center;
}