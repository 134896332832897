import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { UtilitiesService } from 'src/app/services/utilities.service';


@Component({
    selector: 'app-reports-center-settings',
    templateUrl: './reports-center-settings.component.html',
    styleUrls: ['./reports-center-settings.component.scss'],
    standalone: false
})
export class ReportsCenterSettingsComponent implements OnInit {
    private _client: Client;
    @Input() canEdit: boolean = false;
    @Output() onSave = new EventEmitter();
    editForm: FormGroup;
    smsOptions: any[] = [
        { title: 'Limit User Fields in Downloads?', key: 'limitHRMSFieldsInReports', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest' },
        { title: 'Choose HRMS Fields for Downloads', key: 'hrmsFieldsChosenForReports', type: 'select', options: [], validators: [], icon: 'settings_suggest', isMulti: true, displayKey: 'field', valueKey: 'dataKey', checkValue: [{ key: 'limitHRMSFieldsInReports', values: ['Yes'] }] },        
        { title: 'Add Resume Uploaded Date', key: 'addResumeUploadedDate', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest' },
    ];
    constructor(public ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, private util: UtilitiesService) {
        this.editForm = fb.group({});
    }

    ngOnInit(): void {
    }

    @Input()
    set client(client: Client) {
        this._client = client;
        this.buildForms();
    }

    get client() {
        return this._client;
    }


    buildForms() {
        this.smsOptions.filter((option) => option.key == 'hrmsFieldsChosenForReports')[0].options = this.client.integrations.hrmsFields;
        this.smsOptions.forEach((s) => {
            let currentValue = this.editForm.value;
            let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
            if(s.key == "hrmsFieldsChosenForReports") {
                value = this.util.getAAndB(new Set(s.options), new Set(value));
            }
            s.showControl = true;
            (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
            if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
            if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
        });
    }

    saveData() {
        if (this.editForm.invalid) return;
        let d = this.editForm.value;
        d.type = 'reportsCenter';
        this.onSave.emit(d);
    }

    onSMSOptionSelected(smsOption, event) {
        if (['limitHRMSFieldsInReports'].indexOf(smsOption.key) >= 0) this.buildForms();
    }

    evaluateEnterKey(event) {
        if ((event.srcElement.className || '').search('textarea') >= 0) return;
        event.preventDefault()
    }
    compareSelect(o1: any, o2: any) {
        let keys = ['id', 'categoryId'];
        for (let i = 0; i < keys.length; i++) {
            if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
                return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
            }
        }
        return o1 == o2;
    }
}
