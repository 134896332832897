import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
    selector: 'app-projects-settings',
    templateUrl: './projects-settings.component.html',
    styleUrls: ['./projects-settings.component.scss'],
    standalone: false
})
export class ProjectsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  arr30 = Array.from({ length: 30 }, (_, i) => i + 1);
  arr36 = Array.from(Array(37).keys());
  smsOptions: any[] = [
    { title: 'Manage project skills in SMS', key: 'manageProjectSkills', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Number of days gap to consider a break as a new project item', key: 'numDaysGapForProjectMerge', type: 'select', options: this.arr30, icon: "settings_suggest" },
    { title: 'Number of months upto which projects can be edited', key: 'editPastProjects', type: 'select', options: this.arr36, validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Hide projects with zero skills', key: 'hideProjectWithZeroSkills', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Show project responsibilities', key: 'showProjectResponsibilities', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", required: true },
  ];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }
  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'projects';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }
}
