import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Client } from 'src/app/models/client.model';
import { ProficiencySliderOverlayComponent } from '../proficiency-slider-overlay/proficiency-slider-overlay.component';

@Component({
    selector: 'app-proficiency-slider-wrapper',
    templateUrl: './proficiency-slider-wrapper.component.html',
    styleUrls: ['./proficiency-slider-wrapper.component.scss'],
    standalone: false
})
export class ProficiencySliderWrapperComponent implements OnInit {

  @Input() client: Client;
  @Input() key: any;
  @Input() colored: boolean = true;
  @Input() skillItem: any;
  @Input() editable: boolean;
  @Input() hideRatingValue = false;
  @Input() showRequiredProficiency: boolean = false;
  @Input() header: string;
  @Output() onSetValue = new EventEmitter();

  ratingOpts = {
    ratings: ['overallRating']
  }
  @ViewChild(ProficiencySliderOverlayComponent) customMenu!: ProficiencySliderOverlayComponent;


  maxValue = 5;
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    if (this.client && this.client.smsSettings.skillRating == 'Stars 1-4') this.maxValue = 4;
    if (this.showRequiredProficiency && !this.skillItem.requiredRating) this.showRequiredProficiency = false;
  }

  onMenuOpen(){
    this.customMenu.updateSliderProperties();
    this.customMenu.updateTicks()
    this.customMenu.updateProficiency(this.key);
  }

  onRatingSelected(event) {
    this.skillItem[this.key] = event.data.value;
    this.onSetValue.emit(event.data);
  }

}
