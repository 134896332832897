import { Injectable } from '@angular/core';
import { ToastService } from '../modules/common-components/toast/toast.service';
import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import { API_ENDPOINT } from '../constants/api-endpoint.constants';
import { ACCESS_LEVELS } from '../constants';
import { IUserSkillItem } from '../modules/my-skills/my-skills-v2/my-skill.model';
import { DataService } from './data.service';
import { SortingOrder } from '../modules/my-skills/ai-resume-upload/modals/chat-window-modal/chat-window-modal.model';
import { TREATMENT_MAP } from '../constants/treatmentmap.constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  private readonly fileTypeVsContentType: { [key: string]: string } = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf',
    txt: 'text/plain',
    csv: 'text/csv',
  };

  constructor(
    private toast: ToastService,
    private ds: DataService,
    private sanitizer: DomSanitizer
  ) { }

  // keyToMatch is optional argument to match specific key element with the arrays
  public checkEqualArray(arr1, arr2, keyToMatch = null) {
    if (!arr1.length && !arr2.length)
      return true;
    else if (arr1.length !== arr2.length)
      return false;
    return arr1.every(value1 => {
      return arr2.length && arr2.every(value2 => {
        const isObject = typeof value1 === 'object' && typeof value2 === 'object' && !Array.isArray(value1) && !Array.isArray(value2);
        if (isObject && value1[keyToMatch] === value2[keyToMatch])
          return this.deepEqual(value1, value2);
        else if (!isObject)
          return value1?.length === value2?.length && value1?.every(val => value2?.includes(val));
        else
          return true;
      });
    });
  }

  //To check the equality of JSON/Object within multiple levels
  public deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
      ok(x).every(key => this.deepEqual(x[key], y[key]))
    ) : (x === y);
  }

  //To avoid pushing duplicate values to array or set (This will even consider duplicates in objects/json within multiple levels)
  public pushUniqueValue(existingDS: Array<any> | Set<any>, newItem: any): Array<any> | Set<any> {
    let valueExist: boolean = false;
    for (let item of existingDS) {
      if (this.deepEqual(item, newItem)) {
        valueExist = true;
        break;
      }
    }
    if (!valueExist) {
      if (Array.isArray(existingDS)) {
        existingDS.push(newItem)
      } else {
        existingDS.add(newItem);
      }
    }

    return existingDS;
  }

  // To display dates in different formats in UI (eg: dd-MM-YYY)
  // formatDate(new Date(), 'dd MMM yyyy \'at\' hh:mm a') gives 28 Mar 2023 at 04:20 PM
  // Documentation => https://date-fns.org/v4.1.0/docs/format
  public formatDate(date: Date, dateFormat: string): string {
    return date ? format(date, dateFormat) : '';
  }

  // set pagination options of main search bar in tabs in skill groups section to
  // to avoid extra req. calls to BE
  public setSearchBarPaginationOpts(opts: any, searchOpts: any, offset: number = 0) {
    searchOpts.paginationStr = opts.paginationStr || '';
    searchOpts.rightDisabled = Number(opts.cnt) === Number(opts.curCnt);
    searchOpts.leftDisabled = offset === 0;
  }

  //To return short name for users eg: if name is "Rashi Mehta" then "RM" will be returned
  public getShortName(name) {
    if (!name) {
      return '';
    }
    const nameArray = name.split(' ');
    if (nameArray.length === 1) {
      return (nameArray[0].substring(0, 2)).toUpperCase();
    }
    return (nameArray[0].substring(0, 1) + nameArray[1].substring(0, 1)).toUpperCase();
  }

  public downloadFile(href: string, name: string = '') {
    let link = document.createElement('a');
    link.href = href;
    link.download = name;
    link.click();
  }

  public isValidFileFormat(fileName: string, validFormat: string) {
    //eg: fileName -> 'document.docx', validFormat -> '.doc, .docx'
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    const validFormatArray = validFormat.split(',').map((element) => element.trim());
    if (!validFormatArray.includes(extension)) {
      this.toast.showToast({
        'type': 'error', 'msg': 'Invalid file format.',
        'desc': `Only ${validFormat} files are allowed`
      });
      return false;
    }
    return true;
  }

  public toAlphaNumeric(str) {
    return str.toLowerCase().replace(/[^a-z0-9]+/gi, "");
  }

  public setHasObject<T>(set: Set<T>, selectedItem: T) {
    for (const item of set) {
      if (this.deepEqual(item, selectedItem)) {
        return true;
      }
    }
    return false;
  }

  public getANotB<T>(existingDS: Set<T>, possibleValues: Set<T>): Array<T> {
    const aNotB = [];
    existingDS.forEach(selectedItem => {
      const isPresent = this.setHasObject(possibleValues, selectedItem);
      if (!isPresent) {
        aNotB.push(selectedItem);
      }
    });
    return aNotB;
  }
  public getAAndB<T>(existingDS: Set<T>, possibleValues: Set<T>): Array<T> {
    const aAndB = [];
    existingDS.forEach(selectedItem => {
      const isPresent = this.setHasObject(possibleValues, selectedItem);
      if (isPresent) {
        aAndB.push(selectedItem);
      }
    });
    return aAndB;
  }

  public isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  }

  //To sort an array of objects based on a specific field on the object
  public sortBasedOnField(arr: Array<unknown>, field: string) {
    arr.sort((a, b) => {
      if (a[field] < b[field]) {
        return -1;
      }
      if (a[field] > b[field]) {
        return 1;
      }
      return 0;
    });
  }

  public constructCsvRowsForTemplates(fileName: string, csvHeaders: Array<any>) {
    let csvRowString = '';
    for (let i = 0; i < csvHeaders.length; i++) {
      csvRowString += (i ? ',' : '') + csvHeaders[i].key;
    }
    csvRowString += '\r\n';
    for (let i = 0; i < csvHeaders.length; i++) {
      if (!csvHeaders[i].key) continue;
      csvRowString += (i ? ',' : '') + (csvHeaders[i].required ? 'Mandatory' : 'Optional');
    }
    let a = document.createElement("a");
    a.href = 'data:text/csv;base64,' + window.btoa(csvRowString);
    document.body.appendChild(a);
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  }

  public checkEmailDomain(domains) {
    return function (c: AbstractControl) {
      const value = c?.value;
      if (!value) return null;
      const domain = value.split('@')[1];
      if (!domain) return null;

      if (domains.indexOf(domain) === -1) {
        return {
          invalidDomain: true,
        }
      }
      return null;
    }
  }

  public encodeToBase64 = (toBeEncodedString: string) => btoa(toBeEncodedString);
  public decodeFromBase64 = (toBeDecodedString: string) => atob(toBeDecodedString);

  public utf8ToBase64 = (utf8String: string) => {
    const uint8Array = new TextEncoder().encode(utf8String);
    return btoa(uint8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), ''));
  }

  public base64ToUtf8 = (base64String: string) => {
    const uint8Array = new Uint8Array([...atob(base64String)].map((char) => char.charCodeAt(0)));
    return new TextDecoder().decode(uint8Array);
  }

  public isValidUrl(str): boolean {
    const pattern = new RegExp(
      '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );
    return pattern.test(str);
  }

  openUrlInNewTab(url) {
    window.open(url, '_blank');
  }

  public getUserBGColorMap(data, key) {
    const userBGColorMap = {};
    data.forEach(item => {
      const id = _.get(item, key)
      if (!userBGColorMap[id]) {
        userBGColorMap[id] = `rgb(${150 + Math.random() * 100}, ${150 + Math.random() * 100}, ${150 + Math.random() * 100})`;
      }
    })
    return userBGColorMap;
  }

  public filterKeysFromObj =
    (whitelistedFields = []) =>
      (sourceObj, addBlankStringIfNotPresent = false) =>
        whitelistedFields.reduce(
          (acc, el) => ({
            ...acc,
            ...(addBlankStringIfNotPresent
              ? {
                [el]: sourceObj[el] || ""
              }
              : {
                [el]: sourceObj[el]
              })
          }),
          {}
        );

  public filterOutKeysFromObj = (fieldsToRemove) => (sourceObj) =>
    Object.keys(sourceObj)
      .filter((key) => !fieldsToRemove.includes(key))
      .reduce(
        (acc, el) => ({
          ...acc,
          [el]: sourceObj[el]
        }),
        {}
      );

  public compareKeys(v1: any, v2: any) {
    return (v1 || {}).key === (v2 || {}).key;
  }

  public getResumeParsingApiEndpoint() {
    const treatmentMap = JSON.parse(localStorage.getItem("treatmentMap"));
    const { GET_SKILLS_FROM_RESUME, GET_MY_SKILLS } = API_ENDPOINT;
    return treatmentMap?.getSkillsFromResume === 'T1' ? GET_SKILLS_FROM_RESUME : GET_MY_SKILLS;
  }

  public convertObjectToArray(data) {
    return Object.keys(data).map(key => ({
      id: key,
      ...data[key]
    }));
  }

  public checkAccess(userRole: string = ACCESS_LEVELS.NO_ACCESS, clientFeature: string = ACCESS_LEVELS.NO_ACCESS): boolean {
    return userRole !== 'No Access' && clientFeature !== 'No Access';
  }

  public checkEditAccess(userRole: string = ACCESS_LEVELS.NO_ACCESS, clientFeature: string = ACCESS_LEVELS.NO_ACCESS): boolean {
    return userRole === ACCESS_LEVELS.EDIT && clientFeature === ACCESS_LEVELS.EDIT;
  }

  public hasScrollReachedThreshold(scrollElement: any, threshold: number = 0.8, direction: 'vertical' | 'horizontal' = 'vertical'): boolean {
    const isVertical = direction === 'vertical';
    const scrollPosition = isVertical ? scrollElement.scrollTop + scrollElement.clientHeight : scrollElement.scrollLeft + scrollElement.clientWidth;
    const maxScroll = isVertical ? scrollElement.scrollHeight : scrollElement.scrollWidth;

    return scrollPosition / maxScroll > threshold;
  }

  public filterDuplicateSkills(skillItems: IUserSkillItem[] = [], userSkillItems: IUserSkillItem[] = []): IUserSkillItem[] {
    const skillItemIds = skillItems.map(item => item.skillItemId);
    return (userSkillItems).filter(item => !skillItemIds.includes(item.skillItemId));
  }

  public toCamelCase(input: string): string {
    return input
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');
  }

  public isFileSizeExceedingMaxLimit(files: Array<File>, maxFileSizeInMB: number): boolean {
    return files.some(file => file.size > maxFileSizeInMB * 1024 * 1024)
  }

  public preventCharacters(event: KeyboardEvent, charactersToPrevent: string[]) {
    if (charactersToPrevent.includes(event.key)) {
      event.preventDefault();
    }
  }

  public parsePaginationStr(paginationStr: string): { currentEnd: number, totalResults: number } {
    const regex = /Showing \d+ to (\d+) of (\d+)/;
    const match = paginationStr.match(regex);

    if (match && match.length === 3) {
      const currentEnd = parseInt(match[1], 10);
      const totalResults = parseInt(match[2], 10);
      return { currentEnd, totalResults };
    }

    return { currentEnd: 0, totalResults: 0 };
  }

  public hexToRgba(hex: string, alpha: number): string {
    const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }

  public getContentType(fileName: string): string {
    const fileType = fileName.split('.').pop()?.toLowerCase();
    return this.fileTypeVsContentType[fileType] || 'application/octet-stream';
  }

  public getTreatmentMap(key: string) {
    const treatmentMap = this.ds.treatmentMap || JSON.parse(localStorage.getItem("treatmentMap"));
    return treatmentMap[key] || TREATMENT_MAP.V1;
  }

  public generateUniqueRandomString(length: number): string {
    const characters: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  };

  public sortByProperty<T>(arr: T[], property: keyof T, order: SortingOrder = SortingOrder.ASC): T[] {
    return arr.sort((a, b) => {
      if (order === SortingOrder.ASC) {
        return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      } else {
        return a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0;
      }
    });
  }

  public transformTextToLinks(text: string, skipHtmlSanitization: boolean = false,): SafeHtml | string {
    if (!text) return '';

    const regexUrl = /\b(https?:\/\/|www\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}([/?#][^\s]*)?\b/g;
    const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b(?![^<>]*>)/g;

    const htmlHashMap: { [key: string]: string } = {};
    let modifiedText = '';

    // Replace email addresses with placeholders and store the innerHtml strings in the hashmap
    modifiedText = text.replace(regexEmail, (match, index) => {
      const key = `email_${index}`;
      const innerHtml = `<a href="mailto:${match}" target="_blank">${match}</a>`;
      htmlHashMap[key] = innerHtml;
      return key;
    });

    // Replace URLs with placeholders and store the innerHtml strings in the hashmap
    modifiedText = modifiedText.replace(regexUrl, (match, index) => {
        const url = match.startsWith('http') ? match : `https://${match}`;
        const key = `start_${index}_end`;
        const innerHtml = `<a href="${url}" target="_blank">${match}</a>`;
        htmlHashMap[key] = innerHtml;
        return key;
      // }
    });



    // Replace the placeholders with the respective innerHtml strings from the hashmap
    Object.keys(htmlHashMap).forEach((key) => {
      const regex = new RegExp(key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
      modifiedText = modifiedText.replace(regex, htmlHashMap[key]);
    });

    return skipHtmlSanitization ? modifiedText : this.sanitizer.bypassSecurityTrustHtml(modifiedText);
  }

  public isObject(value: any): boolean {
    return value && typeof value === 'object' && !Array.isArray(value);
  }

  public invertObject(oldObject: any): any {
    const newObject = {};
    Object.keys(oldObject).forEach(key => newObject[oldObject[key]] = key);
    return newObject;
  }
}
