<div class="d-flex flex-column align-items-start gap-4 h-100 w-100">
  <div class="d-flex w-100">
    <div class="header-con d-flex justify-content-center align-items-center">
      <mat-icon class="ic-26 gray cursor-pointer" (click)="backClicked()">keyboard_arrow_left</mat-icon>
      <label class="h3 black"><span (click)="backClicked()" class="gray cursor-pointer">
        {{progressCardGroupConfig?.groupName? progressCardGroupConfig?.groupName + ' / ': '' }}
      </span>View All
    </label>
  </div>
  <div class="ml-auto">
    <form>
      <mat-form-field appearance="outline" class="filter">
        <mat-select  multiple [formControl]="filterType" (selectionChange)="onFilterChange()"
          placeholder="Filter by types">
          @for (type of LearningElementTypeOptions; track type) {
            <mat-option [value]="type.pk">{{type.title}}</mat-option>
          }
        </mat-select>
        <mat-icon matSuffix class="mat-custom-arrow">keyboard_arrow_down</mat-icon>
      </mat-form-field>
    </form>
  </div>
</div>
<app-progress-card-group class="w-100" [headerConfig]="headerConfig"
  [config]="[progressCardGroupConfig]">
</app-progress-card-group>

<app-paginator-ui class="w-100" #paginator (onPaginationEvent)="onPaginationEvent($event)"
[data]="{totalDataCnt: totalCardCount}"></app-paginator-ui>
</div>