import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActionModalDataType } from './action-modal.interface';
import { CANCEL, DECLINE, DEFAULT, REJECT_REQUEST } from './action-modal-constants';

@Component({
    selector: 'app-action-modal',
    templateUrl: './action-modal.component.html',
    styleUrls: ['./action-modal.component.scss'],
    standalone: false
})
export class ActionModalComponent implements OnInit {
  @Output() onEvent = new EventEmitter();
  @Input() modalType: string;
  description = new FormControl('', [Validators.required]);

  // we are setting ui for type reject by default
  public data: ActionModalDataType;

  constructor() {}
  ngOnInit(): void {
    switch(this.modalType) {
      case 'cancel':
        this.data = CANCEL;
        break;
      case 'decline':
        this.data = DECLINE;
        break;
      case 'reject_request':
        this.data = REJECT_REQUEST;
        break;
      default: 
        this.data = DEFAULT;
    }
  }

  onButtonClick() {
    this.onEvent.emit(this.description.value);
  }

}