<div class="goal-actions-main-con border-radius-4 py-1">
  @for (action of goalActions; track action) {
    <div class="goal-options-main-con d-flex align-items-center gap-2 p-2 clickable" (click)="onGoalActionClick(action)">
      <mat-icon [ngClass]="{deleteTheme: action.name === 'Delete'}" class="ic-16 goal-actions-icon gray">{{ action.icon }}</mat-icon>
      <div [ngClass]="{deleteTheme: action.name === 'Delete'}" class="caption fw-400">{{ action.name }}</div>
    </div>
  }
</div>


