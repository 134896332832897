import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitilizeFirstCharacter',
    standalone: false
})
export class CapitilizeFirstCharacterPipe implements PipeTransform {
  transform(value: string | null | undefined): string | null | undefined {
    if (!value) {
      return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
