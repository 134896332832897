import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SMS_OPTIONS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';
import { SharedModule } from 'src/app/modules/shared/shared.module';

@Component({
  selector: 'app-gamification-settings',
  templateUrl: './gamification-settings.component.html',
  styleUrls: ['./gamification-settings.component.scss'],
  standalone: true,
  imports: [SharedModule]
})

export class GamificationSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Input() hrmsFieldsArray: string[];
  @Output() onSave = new EventEmitter();

  public editForm: FormGroup;
  public smsOptions: any[] = [];
  public enableLeaderBoardTags: boolean = false;
  public leaderBoardTags: any[] = [];

  constructor(private fb: FormBuilder) { }

  @Input()
  set client(client: Client) {
    this._client = client;
  }
  get client() {
    return this._client;
  }

  ngOnInit(): void {
    this.buildOptions();
    this.editForm = this.fb.group({});
    this.smsOptions.forEach((smsOption) => {
      this.editForm.addControl(smsOption.key, new FormControl('', smsOption.validators));
    });
    this.buildForms();
  }

  private buildOptions() {
    this.smsOptions = [
      {
        title: 'Enable Badges',
        key: 'allowBadges',
        type: 'select',
        options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
        validators: [Validators.required],
        icon: 'settings_suggest',
        defaultValue: SMS_OPTIONS.NO,
        multiple: false,
      },
      {
        title: 'Enable Points',
        key: 'allowKarmaPoints',
        type: 'select',
        options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
        validators: [Validators.required],
        icon: 'settings_suggest',
        defaultValue: SMS_OPTIONS.NO,
        multiple: false,
      },
      {
        title: 'Leaderboard settings',
        type: 'sub-title',
        checkValue: [{ key: 'allowKarmaPoints', values: [SMS_OPTIONS.YES] }]
      },
      {
        title: 'Show Leaderboard',
        key: 'allowLeaderBoard',
        type: 'select',
        options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO],
        validators: [Validators.required],
        icon: 'settings_suggest',
        defaultValue: SMS_OPTIONS.NO,
        multiple: false,
        checkValue: [{ key: 'allowKarmaPoints', values: [SMS_OPTIONS.YES] }]
      },
      {
        heading: "Leaderboard settings",
        title: 'HRMS attributes to allow as filters in the leaderboard',
        key: 'attachedHrmsKeyToLeaderboard',
        type: 'select',
        options: this.hrmsFieldsArray,
        validators: [],
        icon: 'settings_suggest',
        defaultValue: [],
        multiple: true,
        checkValue: [{ key: 'allowLeaderBoard', values: [SMS_OPTIONS.YES] }, { key: 'allowKarmaPoints', values: [SMS_OPTIONS.YES] }]
      },
      {
        title: 'Timeframe',
        key: 'allowedTimeFramesForLeaderBoard',
        type: 'checkbox',
        options: ['alltime', 'week', 'month'],
        validators: [],
        icon: 'settings_suggest',
        checkValue: [{ key: 'allowLeaderBoard', values: [SMS_OPTIONS.YES] }, { key: 'allowKarmaPoints', values: [SMS_OPTIONS.YES] }]
      }
    ];
  }

  public onSelectionChange(smsOption, event) {
    this.editForm.get(smsOption.key).setValue(event.value);
    this.buildForms();
  }

  private buildForms() {
    this.smsOptions.forEach((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.checkValue || []).map((item) => {
        const getFormValue = this.editForm.get(item.key)?.value;
        const validValue = item.values.indexOf(getFormValue ? getFormValue : this.client.smsSettings[item.key]) < 0;
        const dependentValues = !item.values.some((val) => getFormValue?.includes(val));
        if (validValue) {
          s.showControl = false;
        }
        else if (dependentValues) {
          s.showControl = false;
        }
      }
      );

      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl)
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }

  public toggleCheckboxSelection(event: any, value: string): void {
    const control = this.editForm.get('allowedTimeFramesForLeaderBoard');
    const currentValues = control?.value || [];
    control?.setValue(event.checked
      ? [...currentValues, value]
      : currentValues.filter(v => v !== value));
  }

  public saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'gamificationSettings';
    this.onSave.emit(d);
  }

  public evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault();
  }

  public compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }
}
