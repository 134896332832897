import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BULK_ENDORSE_REJECT_SKILLS, SMS_OPTIONS } from 'src/app/constants';
import { Client } from 'src/app/models/client.model';
import { MultiSelectChipComponent } from 'src/app/modules/common-components/multi-select-chip/multi-select-chip.component';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
    selector: 'app-general-sms-settings',
    templateUrl: './general-sms-settings.component.html',
    styleUrls: ['./general-sms-settings.component.scss'],
    standalone: false
})
export class GeneralSmsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  directives: [MultiSelectChipComponent]
  @ViewChild(MultiSelectChipComponent) chipsComp: MultiSelectChipComponent;

  smsOptions: any[] = [
    { title: 'Email Sender Name', key: 'emailSenderName', type: 'text', validators: [Validators.required, Validators.min(3)], icon: 'email', required: true },
    { title: 'Help Email Address (in FAQs)', key: 'helpEmailAddress', type: 'text', validators: [Validators.required, Validators.email], icon: 'email', required: true },
    { title: 'Resume Upload date', key: 'minResumeUploadDate', type: 'date', icon: 'settings_suggest' },
    { title: 'Enable Editable Fields in Integrations', key: 'editableFields', type: 'select', options: ['Yes', 'No'], validators: [Validators.required], icon: "settings_suggest", defaultValue: 'Yes', required: true },
    { title: 'Enable Secure Bulk Uploads', key: 'allowSecureBulkUploads', type: 'select', options: ['Yes', 'No'], validators: [], icon: "settings_suggest", defaultValue: 'No', required: false },
    {
      key: 'certificationRequestEmails', type: 'multiSelectChip', validators: [],
      subOptions: {
        key: 'certificationRequestEmails',
        title: 'Receiver of Certification Request Emails',
        icon: 'email',
        value: [],
        placeholder: 'Add email and hit Enter',
        errorText: 'Enter a valid Email Address',
        validators: [Validators.email],
      }
    },
    { title: 'Free text for FAQ Section', key: 'faqText', type: 'text', validators: [], icon: 'settings_suggest', required: false, 'maxLength': 50000 },
    { title: 'Supported File Formats for Upload Resume', key: 'supportedFileFormatsForUploadResume', type: 'select', isMulti: true, options: ['pdf', 'docx', 'jpg', 'jpeg', 'png', 'pptx'], validators: [Validators.required], icon: "settings_suggest", required: false },
    { title: 'Enable Hotjar Analytics', key: 'isHotjarEnabled', type: 'select', options: ['Yes', 'No'], validators: [], icon: "settings_suggest", defaultValue: 'No', required: false },
    { title: 'Allow to view all employees irrespective of team details access', key: 'isAllEmployeesProfileAccessEnabled', type: 'select', options: ['Yes', 'No'], validators: [], icon: "settings_suggest", defaultValue: 'No', required: false },
    { title: 'Allow users to maintain multiple current experiences in profile', key: 'allowAddingCurrentExternalExperience', type: 'select', options: ['Yes', 'No'], validators: [], icon: "settings_suggest", defaultValue: 'No', required: false },
    { title: 'Allow the manager to bulk endorse/reject skills', key: 'allowManagerToBulkEndorseRejectSkills', type: 'select', options: [BULK_ENDORSE_REJECT_SKILLS.NO, BULK_ENDORSE_REJECT_SKILLS.AT_ASSOCIATE_LEVEL, BULK_ENDORSE_REJECT_SKILLS.FOR_ALL_THE_REQUESTS], validators: [], icon: "settings_suggest", defaultValue: BULK_ENDORSE_REJECT_SKILLS.NO, required: false },
    { title: 'Enable Auto Redaction of PII data of Inactive employees', key: 'enableAutoRedaction', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [], icon: "settings_suggest", defaultValue: SMS_OPTIONS.NO, required: false },
    { title: 'Number of inactive years after which PII should be redacted', key: 'inactiveYears', type: 'number', validators: [Validators.min(1), Validators.max(10)], icon: "settings_suggest", defaultValue: 2, required: false, min: 1, max: 10, checkValue: [{ key: 'enableAutoRedaction', values: [SMS_OPTIONS.YES] }] },
    { title: 'Resume upload information popup text', key: 'resumeUploadInfoPopUpText', type: 'textarea', validators: [], icon: "settings_suggest", required: false, 'maxLength': 50000 },
  ]

  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, private util: UtilitiesService) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) {
        if (s.key == 'certificationRequestEmails') {
          s.subOptions.value = value || [];
          if (this.client.features.sms.certifications == 'No Access') {
            s.showControl = false;
          }
          s.subOptions.validators = [...s.subOptions.validators, this.util.checkEmailDomain(this.client.security.permittedDomains || [])];
        }
        this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
      }
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'general';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

  public onValueChange(option: any) {
    if (['enableAutoRedaction'].indexOf(option.key) >= 0) {
      this.buildForms();
    }
  }

}
