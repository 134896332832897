import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { IStaffingRoleAndUserPreferenceInput, IStaffingRoleListResponse, IStaffingRoleModalData, IUserPreferences } from './staffing-role-modal.model';
import { IStaffingRole } from 'src/app/models/user.model';
import { StaffingRoleService } from 'src/app/services/staffing-role.service';
import { IDictionary } from 'src/app/models/client.model';

@Component({
    selector: 'app-staffing-role-modal',
    templateUrl: './staffing-role-modal.component.html',
    styleUrls: ['./staffing-role-modal.component.scss'],
    standalone: false
})
export class StaffingRoleModalComponent implements OnInit {

  @Input() data: IStaffingRoleModalData = {
    isProfileModal: false,
    selectedStaffingRole: []
  };
  @Output() onEvent = new EventEmitter();
  public staffingRoleAndPreferencesForm: FormGroup;
  public waveEmoji = '\u{1F44B}';
  public maxLimit: number = 0; 
  public dictionary: IDictionary = this.ds.dictionary;
  public staffingRoleInput: IStaffingRoleAndUserPreferenceInput = {
    key: 'staffingRole',
    disabled: false, 
    title: 'Choose from below options ', 
    placeholder: `Search for ${this.dictionary?.staffingRole}`,
    type: 'auto-complete-multi', 
    selectedItems: [], 
    isMulti: false, 
    options: [], 
    responseKey: 'data',
    nameField: 'tagValue',
    idField: '_id',
    hideSelectAllCheckbox: true,
    disableSelection: false
  };
  public selectedRoles = [];
  public isStaffingRoleMandatory: boolean = false;
  public userPreferencesInputs: IStaffingRoleAndUserPreferenceInput[] = [];
  public isStaffingRoleEnabled: boolean = this.staffingRoleService.isStaffingRoleEnabled;
  private userPreferences: IUserPreferences[] = [];

  constructor(
    private fb: FormBuilder,
    private ds: DataService,
    private weds: WaitErrorDialogsService,
    private staffingRoleService: StaffingRoleService
  ) { }

  ngOnInit(): void {
    if(!this.staffingRoleService.selectedUserCustomTags?.length){
      return;
    }
    const { smsSettings } = this.ds.client;
    this.maxLimit = smsSettings.maximumStaffingRoles;
    this.isStaffingRoleMandatory = smsSettings.isStaffingRoleMandatory === SMS_OPTIONS.YES;

    this.buildStaffingRoleAndPreferencesForm();
    this.getUserStaffingRolesAndPreferences();
    this.getStaffingRolesList();
    this.setData();
  }

  public onMultiAutoCompleteChange(event) {
    const { inputObj, selectedItems } = event;
    this.staffingRoleAndPreferencesForm.controls?.[inputObj.key]?.setValue(selectedItems);
    if (inputObj.key === 'staffingRole') {
      this.selectedRoles = selectedItems;
    }
    this.staffingRoleAndPreferencesForm.controls?.[inputObj.key]?.markAsTouched();
  }

  public onRemove(item: IStaffingRole) {
    this.selectedRoles = this.selectedRoles.filter(role => role !== item);
    this.staffingRoleAndPreferencesForm.get('staffingRole').setValue(this.selectedRoles);
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      selectedItems: this.selectedRoles
    }
  }

  public onDismiss() {
    this.onEvent.emit({ type: 'close' });
  }


  public onSave() {
    const loaded = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -5, dontCloseAllDialogs: true });
    const saveDataConfig = this.getSaveDataConfig();
    this.ds.saveData(saveDataConfig.endPoint, saveDataConfig.payload)
    .pipe(take(1))
    .subscribe((rs: {status: string, error?: any}) => {
      this.weds.closeDialog(loaded);
      if (rs.status == RESPONSE_STATUS.SUCCESS) {
        const staffingRole = this.staffingRoleAndPreferencesForm?.value?.staffingRole || [];
        this.onEvent.emit({ type: 'save', selectedRoles: staffingRole });
        this.weds.showDialog({type: DIALOG_TYPES.GENERIC, code: -1 });
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 99, message: rs.error?.description });
      }
    })
  }

  public get isMaxLimitReached() {
    return this.selectedRoles?.length > this.maxLimit;
  }

  public get isFormEmpty () {
    const { staffingRole = [] } = this.staffingRoleAndPreferencesForm.value;
    return this.ds.client.smsSettings.isStaffingRoleMandatory === SMS_OPTIONS.YES ? !staffingRole.length : false;
  }

  public onValueInvalid(event) {
    let inputObj = event["inputObj"];
    const formControl = this.staffingRoleAndPreferencesForm.controls[inputObj.key];
    if (formControl) {
      if (!formControl.value) {
        formControl.setErrors({ 'incorrect': true });
      } else {
        formControl.setErrors(null);
      }
    }
  }

  public onAutoCompleteChange(event) {
    let inputObj = event["inputObj"];
    let selectedOption = event["selectedOption"];
    this.staffingRoleAndPreferencesForm.controls[inputObj.key].setValue(selectedOption);
  }

  public isFieldRequiredAndEmpty(fieldKey: string): boolean {
    const control = this.staffingRoleAndPreferencesForm?.get(fieldKey);
    const value = control?.value;
    const isEmpty = Array.isArray(value) ? !value.length : !value;
    const isInCorrectOption = control && control.errors?.['incorrect'];
    return control && control.hasValidator && control.hasValidator(Validators.required) && isEmpty && !isInCorrectOption && control.touched;
  }

  public get isSaveDisabled(): boolean {
    const { isPreferencesSectionPresent, isStaffingRoleSectionPresent } = this.sectionStatus;

    let isDisabled = false;

    if (isStaffingRoleSectionPresent) {
      isDisabled = this.isMaxLimitReached || this.isFormEmpty;
    }
    if (isPreferencesSectionPresent) {
      isDisabled = isDisabled || this.hasRequiredUserPreferencesMissing() || this.staffingRoleAndPreferencesForm.invalid;
    }
    return isDisabled;
  }

  public get tooltipMessage(): string {
    const { isPreferencesSectionPresent, isStaffingRoleSectionPresent } = this.sectionStatus;

    if (isStaffingRoleSectionPresent) {
      if (this.isMaxLimitReached) {
        return `Maximum ${this.maxLimit} ${this.dictionary?.staffingRole}(s) allowed`;
      }
      if (this.isFormEmpty) {
        return `Choose at least one ${this.dictionary?.staffingRole}`;
      }
    }
    if (isPreferencesSectionPresent && this.hasRequiredUserPreferencesMissing()) {
      return 'Please fill in all required preferences';
    }
    return '';
  }

  private get sectionStatus() {
    return {
      isPreferencesSectionPresent: this.data.isProfileModal && this.userPreferencesInputs.length > 0,
      isStaffingRoleSectionPresent: this.isStaffingRoleEnabled
    };
  }

  private hasRequiredUserPreferencesMissing(): boolean {
    if (!this.staffingRoleAndPreferencesForm || !this.data.isProfileModal || !this.userPreferencesInputs.length) {
      return false;
    }
    return Object.keys(this.staffingRoleAndPreferencesForm.controls).some(key => 
      this.isFieldRequiredAndEmpty(key)
    );
  }

  private setData() {
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      selectedItems: this.data.selectedStaffingRole || [],
    }
    this.selectedRoles = this.data.selectedStaffingRole || [];
    this.staffingRoleAndPreferencesForm.get('staffingRole').setValue(this.selectedRoles);
  }

  private getSaveDataConfig() {
    const formValues = this.staffingRoleAndPreferencesForm.value;
    
    const staffingRoleAndPreferencesPayload = {
      clientId: this.ds.client.clientId,
      userId: this.staffingRoleService.currentUserId,
      tagGroups: this.getSelectedTagIdsGroups(formValues),
    };
  
    return {
      endPoint: API_ENDPOINT.SAVE_USER_CUSTOM_TAGS_MULTIPLE ,
      payload: staffingRoleAndPreferencesPayload
    };
  }
  
  private getSelectedTagIdsGroups(formValues: any) {
    const tagGroups: { tag: string; tagIds: string[] }[] = [];
    Object.keys(formValues)?.forEach((key) => {
      const value = formValues[key];
      if (Array.isArray(value)) {
        tagGroups.push({
          tag: key,
          tagIds: value?.map(item => item._id) || [],
        });
      } else if (!Array.isArray(value)) {
        tagGroups.push({
          tag: key,
          tagIds: value && value._id ? [value._id] : [],
        });
      }
    });
    return tagGroups;
  }

  private buildStaffingRoleAndPreferencesForm() {
    this.staffingRoleAndPreferencesForm = this.fb.group({
      staffingRole: [{  value: this.selectedRoles, disabled: false }],
    });
  }

  private getStaffingRolesList() {
    if(!this.staffingRoleService.isStaffingRoleEnabled) {
      return;
    }
    const loader = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const payload = { clientId: this.ds.currentAdminClientId, attributeType: 'staffingRole', tagType: 'Custom' };
    this.ds.searchData(API_ENDPOINT.GET_TAG_VALUES_FOR_TAG_SMS, payload)
      .pipe(take(1))
      .subscribe((rs: IStaffingRoleListResponse) => {
        this.weds.closeDialog(loader);
        if (rs.status === RESPONSE_STATUS.SUCCESS) {
          this.handleSuccess(rs);
        } else {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: rs.error ? rs.error.code : 99 });
        }
      });
  }

  private handleSuccess(res: IStaffingRoleListResponse) {
    this.staffingRoleInput = {
      ...this.staffingRoleInput,
      options: res.data
    }
  }

  private getUserStaffingRolesAndPreferences(): void {
    if(!this.staffingRoleService.selectedUserCustomTags?.length) {
      return;
    }
    this.staffingRoleService.getUserStaffingRolesAndPreferences()
    .pipe(take(1))
    .subscribe({
      next: ({ staffingRole, otherPreferences }) => {
        this.data.selectedStaffingRole = staffingRole;
        this.userPreferences = otherPreferences;
        this.createUserPreferencesInputs();
        this.setData();
      }
    });
  }

  private createUserPreferencesInputs() {
    this.userPreferencesInputs = this.userPreferences.map(tag => ({
      key: tag.attributeType,
      endpoint: API_ENDPOINT.GET_TAG_VALUES_FOR_TAG_SMS,
      apiParams: { attributeType: tag.attributeType, tagType: 'Custom' },
      disabled: false, 
      title: `Preferred ${tag.attributeType}`, 
      placeholder: `Search for ${tag.attributeType}`,
      type: tag.isMultiSelect ? 'auto-complete-multi' : 'auto-complete',
      selectedItems: tag.isMultiSelect ? tag.values : tag.values?.[0] || [],// 
      isMulti: tag.isMultiSelect, 
      notRequired: !tag.isMandatory,
      required: tag.isMandatory,    
      options: [],
      responseKey: 'data',
      nameField: 'tagValue',
      idField: '_id',
      hideSelectAllCheckbox: true,
      value: '',
    }));

    this.addUserPreferenceFormControls();
  }

  private addUserPreferenceFormControls() {
    this.userPreferencesInputs.forEach((input) => {
      this.staffingRoleAndPreferencesForm.addControl(input.key, new FormControl({ value: input.selectedItems, disabled: false }, input?.required ? Validators.required : null));
      if(!input.isMulti) {
        this.staffingRoleAndPreferencesForm.controls[input.key].markAsTouched();
      }
    });
  }
}
