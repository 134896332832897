<div class="search-dialog d-flex align-items-center flex-row p-0">
  <mat-form-field class="left" appearance="outline">
    <mat-select #matSelect [(ngModel)]="selectedOption" class="gray" (selectionChange)="onFilterSelection()">
      @for (option of filterOptions; track option) {
        <mat-option [value]="option?.key" class="caption">{{option?.name | titlecase}}</mat-option>
      }
    </mat-select>
    <mat-icon matSuffix class="mat-custom-arrow gray">keyboard_arrow_down</mat-icon>
  </mat-form-field>

  <mat-form-field class="right flex-fill" appearance="outline">
    <input matInput #searchInput placeholder="Search..." class="w-90 py-12 d-flex" [matAutocomplete]="auto" type="text" [(ngModel)]="searchText" (input)="onSearchInput()" >
    <mat-autocomplete #auto="matAutocomplete" class="global-search-max-height">
      @for (option of searchResults; track option) {
        <mat-option class="option-con" [value]="option.name">
          @if (option) {
            <ng-container class="d-flex h-100">
              <div class="d-flex align-items-center justify-content-between gap-4">
                <div class="d-flex flex-column flex-fill align-self-stretch align-items-start justify-content-center nowrap">
                  <label matTooltip="{{ option.name || option.title}}" class="option-ellipsis h-24px body black fw-500">{{ option.name || option.title}}</label>
                  <label class="h-24px caption gray">in {{ option['Skill Category'] || option?.skillCategoryTitle || option?.type | titlecase}}</label>
                </div>
                <button mat-button [ngClass]="option.isAdded ? 'secondary-button primary' : 'primary-button white' " class="" (click)="onAddItemClick(option)" >
                  <div class="d-flex label fw-500 align-items-center justify-content-center flex-row">
                    <mat-icon matPrefix [hidden]="option.isAdded" class="ic-16 mr-2 d-flex align-items-center">add</mat-icon>
                    <span>{{ option.isAdded ? 'Added' : 'Add'}}</span>
                  </div>
                </button>
              </div>
            </ng-container>
          }
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>
