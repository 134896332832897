@if (client) {
  <div class="demand-unit-settings-con">
    <div class="h3 black ">{{(client?.features?.admin?.['demand-units']||'No Access')=='No Access'?
    'Skill Cluster Settings': 'Demand Unit & Skill Cluster Settings'}}</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
      <div class="d-flex flex-column gap-3">
        <div class="admin-form-con">
          @for (smsOption of smsOptions; track smsOption) {
            <div
              [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
              [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
              @if (smsOption.type=='title') {
                <div class="h3 black " style="margin:10px 0;">
                {{smsOption.title}}</div>
              }
              @if (smsOption.type=='sub-title') {
                <div class="h4 black" style="margin:10px 0;">
                {{smsOption.title}}</div>
              }
              @if ((smsOption.type=='text'||smsOption.type=='number')&&smsOption.showControl) {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                      [min]="smsOption.min||0" [max]="smsOption.max||100"
                      maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                    @if (editForm.get(smsOption.key).touched&&editForm.get(smsOption.key).invalid) {
                      <mat-error>
                      {{editForm.get(smsOption.key).errors.error||smsOption.errorText}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='textarea'&&smsOption.showControl) {
                <mat-form-field appearance="outline"
                  class="admin-input-form-field admin-text-area-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                  <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                </mat-form-field>
              }
              @if (smsOption.type=='select'&&smsOption.showControl) {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    @if (smsOption.icon) {
                      <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    }
                    <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                      (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                      [compareWith]="compareSelect">
                      @for (option of smsOption.options; track option) {
                        <mat-option
                          [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                          {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                        </mat-option>
                      }
                    </mat-select>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (smsOption.type=='date'&&smsOption.showControl) {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>date_range</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                      (focus)="picker.open()" />
                    <mat-datepicker #picker></mat-datepicker>
                    @if (editForm.get(smsOption.key).invalid) {
                      <mat-error>{{smsOption.errorText||'This input is
                      mandatory'}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
        @if (canEdit) {
          <div class="admin-save-button-con">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
          </div>
        }
      </div>
    </form>
    @if (showSkillClustersTags) {
      <div class="d-flex flex-column gap-3 pt-3">
        <div class="h3 black ">Skill Cluster Tags</div>
        <form [formGroup]="skillClusterTagsSettingsFormGroup">
          <main class="admin-form-con">
            @if (showHrmsTags) {
              <section class="admin-input-con-half">
                <app-data-ordering-control formControlName="hrmsTags" [input]="hrmsTagsConfig" (onSave)="saveSkillClusterHrmsTags()"></app-data-ordering-control>
              </section>
            }
            @if (showCustomTags) {
              <section class="admin-input-con-half">
                <app-data-ordering-control formControlName="customTags" [input]="customTagsConfig" (onSave)="saveSkillClusterCustomTags()"></app-data-ordering-control>
              </section>
            }
          </main>
        </form>
        <form class="mt-4" [formGroup]="tagsToBeShownAsFiltersFormGroup">
          <main class="admin-form-con">
            <section class="admin-input-con-half">
              <app-data-ordering-control formControlName="tagsToBeShownAsFiltersToEmployees" [input]="tagsToBeShownAsFiltersToEmployeesConfig" (onSave)="saveTagsToBeShownAsFilters('Employees')"></app-data-ordering-control>
            </section>
            <section class="admin-input-con-half">
              <app-data-ordering-control formControlName="tagsToBeShownAsFiltersToManagers" [input]="tagsToBeShownAsFiltersToManagersConfig" (onSave)="saveTagsToBeShownAsFilters('Managers')"></app-data-ordering-control>
            </section>
          </main>
        </form>
      </div>
    }
    <form [formGroup]="weightagesForm" (ngSubmit)="saveWeightagesData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
      <div class="d-flex flex-column gap-3">
        <div class="admin-form-con">
          @for (smsOption of weightagesOptions; track smsOption) {
            <div
              [ngClass]="smsOption.type=='textarea'||!smsOption.key ? 'admin-input-con-full' : 'admin-input-con-half'"
              [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
              @if (smsOption.type=='title') {
                <div class="h3 black " style="margin:10px 0;">
                {{smsOption.title}}</div>
              }
              @if (smsOption.type=='sub-title') {
                <div class="h4 black" style="margin:10px 0;">
                {{smsOption.title}}</div>
              }
              @if ((smsOption.type=='text'||smsOption.type=='number')&&smsOption.showControl) {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{smsOption.title}}</mat-label>
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                    <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                      [required]="smsOption.required" autocomplete="off" [type]="smsOption.type"
                      [min]="smsOption.min||0" [max]="smsOption.max||100"
                      maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" (change)="changeWeightage($event, smsOption.weightagesPart)"/>
                    @if (weightagesForm.get(smsOption.key).invalid) {
                      <mat-error>
                      {{weightagesForm.get(smsOption.key).errors.error||smsOption.errorText}}</mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
        @if (canEdit) {
          <div class="admin-save-button-con">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
          </div>
        }
      </div>
    </form>
  </div>
}
