import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-configurable-checkbox',
  templateUrl: './configurable-checkbox.component.html',
  styleUrls: ['./configurable-checkbox.component.scss'],
  standalone: false
})
export class ConfigurableCheckboxComponent{
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() disabled: boolean = false;
  @Input() checked: boolean = false;

  @Output() onCheckboxChange = new EventEmitter<boolean>();
  constructor() { }

  public onCheckboxSelected(event: any){
    this.onCheckboxChange.emit(event);
  }

}
