import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { addMonths, addYears, differenceInMilliseconds, differenceInMonths, differenceInSeconds, endOfMonth, format, getQuarter, isSameDay } from 'date-fns';

@Component({
    selector: 'app-timeline-selector',
    templateUrl: './timeline-selector.component.html',
    styleUrls: ['./timeline-selector.component.scss'],
    standalone: false
})
export class TimelineSelectorComponent implements OnInit {
  @Input() data: any;//fromDt, toDt, minFromDt, maxToDt, useQuarters
  @Output() onEvent = new EventEmitter();
  useQuarters = true;
  dates: any[] = [];
  timeText = "";
  hideQuarters = false;
  selectedFromDt;
  selectedToDt;
  selectingToDt = false;
  allowSingleQuarter = false;
  disableFutureSelection = false;
  quarterToQuartersStartingMonthAndEndingMonthMap = {
    1: ['Jan', 'Mar'],
    2: ['Apr', 'Jun'],
    3: ['Jul', 'Sep'],
    4: ['Oct', 'Dec'],
  };

  ngOnInit(): void {
    this.useQuarters = this.data.useQuarters === undefined ? this.useQuarters : this.data.useQuarters;
    if (this.data.hideQuarters || this.data.timelineMode == 'month') this.hideQuarters = true;
    this.allowSingleQuarter = this.data?.allowSingleQuarter ?? false;
    this.disableFutureSelection = this.data?.disableFutureSelection ?? false;
    this.generateDateOptions();
  }
  generateDateOptions() {
    //Initialize dates
    let { minFromDt, maxToDt, fromDt, toDt } = this.data;
    if (minFromDt) minFromDt = new Date(this.data.minFromDt);
    if (maxToDt) maxToDt = new Date(this.data.maxToDt);
    if (fromDt) fromDt = new Date(this.data.fromDt);
    if (toDt) toDt = new Date(this.data.toDt);
    if (!minFromDt) minFromDt = addYears(endOfMonth(new Date()), -5);
    if (!maxToDt) maxToDt = endOfMonth(new Date());
    //Generate
    if (this.useQuarters) {
      let m = minFromDt.getMonth() + 1;
      if (m % 3) minFromDt = endOfMonth(addMonths(minFromDt, (m % 3 - 2) * -1));
      m = maxToDt.getMonth() + 1;
      if (m % 3) maxToDt = endOfMonth(addMonths(maxToDt, 3 - (m % 3)));
      if (this.allowSingleQuarter && !(toDt && fromDt)) toDt = fromDt =  maxToDt;
      if (!toDt) toDt = maxToDt;
      if (!fromDt) fromDt = addMonths(maxToDt, -12);
    } else {
      if (!toDt) toDt = maxToDt;
      if (!fromDt) fromDt = addMonths(maxToDt, -12);
    }
    if (!this.selectedFromDt) {
      this.selectedFromDt = fromDt;
      this.selectedToDt = toDt;
    }
    if (this.selectedFromDt && this.selectedToDt && differenceInSeconds(this.selectedFromDt, this.selectedToDt) > 30) {
      let temp = this.selectedFromDt; this.selectedFromDt = this.selectedToDt; this.selectedToDt = temp;
    }
    if (this.data.timelineMode == 'month') this.selectedFromDt = this.selectedToDt;
    this.dates = [];
    let yearHash = {};
    let dt = maxToDt;

    while (differenceInMilliseconds(dt, minFromDt) >= 0) {
      let year = format(dt, 'yyyy');
      if (!yearHash[year]) {
        yearHash[year] = true;
        this.dates.push({ type: 'year', value: year });
      };
      const disableQuarter = differenceInMonths(addMonths(dt, -3), new Date()) > 0 ? 'disable' : 'enable';
      let o: any = { type: 'justify-content-center date' + (this.useQuarters ? '-quarter' : '-month'), value: this.useQuarters ? format(addMonths(dt, -2), 'MMM') + ' - ' + format(dt, 'MMM') : format(dt, 'MMMM'), selected: '', dt: format(dt, 'dd MMM yyyy'), ...(this.disableFutureSelection && this.useQuarters && {disable: disableQuarter}) };
      if (this.selectedToDt && format(dt, 'dd MMM yyyy') == format(this.selectedToDt, 'dd MMM yyyy')) o.selected = 'start';
      else if (this.selectedFromDt && format(dt, 'dd MMM yyyy') == format(this.selectedFromDt, 'dd MMM yyyy')) o.selected = 'end';
      else if (this.selectedFromDt && differenceInMilliseconds(dt, this.selectedToDt) < 0 && differenceInMilliseconds(dt, this.selectedFromDt) > 0) o.selected = 'middle';
      if (this.selectedFromDt && format(dt, 'dd MMM yyyy') == format(this.selectedFromDt, 'dd MMM yyyy') && this.selectedToDt && format(dt, 'dd MMM yyyy') == format(this.selectedToDt, 'dd MMM yyyy')) o.selected = 'startend';
      this.dates.push(o);
      dt = endOfMonth(addMonths(dt, (this.useQuarters ? 3 : 1) * -1));
    }
    if(this.data.timelineMode == 'month') {
      this.timeText = format(this.selectedFromDt, 'MMM yyyy');
    } else {
      if (isSameDay(this.selectedFromDt, this.selectedToDt)) {
        this.timeText = this.useQuarters
          ? this.getTimelineTextForQuarters(
              this.selectedFromDt,
              this.selectedFromDt
            )
          : format(this.selectedFromDt, 'MMM yyyy');
      } else {
        this.timeText = this.useQuarters
          ? this.getTimelineTextForQuarters(
              this.selectedFromDt,
              this.selectedToDt
            )
          : this.getTimelineTextForMonths(
              this.selectedFromDt,
              this.selectedToDt
            );
      }
    }
  }
  quartersChange(event) {
    this.useQuarters = event.checked;
    if (this.useQuarters) {
      let m = this.selectedFromDt.getMonth() + 1;
      if (m % 3) this.selectedFromDt = endOfMonth(addMonths(this.selectedFromDt, m % 3 - 2));
      m = this.selectedToDt.getMonth() + 1;
      if (m % 3) this.selectedToDt = endOfMonth(addMonths(this.selectedToDt,3 - (m % 3)));
    }
    this.generateDateOptions();
  }
  onSelection(item) {
    if (this.allowSingleQuarter && this.useQuarters) {
      this.selectedFromDt = this.selectedToDt = new Date(item.dt);
    }
    else {
      if (this.selectingToDt) {
        this.selectingToDt = false;
        this.selectedToDt = new Date(item.dt);
      } else {
        this.selectedFromDt = new Date(item.dt);
        if (this.data.timelineMode == 'month') {
          this.selectedToDt = this.selectedFromDt;
        } else {
          this.selectingToDt = true;
          this.selectedToDt = null;
        }
      }
    }
    this.generateDateOptions();
  }
  applyRange() {
    if (!this.selectedFromDt || !this.selectedToDt) return;
    let d: any = { type: 'timeline', fromDt: format(this.selectedFromDt, "dd MMM yyyy"), toDt: format(this.selectedToDt, "dd MMM yyyy"), useQuarters: this.useQuarters };
    if (d.fromDt == this.data.fromDt && d.toDt == this.data.toDt && this.useQuarters == this.data.useQuarters) d = { type: 'closeDialog' };
    d.timelineText = this.timeText;
    this.onEvent.emit(d);
  }
  getTimelineTextForQuarters(quarterRangeStartDt, quarterRangeEndDt) {
    const actualQuarterRangeEndDt = quarterRangeEndDt || quarterRangeStartDt;
    return `${this.getQuarterStartingMonthInMonthYearFormat(
      quarterRangeStartDt
    )} - ${this.getQuarterEndingMonthInMonthYearFormat(
      actualQuarterRangeEndDt
    )}`;
  }
  getTimelineTextForMonths(monthRangeStartDt, monthRangeEndDt) {
    return `${format(new Date(monthRangeStartDt), 'MMM yyyy')}${
      monthRangeEndDt ? ' - ' + format(new Date(monthRangeEndDt), 'MMM yyyy') : ''
    }`;
  }
  getQuarterStartingMonthInMonthYearFormat(date) {
    return `${
      this.quarterToQuartersStartingMonthAndEndingMonthMap[
        getQuarter(new Date(date))
      ][0]
    } ${format(new Date(date), 'yyyy')}`;
  }
  getQuarterEndingMonthInMonthYearFormat(date) {
    return `${
      this.quarterToQuartersStartingMonthAndEndingMonthMap[
        getQuarter(new Date(date))
      ][1]
    } ${format(new Date(date), 'yyyy')}`;
  }
}
