import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DIALOG_TYPES, RESPONSE_STATUS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { IAvatar } from '../../layouts/avatar-layout/avatar.model';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
    standalone: false
})
export class ImageCropperComponent implements OnInit {


  @Input() showAvatarDeleteBtn: boolean = true;

  @Output() onEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() enableDisableEvent = new EventEmitter();
  @Output() updateEvent = new EventEmitter();

  public selectedFile: any;
  public isClientUser: boolean = false;
  public imageChangedEvent: any = '';
  public croppedImage: any = {};
  public imageURL: string = '';
  public selectedAvatar: IAvatar = null;

  public sliderValue: number = 50;
  public minValue: number = 0;
  public maxValue: number = 100;
  public step: number = 5;
  public selectedGender: string = 'male';

  private readonly allowedFormatTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private ds: DataService, private weds: WaitErrorDialogsService) { }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnInit(): void {
    this.imageChangedEvent = this.selectedFile;
    this.selectedGender = this.selectedAvatar?.gender || this.selectedGender;
  }

  //------------------------------------------------------------------
  // Event Handlers
  //------------------------------------------------------------------

  public imageCropped(event: any) {
    this.croppedImage = event;
  }

  public loadImageFailed(event) {
    console.error('loadImageFailed', event);
  }

  public delete() {
    this.deleteEvent.emit(this.selectedAvatar);
  }

  public enableDisableToggle() {
    this.enableDisableEvent.emit(this.selectedAvatar);
  }

  public update() {
    this.selectedAvatar.gender = this.selectedGender;
    if(this.sliderValue === 100) {
      this.updateEvent.emit(this.selectedAvatar);
    } else {
      this.onSubmit();
    }
  }

  public add() {
    this.onSubmit();
  }

  public apply() {
    this.onSubmit();
  }

  public decrease() {
    if (this.sliderValue > this.minValue) {
      this.sliderValue -= this.step;
    }
  }

  public increase() {
    if (this.sliderValue < this.maxValue) {
      this.sliderValue += this.step;
    }
  }

  public onSubmit() {
    if (!this.croppedImage) return;

    const dialogData = this.createUploadDialogData();
    const dialogRef = this.weds.showDialog(dialogData);
    const file = this.createFileFromCroppedImage();

    this.uploadFile(file, dialogData, dialogRef);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private createUploadDialogData() {
    return {
      type: DIALOG_TYPES.WAIT,
      title: 'Uploading',
      message: 'Please wait while the image is uploaded',
      showProgress: true,
      progress: 0,
      dontCloseAllDialogs: true,
    };
  }

  private createFileFromCroppedImage(): File {
    const defaultFileName = `avatar_${Date.now()}.png`;
    const fileName = this.selectedFile?.name || this.selectedFile?.value?.split('\\')?.[2] || defaultFileName;

    if (this.croppedImage.blob) {
      const file = new File([this.croppedImage.blob], fileName, { type: this.croppedImage.blob.type || 'image/png' });
      return file;
    }

    return this.dataURLtoFile(this.croppedImage, fileName);
  }

  private uploadFile(file: File, dialogData: any, dialogRef: any) {
    if (!this.croppedImage) return;
    const uploadRequest = this.ds.uploadFiles([file], this.ds.client.clientId, this.allowedFormatTypes, 1).subscribe((response: any) => {
      if (response.type == 1) {
        dialogData.progress = Math.round(response.loaded * 100 / response.total);
      } else if (response.status == 200 && response.body) {
        uploadRequest.unsubscribe();
        const serverResponse = response.body;
        if (serverResponse.status == RESPONSE_STATUS.SUCCESS && serverResponse.files.length) {
          this.onEvent.emit({ file: serverResponse.files[0], gender: this.selectedGender });
          dialogRef.close();
        } else {
          this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: serverResponse.error ? serverResponse.error.code : 99 });
        }
      }
    }, (err) => {
      dialogRef.close();
      this.weds.showDialog({ type: 'generic', code: 0 });
      uploadRequest.unsubscribe();
    });
  }

  private dataURLtoFile(dataurl: string, filename: string): File {
    const [mimeData, base64Data] = dataurl.split(',');
    const mime = mimeData.match(/:(.*?);/)[1];
    const binaryStr = atob(base64Data);
    const len = binaryStr.length;
    const u8arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      u8arr[i] = binaryStr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
  }

}
