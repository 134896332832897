import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { BUTTON_EVENTS, DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';
import { CommonCalculationsService } from 'src/app/services/common-calculations.service';
import { SkillDetailsModalComponent } from '../../modals/skill-details-modal/skill-details-modal.component';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { PrismSearchService } from 'src/app/modules/sms/prism-search/prism-search.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { SkillUtilitiesService } from '../../../services/skill-utilities.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ReviewSkillComponent } from '../../../review-skill/review-skill.component';
import { SkillTypeValidationService } from '../../../services/skill-type-validation.service';
import { DIALOG_ACTION_TYPE } from 'src/app/modules/my-profile/my-profile/my-profile.modal';
import { GlobalSkillTypeValidationService } from '../../../services/global-skill-type-validation.service';
import { SkillCautionsService } from '../../../services/skill-cautions.service';

export enum ProficiencySliderMode {
  GENERAL = 'general',
  ADD = 'add',
  EDIT = 'edit',
  ENDORSER_AIDS_SIDEBAR = 'endorserAidsSidebar'
}
@Component({
  selector: 'app-proficiency-slider',
  templateUrl: './proficiency-slider.component.html',
  styleUrls: ['./proficiency-slider.component.scss'],
  standalone: false
})
export class ProficiencySliderComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() data: any;
  @Input() mode: string = ProficiencySliderMode.GENERAL
  @Output() onRemove = new EventEmitter();
  @Input() isGlobalSkillTypeEnabled: boolean = false;

  public skillForm: FormGroup;
  public settings: any;
  public maxRating: number = 5;
  public proficiencyLevels = [];
  public isLoading: boolean = true;
  public skillQuestions = [
    { key: "incomingRating", title: "Incoming Rating", type: "Rating", editable: true, order: 1 },
    { key: "aspirationalRating", title: "Aspirational", type: "Rating", editable: true, order: 2 },
    { key: "experience", title: "Experience (yrs)", type: "Experience", form: true, validators: [Validators.min(0.5), Validators.max(30)], placeholder: 'Experience', options: this.getExperienceOptions(), order: 3 },
    { key: "lastUsed", title: "Last Used", type: "Year", form: true, validators: [Validators.min(1950), Validators.max(new Date().getFullYear())], placeholder: 'Year', options: Array.from({ length: 50 }, (_, i) => new Date().getFullYear() - i), order: 4 },
    { key: "skillTypeId", title: this.ds.dictionary.skillType, type: "Tagging", form: true, validators: [], placeholder: 'None', order: 5 },
  ];
  public proficiencySliderMode = ProficiencySliderMode;
  public isMobileDevice: boolean = false;

  constructor(
    public ds: DataService,
    private skillUtils: SkillUtilitiesService,
    private fb: FormBuilder,
    private commonCalculations: CommonCalculationsService,
    private dialog: MatDialog,
    private prismSearchService: PrismSearchService,
    private weds: WaitErrorDialogsService,
    private utils: UtilitiesService,
    private skillTypeValidationService: SkillTypeValidationService,
    private globalSkillTypeValidationService: GlobalSkillTypeValidationService,
    private skillCautionService: SkillCautionsService
  ) { }

  ngOnInit() {
    this.isMobileDevice = this.utils.isMobileDevice();
    this.initializeSettings();
    this.updateQuestionsForSettings();
    this.createSkillForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && changes.data.currentValue != changes.data.previousValue && !changes.data.firstChange) {
      this.data = changes.data.currentValue;
      this.initializeSettings();
      this.updateQuestionsForSettings();
      this.createSkillForm();
      setTimeout(() => {
      this.updateProperties();
      });
    }
  }

  ngAfterViewInit(): void {
    this.updateProperties();
  }

  updateProperties() {
    this.updateSliderProperties();
    this.updateTicks();
    this.skillQuestions.forEach(q => {
      if (q.type === 'Rating') {
        this.updateProficiency(q.key);
      }
    })
    const skillCategory = this.ds.client.smsSettings.categoryWiseSettings.find(item => item.categoryId === this.data.skillCategory.categoryId);
    this.data.skillType = skillCategory.skillTypes.find(item => item.skillTypeId === this.data.skillTypeId)?.name || '';
  }

  get isHighDemandSkillEnabled(): boolean {
    return this.skillUtils.checkIsHighDemandSkillTaggingEnabled;
  }

  private initializeSettings() {
    this.proficiencyLevels = [];
    const smsSettings = this.ds.client.smsSettings;
    const skillRating = smsSettings.skillRating;
    if (skillRating === 'Stars 1-5') {
      for (let i = 1; i <= 5; i++) {
        this.maxRating = 5;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        this.maxRating = 4;
        this.proficiencyLevels.push(smsSettings[`skillToolTipLabel${i}`])
      }
    }
    this.settings = this.ds.client.smsSettings.categoryWiseSettings.find(
      (item: any) => item.categoryId === (this.data.skillCategory?.categoryId || this.data.skillCategoryId)
    );

    // Set the names for each rating key
    this.skillQuestions.forEach(q => {
      if (q.type === 'Rating') {
        q.title = this.settings.skillRatings.find(item => item.dataKey === q.key)?.title;
      }
    });
  }

  private updateQuestionsForSettings() {
    for (let i = 0; i < this.skillQuestions.length; i++) {
      let q = this.skillQuestions[i];
      if (q.type == 'Rating' && !this.settings.skillRatings.filter((rating) => rating.useRating == SMS_OPTIONS.YES && rating.dataKey == q.key)[0]) {
        this.skillQuestions.splice(i--, 1)
      }
      if (q.key == 'experience' && this.settings.collectExperience != SMS_OPTIONS.YES) {
        this.skillQuestions.splice(i--, 1);
      }
      if (q.key == 'lastUsed' && this.settings.collectSkillLastUsed != SMS_OPTIONS.YES) {
        this.skillQuestions.splice(i--, 1);
      }
      if (this.isGlobalSkillTypeEnabled && q.key == 'skillTypeId') {
        q.options = this.globalSkillTypeValidationService.transformGlobalSkillType() || [];
      } else {
        if (q.key == 'skillTypeId' && this.settings.enablePrimarySkill != SMS_OPTIONS.YES) {
          this.skillQuestions.splice(i--, 1);
        }
        else if (q.key == 'skillTypeId') {
          q.options = this.settings.skillTypes || [];
        }
      }
    }

    this.skillQuestions.sort((a, b) => (a.order - b.order));
  }

  private updateSliderProperties() {
    const ratingQuestions = this.skillQuestions.filter(item => item.type === 'Rating');
    ratingQuestions.forEach(item => {
      const slider = document.querySelector(`.proficiency-slider-range_${item.key}_${this.data.skillItemId}`) as HTMLElement;
      slider.style.setProperty('--max-rating', this.maxRating.toString());
    });
  }

  public updateProficiency(key: string) {
    const slider = document.querySelector(`.proficiency-slider-range_${key}_${this.data.skillItemId}`) as HTMLElement;
    slider.style.setProperty('--value', this.getFormControlValue(key).toString());
    this.data[key] = this.getFormControlValue(key).toString();
    this.commonCalculations.calculateOverallRating(this.settings.skillRatings, this.data);
    this.skillCautionService.addCautionTextToSkillForUsers(this.data);
    this.updateTicks();
  }

  public getFormControlValue(controlName: string): number {
    return this.skillForm.get(controlName)?.value || 1;
  }

  get isHighDemandSkill(): boolean {
    return this.data.data ? this.data.data?.isHighDemandSkill === SMS_OPTIONS.YES : this.data?.isHighDemandSkill === SMS_OPTIONS.YES;
  }

  public showSkillOverlayDetails(): void {
    const dialogRef = this.dialog.open(SkillDetailsModalComponent, {
      width: this.isMobileDevice ? (1 * window.innerWidth) + 'px' : ((0.65 * window.innerWidth) < 600 ? (0.65 * window.innerWidth) : 600) + 'px',
      height: this.isMobileDevice ? (1 * window.innerHeight) + 'px' : (0.65 * window.innerHeight) + 'px',
      panelClass: this.isMobileDevice ? 'browse-skills' : 'resume-builder'
    });
    dialogRef.componentInstance.skillData = this.data;
    dialogRef.componentInstance.onEvent.pipe(take(1)).subscribe((data: any) => {
      if (data.status === 'close') {
        dialogRef.close();
      }
    });
  }

  public showRemoveConfirmationModal(): void {
    const dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      title: 'Remove skill?',
      message: 'Are you sure you want to remove this skill?',
      btnTitle: 'Remove Skill',
      btn2Title: 'Cancel'
    };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        dialogRef.close();
        this.onRemove.emit(this.data);
      } else if (data.event === BUTTON_EVENTS.BUTTON2_CLICK) {
        dialogRef.close();
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  private getExperienceOptions() {
    const options = [
      { name: '< 1 Year', value: 0.5 },
      { name: '1 Year', value: 1 },
    ];

    for (let i = 2; i <= 30; i++) {
      options.push({ name: `${i} Years`, value: i });
    }

    options.push({ name: '> 30 Years', value: 30 });
    return options;
  }

  private createSkillForm() {
    this.skillForm = this.fb.group({});
    this.addControlForSkillItem(this.data);
    this.setDefaultsForAllowSaveSkillsWOMandatory();
  }

  private addControlForSkillItem(skillItem) {
    this.skillQuestions.forEach((q) => {
      let value = '';

      if (this.mode === ProficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
        value = skillItem.previousRatings.find(rating => rating.dataKey === q.key)?.rating || '1';
      } else {
        if (q.type === 'Rating' && skillItem.recommendedValues?.rating) {
          value = skillItem.recommendedValues.rating.toString();
        }
        else if (skillItem[q.key] && q.key === 'skillTypeId') {
          value = skillItem[q.key];
        }
        else if (skillItem.recommendedValues && skillItem.recommendedValues[q.key]) {
          value = skillItem.recommendedValues[q.key];
        }
        else if (skillItem.existingValues && skillItem.existingValues[q.key]) {
          value = skillItem.existingValues[q.key];
        }
        else if (q.type === 'Rating') {
          value = '1';
        }
      }

      this.skillForm.addControl(q.key, new FormControl(value, q.validators));
    });
  }

  private setDefaultsForAllowSaveSkillsWOMandatory() {
    const experienceControl = this.skillForm.get('experience');
    if (experienceControl && !experienceControl.value) {
      experienceControl.setValue(this.getExperienceOptions()[0].value, { onlySelf: true, emitEvent: false });
    }

    const lastUsedControl = this.skillForm.get('lastUsed');
    if (lastUsedControl && !lastUsedControl.value) {
      lastUsedControl.setValue(new Date().getFullYear(), { onlySelf: true, emitEvent: false });
    }

    const skillTypeIdControl = this.skillForm.get('skillTypeId');
    if (skillTypeIdControl && !skillTypeIdControl.value) {
      const globalSkillTypes = this.globalSkillTypeValidationService.transformGlobalSkillType();
      const defaultValue = this.isGlobalSkillTypeEnabled ? globalSkillTypes[globalSkillTypes.length - 1].skillTypeId : this.settings.skillTypes[this.settings.skillTypes.length - 1].skillTypeId;
      skillTypeIdControl.setValue(defaultValue, { onlySelf: true, emitEvent: false });
    }
    if (skillTypeIdControl && this.isGlobalSkillTypeEnabled) {
      skillTypeIdControl.disable();
    }
  }

  private updateTicks(): void {
    const ratingQuestions = this.skillQuestions.filter(item => item.type === 'Rating');
    ratingQuestions.forEach(item => {
      const value = this.getFormControlValue(item.key);
      const ticks = document.querySelectorAll(`.proficiency-slider-range_${item.key}_${this.data.skillItemId} ~ .proficiency-slider-ticks .tick`);

      ticks.forEach((tick, index) => {
        tick.classList.toggle('active', index + 1 < Number(value));
        tick.classList.toggle('hidden', index + 1 === Number(value));
      });
    });
  }

  public onAddToMySkills() {
    const existingSkills = this.prismSearchService.existingUserSkills();
    this.data = { ...this.data, ...this.skillForm.value, updateSource: '' };
    this.reviewSkillDialog(this.data.skillCategory, existingSkills, [this.data]);
  }

  public onUpdate() {
    const existingSkills = this.prismSearchService.existingUserSkills();
    const index = existingSkills.findIndex((skill) => skill.skillItemId === this.data.skillItemId);
    existingSkills[index] = { ...existingSkills[index], ...this.skillForm.value };
    this.reviewSkillDialog(this.data.skillCategory, this.utils.filterDuplicateSkills([existingSkills[index]], existingSkills), [existingSkills[index]]);
  }

  public onDelete() {
    const existingSkills = this.prismSearchService.existingUserSkills();
    const skillItems = existingSkills.filter((skill) => skill.skillItemId !== this.data.skillItemId);
    this.save({ userId: this.ds.currentUserData.userId, type: 'saveMySkills', updateSource: '', skillItems: skillItems, deletedSkillItems: [this.data] });
  }

  get tooltipForGlobalSkillType(): string {
    return this.globalSkillTypeValidationService.getGlobalSkillTypeLabel(this.data.skillTypeId) + '\n(Go back to update)';
  }

  private save(payload: any) {
    this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -5, dontCloseAllDialogs: true });
    this.ds.saveData(API_ENDPOINT.SAVE_MY_SKILLS, payload).pipe(take(1)).subscribe((rs: any) => {
      this.weds.closeDialog();
      if (rs.status === RESPONSE_STATUS.SUCCESS) {
        this.prismSearchService.refreshAPI.set(true);
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: rs.error ? rs.error.code : 99 });
      }
    })
  }

  private reviewSkillDialog(skillCategory, userExistingSkills, userNewSkills): void {
    const isValidationEnabled = this.isSkillValidationEnabled(skillCategory);
    const validationError = this.getSkillValidationError(skillCategory, userExistingSkills, userNewSkills);
    if (isValidationEnabled && this.hasValidationError(validationError)) {
      this.openReviewSkillDialog(userExistingSkills, userNewSkills, skillCategory, validationError);
    } else {
      let payload: any = { userId: this.ds.currentUserData.userId, type: 'saveMySkills', skillItems: [...userExistingSkills, ...userNewSkills], updateSource: '' };
      this.save(payload);
    }
  }

  private isSkillValidationEnabled(skillCategory): boolean {
    return this.skillTypeValidationService.skillTypeMinMaxEnabled(skillCategory?.categoryId);
  }

  private getSkillValidationError(skillCategory, userExistingSkills, userNewSkills): any {
    const skillSummary = this.skillTypeValidationService.getCategorySkillSummary([
      ...userNewSkills,
      ...userExistingSkills,
    ]);
    return this.skillTypeValidationService.getSkillTypeValidationMessages(
      skillCategory?.categoryId,
      skillSummary
    );
  }

  private hasValidationError(validationError): boolean {
    return validationError?.validation.some((validation) => validation.error);
  }

  private openReviewSkillDialog(userExistingSkills, userNewSkills, skillCategory, validationError) {
    const errorSkillType = validationError.validation.map(obj => obj.skillTypeId);
    const existingSkills = userExistingSkills.filter(skill => errorSkillType.includes(skill.skillTypeId));
    const currentSkills = userNewSkills.filter(skill => errorSkillType.includes(skill.skillTypeId));
    const skillReviewDialog = this.dialog.open(ReviewSkillComponent, { width: (0.95 * window.innerWidth) + 'px', height: (0.95 * window.innerHeight) + 'px', panelClass: 'browse-skills' });

    skillReviewDialog.componentInstance.reviewSkillParams = {
      skillTypeValidationError: validationError,
      categoryId: skillCategory.categoryId,
      categoryName: skillCategory.name,
      skillData: [...currentSkills, ...existingSkills],
      newSkillIds: new Set(currentSkills.map(item => item.skillItemId)),
      updateSkillObject: {
        existingSkillsForDelete: [],
        existingSkillsForUpdate: [],
        newSkillsForUpdate: [],
        newSkillsForDelete: []
      },
    }

    skillReviewDialog.componentInstance.onEvent.pipe(take(1)).subscribe((data: any) => {
      skillReviewDialog.close();
      if (data?.type === DIALOG_ACTION_TYPE.UPDATE) {
        const skillItems = this.processDataToUpdate(data.updateObjects, userNewSkills);

        let payload: any = { userId: this.ds.currentUserData.userId, type: 'saveMySkills', ...skillItems, updateSource: '' };
        this.save(payload);
      }
    });
  }

  private processDataToUpdate(updatedData, userNewSkills) {
    const newSkillIds = new Set(updatedData.newSkillsForUpdate.map(s => s.skillItemId));
    const newSkillDeletedIds = new Set(updatedData.newSkillsForDelete.map(s => s.skillItemId));

    const skillItems = [
      ...updatedData.existingSkillsForUpdate,
      ...updatedData.newSkillsForUpdate,
      ...userNewSkills.filter(s => !newSkillIds.has(s.skillItemId) && !newSkillDeletedIds.has(s.skillItemId))
    ];

    return { deletedSkillItems: [...updatedData.existingSkillsForDelete], skillItems };
  }
}
