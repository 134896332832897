<div class="reports-center-settings-con">
  <div class="h4 black">Certification Settings</div>
  <form [formGroup]="editForm" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div
      [ngClass]="{
        'admin-input-con-full': smsOption.type === 'textarea' || smsOption.type === 'sub-title',
        'admin-input-con-half': smsOption.type !== 'textarea' && smsOption.type !== 'sub-title',
        'd-block': smsOption.showControl,
        'd-none': !smsOption.showControl
      }">
          @if (smsOption.type=='select'&&smsOption.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                @if (smsOption.icon) {
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                }
                <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                  [multiple]="smsOption.isMulti">
                  @for (option of smsOption.options; track option) {
                    <mat-option
                      [value]="option.value" [disabled]="option.disabled">
                      {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                    </mat-option>
                  }
                </mat-select>
                @if (editForm.get(smsOption.key).invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type=='multiSelectChip' && editForm?.controls?.[smsOption.key]) {
            <div class="input-con-half">
              <app-multi-select-chip [disabled]="!canEdit" [input]="smsOption.subOptions" [formControlName]="smsOption.key">
              </app-multi-select-chip>
            </div>
          }
        </div>
      }
    </div>
  </form>
</div>
