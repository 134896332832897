import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
    selector: 'app-certifications-settings',
    templateUrl: './certifications-settings.component.html',
    styleUrls: ['./certifications-settings.component.scss'],
    standalone: false
})
export class CertificationsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  smsOptions: any[] = [
    { 
      title: 'Use certifications master',
      key: 'useCertificationsMaster',
      type: 'select',
      options: ['Yes', 'No'],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true,

    },
    { 
      title: 'Allow manual addition of certifications', 
      key: 'allowManualCertifications',
      type: 'select',
      options: ['Yes', 'No'],
      validators: [Validators.required],
      icon: 'settings_suggest',
      required: true 
    },
    { 
      title: 'Manage certifications skills in SMS', 
      key: 'manageCertificationsSkills', 
      type: 'select', 
      options: ['Yes', 'No'], 
      validators: [Validators.required],
      icon: 'settings_suggest', 
      required: true 
    },
    { 
      title: 'Additional mandatory fields for user certifications addition', 
      isMulti: true, 
      key: 'userCertificationsAdditionalFields', 
      type: 'select', 
      options: [
        {key: 'Certification Number', value: 'certificationIssuerId'}, 
        {key: 'Certificaton Renewal Needed', value: 'certificationRenewalNeeded'}, 
        {key: 'Skills', value: 'skills'},
        {key: 'Certification Date', value: 'certificationDt'}, 
        {key: 'Expiry Date', value: 'certificationExpiryDt'}
      ],
      icon: 'settings_suggest',
      displayKey: 'key',
      checkValue: [{ key: 'allowManualCertifications', values: ['Yes'] } ],
      tooltip: 'This mandatory validation is only applicable on the UI'
    },
    { 
      title: 'Disclaimer text below Certification search', 
      key: 'disclaimerBelowCertificationSearch', 
      type: 'textarea', 
      icon: 'settings_suggest', 
    }
  ];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || 'No Access') == 'No Access') s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }
  saveData() {
    if (this.editForm.invalid) return;
    let d = this.editForm.value;
    d.type = 'certifications';
    this.onSave.emit(d);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
    if (smsOption.key === 'allowManualCertifications') {
        this.buildForms();
    }
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }
}
