import { inject, Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { STORAGE_CONSTANTS } from 'src/app/constants/storage.constants';
import { DialogComponent } from 'src/app/modules/shared/dialog/dialog.component';
import { FilterKeys, IPrismSearchPayload, ITagsConfigMap } from './prism-search.model';
import { ColumnType, IColumn, IDialog } from './../../shared/shared.model';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AdvancedSearchModalComponent } from './advanced-search-modal/advanced-search-modal.component';

@Injectable({
  providedIn: 'root'
})
export class PrismSearchService {
  private router = inject(Router);
  private dialog = inject(MatDialog);
  private utils = inject(UtilitiesService);
  
  public tagsConfigMap: ITagsConfigMap = {
    allocation: ['allocation', 'allocation-percentage'],
  };
  public prismSearchPayload = signal<IPrismSearchPayload>(null);
  public existingUserSkills = signal<any>(null);
  public refreshAPI = signal<boolean>(true);

  public navigateToPrismSearch(query: any, isAdvancedSearch = false) {
    let formattedQuery = '';
    if (isAdvancedSearch) {
      formattedQuery = this.formatAdvancedSearchQuery(query);
      localStorage.setItem(STORAGE_CONSTANTS.ADVANCED_SEARCH_STRING_QUERY, formattedQuery);
      this.prismSearchPayload.set({ payload: query, isAdvancedSearch, formattedQuery });
    } else {
      this.prismSearchPayload.set({ payload: query, isAdvancedSearch, formattedQuery: query });
    }
    this.router.navigate(['/sms/prismSearch']);
  }

  public transformSkillData(skillData: any, settings: any){
    const skillHierarchySettings = settings.client.smsSettings.skillHierarchy;
    skillData.forEach(skill => {
      skill.skillHierarchy = skill.skillHierarchy.map((item) => {
        const hierarchy = skillHierarchySettings.find((setting) => item.order === setting.order);
        return {...item, hierarchyName: hierarchy.name}
      })
      skill.skillHierarchy.sort((a, b) => b.order - a.order);
    });
    return skillData;
  }

  public matchedSearchValue(values: string[]): string {
    const matchedBoldValues = values.slice(0, 3).map(value => {
      return value.replace(/<em>(.*?)<\/em>/g, '<span class="highlights-text">$1</span>');
    });
    const extraHighlightValues = values.slice(3);
    let highlightValue = matchedBoldValues.join(', ');
    if (extraHighlightValues.length > 0) {
      highlightValue += ` ... +${extraHighlightValues.length} more`;
    }

    return highlightValue;
  }
  
  public openExportHistory() {
    const data: IDialog = {
      icon: 'history',
      title: 'Export History',
      datatable: {
        title: 'export history',
        api: {
          endpoint: API_ENDPOINT.PRISM_SEARCH_EXPORT,
          payload: {
            operation: 'GET'
          },
          clientId: true
        },
        parseResponseKey: 'exportHistory',
        columns: [
          {
            name: 'Search Query',
            key: 'searchTerm',
            type: ColumnType.String
          },
          {
            name: 'Entity',
            key: 'entityType',
            type: ColumnType.String,
            valueFunction: (config: IColumn, row: unknown) => {
              const mapObject = this.utils.invertObject(FilterKeys);
              return mapObject[row[config.key]];
            }
          },
          {
            name: '# Results',
            key: 'results',
            type: ColumnType.Number
          },
          {
            name: 'Date',
            key: 'date',
            type: ColumnType.DateTime
          },
          {
            name: 'Status',
            key: 'status',
            type: ColumnType.Status
          }
        ]
      }
    }
    this.dialog.open(DialogComponent, {
      disableClose: true,
      width: window.innerWidth < 1000 ? 0.9 * window.innerWidth + 'px' : '1000px',
      height: 0.9 * window.innerHeight + 'px',
      autoFocus: false,
      restoreFocus: false,
      data: data
    });
  }

  public openAdvancedSearchModal(): void {
    const { innerWidth, innerHeight } = window;
    const dialogWidth = innerWidth < 900 ? `${0.65 * innerWidth}px` : '1000px';
    const dialogHeight = `${0.65 * innerHeight}px`;

    this.dialog.open(AdvancedSearchModalComponent, {
      width: dialogWidth,
      height: dialogHeight,
      panelClass: 'dd-modal',
      disableClose: false
    });
  }

  public formatAdvancedSearchQuery(query: any): string {
    if (!query?.value || !Array.isArray(query.value)) {
      return '';
    }

    return query.value
      .map((condition: any) => {
        if (condition.groupOperator && Array.isArray(condition.value)) {
          return `(${this.formatAdvancedSearchQuery(condition)})`;
        }
        return this.formatCondition(condition);
      })
      .filter(Boolean)
      .join(` ${query.groupOperator.toUpperCase() === 'AND' ? '&' : '|'} `);
  }

  private formatCondition(condition: any): string {
    const entries: any = Object.entries(condition)[0];
    const [field, details] = entries;
    const { operator, value, secondaryValue } = details;

    if (!value || (Array.isArray(value) && value.length === 0)) {
      return '';
    }

    if (field?.includes('date')) {
      return this.formatDateCondition(field, value, operator);
    }

    if (this.tagsConfigMap.allocation.includes(field)) {
      return this.formatNumericCondition(field, value, operator, secondaryValue);
    }

    return this.formatDefaultCondition(field, value, operator);
  }

  private formatNumericCondition(field: string, value: any, operator: string, secondaryValue?: any): string {
    if (operator === 'range') {
      return `${field}: ${value}% to ${secondaryValue}%`;
    }
    return `${field} ${operator} ${value}%`;
  }

  private formatDateCondition(field: string, value: any, operator: string): string {
    const formattedDate = this.formatDate(value);
    return `${field} ${operator} ${formattedDate}`;
  }

  private formatDefaultCondition(field: string, value: any, operator: string): string {
    if (Array.isArray(value) && value.length > 1) {
      return value.map((val: string) => `${field}: "${val}"`).join(' | ');
    }
    return `${field}: "${value}"`;
  }

  private formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  }
}
