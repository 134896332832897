<div class="advanced-search-modal-wrapper">
  <div class="advanced-search-modal-header">
    <div class="advanced-search-modal-header-title">
      <mat-icon class="advanced-search-modal-header-title-icon">tune</mat-icon>
      <div class="advanced-search-modal-header-title-text">
         <span class="h4 title">Advanced Search</span>
         <span class="h5 fw-500">Build your search query to perform advanced search.</span>
      </div>
    </div>
    <mat-icon mat-dialog-close class="ic-20 color-color-white cursor-pointer">close</mat-icon>
  </div>
  <div class="advanced-search-modal-body">
      <div class="advanced-search-modal-body-cond">
        <div class="advanced-search-modal-body-cond-btns">
        <app-advanced-search-form class="w-100" [tagsConfigMap]="tagsConfigMap"></app-advanced-search-form>
      </div>
  </div>
  <div class="advanced-search-modal-footer">
      <button mat-dialog-close mat-stroked-button class="btn secondary-button">Close</button>
      <button mat-dialog-close mat-stroked-button class="btn primary-button" (click)="generateQueryAndSearch()">Search</button>
  </div>
</div>
