<form [formGroup]="editForm">
  <mat-form-field appearance="outline" class="w-100 mt-2 filter">
    @if (input.title && !input.noAutoCompleteTitle) {
      <mat-label>{{input.title}}</mat-label>
    }
    @if (input.icon) {
      <mat-icon matPrefix>{{input.icon}}</mat-icon>
    }
    <input matInput placeholder="{{input.placeholder}}" formControlName="input" [required]="input.required"
      [matAutocomplete]="auto" (focus)="getData()" />
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="getDisplayName"
      (optionSelected)="onSelectionChange($event)" (opened)="autocompleteScroll()">
      @for (option of options; track option) {
        <mat-option [value]="option">
          {{option[input.nameField] || option.name || option}}
        </mat-option>
      }
    </mat-autocomplete>
    @if (input.suffixIcon) {
      <mat-icon matSuffix class="mat-custom-arrow">{{input.suffixIcon}}</mat-icon>
    }
  </mat-form-field>

  <div class="tags">
    @for (item of selectedItems; track item) {
      <div class="tag d-flex h-100 align-items-baseline mt-2">
        <span class="body black">{{item[input.nameField] || item.name || item}}</span>
        <mat-icon (click)="removeRole(item)">cancel</mat-icon>
      </div>
    }
  </div>

</form>
