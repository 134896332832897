<div style="width:100%" class="general-admin-con">
  <div class="h3 black ">General Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
        <div
          [ngClass]="smsOption.type === 'textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{ 'display': smsOption.showControl && editForm.controls[smsOption.key] ? 'block': 'none'}">
          @if ((smsOption.type=='text' || smsOption.type=='number') && smsOption.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                  [required]="smsOption.required" autocomplete="off"
                  maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" type="{{smsOption.type}}" />
                @if (editForm.get(smsOption.key).invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type === 'textarea' && smsOption.showControl) {
            <mat-form-field appearance="outline"
              class="admin-input-form-field admin-text-area-field">
              <mat-label>{{smsOption.title}}</mat-label>
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
            </mat-form-field>
          }
          @if (smsOption.type === 'select' && smsOption.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                @if (smsOption.icon) {
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                }
                <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                  [multiple]="smsOption.isMulti"
                  [compareWith]="compareSelect" (selectionChange)="onValueChange(smsOption)">
                  @for (option of smsOption.options; track option) {
                    <mat-option
                      [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                      {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                    </mat-option>
                  }
                </mat-select>
                @if (editForm.get(smsOption.key).invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type === 'date' && smsOption.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                <mat-icon matPrefix>date_range</mat-icon>
                <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                  [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                  (focus)="picker.open()" />
                <mat-datepicker #picker></mat-datepicker>
                @if (editForm.get(smsOption.key).invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type === 'multiSelectChip' && editForm.controls[smsOption.key]) {
            <div class="input-con-half">
              <app-multi-select-chip [input]="smsOption.subOptions"
                [formControlName]="smsOption.key">
              </app-multi-select-chip>
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
</div>
