<div class="skill-taxonomy-con">
  <div class="h3 black">Skill Management</div>
  <div class="h4 mt-4 black">General Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of smsOptions; track smsOption) {
      <div [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
        [ngStyle]="{'display':smsOption.showControl&&renderSkillProficiencyRelationNode(smsOption.key)?'block':'none'}">
        @if (smsOption.showControl) {
        <div>
          @if (smsOption.type=='text') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{smsOption.title}}</mat-label>
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                [required]="smsOption.required" autocomplete="off"
                maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
              @if (editForm.get(smsOption.key).invalid) {
              <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </mat-form-field>
          </div>
          }
          @if (smsOption.type=='textarea') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
              <mat-label>{{smsOption.title}}</mat-label>
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
                class="config-tooltip h5 black"></textarea>
            </mat-form-field>
          </div>
          }
          @if (smsOption.type=='number') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{smsOption.title}}</mat-label>
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                [required]="smsOption.required" autocomplete="off" [type]="smsOption.type" [min]="smsOption.min||0" />
              @if (editForm.get(smsOption.key).invalid) {
              <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </mat-form-field>
          </div>
          }
          @if (smsOption.type=='select') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{smsOption.title}}</mat-label>
              @if (smsOption.icon) {
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              }
              <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                [compareWith]="compareSelect">
                @for (option of smsOption.options; track option) {
                <mat-option
                  [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                  {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                </mat-option>
                }
              </mat-select>
              @if (editForm.get(smsOption.key).invalid) {
              <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </mat-form-field>
          </div>
          }
          @if (smsOption.type=='date') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{smsOption.title}}</mat-label>
              <mat-icon matPrefix>date_range</mat-icon>
              <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off" (focus)="picker.open()" />
              <mat-datepicker #picker></mat-datepicker>
              @if (editForm.get(smsOption.key).invalid) {
              <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </mat-form-field>
          </div>
          }
        </div>
        }
      </div>
      }
      @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
      }
    </div>
  </form>
  <!-- Skill Type Settings -->
  <div class="h4 mt-4 black">Skill Type Settings</div>
  <form [formGroup]="skillTypeSettingsForm" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (smsOption of skillTypeSettingOptions; track smsOption) {
      <div [ngClass]="smsOption.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'">
        <div>
          @if (smsOption.type === 'select') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{smsOption.title}}</mat-label>
              @if (smsOption.icon) {
              <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
              }
              <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                [compareWith]="compareSelect">
                @for (option of smsOption.options; track option) {
                <mat-option
                  [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                  {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                </mat-option>
                }
              </mat-select>
              @if (skillTypeSettingsForm.get(smsOption.key).invalid) {
              <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </mat-form-field>
          </div>
          }
        </div>
      </div>
      }
    </div>
    @if (skillTypeSettingsForm.get('enableGlobalSkillType').value === 'Yes') {
    <div class="table-con">
      <app-editable-table #skillTypes [canEdit]="canEdit" [enableDelete]="false" [columns]="skillTypesColumns"
        class="sms-editable-table" [dataSource]="globalSkillTypes"
        (formValidityChange)="onSkillTypesValidityChange($event)" [blockNegativeInputs]="true" [enableDrag]="false">
      </app-editable-table>
    </div>
    }
    @if (canEdit) {
    <div class="admin-save-button-con">
      <button (click)="saveSkillTypeData()" [disabled]="skillTypeSettingsForm.invalid || !skillTypesFormValid"
        type="submit" mat-stroked-button class="primary-button">Save</button>
    </div>
    }
  </form>
  <!-- End of Skill Type Settings -->
  <div class="d-flex mt-4 flex-column">
    <div class="h4 black">My Skills Summary</div>
    <form class="admin-form" [formGroup]="mySkillsSummaryFormGroup" (ngSubmit)="saveMySkillsSummaryData()"
      (keydown.enter)="evaluateEnterKey($event)">
      <div class="admin-form-con">
        @for (option of mySkillsSummaryOptions; track option) {
        <div class="admin-input-con-full">
          @if (option.type === 'select') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field">
              <mat-label>{{option.title}}</mat-label>
              @if (option.icon) {
              <mat-icon matPrefix>{{option.icon}}</mat-icon>
              }
              <mat-select formControlName="{{option.key}}" [required]="option.required" [compareWith]="compareSelect">
                @for (option of option.options; track option) {
                <mat-option [value]="option">
                  {{option}}
                </mat-option>
                }
              </mat-select>
              @if (mySkillsSummaryFormGroup.get(option.key).invalid) {
              <mat-error>
                {{option.errorText ||'This input is mandatory'}}
              </mat-error>
              }
            </mat-form-field>
          </div>
          }
          @if (option.type=='textarea') {
          <div>
            <mat-form-field appearance="outline" class="admin-input-form-field admin-text-area-field">
              <mat-label>{{option.title}}</mat-label>
              <mat-icon matPrefix>{{option.icon}}</mat-icon>
              <textarea matInput [placeholder]="option.title" formControlName="{{option.key}}" cdkTextareaAutosize
                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="20"
                class="config-tooltip h5 black"></textarea>
            </mat-form-field>
          </div>
          }
        </div>
        }
        @if (canEdit) {
        <div class="d-flex flex-grow-1 justify-content-center">
          <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
        }
      </div>
    </form>
  </div>
  @if (showSkillAdditionRequests) {
  <div class="d-flex mt-4 flex-column">
    <div class="d-flex flex-column flex-half pt-2 pb-4">
      <div class="h4 black">Skill Addition Requests</div>
      <form class="admin-form" [formGroup]="skillRequestOptionsFormGroup" (ngSubmit)="saveSkillRequestData()"
        (keydown.enter)="evaluateEnterKey($event)">
        <div class="admin-form-con flex-column">
          @for (option of skillRequestOptions; track option) {
          <div class="admin-input-con-half">
            @if (option.type === 'select') {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{option.title}}</mat-label>
                @if (option.icon) {
                <mat-icon matPrefix>{{option.icon}}</mat-icon>
                }
                <mat-select formControlName="{{option.key}}" [required]="option.required" [compareWith]="compareSelect">
                  @for (option of option.options; track option) {
                  <mat-option [value]="option">
                    {{option}}
                  </mat-option>
                  }
                </mat-select>
                @if (skillRequestOptionsFormGroup.get(option.key).invalid) {
                <mat-error>
                  {{option.errorText ||'This input is mandatory'}}
                </mat-error>
                }
              </mat-form-field>
            </div>
            }
          </div>
          }
          @if (canEdit) {
          <div class="d-flex flex-grow-1 justify-content-center">
            <button type="submit" mat-stroked-button class="primary-button">Save</button>
          </div>
          }
        </div>
      </form>
    </div>
  </div>
  }
  <app-tooltip-settings [levels]="editForm.get('skillRating').value" [canEdit]="canEdit" [client]="client"
    (onSave)="saveTooltipData($event)"></app-tooltip-settings>
</div>
