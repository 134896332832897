<div class="d-flex flex-column gap-3">
  <div class="h3 black ">Custom Tags</div>
  <section class="d-flex flex-column gap-2">
    <div class="d-flex justify-content-between">
      @if (canEdit) {
        <mat-form-field class="input-form-field admin-input-con-half" appearance="outline">
          <mat-label>Add new custom tag</mat-label>
          <input matInput placeholder="Custom tag" [(ngModel)]="newCustomTag" (keyup.enter)="addCustomTag()"/>
        </mat-form-field>
      }
      <mat-form-field appearance="outline" class="input-form-field admin-input-con-half">
        <mat-label>Choose from available custom tags</mat-label>
        <mat-icon matPrefix>settings_suggest</mat-icon>
        <mat-select multiple [formControl]="customTagsAvailableFieldsFormControl"
          (selectionChange)="customTagsChosenForMaster($event)">
          @for (field of userCustomTagPresets; track field) {
            <mat-option [value]="field?.dataKey">
              {{field?.field}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex flex-column gap-3">
      <form [formGroup]="fieldsForCustomTagsFormGroup">
        <div class="d-flex flex-column custom-tags border-radius-4">
          <div
            class="d-flex custom-tag flex-row align-items-center col-gap-2 pt-2 pr-4 pb-2 pl-4 h4 black font-weight-bold">
            <span class="flex-grow-1">Tag</span>
            <div class="d-flex flex-row align-items-center col-gap-3">
              <span class="w-200">Mandatory/Optional</span>
              <span class="w-200">Input Type</span>
              <span class="w-200 pr-5">Additional Fields</span>
              <mat-icon class="white">clear</mat-icon>
            </div>
          </div>
          @for (field of fieldsForCustomTags; track field; let i = $index) {
            <div class="d-flex custom-tag flex-row align-items-center col-gap-3 pt-1 pr-4 pb-1 pl-4"
              >
              <span class="flex-grow-1">{{ field.field }}</span>
              <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>Choose option</mat-label>
                  <mat-select [disabled]="field?.dataKey === 'staffingRole'" [formControlName]="field.dataKey + 'isMandatory'">
                    @for (option of requiredOptions; track option) {
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </span>
              <span class="d-flex flex-row align-items-center col-gap-3 w-200">
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>Choose option</mat-label>
                  <mat-select [formControlName]="field.dataKey + 'isMultiSelect'">
                    @for (option of inputTypeOptions; track option) {
                      <mat-option [value]="option">{{option}}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </span>
              <span class="d-flex flex-row align-items-center col-gap-3 w-200 pr-5">
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>Choose options</mat-label>
                  <mat-select [disabled]="field?.dataKey === 'staffingRole'" [formControlName]="field.dataKey + 'additionalFields'" multiple>
                    <!-- Preconfigured Additional fields -->
                    @for (addtionalField of preconfiguredAddtionalField; track addtionalField) {
                      <mat-option [value]="addtionalField">{{addtionalField}}
                      </mat-option>
                    }
                    <!-- Additional fields coming from BE -->
                    @for (addtionalField of field.addtionalFields; track addtionalField) {
                      <mat-option [value]="addtionalField">{{addtionalField}}
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </span>
              @if (canEdit) {
                <mat-icon (click)="removeFieldCustomTags(field.dataKey)" class="opacity-2 opacity-hover-7 cursor-pointer">clear</mat-icon>
              }
            </div>
          }
        </div>
      </form>
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" (click)="saveCustomTags()" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </section>
</div>
