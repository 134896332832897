import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
    selector: 'app-search-dd-dialog',
    templateUrl: './search-dd-dialog.component.html',
    styleUrls: ['./search-dd-dialog.component.scss'],
    standalone: false
})
export class SearchDdDialogComponent implements OnInit {
  @Input() data: any;
  @Output() onEvent = new EventEmitter();

  allItems: any[] = [];
  list: any[] = [];
  selectedItems: any[] = [];
  searchControl = new FormControl();
  numVisible = 20;
  timer: any;
  showClearAll: boolean;
  key: string;
  enableTooltip: boolean = false;

  constructor(private ds: DataService, private weds: WaitErrorDialogsService) { }

  ngOnInit(): void {
    this.allItems = this.data.data;
    this.enableTooltip = this.data.enableTooltip ?? false;
    this.showClearAll = this.data.showClearAll ?? true;
    this.list = this.data.data;
    if (this.data.selectedItems.length) {
      this.selectedItems = this.data.selectedItems.map((item) => {
        item.selected = true;
        return item;
      });
    }
    this.key = this.data.key;
    this.initSearch();
    this.timer = setTimeout(() => this.numVisible = 1000, 500);
  }
  initSearch() {
    this.searchControl.valueChanges.subscribe((val) => {
      val = val.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      let regExp = new RegExp(val, 'i');
      let items = [];
      this.allItems.map((s) => {
        s.searchVal = s.search.search(regExp);
        if (s.searchVal >= 0) items.push(s);
      });
      if (!this.data.noSort)
        items.sort((a, b) => a.selected && !b.selected ? -1 : !a.selected && b.selected ? 1 : a.searchVal > b.searchVal ? 1 : a.searchVal < b.searchVal ? -1 : 0);
      if (this.timer) clearTimeout(this.timer);
      this.numVisible = 20;
      this.timer = setTimeout(() => {
        this.numVisible = 1000;
      }, 200);
      this.list = items;
    });
  }
  onItemSelected(item) {
    if (!this.showClearAll && item.selected) return
    item.selected = item.selected ? false : true;
    if (item.selected) {
      this.selectedItems.push(item);
    }
    else {
      let index = this.selectedItems.findIndex((s) => s.inputId == item.inputId);
      this.selectedItems.splice(index, 1);
    }
    // this.searchControl.setValue("");
    if (this.data.maxItems && this.selectedItems.length > this.data.maxItems) {
      this.selectedItems[0].selected = false;
      this.selectedItems.splice(0, 1);
    }
    this.onEvent.emit({ type: 'selection', selectedItems: this.selectedItems });

  }
  clearAll() {
    this.allItems.map((item) => (item.selected = false));
    this.selectedItems = [];
    const isInsightSkillFilters = this.ds.smsCache?.dbFilterData?._sequence.includes(this.key);
    if (isInsightSkillFilters) {
      const filterData = this.ds.smsCache?.dbNoneFilterData;
      if (filterData)
        this.data.data = filterData[filterData?._fieldMap[this.key]];
    }
    this.onEvent.emit({ type: 'selection', selectedItems: this.selectedItems, event: 'CLEAR_ALL' });
  }
}
