<div class="app-container">
  <!-- Sidebar -->
  <div class="sidebar">
    <div class="skill-activity-container">
      <div class="header h4">
        <h2>Recent activity for {{ skill?.name }}</h2>
        <button class="close-button" (click)="closeSidebar()">×</button>
      </div>
      <div class="p-4">
        <app-recent-skill-activity [skill]="skill" [isEndorsement]="isEndorsement" [isSkillActivity]="true"></app-recent-skill-activity>
      </div>
    </div>
  </div>
</div>