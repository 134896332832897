<div class="con">
  <div class="search-con">
    <mat-icon matPrefix class="icon">search</mat-icon>
    <input [placeholder]="opts.placeholder||'Search'" autocomplete="off" class="search" matInput
      [formControl]="searchControl" #search [matAutocomplete]="auto"
      [matAutocompleteDisabled]="opts.searchEndpoint?false:true" (keydown.enter)="onEnter($event)" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchResultSelected($event)">
      @for (search of searchResults | async; track search) {
      <mat-option [value]="search">
        {{search.searchDisplayText || search[opts.searchDisplayField]}}
      </mat-option>
      }
    </mat-autocomplete>
    @if (opts.multipleFilter) {
    <div class="search-filter">
      <mat-form-field appearance="outline">
        <mat-select #matSelectRef multiple [disabled]="!secondaryOpts.length" placeholder="Roles" disableOptionCentering
          [(ngModel)]="selectedValues" (ngModelChange)="onSelectionChange()">
          @for (role of secondaryOpts; track role) {
          <mat-option [value]="role">
            <span class="h4-black">{{role.name}}</span>
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    }
    @if (opts.add) {
    <button [matTooltip]="opts.add" mat-stroked-button class="search-button"
      (click)="onAddClick()">{{opts.add}}</button>
    }
    @if(opts.dropdown) {
    <div>
      <button [matMenuTriggerFor]="menu" class="dropdown-button">{{opts.dropdown.label}}
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="mt-1">
        @for(option of opts.dropdown.options; track option) {
        @if (option.name) {
        <button mat-menu-item (click)="onDropdownItemClick(option)">{{option.name}}</button>
        }
        }
      </mat-menu>
    </div>
    }
    @if (opts.secondaryButton) {
    <button [matTooltip]="isObject(opts.secondaryButton) ? opts.secondaryButton.name : opts.secondaryButton"
      mat-stroked-button class="secondary-search-button" (click)="onSecondaryButtonClick()">
      @if(isObject(opts.secondaryButton)) {
      <div class="item-center gap-2">
        <mat-icon class="h-20px w-20px fs-20">{{opts.secondaryButton.icon}}</mat-icon>
        <span>{{opts.secondaryButton.name}}</span>
      </div>
      } @else {
      {{opts.secondaryButton}}
      }
    </button>
    }
  </div>
  <div class="bottom-con">
    <div class="caption gray">{{opts.paginationStr}}</div>
    <div class="search-icons">
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        @if (opts.showSkillAdjacencyText) {
        <div class="caption gray">Skill Adjacency</div>
        }
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          @if (opts.uploadAdjacentSkill) {
          <mat-icon matTooltip="Upload Adj. Skills file" class="right-icon" (click)="uploadAdjacentSkill()">cloud_upload
          </mat-icon>
          }
          @if (opts.downloadAdjacentSkill) {
          <mat-icon matTooltip="Download Adj. Skills" class="right-icon" (click)="downloadAdjacentSkill()">
            cloud_download</mat-icon>
          }
        </div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        @if (opts.showSkillAdjacencyText) {
        <div class="caption gray">Skill</div>
        }
        <div style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          @if (opts.uploadSpecialization) {
          <mat-icon [matTooltip]="'Upload ' + ds.dictionary.specializations.toLowerCase() + 's'" class="right-icon"
            (click)="onUploadSpecializationClick()">
            cloud_upload</mat-icon>
          }
          @if (opts.upload) {
          <mat-icon [matTooltip]="opts.uploadText || 'Upload'" class="right-icon" (click)="onUploadClick()">
            cloud_upload</mat-icon>
          }
          @if (opts.download) {
          <mat-icon [matTooltip]="opts.downloadText || 'Download'" class="right-icon" (click)="onDownloadClick()">
            cloud_download</mat-icon>
          }
          @if (opts.additionalDownload) {
          <mat-icon [matTooltip]="opts.additionalDownloadText || 'Download'" class="right-icon"
            (click)="onAdditionalDownloadClick('ADDITIONAL_DOWNLOAD')">
            cloud_download</mat-icon>
          }
          @if (opts?.additionalDownload2) {
          <mat-icon [matTooltip]="opts?.additionalDownload2Text || 'Download'" class="right-icon"
            (click)="onAdditionalDownloadClick('ADDITIONAL_DOWNLOAD2')">cloud_download
          </mat-icon>
          }
        </div>
      </div>
      @if (opts.left) {
      <button mat-icon-button class="right-btn" [disabled]="opts.leftDisabled" (click)="onLeftClick()">
        <mat-icon matTooltip="Previous page" [matTooltipDisabled]="opts.leftDisabled" class="right-icon">
          keyboard_arrow_left</mat-icon>
      </button>
      }
      @if (opts.right) {
      <button mat-icon-button class="left-btn" [disabled]="opts.rightDisabled" (click)="onRightClick()">
        <mat-icon matTooltip="Next page" [matTooltipDisabled]="opts.rightDisabled" class="right-icon">
          keyboard_arrow_right
        </mat-icon>
      </button>
      }
      @if (opts.filter) {
      <mat-icon class="right-icon" (click)="onFilterClick()">filter_alt</mat-icon>
      }
      @if (opts.add) {
      <mat-icon class="right-icon add-icon" (click)="onAddClick()">{{opts.addIcon||'add'}}
      </mat-icon>
      }
    </div>
  </div>
</div>
