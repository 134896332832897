@if (config.displayType == skillDetailsMode.GENERAL) {
  <div class="skill-details-wrapper">
    <div class="skill-details-header">
      Skill Details
      <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
    </div>
    <div class="skill-details-body">
      <div class="skill-details-body-inner">
        <div class="skill-details-body-inner-header">
          <div class="item-center gap-2">
            <div class="skill-details-body-inner-header-icon">
              <app-svg-icon class="mt-1" src="icons/skill-icon.svg" height="20px" width="20px"
              fill="var(--color-black-pearl)" viewBox="0 0 20 20"></app-svg-icon>
            </div>
            <span class="skill-details-body-inner-header-text">{{skillData.name}}</span>
          </div>
          @if (isHighDemandSkill && isHighDemandSkillEnabled) {
            <div class="skill-details-body-inner-header-in-demand-skill-wrapper">
              <mat-icon  class="skill-details-body-inner-header-in-demand-skill-wrapper-icon">trending_up</mat-icon>
              <span class="skill-details-body-inner-header-in-demand-skill-wrapper-text">{{highDemandSkillAlias}}</span>
            </div>
          }
        </div>
        <div class="skill-details-body-inner-grid">
          <div class="d-flex flex-column">
            <span class="skill-details-body-inner-grid-header">Category</span>
            <span class="skill-details-body-inner-grid-tag">{{skillData.skillCategory?.name}}</span>
          </div>
          @for (hierarchy of skillData.skillHierarchy | slice:0:skillData.skillHierarchy.length - 1; track hierarchy; let i = $index) {
            @if (!hideHierarchies[i]) {
              <div class="d-flex flex-column">
                <span class="skill-details-body-inner-grid-header">{{hierarchy.hierarchyName}}</span>
                <span class="skill-details-body-inner-grid-text">{{hierarchy.name}}</span>
              </div>
            }
          }
        </div>
        @if (skillData.skillDefinition) {
          <div class="skill-details-body-inner-definitions">
            <div class="skill-details-body-inner-definitions-header">Definitions</div>
            <div class="skill-details-body-inner-definitions-text">
              {{skillData.skillDefinition}}
            </div>
          </div>
        }
        @for (proficiency of proficiencyLevels; track proficiency; let i = $index) {
          <div class="skill-details-body-inner-level-info">
            <div class="skill-details-body-inner-level-info-header d-flex justify-content-between">
              Level {{ i + 1}} : {{proficiencyLevels[i]}}
              <div class="skill-details-body-inner-level-info-header-star-wrapper">
                @for (level of proficiencyLevels; track level; let j = $index) {
                  <app-svg-icon src="icons/skill-star.svg"
                    [fill]="j <= i ? 'var(--primary-color)' : 'var(--color-gray-3)'" height="20px" width="20px"
                  viewBox="0 0 25 24"></app-svg-icon>
                }
              </div>
            </div>
            <div class="skill-details-body-inner-level-info-text">
              {{ getProficiencyData(skillData.proficiencyData, i + 1) }}
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

<!--- Endorser Aids Sidebar -->
@if (config?.displayType == skillDetailsMode.ENDORSER_AIDS_SIDEBAR) {
  <div class="skill-details-wrapper h-auto elevation-0 mb-4">
    <div class="skill-details-body p-0 border-0 d-flex flex-column gap-20">
      <ng-container *ngTemplateOutlet="accordion"></ng-container>
    </div>
  </div>
}

@if (config?.displayType == skillDetailsMode.ACCORDION) {
  <div class="skill-details-wrapper">
    <div class="skill-details-header">
      Skill Details
      <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
    </div>
    <div class="skill-details-body flex-1 d-flex flex-column gap-20">
      <ng-container *ngTemplateOutlet="accordion"></ng-container>
    </div>
  </div>
}


<ng-template #accordion>
  <mat-accordion>
    <mat-expansion-panel #panel hideToggle [expanded]="config?.accordionState">
      <mat-expansion-panel-header>
        <div class="skill-details-body-inner-header d-flex gap-3 align-items-centern justify-content-between w-100">
          <div class="d-flex align-items-center  gap-2">
            <div class="skill-details-body-inner-header-icon">
              <app-svg-icon class="mt-1" src="icons/skill-icon.svg" height="20px" width="20px"
              fill="var(--color-black-pearl)" viewBox="0 0 20 20"></app-svg-icon>
            </div>
            <span class="skill-details-body-inner-header-text">{{skillData.name}}</span>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div [ngClass]="panel.expanded ? 'skill-details-body-inner-header-toggle-icon-clicked' : 'skill-details-body-inner-header-toggle-icon-hover'" class="skill-details-body-inner-header-toggle-icon d-flex align-items-center justify-content-center p-2" >
              <mat-icon class="mb-1">chevron_right</mat-icon>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      @if (true) {
        <mat-expansion-panel-body>
          <div class="skill-details-body-inner-grid">
            <div class="d-flex flex-column">
              <span class="skill-details-body-inner-grid-header">Category</span>
              <span class="skill-details-body-inner-grid-tag">{{skillData.skillCategory?.name}}</span>
            </div>
            @for (hierarchy of skillData.skillHierarchy | slice:0:skillData.skillHierarchy.length - 1; track hierarchy; let i = $index) {
              @if (!hideHierarchies[i]) {
                <div class="d-flex flex-column">
                  <span class="skill-details-body-inner-grid-header">{{hierarchy.hierarchyName}}</span>
                  <span class="skill-details-body-inner-grid-text">{{hierarchy.name}}</span>
                </div>
              }
            }
          </div>
          @if (skillData.skillDefinition) {
            <div class="skill-details-body-inner-definitions">
              <div class="skill-details-body-inner-definitions-header">Definitions</div>
              <div class="skill-details-body-inner-definitions-text">
                {{skillData.skillDefinition}}
              </div>
            </div>
          }
          @for (proficiency of proficiencyLevels; track proficiency; let i = $index) {
            <div class="skill-details-body-inner-level-info">
              <div class="skill-details-body-inner-level-info-header d-flex justify-content-between">
                Level {{ i + 1}} : {{proficiencyLevels[i]}}
                <div class="skill-details-body-inner-level-info-header-star-wrapper">
                  @for (level of proficiencyLevels; track level; let j = $index) {
                    <app-svg-icon src="icons/skill-star.svg"
                      [fill]="j <= i ? 'var(--primary-color)' : 'var(--color-gray-3)'" height="20px" width="20px"
                    viewBox="0 0 25 24"></app-svg-icon>
                  }
                </div>
              </div>
              <div class="skill-details-body-inner-level-info-text">
                {{ getProficiencyData(skillData.proficiencyData, i + 1) }}
              </div>
            </div>
          }
        </mat-expansion-panel-body>
      }
    </mat-expansion-panel>
  </mat-accordion>
  <app-proficiency-slider [data]="skillData" [mode]="config?.proficiencySliderMode">
  </app-proficiency-slider>
</ng-template>
