import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DataOrderComponentConfig } from 'src/app/modules/common-components/data-ordering-control/data-ordering-control.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ACCESS_LEVELS, SMS_OPTIONS } from 'src/app/constants';
import { DesignationsWeightLabels } from '../sms-settings.model';

const { NO, YES } = SMS_OPTIONS;
@Component({
    selector: 'app-demand-units-settings',
    templateUrl: './demand-units-settings.component.html',
    styleUrls: ['./demand-units-settings.component.scss'],
    standalone: false
})
export class DemandUnitsSettingsComponent implements OnInit {
  private _client: Client;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  weightagesForm: FormGroup;
  smsOptions: any[] = [
    { title: 'Map skill clusters to users', key: 'mapSkillClustersToUsers', type: 'select', options: [YES, NO], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Map categories to skus', key: 'mapCategoriesToSkus', type: 'select', options: [], validators: [], icon: 'settings_suggest', isMulti: true, displayKey: 'name', valueKey: 'categoryId', allowIf: ['sku'] },
    { title: 'Additional demand unit fields', key: 'additionalSpecializationFields', type: 'select', options: ['Location', 'Business Unit'], isMulti: true, validators: [], icon: 'settings_suggest', allowIf: ['demand-units'] },
    { title: 'Use Skill Weightage', key: 'useSkillWeightage', type: 'select', options: [YES, NO], validators: [Validators.required], icon: "settings_suggest", required: true },
    { title: 'Use Core Mandatory Skills', key: 'useCoreMandatorySkills', type: 'select', options: [YES, NO], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Are Core Mandatory Skills Compulsory for Matching', key: 'isCoreMandatoryCompulsory', type: 'select', options: [YES, NO], validators: [Validators.required], icon: "settings_suggest", required: true, checkValue: [{ key: 'useCoreMandatorySkills', values: [YES] }] },
    { title: 'Are Mandatory Skills Compulsory', key: 'isMandatorySkillCompulsory', type: 'select', options: [YES, NO], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Is Skill Cluster External ID from outside SMS', key: 'isSkillClusterExternalIDFromOutside', type: 'select', options: [YES, NO], validators: [Validators.required], icon: 'settings_suggest', required: true },
    { title: 'Use Endorsement Status for Matching', key: 'useEndorsementStatusInScans', type: 'select', options: ['Not Required', 'Pending', 'Approved', 'Rejected', 'Skipped'], isMulti: true, validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Add IDs to Scan Report', key: 'addUUIDinClusterMappingReport', type: 'select', options: [YES, NO], defaultValue: NO, validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Use Skill Cluster Tags', key: 'useSkillClusterTags', type: 'select', options: [YES, NO], defaultValue: NO, validators: [Validators.required], icon: 'settings_suggest' },
    { title: 'Enable Skill Cluster Service linking', key: 'allowSkillClusterServiceLinking', type: 'select', options: [YES, NO], defaultValue: YES, validators: [Validators.required], icon: 'settings_suggest', allowIf: ['services'] },
    { title: 'Use endorsement status for UI', key: 'useEndorsementStatusForUI', type: 'select', options: ['Not Required', 'Pending', 'Approved', 'Rejected', 'Skipped'], isMulti: true, validators: [Validators.required], icon: 'settings_suggest' },
  ];
  weightagesOptions: any[] = [
    { title: 'Demand Units Weightage distribution', type: 'sub-title', allowIf: ['demand-units'] },
    { title: 'Skills', key: 'skillWeightageForDemandUnit', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSkillRoleWeightages], icon: "settings_suggest", required: true, weightagesPart: 1, allowIf: ['demand-units'] },
    { title: 'Designation', key: 'designationWeightageForDemandUnit', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSkillRoleWeightages], icon: "settings_suggest", required: true, weightagesPart: 1, allowIf: ['demand-units'] },
    { title: `${this.ds.dictionary.specializations} weightage`, key: 'skillWeightageForSpecialization', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSpecDesignationWeightages(this.ds.dictionary.specializations, this.ds.dictionary.designation)], icon: "settings_suggest", required: true, weightagesPart: 1, allowIf: ['demand-units'] },
    { title: `${this.ds.dictionary.designation} weightage`, key: 'designationWeightageForSpecialization', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSpecDesignationWeightages(this.ds.dictionary.specializations, this.ds.dictionary.designation)], icon: "settings_suggest", required: true, weightagesPart: 1, allowIf: ['demand-units'] },
    { title: 'Threshold for storing User Spec records', key: 'autoClusterMappingMatchScoreThresholdForSpec', type: 'number', validators: [Validators.required, ValidateWeightage], icon: "settings_suggest", required: true, weightagesPart: 1 },
    { title: 'SKU Weightages', type: 'sub-title', allowIf: ['sku'] },
    { title: 'Demand Unit', key: 'duWeightageForSKU', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSKUWeightages], icon: "settings_suggest", required: true, weightagesPart: 2, allowIf: ['sku'] },
    { title: 'Location', key: 'locationWeightageForSKU', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSKUWeightages], icon: "settings_suggest", required: true, weightagesPart: 2, allowIf: ['sku'] },
    { title: 'Domain Skills', key: 'domainSkillsWeightageForSKU', type: 'number', validators: [Validators.required, ValidateWeightage, ValidateSKUWeightages], icon: "settings_suggest", required: true, weightagesPart: 2, allowIf: ['sku'] },
    { title: 'Skills Weightages', type: 'sub-title', checkValueBasedOnOtherSMSoptions: [{ key: 'useSkillWeightage', values: [YES] }] },
    { title: 'Mandatory Skills', key: 'mandatorySkillsWeightage', type: 'number', validvalidators: [Validators.required, ValidateWeightage], icon: "settings_suggest", checkValueBasedOnOtherSMSoptions: [{ key: 'useSkillWeightage', values: [YES] }], required: true, weightagesPart: 3 },
    { title: 'Conditional Mandatory Skills', key: 'condMandatorySkillsWeightage', type: 'number', validators: [Validators.required, ValidateWeightage], icon: "settings_suggest", checkValueBasedOnOtherSMSoptions: [{ key: 'useSkillWeightage', values: [YES] }], required: true, weightagesPart: 3  },
    { title: 'Optional Skills', key: 'optionalSkillsWeightage', type: 'number', validators: [Validators.required, ValidateWeightage], icon: "settings_suggest", checkValueBasedOnOtherSMSoptions: [{ key: 'useSkillWeightage', values: [YES] }], required: true, weightagesPart: 3  },
    { title: 'Core Mandatory Skills', key: 'coreMandatorySkillsWeightage', type: 'number', validators: [Validators.required, ValidateWeightage], icon: "settings_suggest", checkValueBasedOnOtherSMSoptions: [{ key: 'useCoreMandatorySkills', values: [YES] }, { key: 'useSkillWeightage', values: [YES] }], required: true, weightagesPart: 3 },
    { title: 'Threshold for storing User Skill Cluster records', key: 'autoClusterMappingMatchScoreThresholdForSC', type: 'number', validators: [Validators.required, ValidateWeightage], icon: "settings_suggest", required: true, weightagesPart: 3 },
  ]

  showSkillClustersTags: boolean = false;
  skillClusterTagsSettingsFormGroup = new FormGroup({});
  tagsToBeShownAsFiltersFormGroup = new FormGroup({});
  showHrmsTags: boolean = false;
  showCustomTags: boolean = false;

  hrmsTagsConfig: DataOrderComponentConfig = {
    key: 'hrmsTags',
    subTitle: 'HRMS Tags',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose HRMS Tags',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true,
  }

  customTagsConfig: DataOrderComponentConfig = {
    key: 'customTags',
    subTitle: 'Custom Tags',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose Custom Tags',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true
  }

  tagsToBeShownAsFiltersToEmployeesConfig: DataOrderComponentConfig = {
    key: 'tagsToBeShownAsFiltersToEmployees',
    subTitle: 'Tags To Be Shown as Filters to Employees',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose Tags To Be Shown as Filters to Employees',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true,
  }

  tagsToBeShownAsFiltersToManagersConfig: DataOrderComponentConfig = {
    key: 'tagsToBeShownAsFiltersToManagers',
    subTitle: 'Tags To Be Shown as Filters To Managers',
    canRemoveItem: true,
    addFromDropDown: true,
    dropDownPlaceholder: 'Choose Tags To Be Shown as Filters to Managers',
    maxCount: 10,
    canOrder: true,
    useSaveButton: true
  }

  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, private util: UtilitiesService, private cdr: ChangeDetectorRef) {
    this.editForm = fb.group({});
    this.weightagesForm = fb.group({});
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.buildWeightagesForms();
    this.cdr.detectChanges();
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
    this.buildWeightagesForms();
    this.setInputConfigData();
    this.buildSkillClustersTagsForm();
    this.buildTagsToBeShownsFiltersForm();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.filter((option) => option.key === 'mapCategoriesToSkus')[0].options = this.client.smsSettings.skillCategories;
    this.smsOptions.map((s) => {
      const currentValue = this.editForm.value;
      const value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) === ACCESS_LEVELS.NO_ACCESS) s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if(s.key === 'useSkillClusterTags') {
        if (
          this.client.features.admin['hrmsMasters'] ===
            ACCESS_LEVELS.NO_ACCESS &&
          this.client.features.admin['customTags'] === ACCESS_LEVELS.NO_ACCESS
        ) {
          s.showControl = false;
        } else {
          this.showSkillClustersTags = value === YES;
        }
      }
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }

  buildWeightagesForms() {
    this.weightagesOptions.map((s) => {
      let currentValue = this.weightagesForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) === ACCESS_LEVELS.NO_ACCESS) s.showControl = false; });
      (s.checkValue || []).map((item) => { if (item.values.indexOf(this.weightagesForm.contains(item.key) ? this.weightagesForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      (s.checkValueBasedOnOtherSMSoptions || []).map((item) => { if (item.values.indexOf(this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]) < 0) s.showControl = false; });
      if (this.weightagesForm.contains(s.key)) this.weightagesForm.removeControl(s.key);
      if (s.showControl) this.weightagesForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
  }

  saveData() {
    if (this.editForm.invalid) return;
    let genaralSettingsData = this.editForm.value;
    genaralSettingsData.type = 'demandUnitsGeneralSettings';
    this.onSave.emit(genaralSettingsData);
  }

  saveWeightagesData() {
    if (this.weightagesForm.invalid) return;
    let weightagesSettingsData = this.weightagesForm.value;
    weightagesSettingsData.type = 'demandUnitsWeightageSettings';
    this.onSave.emit(weightagesSettingsData);
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] === (o2 || {})[keys[i]];
      }
    }
    return o1 === o2;
  }

  onSMSOptionSelected(smsOption, { value: selectedValue}) {
    if (['useCoreMandatorySkills', 'useSkillWeightage'].indexOf(smsOption.key) >= 0) {
      this.buildForms();
      this.buildWeightagesForms();
    };
    if (smsOption.key === 'useSkillClusterTags') {
      if(selectedValue === YES) this.showSkillClustersTags = true;
      else this.showSkillClustersTags = false;
    }
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

  buildSkillClustersTagsForm() {
    this.skillClusterTagsSettingsFormGroup.addControl('hrmsTags', new FormControl({ value: this.hrmsTagsConfig.values, disabled: !this.showHrmsTags }, []));
    this.skillClusterTagsSettingsFormGroup.addControl('customTags', new FormControl({ value: this.customTagsConfig.values, disabled: !this.showCustomTags }, []));
  }

  buildTagsToBeShownsFiltersForm() {
    this.tagsToBeShownAsFiltersFormGroup.addControl('tagsToBeShownAsFiltersToEmployees', new FormControl({ value: this.tagsToBeShownAsFiltersToEmployeesConfig.values, disabled: !this.showSkillClustersTags }, []));
    this.tagsToBeShownAsFiltersFormGroup.addControl('tagsToBeShownAsFiltersToManagers', new FormControl({ value: this.tagsToBeShownAsFiltersToManagersConfig.values, disabled: !this.showSkillClustersTags }, []));
  }

  setInputConfigData() {
    const adminFeatures = this._client.features.admin;
    const smsSettings = this._client.smsSettings;
    this.showHrmsTags = adminFeatures.hrmsMasters !== ACCESS_LEVELS.NO_ACCESS;
    this.showCustomTags = adminFeatures.customTags !== ACCESS_LEVELS.NO_ACCESS;

    this.hrmsTagsConfig.disabled = adminFeatures.hrmsMasters !== ACCESS_LEVELS.EDIT;
    this.hrmsTagsConfig.dropDownOptions = smsSettings.hrmsFieldsChosenForMaster?.map(field => field.field) ?? []
    this.hrmsTagsConfig.values = smsSettings.hrmsFieldsChosenForTags?.map(field => field.field) ?? [];

    this.customTagsConfig.disabled = adminFeatures.customTags !== ACCESS_LEVELS.EDIT;
    this.customTagsConfig.dropDownOptions = smsSettings.fieldsForCustomTags?.map(field => field.field) ?? [];
    this.customTagsConfig.values = smsSettings.skillClusterCustomTags?.map(field => field.field) ?? [];

    const dropDownOptions = (smsSettings.hrmsFieldsChosenForMaster || []).concat(smsSettings.fieldsForCustomTags || []).map(field => field.field);

    this.tagsToBeShownAsFiltersToEmployeesConfig.dropDownOptions = dropDownOptions;
    this.tagsToBeShownAsFiltersToManagersConfig.dropDownOptions = dropDownOptions;
    this.tagsToBeShownAsFiltersToEmployeesConfig.values = smsSettings.tagsToBeShownAsFiltersToEmployees?.map(field => field.field) ?? [];
    this.tagsToBeShownAsFiltersToManagersConfig.values =  smsSettings.tagsToBeShownAsFiltersToManagers?.map(field => field.field) ?? [];
  }

  saveSkillClusterHrmsTags() {
    this.onSave.emit({
      type: 'skillClusterHRMSTags',
      skillClusterTags: this.skillClusterTagsSettingsFormGroup.controls[
        'hrmsTags'
      ].value
        .map((selectedHrmsTag) => {
          return this._client.smsSettings.hrmsFieldsChosenForMaster.filter(
            (hrmsTag) => hrmsTag.field === selectedHrmsTag
          );
        })
        .flat(),
    });
  }

  processCustomTags(customTags) {
    return customTags.map(customTag => {
      return {
        additionalFields: [],
        dataKey: this.util.toAlphaNumeric(customTag),
        dataType: "Text",
        field: customTag
      }
    })
  }

  saveSkillClusterCustomTags() {
    this.onSave.emit({
      type: 'skillClusterCustomTags',
      skillClusterTags: this.skillClusterTagsSettingsFormGroup.controls[
        'customTags'
      ].value
        .map((selectedCustomTag) => {
          return this._client.smsSettings.fieldsForCustomTags.filter(
            (customTag) => customTag.field === selectedCustomTag
          );
        })
        .flat(),
    });
  }

  saveTagsToBeShownAsFilters(targetGroup: 'Employees' | 'Managers') {
    const controlName = `tagsToBeShownAsFiltersTo${targetGroup}`;

    const hrmsFields = this._client.smsSettings.hrmsFieldsChosenForMaster ?? [];
    const customFields = this._client.smsSettings.fieldsForCustomTags ?? [];
    const concatenatedOptions = hrmsFields.concat(customFields);

    this.onSave.emit({
      type: controlName,
      [controlName]: this.tagsToBeShownAsFiltersFormGroup.controls[controlName]
        .value
          .map((selectedTag) => {
            return concatenatedOptions.filter(
              (tag) => tag.field === selectedTag
            )
          })
          .flat(),
    });
  }

  changeWeightage(event, weightagesPart) {
    if (weightagesPart === 3) {
      let keys = [
        'mandatorySkillsWeightage',
        'condMandatorySkillsWeightage',
        'optionalSkillsWeightage',
      ];

      if (
        this.editForm.controls['useSkillWeightage'].value === YES &&
        this.editForm.controls['useCoreMandatorySkills'].value === YES
      ) {
        keys.push('coreMandatorySkillsWeightage');
        if (!this.checkIfWeightagesTotalIs100(keys)) {
          this.showErrorForGivenWeightages(keys);
          return;
        }
      } else if (
        this.editForm.controls['useSkillWeightage'].value === YES &&
        this.editForm.controls['useCoreMandatorySkills'].value === NO
      ) {
        if (!this.checkIfWeightagesTotalIs100(keys)) {
          this.showErrorForGivenWeightages(keys);
          return;
        }
      }
      keys.map((key) => {
        if (this.weightagesForm.controls[key]) {
          this.weightagesForm.controls[key].setErrors(null);
        }
      });
    }
  }

  checkIfWeightagesTotalIs100(weightageKeys) {
    let total = 0;
    weightageKeys.map((key) => {
      if (this.weightagesForm.controls[key]) {
        total += this.weightagesForm.controls[key].value * 1 || 0;
      }
    });
    return total === 100;
  }

  showErrorForGivenWeightages(weightageKeys) {
    weightageKeys.map((key) => {
      if (this.weightagesForm.controls[key]) {
        this.weightagesForm.controls[key].setErrors({
          error: 'Sum of all skills weightages should add up to 100',
        });
      }
    });
  }
}

export function ValidateWeightage(control: AbstractControl): { [key: string]: any } | null {
  if (Number.isNaN(parseFloat(control.value)) || control.value * 1 < 0 || control.value * 1 > 100) return { error: 'Weightage needs to be valid number between 0-100' };
  return null;
}

function modifyControlErrors(control: AbstractControl, fields: DesignationsWeightLabels[], errorMessage?: string) {
  fields.forEach(field => {
    const controlToModify = control.parent?.controls[field.key];
    if (controlToModify) {
      if (errorMessage && controlToModify.touched) {
        controlToModify.setErrors({ error: errorMessage });
      } else {
        controlToModify.setErrors(null);
      }
    }
  });
}

export function validateWeightages(control: AbstractControl, fields: DesignationsWeightLabels[], totalRequired: number = 100): { [key: string]: any } | null {
  if (!control.parent) return null;
  let total = 0;
  let weightageControls = true;
  const fieldLabels = [];
  fields.forEach(field => {
    const controlValue = control.parent.controls[field.key];
    if (controlValue) {
      total += Number(controlValue.value) || 0;
      fieldLabels.push(field.label);
    } else {
      weightageControls = false;
    }
  });
  if (!weightageControls) return null;
  if (total !== totalRequired) {
    const errorMessage = `${fieldLabels.join(' + ')} should add up to ${totalRequired}`;
    modifyControlErrors(control, fields, errorMessage);
    return { error: errorMessage };
  }
  modifyControlErrors(control, fields);
  return null;
}

export function ValidateSkillRoleWeightages(control: AbstractControl) {
  return validateWeightages(control, [
    { key: 'skillWeightageForDemandUnit', label: 'Skill Weightage' },
    { key: 'designationWeightageForDemandUnit', label: 'Designation Weightage' }
  ]);
}

export function ValidateSKUWeightages(control: AbstractControl) {
  return validateWeightages(control, [
    { key: 'duWeightageForSKU', label: 'Demand Unit Weightage' },
    { key: 'locationWeightageForSKU', label: 'Location Weightage' },
    { key: 'domainSkillsWeightageForSKU', label: 'Domain Skills Weightage' }
  ]);
}

export function ValidateSkillsWeightages(control: AbstractControl) {
  return validateWeightages(control, [
    { key: 'mandatorySkillsWeightage', label: 'Mandatory Skills Weightage' },
    { key: 'condMandatorySkillsWeightage', label: 'Conditional Mandatory Skills Weightage' },
    { key: 'optionalSkillsWeightage', label: 'Optional Skills Weightage' },
    { key: 'coreMandatorySkillsWeightage', label: 'Core Mandatory Skills Weightage' }
  ]);
}

export function ValidateSpecDesignationWeightages(specializations: string, designation: string) {
  return (control: AbstractControl) => {
    return validateWeightages(control, [
      { key: 'skillWeightageForSpecialization', label: `${specializations} Weightage` },
      { key: 'designationWeightageForSpecialization', label: `${designation} Weightage` }
    ]);
  };
}
