
export interface Attempt {
  attemptNumber : number;
  userDescription : string,
  resumeData : any | null,
  characterCount : number,
  isL2ResponseSubmitted : boolean,
  isL2Loading : boolean,
  isAddSkillSelected : boolean,
  L2Data : {
    clientSkills : any[],
    nonClientSkills : any[],
    addedSkills : any[],
  },
  allSelectedL2 : boolean,
  clientSkillsDisplayedCount : number,
  nonClientSkillsDisplayedCount : number,
  showBadRecommendationsFormL2 : boolean,
  badRecommendationsForm: any;
  isFeedbackSubmitted : boolean,
  feedbackCharacterCount : number,
  skillOverlayDataL2 : any[],
}

export enum SortingOrder{
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum SkillType {
  NEW_SKILL = 'new skill',
  UPDATED_SKILL = 'updated skill',
  ADDED_SKILL = 'added skill',
}

export enum Modes {
  CHAT_WINDOW = 'chat window',
  SKILL_CLASSIFIER = 'skill classifier',
  SKILL_CLASSIFIER_WITH_SAVE = 'skill classifier with save',
  RATE_YOUR_EXPERTISE = 'rate your expertise',
}
