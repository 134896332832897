<div class="multilevel-dropdown-container" [style.min-width.px]="minWidth">
  <!-- Selected option display -->
  <div class="selected-option" (click)="toggleDropdown()">
    {{ selectedOption?.label || 'Select an option' }}
  </div>

  <!-- Main Dropdown -->
  @if (dropdownVisible) {
    <div
      class="dropdown"
      [ngClass]="dropdownPosition"
      (mouseleave)="closeDropdown()"
      (mouseenter)="keepDropdownOpen()"
      >
      @for (option of options; track option) {
        <div
          class="dropdown-item"
          (mouseenter)="option.subOptions ? showSubMenu(option) : hideSubMenu()"
          (click)="!option.subOptions && onOptionSelect(option)"
          >
          {{ option.label }}
          @if (option.subOptions) {
            <span class="arrow-right">&#9654;</span>
          }
        </div>
      }
      <!-- Sub Dropdown -->
      @if (subOptionsVisible && hoveredOption?.subOptions) {
        <div
          class="sub-dropdown"
          [style.left.px]="subMenuLeftPosition"
          (mouseenter)="keepSubMenuOpen()"
          (mouseleave)="hideSubMenu()"
          >
          @for (subOption of hoveredOption.subOptions; track subOption) {
            <div
              class="dropdown-item"
              (click)="onSubOptionSelect(subOption)"
              >
              <mat-icon class="btn-icon ic-16 mt-2">{{ subOption.icon }}</mat-icon> {{ subOption.label }}
            </div>
          }
        </div>
      }
    </div>
  }
</div>
