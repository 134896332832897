import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ASSIGNED_TYPE, COLORS, IProgressCard, PROGRESS_TYPE, STATUS, TYPE } from './progress-card.model';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from '../shared-dialog/shared-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { BUTTON_EVENTS, DIALOG_TYPES, SMS_OPTIONS } from 'src/app/constants';
import { ICardConfig } from '../../my-learnings/learning-element-type-tabs/learning-element-type-tabs.model';
import { PreviewBoxDialogComponent } from '../preview-box-dialog/preview-box-dialog.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { SkeletonNewDirective } from 'src/app/directives/skeleton-new.directive';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss'],
  animations: [
    trigger('delayTooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s 0.2s ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('0.2s ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    CommonModule,
    SkeletonNewDirective
  ]
})
export class ProgressCardComponent implements OnChanges {
  @Input() data: IProgressCard;
  @Input() cardConfig: ICardConfig = {
    labelRequired: true,
    showSkills: false,
    showEditButton: false,
    showCurrentState: false,
    redirectToPreview: false
  }
  @Input() isHoverPopupEnabled: boolean = true;
  @Output() onClickEvent = new EventEmitter();
  @ViewChild('progressCard') progressCard: ElementRef;
  public EVENT = 'EVENT';
  public TYPE = TYPE;
  public STATUS = STATUS;
  public COLORS = COLORS;
  public showTooltip: boolean = false;
  public canDrop: boolean = true;
  public isLoading: boolean = true;
  public userStatusMap = {
    COMPLETED: 'Completed',
    IN_PROGRESS: 'In Progress',
    NOT_STARTED: this.ds.dictionary.myLearningInitialStatus,
    PASSED: 'Passed',
    FAILED: 'Failed',
  };
  public useResultStatus: string;
  public statusFailedAndCompleted:boolean = false;
  public userId: string;
  public ASSIGNED_TYPE = ASSIGNED_TYPE;
  public useFinishingLearningElement: boolean = false;

  constructor(public util: UtilitiesService, private dialog: MatDialog, private ds: DataService) {}

  ngOnChanges(change: SimpleChanges) {
    this.userId = this.ds.user?.userId;
    this.useResultStatus = this.ds?.client?.smsSettings?.useResultStatus;
    this.useFinishingLearningElement = this.ds.client?.smsSettings?.useFinishingLearningElement === SMS_OPTIONS.YES;
    this.processData();
  }

  processData() {
    if (this.data?.pk) {
      if (this.data.assignedBy) {
        this.data.assigneeInitials = this.util.getShortName(this.data.assigneeName);
      }
      this.data.durationInTime = this.decimalToHoursMinutes(this.data.duration);
      this.data.progress = Number(
        this.data.userProgress?.filter((progress) => progress.type === PROGRESS_TYPE.PERCENT)?.[0]?.value
      );
      this.processResultStatus();
      this.processUserStatus();
      this.checkUserSkillItemExist();
      this.isLoading = false;
    }
  }

  getBestTooltipPosition(): string {
    const { x, y, width, height } = this.progressCard.nativeElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    const spaceRight = windowWidth - (x + width) - scrollbarWidth;
    const spaceBottom = windowHeight - (y + height);

    if (spaceRight >= width + 200) {
      return 'tooltip-right flex-row';
    } else if (x >= width + 400) {
      return 'tooltip-left flex-row-reverse';
    } else if (y >= height) {
      return 'tooltip-top flex-column-reverse';
    } else if (spaceBottom >= height) {
      return 'tooltip-bottom flex-column';
    }

    return 'tooltip-bottom flex-column';
  }

  onDropClick() {
    this.showTooltip = false;
    const deleteData = { clientID: this.data.clientId, learningElementPk: this.data.learningElementPk };
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = {
      type: DIALOG_TYPES.GENERIC,
      title: 'Confirm Drop',
      message: 'Are you sure you want to drop this ' + this.data?.type + '? Your progress will be reset to zero.',
      message2: 'This action CANNOT be undone',
      btnTitle: 'YES, DROP',
      btn2Title: 'NO THANKS',
      warnButton: true,
      whiteButton: true,
    };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event === BUTTON_EVENTS.BUTTON_CLICK) {
        // api call for delete the card, and pass the {deleteData}
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  public viewDetails(data: IProgressCard){
    if (this.cardConfig.redirectToPreview && data?.referenceFile) {
      const dialogRef = this.dialog.open(PreviewBoxDialogComponent, { 
        width: window.innerWidth < 900 ? (0.9 * window.innerHeight) + 'px' : '700px',
        height: window.innerWidth < 900 ? (0.8 * window.innerHeight) + 'px' : '600px',
        panelClass: 'dd-modal',
      });

      dialogRef.componentInstance.data = {
        url: data.referenceUrl || data.contentUrl,
        referenceFile: data.referenceFile,
        linkTitle: 'Certificate Link',
        showRedirectTo: true,
      }
    } else {
      this.util.openUrlInNewTab(data?.referenceUrl || data?.contentUrl);
    }
  }

  public onEditClick() {
    this.onClickEvent.emit({data: this.data});
  }

  private processUserStatus() {
    this.data.userStatus = this.data.userProgress?.filter((progress) => progress.type === PROGRESS_TYPE.STATUS)?.[0]?.value;
    
    const resultStatus = this.data.resultStatus?.toUpperCase();
    if (resultStatus === STATUS.FAILED && this.data?.progress === 100) {
      this.data.userStatus = null;
      this.statusFailedAndCompleted = true;
    }
  }

  public calculateBackgroundColor(data) {
    // special case: when userStatus is 100 but resultStatus is FAILED
    const resultStatus = this.data.resultStatus?.toUpperCase();
    if(resultStatus === STATUS.FAILED && data?.progress === 100) {
      return this.COLORS.IN_PROGRESS;
    }

    return data?.userStatus === (this.STATUS.IN_PROGRESS && data?.progress < 100)
      ? this.COLORS.IN_PROGRESS
      : data?.userStatus === this.STATUS.COMPLETED && data?.progress >= 100
      ? this.COLORS.COMPLETED
      : '';
  }

  private processResultStatus() {
    if (!this.useResultStatus || this.useResultStatus === SMS_OPTIONS.NO) {
      this.data.resultStatus = null;
      return;
    };

    const resultStatus = this.data.resultStatus?.toUpperCase();
    this.data.resultStatus = resultStatus;

    if (!resultStatus || ['NA', 'NULL'].includes(resultStatus)) {
      this.data.resultStatus = null;
    }
  }

  private decimalToHoursMinutes(decimalTime: number): string {
    if (!decimalTime) return;
    const hours = Math.floor(decimalTime);
    const minutes = Math.round((decimalTime - hours) * 60);
    return minutes === 0 ? `${hours}h` : `${hours}h ${minutes}m`;
  }

  private checkUserSkillItemExist() {
    if (!this.data?.skillItemIds?.length) {
      return;
    }
    this.data.skillItemIds = this.data.skillItemIds.filter(id => id in (this.data?.skillItemIdVsName || {}));
  }
}
