<div class="d-flex flex-column overflow-hidden h-100">
  @if (data.isProfileModal) {
    <div class="d-flex" [ngClass]="data.isProfileModal ? 'my-profile-modal' : 'welcome-window'">
      @if (data.isProfileModal) {
        <label class="h4 white">Key Information</label>
      }
      <mat-icon class="fs-18 white cursor-pointer" (click)="onDismiss()">close</mat-icon>
    </div>
  }
  <div class="main-con d-flex flex-column gap-4" [ngClass]="data.isProfileModal?'my-profile-modal-height':''">
    @if (!data.isProfileModal && isStaffingRoleMandatory) {
      <div class="pt-40"></div>
    }
    @if (!data.isProfileModal && !isStaffingRoleMandatory) {
      <div class="d-flex justify-content-end p-20">
        <mat-icon class="fs-18 gray cursor-pointer" (click)="onDismiss()">close</mat-icon>
      </div>
    }

    @if (!data.isProfileModal) {
      <div class="item-center flex-column">
        <span class="wave-emoji">{{ waveEmoji }}</span>
        <label class="h3 black">Tell us about your work</label>
        <label class="caption gray">This helps us match you with the relevant work opportunities.</label>
      </div>
    }

    <form class="d-flex flex-column modal-body-con" [formGroup]="staffingRoleAndPreferencesForm">
      <div class="d-flex flex-column modal-body-con p-20">
        @if (data.isProfileModal && isStaffingRoleEnabled) {
          <div class="d-flex flex-column gap-2">
            <label class="h5">Key job {{dictionary?.staffingRole}}(s)</label>
            <label class="caption gray">{{dictionary?.staffingRole}}s that best represent your primary job functions. Make sure to keep it up-to-date as they help us match you with the relevant work opportunities.</label>
          </div>
        }
        <!-- staffing role section -->
        @if (isStaffingRoleEnabled) {
          <label class="body black m-2">Select key job {{dictionary?.staffingRole}}(s)</label>
          @if (staffingRoleInput.options.length) {
            <app-searchable-multiselect [input]="staffingRoleInput" [customPanelClass]="'staffing-role-modal'"
              class="d-inline-block" (selectionChange)="onMultiAutoCompleteChange($event)">
            </app-searchable-multiselect>
          }
          @if (selectedRoles?.length <= maxLimit) {
            <label class="caption gray">Upto {{maxLimit}}</label>
          }
          @if (selectedRoles?.length > maxLimit) {
            <mat-error class="caption mt-n3">Maximum {{maxLimit +' '+ dictionary?.staffingRole}}(s) allowed.</mat-error>
          }
          <div class="mt-3">
            <mat-chip-list>
              @for (item of selectedRoles; track item) {
                <mat-chip class="chip m-1" (removed)="onRemove(item)">
                  {{item.tagValue}}
                  <button matChipRemove><mat-icon>close</mat-icon></button>
                </mat-chip>
              }
            </mat-chip-list>
          </div>
        }

        @if (isStaffingRoleEnabled && data.isProfileModal && userPreferencesInputs.length) {
          <mat-divider class="my-3"></mat-divider>
        }

        <!-- Preferences section -->
        @if (data.isProfileModal && userPreferencesInputs.length) {
          <div class="h5">Preferences</div>
          <div class="item-between flex-wrap gap-2 mt-3">
            @for (preferenceInput of userPreferencesInputs; track preferenceInput) {
              <div class="input-con-half">
                <div class="h5 fw-500 mt-1 mb-2">Preferred {{ preferenceInput.key }}</div>
                @if (preferenceInput.type === 'auto-complete') {
                  <app-auto-complete [input]="preferenceInput"
                    (selectionChange)="onAutoCompleteChange($event)"
                    (onValueInvalid)="onValueInvalid($event)">
                  </app-auto-complete>
                }
                @if (preferenceInput.type === 'auto-complete-multi') {
                  <app-searchable-multiselect
                    [customPanelClass]="'staffing-role-modal'" [input]="preferenceInput"
                    (selectionChange)="onMultiAutoCompleteChange($event)">
                  </app-searchable-multiselect>
                }
                @if (preferenceInput.type === 'auto-complete-multi' && isFieldRequiredAndEmpty(preferenceInput.key)) {
                  <mat-error class="caption mt-n3">
                    Preferred {{ preferenceInput.key }} is required
                  </mat-error>
                }
              </div>
            }
          </div>
        }
      </div>
    </form>
  </div>
  <div class="p-20" [ngClass]="!isStaffingRoleMandatory || data.isProfileModal ? 'item-between' : 'item-center'">
    @if (!isStaffingRoleMandatory || data.isProfileModal) {
      <button mat-stroked-button class="secondary-button" (click)="onDismiss()"
      >Dismiss</button>
    }
    <span [matTooltip]="tooltipMessage" matTooltipPosition="above">
      <button mat-stroked-button class="primary-button" [disabled]="isSaveDisabled" (click)="onSave()">Save</button>
    </span>
  </div>
</div>