import { Component, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { SortingOrder } from '../chat-window-modal/chat-window-modal.model';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { SkillUtilitiesService } from '../../../services/skill-utilities.service';
import { ProficiencySliderMode } from '../../components/proficiency-slider/proficiency-slider.component';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { take } from 'rxjs/operators';

export enum SkillDetailsMode {
  GENERAL = 'general',
  ACCORDION = 'accordion',
  ENDORSER_AIDS_SIDEBAR = 'endorserAidsSidebar'
}

interface Config {
  displayType?: SkillDetailsMode;
  proficiencySliderMode?: ProficiencySliderMode;
  accordionState?: boolean;
}
@Component({
  selector: 'app-skill-details-modal',
  templateUrl: './skill-details-modal.component.html',
  styleUrls: ['./skill-details-modal.component.scss'],
  standalone: false
})

export class SkillDetailsModalComponent implements OnInit {

  @Input() skillData: any;
  @Output() onEvent = new EventEmitter();
  @Input() config: Config = {
    displayType: SkillDetailsMode.GENERAL,
  };

  public settings: any;
  public proficiencyLevels = [];
  public hideHierarchies = [false, false];
  public highDemandSkillAlias: string = '';
  public skillDetailsMode = SkillDetailsMode;
  public proficiencySliderMode = ProficiencySliderMode;

  private smsSettings: any;
  private tooltipValue = [
    "Basic Knowledge / Classroom training",
    "Basic hands-on knowledge / Limited project experience",
    "Good knowledge of skill / Ready for client deployment with some support",
    "Very good knowledge of skill / Ready for client deployment without support",
    "Expert in skill / Can train others"
  ];


  constructor(
    private ds: DataService,
    private utils: UtilitiesService,
    private skillUtils: SkillUtilitiesService,
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.skillData && changes.skillData.currentValue != changes.skillData.previousValue) {
      this.skillData = changes.skillData.currentValue;
      this.initializeData();
    }
  }

  public initializeData() {
    this.proficiencyLevels = [];
    this.highDemandSkillAlias = this.ds.dictionary.highDemandSkill;
    this.smsSettings = this.ds.client.smsSettings;
    const skillRating = this.smsSettings.skillRating;
    const totalProficiencyLevels: number = skillRating === 'Stars 1-5' ? 5 : 4;
    for (let i = 1; i <= totalProficiencyLevels; i++) {
      this.proficiencyLevels.push(this.smsSettings[`skillToolTipLabel${i}`])
    }
    this.settings = this.smsSettings.categoryWiseSettings.find(
      (item: any) => item.categoryId == this.skillData.skillCategory.categoryId
    );
    this.hideHierarchies[0] = this.settings.hide2ndLevelHierarchy === SMS_OPTIONS.YES;
    this.hideHierarchies[1] = this.settings.hide3rdLevelHierarchy === SMS_OPTIONS.YES;
    if (this.config.displayType === SkillDetailsMode.ENDORSER_AIDS_SIDEBAR) {
      this.getProficiencyDataFromAPI();
    }
  }

  public getProficiencyData(proficiency, level: number) {
    if (proficiency && proficiency[`l${level}description`]) {
      return proficiency[`l${level}description`];
    } else if (this.smsSettings[`configureSkillsTooltip${level}`]) {
      return this.smsSettings[`configureSkillsTooltip${level}`];
    } else {
      return this.tooltipValue[level - 1];
    }
  }

  public closeDialog() {
    this.onEvent.emit({ status: 'close' })
  }

  get isHighDemandSkill(): boolean {
    return this.skillData?.data?.isHighDemandSkill === SMS_OPTIONS.YES || this.skillData?.isHighDemandSkill === SMS_OPTIONS.YES;
  }

  get isHighDemandSkillEnabled(): boolean {
    return this.skillUtils.checkIsHighDemandSkillTaggingEnabled;
  }

  private getProficiencyDataFromAPI() {
    const skillHierarchies = this.utils.sortByProperty(this.ds.client.smsSettings.skillHierarchy, 'order').map((item: { order: any; name: string }) => item.name);
    this.ds.saveData(API_ENDPOINT.GET_SKILL_OVERLAY_DETAILS, { skillItemIds: [this.skillData.skillItemId] }).pipe(take(1)).subscribe((rs: any) => {
      if (rs.status === RESPONSE_STATUS.SUCCESS) {
        rs.data.skillDetails.forEach(item => {
          if (item.proficiencyData) {
            this.skillData.proficiency = this.utils.filterOutKeysFromObj('skillItemId')(item.proficiencyData);
          }
          this.skillData.skillHierarchy = item.skillHierarchy.filter(item => item !== null);
          this.skillData.skillHierarchy.forEach((item, index) => item['hierarchyName'] = skillHierarchies[index]);
          this.skillData.skillHierarchy = this.utils.sortByProperty(item.skillHierarchy, 'order', SortingOrder.DESC);
        });
      }
    });
  }
}
