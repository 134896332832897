import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { debounceTime, tap, switchMap, catchError, distinctUntilChanged, filter } from 'rxjs/operators';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { ISuggestion, ITagsConfigMap, IUserData, IPrismSearchResponse } from '../../../prism-search.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'search-group',
  templateUrl: './search-group.component.html',
  styleUrls: ['./search-group.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgSelectComponent,
    CommonModule
  ]
})
export class SearchGroupComponent implements OnInit, OnChanges {
  @Input() groupForm: FormGroup;
  @Input() maxNestingLevel: number;
  @Input() parentArray!: FormArray;
  @Input() tagValuesAPIResposne: any = null;
  @Input() currentParentIndex: number = 0;
  @Input() tagsConfigMap: ITagsConfigMap;

  public designationOptions = ['Designation', 'Skill'];
  public operatorOptions = ['is', 'contains'];
  public experienceOptions = ['Experience', 'Endorsements', 'Proficiency', 'Type'];
  public conditionTypes = ['equals', 'greater than', 'less than', 'in between'];
  public proficiencyLevels = [];

  public items: IUserData[] = [];
  public search$ = new Subject<string>();

  public itemsMap: { [key: string]: any[] } = {};
  public loadingMap: { [key: string]: boolean } = {};

  private ignoreList: string[] = ['skillRating', 'allocation-percentage', 'allocation-date', 'pool-code'];

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private fb: FormBuilder, private ds: DataService, private weds: WaitErrorDialogsService) {}

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.tagValuesAPIResposne && !!this.tagValuesAPIResposne) {
      this.designationOptions = Object.keys(this.tagValuesAPIResposne?.tagOperatorMapping);
      this.designationOptions = this.designationOptions.filter(x => !this.ignoreList.includes(x));
      this.designationOptions.push('allocation');
    }
  }

  ngOnInit(): void {
    this.proficiencyLevels = this.getRangeArray(this.ds.client?.smsSettings?.skillRating);

    this.search$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((searchObj: any) => !!searchObj?.term?.trim()),
        tap(searchObj => this.setLoadingState(searchObj.tag, true)),
        switchMap(searchObj => this.searchItems(searchObj)),
        tap((res: any) => this.setLoadingState(res.tag, false))
      )
      .subscribe(results => {
        const tag = results?.tag === 'pool-code' ? 'allocation' : results?.tag; 
        this.itemsMap[tag] = results.nonPIISuggestion.map((suggestion: ISuggestion) => {
          let searchText: string = suggestion?.operands?.[0]?.element;
          searchText = searchText.substring(1, searchText.length - 1);
          return {
            element: searchText
          };
        });
      });
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public onSearch(searchObj: any, group: FormGroup) {
    const tag = group.get('tag')?.value;
    if (!tag) {
      return;
    }
    searchObj.group = group.value;
    searchObj.tag = tag;
    if (searchObj.tag === 'allocation') {
      searchObj.group.tag = 'pool-code';
      searchObj.tag = 'pool-code';
    }
    this.search$.next(searchObj);
  }

  public getItemsForTag(tag: string): any[] {
    return this.itemsMap[tag] || [];
  }

  public isLoading(tag: string): boolean {
    return this.loadingMap[tag] || false;
  }

  private setLoadingState(tag: string, state: boolean): void {
    this.loadingMap[tag] = state;
  }

  public get conditions(): FormArray {
    return this.groupForm.get('conditions') as FormArray;
  }

  public trackByFn(item: IUserData) {
		return item.element;
	}

  public addCondition() {
    const condition = this.fb.group({
      type: 'condition',
      tag: [''],
      operator: ['is'],
      searchValue: [''],
      experienceOptions: [''],
      alphanumeric: [''],
      startDate: [''],
      endDate: [''],
      conditionType: ['equals'],
      proficiencyLevel: ['ALL'],
      proficiencyLevel2: ['ALL'],
      items: [null],
      poolCode: [''],
      allocationPercentage: [''],
      allocationPercentage2: [''],
      allocationPercentageDisabled: [''],
      },
      { validators: this.dateRangeValidator.bind(this) }
    );
    this.conditions.push(condition);
  }

  public addSubGroup() {
    if (this.maxNestingLevel > 1) {
      const subGroup = this.fb.group({
        type: 'group',
        logicOperator: ['AND'],
        conditions: this.fb.array([]),
      });
      (subGroup.get('conditions') as FormArray)?.push(this.createCondition());
      (subGroup.get('conditions') as FormArray)?.push(this.createCondition());
      this.conditions.push(subGroup);
    }
  }

  public removeCondition(index: number) {
    this.conditions.removeAt(index);
  }

  public removeGroup(index: number) {
    this.parentArray?.removeAt(index);
  }

  public removeConditionOrGroup(index: number) {
    this.conditions?.removeAt(index);
  }

  public onTagSelectChange(event: any, group: FormGroup) {
    group.get('searchValue')?.reset();

    // Handle allocation special case
    if (event === 'allocation') {
      // Show all allocation-related fields
      group.get('poolCode')?.enable();
      group.get('allocationPercentage')?.enable();
      group.get('allocationPercentage2')?.enable();
    } else {
      // Hide allocation-related fields
      group.get('poolCode')?.disable();
      group.get('allocationPercentage')?.disable();
      group.get('allocationPercentage2')?.disable();
    }

    this.listenForDesignationChanges(event, group);
    this.setProficiencyLevel(group, event);
  }

  public isAllocationType(tag: string): boolean {
    return tag === 'allocation';
  }

  public toggleAndOr(group: FormGroup) {
    const currentOperator = group.get('logicOperator')?.value;
    const newOperator = currentOperator === 'AND' ? 'OR' : 'AND';
    group.get('logicOperator')?.setValue(newOperator);
  }

  public get isSkillTag(): (tag: string | null) => boolean {
    return (tag: string | null) => {
      const newTag = tag?.toLowerCase();
      return newTag === 'skill';
    };
  }

  public isAllocationTag(tag: string): boolean {
      return this.tagsConfigMap['allocation'].includes(tag);
  }

  public onFocus(picker: MatDatepicker<any>) {
    picker.open();
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private getRangeArray(range: string | null | undefined): (string | number)[] {
    const defaultMax = 4;
    const match = range?.match(/(\d+)(?!.*\d)/);
    const max = match ? parseInt(match[0], 10) : defaultMax;
    const rangeArray = ['ALL', ...Array.from({ length: max }, (_, i) => i + 1)];
    return rangeArray;
  }

  private searchItems(searchObj: any): Observable<IPrismSearchResponse> {
    const payload = {
      clientId: this.ds.client.clientId,
      searchString: searchObj?.group?.tag + ': ' + searchObj.term,
      limit: 5
    }

    return this.ds.careerPrismDataPostApi(API_ENDPOINT.SEARCH_AUTO_COMPLETE, payload)
    .pipe(
      tap( res => res.tag = searchObj?.group?.tag),
      catchError(() => {
        return [];
      })
    );
  }

  private setProficiencyLevel(group: FormGroup<any>, event: any) {
    group.get('proficiencyLevel').patchValue('ALL');
    group.get('proficiencyLevel2').patchValue('ALL');
  }

  private createCondition(): FormGroup {
    return this.fb.group({
      type: 'condition',
      tag: [''],
      operator: ['is'],
      searchValue: [''],
      experienceOptions: [''],
      alphanumeric: [''],
      startDate: [''],
      endDate: [''],
      conditionType: ['equals'],
      proficiencyLevel: ['ALL'],
      proficiencyLevel2: ['ALL'],
      items: [null],
      poolCode: [''],
      allocationPercentage: [''],
      allocationPercentage2: [''],
      allocationPercentageDisabled: [''],
    },
    { validators: this.dateRangeValidator.bind(this) }
  );
  }

  private listenForDesignationChanges(value: string, conditionGroup: FormGroup) {
    const experienceOptionsControl = conditionGroup?.get('experienceOptions');
    const poolCodeControl = conditionGroup?.get('poolCode');
    const allocationPercentageDisabledControl = conditionGroup?.get('allocationPercentageDisabled');
    if (value.toLowerCase() === 'skill') {
      this.setAndDisableControl(experienceOptionsControl, 'Proficiency');
    }
    else if (value.toLowerCase() === 'allocation') {
      this.setAndDisableControl(poolCodeControl, 'Pool code');
      this.setAndDisableControl(allocationPercentageDisabledControl, 'Allocation percentage(%)');
    }
    else {
      experienceOptionsControl.enable();
    }
  }

  private setAndDisableControl(control: AbstractControl | null, value: string) {
    if (control) {
      control.setValue(value);
      control.disable();
    }
  }

  private dateRangeValidator(group: FormGroup) {
    const tag = group.get('tag')?.value;
    const startDate = group.get('startDate');
    const endDate = group.get('endDate');

    if (this.tagsConfigMap['allocation'].includes(tag)) {
      const startDateInvalid = !startDate?.value;
      const endDateInvalid = !endDate?.value;

      if (startDate) {
        startDate.setErrors(startDateInvalid ? { required: true } : null);
      }
      if (endDate) {
        endDate.setErrors(endDateInvalid ? { required: true } : null);
      }

      if (startDateInvalid || endDateInvalid) {
        return { dateRangeInvalid: true };
      }
    }

    if (startDate) {
      startDate.setErrors(null);
    }
    if (endDate) {
      endDate.setErrors(null);
    }
    return null;
  }

}
