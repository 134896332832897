@if (skillItem) {
  <div class="con">
    <table>
      <tbody>
        @if (showRequiredProficiency&&skillItem.requiredRating) {
          <tr>
            <td class="fw-700" style="margin-right:4px;" colspan="2">
              <div>
                <div style="margin-bottom: 6px;">Expected Proficiency</div>
                <div style="display:flex;justify-content: flex-end;">
                  <app-star-rating key="requiredRating" [editable]="false" noTooltip="true"
                    [skillItem]="skillItem||{}" [client]="client" color="#00A2ED">
                  </app-star-rating>
                </div>
                <div class="proficiency-title-con">
                  @if (skillItem.requiredRating>skillItem.overallRating) {
                    <app-svg-icon class="prefix"
                    src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
                  }
                  Proficiency
                </div>
              </div>
            </td>
          </tr>
        }
        @for (item of opts.ratings; track item) {
          <tr class="rating-list-con">
            @if (item.title) {
              <td class="caption black" style="margin-right:4px;">
                <div class="flex-con">
                  <div>{{item.title}}:</div>
                </div>
              </td>
            }
            <td [colSpan]="item.title?1:2">
              <app-star-rating  [onEndorsementPage]="true" [greyedOut]="!item.editable || opts.prefix && (opts.prefix[item.key] != 'help')" [key]="item.key" [editable]="item.editable && opts.prefix && (opts.prefix[item.key] == 'help')" [title]="item.title" [skillItem]="skillItem||{}" [client]="client" (onSetValue)="onSetValue($event,item)"></app-star-rating>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}
