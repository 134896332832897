@if (toasts?.length) {
  <div class="toast-container">
    @for (toast of toasts; track toast) {
      <div class="toast p-12">
        <app-svg-icon class="mr-2" src="icons/{{toast.icon?.src}}" [ngClass]="{'mt-2px': toast.desc || toast.actionBtnConfig }"
        fill={{toast.icon?.fill}} viewBox={{toast.icon?.viewBox}}></app-svg-icon>
        <span class="msg-con mr-3">
          <div class="h5 white fw-500">{{toast.msg}}</div>
          @if (toast.desc) {
            <div class="caption desc mt-1">{{toast.desc}}</div>
          }
          @if (toast.actionBtnConfig) {
            <div class="h5 fw-500 white text-underline text-uppercase cursor-pointer mt-2"
            (click)="toast.actionBtnConfig?.callBackFn()">{{toast.actionBtnConfig?.label}}</div>
          }
        </span>
        <mat-icon class="white h4 cursor-pointer cross-icon" (click)="toastService.hideToast(toast.id)">close</mat-icon>
      </div>
    }
  </div>
}
