export interface ILearningTypes {
  name?: string;
  enable: boolean;
  isCustom?: boolean;
  id?: string;
  key?: string;
}

export const DEFAULT_LEARNING_TYPES = [
  {
      "id": "certification",
      "name": "CERTIFICATION",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "course",
      "name": "COURSE",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "assesment",
      "name": "ASSESSMENT",
      "enable": true,
      "isCustom": false
  },
  {
      "id": "assignemnt",
      "name": "ASSIGNMENT",
      "enable": true,
      "isCustom": false
  }
]

export interface ILearningElementActionsFormData {
  learningElementActions: ILearningElementActions;
  type: string;
}

export interface ILearningElementActions {
  userLearningElementProgress: string[];
  endorseHigherSkillRating?: string;
  allowedMandatoryLECertificationField?: string[];
  showOrHideCertificationFields?: string[];
  learningElementCertificationRequestEmails?: string[];
  type: string;
}

export interface IMyLearningTreatmentV3Field {
  title: string;
  key?: string;
  type: string;
  options?: any[];
  defaultValue?: string;
  displayKey?: string;
  validators?: any[];
  icon?: string;
  hoverText?: string;
  checkValue?: any[];
}

export enum ELeFieldsKeys {
  MY_LEARNING_MODULE_TYPE = 'myLearningModuleType',
  SKILL_PROF_ENDORS_STATUS_ENDORSED = 'skillProfEndorsStatusEndorsed',
  SKILL_PROF_ENDORS_STATUS_PENDING = 'skillProfEndorsStatusPending',
  SKILL_PROF_ENDORS_STATUS_SKIPPED = 'skillProfEndorsStatusSkipped',
  SKILL_PROF_ENDORS_STATUS_NOT_REQUIRED = 'skillProfEndorsStatusNotRequired',
  SKILL_PROF_ENDORS_STATUS_REJECTED = 'skillProfEndorsStatusRejected',
  USE_FINISHING_LEARNING_ELEMENT = 'useFinishingLearningElement',
  SHOW_FINISHING_LEARNING_ELEMENT_TO_USER = 'showFinishingLearningElementToUser',
  AFTER_COMPLETION_OF_LC = 'afterCompletionOfLC',
  SKILL_RATING_TYPE_FOR_LC_COMPLETION_UPDATE = 'skillRatingTypeForLCCompletionUpdate',
  USER_LEARNING_ELEMENT_PROGRESS = 'userLearningElementProgress',
  ENDORSE_HIGHER_SKILL_RATING = 'endorseHigherSkillRating',
  ALLOWED_MANDATORY_LE_CERTIFICATION_FIELD = 'allowedMandatoryLECertificationField',
  SHOW_OR_HIDE_CERTIFICATION_FIELDS ='showOrHideCertificationFields',
  LEARNING_ELEMENT_CERTIFICATION_REQUEST_EMAILS = 'learningElementCertificationRequestEmails',
  MESSAGE_FOR_MISSING_LEARNING_PATH_OF_SKILL = 'messageForMissingLearningPathOfSkill',
}
