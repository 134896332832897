<div class="con">
  <div class="top body black">
    {{timeText}}
  </div>
  <div class="selection-con">
    <div class="selection-con-inner">
      @for (item of dates; track item) {
        <div [class]="item.type + ' caption black d-flex align-items-center ' + item.selected + ' date-item-' + item.disable"
          (click)="onSelection(item)">
          <span>{{ item.value }}</span>
        </div>
      }
    </div>
  </div>
  <div class="bottom-con">
    <mat-slide-toggle color="primary" [checked]="useQuarters" labelPosition="before" class="body gray"
      [ngStyle]="{'visibility':!hideQuarters?'visible':'hidden'}" (change)="quartersChange($event)">
      Select by Quarters
    </mat-slide-toggle>
    <button mat-stroked-button class="primary-button" (click)="applyRange()">Apply
    {{data.timelineMode=='month'?'':'Range'}}</button>
  </div>
</div>
