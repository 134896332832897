import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS, DIALOG_TYPES, BUTTON_EVENTS, SKILL_CAUTION_MODE, RESPONSE_STATUS } from 'src/app/constants';
import { SINGLE_SKILL_CAUTION_DIALOG, ALL_SKILLS_CAUTION_DIALOG, PARTIAL_SKILLS_CAUTION_DIALOG, USER_SKILLS_CAUTION_DIALOG } from 'src/app/constants/skill-caution-dialogs.constants';
import { ISkillItem } from '../../team-skill-approvals/team-skill-approvals-v2/team-skill-approvals.model';
import { SharedDialogComponent } from '../../common-components/shared-dialog/shared-dialog.component';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
interface ISkillAccuracySettings {
    userCautionText: string;
    enableUserCaution: string;
    endorserCautionText: string;
    enableAidForEndorser: string;
    enableEndorserCaution: string;
    captureSkillProofFromUser: string;
    endorserShadowRatingCautionText: string;
    enableEndorserShadowRatingCaution: string;
}

@Injectable({
    providedIn: 'root'
})
export class SkillCautionsService {
    constructor(
        private ds: DataService,
        private dialog: MatDialog
    ) { }

    //------------------------------------------------------------------
    // Getters
    //------------------------------------------------------------------

    get skillCautionSettings(): ISkillAccuracySettings | undefined {
        return this.ds.client.smsSettings?.skillAccuracySettings;
    }

    get isUserCautionEnabled(): boolean {
        return this.skillCautionSettings?.enableUserCaution === SMS_OPTIONS.YES;
    }

    get userCautionText(): string {
        return this.skillCautionSettings?.userCautionText;
    }

    get isEndorserCautionEnabled(): boolean {
        return this.skillCautionSettings?.enableEndorserCaution === SMS_OPTIONS.YES;
    }

    get endorserCautionText(): string {
        return this.skillCautionSettings?.endorserCautionText;
    }

    get isEndorserAidEnabled(): boolean {
        return this.skillCautionSettings?.enableAidForEndorser === SMS_OPTIONS.YES;
    }

    get isSkillProofRequired(): boolean {
        return this.skillCautionSettings?.captureSkillProofFromUser === SMS_OPTIONS.YES;
    }

    get isEndorserShadowRatingCautionEnabled(): boolean {
        return this.skillCautionSettings?.enableEndorserShadowRatingCaution === SMS_OPTIONS.YES;
    }

    get endorserShadowRatingCautionText(): string {
        return this.skillCautionSettings?.endorserShadowRatingCautionText;
    }

    //------------------------------------------------------------------
    // Public Methods
    //------------------------------------------------------------------

    public addCautionTextToSkillForUsers(skillItem: any): boolean {
        const categorySettings = this.ds.client.smsSettings?.categoryWiseSettings.find(category => category.categoryId === skillItem.skillCategory.categoryId);
        if (categorySettings?.approvalThresholdRating_1 > skillItem.overallRating) {
            return false;
        }

        if (!this.isUserCautionEnabled) {
            return false;
        }
        this.checkCaution(skillItem, SKILL_CAUTION_MODE.USER);
    }

    public checkCaution(skillItem, mode: typeof SKILL_CAUTION_MODE.USER | typeof SKILL_CAUTION_MODE.ENDORSER) {
        const ratingScale = this.ds.client.smsSettings?.skillRating;
        const skillRating = Math.round(skillItem?.overallRating);
        const shadowRating = Math.round(skillItem?.shadowRating);
        const cautionText = mode === SKILL_CAUTION_MODE.USER ? this.userCautionText : this.endorserCautionText;

        skillItem.hasCaution = false;

        if (mode === SKILL_CAUTION_MODE.USER) {
            skillItem.error = skillItem.error || '';

            // Remove any existing caution text while preserving other errors
            if (skillItem.error.includes(this.userCautionText)) {
                skillItem.error = skillItem.error
                    .replace(this.userCautionText, '')
                    .trim();
            }
        }

        // Check rating thresholds
        switch (ratingScale) {
            case 'Stars 1-5':
                if (skillRating >= 4 || skillRating > shadowRating) {
                    skillItem.hasCaution = true;
                    skillItem.error += cautionText;
                }
                break;
            case 'Stars 1-4':
                if (skillRating >= 3 || skillRating > shadowRating) {
                    skillItem.hasCaution = true;
                    skillItem.error += cautionText;
                }
                break;
            default:
                break;
        }
    }

    public showCautionDialogForUser(cautionSkillsCount: number): Observable<boolean> {
        if (!this.isUserCautionEnabled) {
            return new Observable(observer => {
                observer.next(true);
                observer.complete();
            });
        }

        return new Observable(observer => {
            const dialogRef = this.dialog.open(SharedDialogComponent, {
                panelClass: 'shared-dialog',
                disableClose: false
            });

            const dialogData = {
                ...USER_SKILLS_CAUTION_DIALOG,
                title: `${cautionSkillsCount} skill${cautionSkillsCount === 1 ? '' : 's'} found with caution`,
            };

            dialogRef.componentInstance.data = {
                type: DIALOG_TYPES.GENERIC,
                ...dialogData
            };

            const subscription = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
                dialogRef.close();
                observer.next(data.event === BUTTON_EVENTS.BUTTON_CLICK);
                observer.complete();
            });

            dialogRef.afterClosed().subscribe(() => {
                subscription.unsubscribe();
            });
        });
    }

    public showCautionDialogForEndorser(skillItems: ISkillItem[]): Observable<boolean> {
        const cautionSkillsCount = skillItems.filter(skillItem => skillItem.hasCaution).length;

        if (!this.isEndorserCautionEnabled || cautionSkillsCount === 0) {
            return new Observable(observer => {
                observer.next(true);
                observer.complete();
            });
        }

        return new Observable(observer => {
            const dialogRef = this.dialog.open(SharedDialogComponent, {
                panelClass: 'shared-dialog',
                disableClose: false
            });

            const dialogData = this.getDialogConfigForEndorser(skillItems, cautionSkillsCount);

            dialogRef.componentInstance.data = {
                type: DIALOG_TYPES.GENERIC,
                ...dialogData
            };

            const subscription = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
                dialogRef.close();
                observer.next(data.event === BUTTON_EVENTS.BUTTON_CLICK);
                observer.complete();
            });

            dialogRef.afterClosed().subscribe(() => {
                subscription.unsubscribe();
            });
        });
    }

    private getDialogConfigForEndorser(skillItems: ISkillItem[], cautionSkillsCount: number): any {
        // Single skill dialog
        if (skillItems.length === 1) {
            const ratingAlias = this.ds.dictionary?.rating;
            const overallRating = skillItems[0].overallRating;
            const shadowRating = skillItems[0].shadowRating;
            const tooltipLabel = this.ds.client.smsSettings?.[`skillToolTipLabel${overallRating}`] || '';
            const article = /^[AEIOU]/i.test(tooltipLabel) ? 'an' : 'a';

            return {
                ...SINGLE_SKILL_CAUTION_DIALOG,
                message2: overallRating > shadowRating ? `AI model suggested ${ratingAlias} is ${shadowRating}, while you are approving the skill at ${overallRating}.\n\nApprove if you agree` : `${ratingAlias} of ${overallRating} implies that they are ${article} ${tooltipLabel.toLowerCase()} in this skill.\n\nApprove if you agree`
            };
        }

        // Case: All skills have cautions
        if (skillItems.length === cautionSkillsCount) {
            return {
                ...ALL_SKILLS_CAUTION_DIALOG,
                title: `${cautionSkillsCount} skills found with caution!`
            };
        }

        // Case: Some skills have cautions
        const remainingCount = skillItems.length - cautionSkillsCount;
        const skillWord = remainingCount === 1 ? 'Skill' : 'Skills';

        return {
            ...PARTIAL_SKILLS_CAUTION_DIALOG,
            title: `${cautionSkillsCount} ${cautionSkillsCount === 1 ? 'skill' : 'skills'} found with caution!`,
            message2: `Would you like to approve the remaining ${remainingCount} ${remainingCount === 1 ? 'skill' : 'skills'}?`,
            btnTitle: `Approve Remaining ${skillWord}`
        }
    }

    public getUserSkillEvidences(payload: any): Observable<any> {
        return this.ds.saveData<any>(API_ENDPOINT.GET_USER_SKILL_EVIDENCES, payload).pipe(
          take(1)
        );
      }

    public getProofsForSkills(skillItems: any[], userIds : string[]): Observable<any> {
      const skillItemIds = skillItems.map((skill) => skill.skillItemId);
      let skillProofHash = {};
      const payload = { skillItemIds, userIds };
      return this.getUserSkillEvidences(payload).pipe(
        switchMap((res) => {
          if (res.status === RESPONSE_STATUS.SUCCESS) {
            skillProofHash = res.data.reduce((acc, item) => {
              const { skillItemId } = item;
              if (!acc[skillItemId]) {
                acc[skillItemId] = [];
              }
              acc[skillItemId].push(item);
              return acc;
            }, {});
            skillItems = skillItems.map((skill) => {
              const proofs = skillProofHash[skill.skillItemId] || [];
              return {
                ...skill,
                proofs
              };
            });
          }
          return of(skillItems);
        })
      );
    }
}
