<form [formGroup]="chipEditForm" [hidden]="input.hidden">
  <mat-form-field class="input-form-field" appearance="outline">
    @if (input.title) {
      <mat-label>{{input.title}}</mat-label>
    }
    @if (input.icon) {
      <mat-icon matPrefix>{{input.icon}}</mat-icon>
    }
    <input matInput [placeholder]="input.placeholder"
      formControlName="chipInput" (keyup.enter)="onAdd($event)"
      (focusout)="onBlur()"/>
    @if (chipEditForm.invalid) {
      <mat-error>{{input.errorText||'This input is
      mandatory'}}</mat-error>
    }
  </mat-form-field>
  @if (input?.subTitle) {
    <div class="caption gray mb-2">{{input?.subTitle}}</div>
  }
  <mat-chip-listbox class="chip-row">
    @for (item of selectedItems; track item) {
      <mat-chip-option class="chip"
        (removed)="onRemove(item)" [disabled]="chipEditForm.controls['chipInput'].disabled ">{{item}}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip-option>
    }
  </mat-chip-listbox>
</form>
