<div class="proficiency-slider-wrapper wrapper">
  <div class="proficiency-slider-header header">
    <span>{{skillItem.name}}</span>
    <div class="proficiency-slider-header-actions">
      <div class="proficiency-slider-header-actions-info" (click)="showSkillOverlayDetails()">
        <app-svg-icon src="icons/info.svg" class="mt-1" viewBox="0 0 14 14"
        fill="var(--color-info-blue)"></app-svg-icon>
        Details
      </div>
    </div>
  </div>
  <div class="proficiency-slider-body body">
    <div class="proficiency-slider-container">
      <div class="proficiency-slider-container-data flex-column gap-2">
        @if (showRequiredProficiency) {
          <div class="d-flex w-100 justify-content-between">
            <div>Expected Proficiency</div>
            <div class="d-flex gap-2px align-items-center">
              @if ((skillItem && skillItem['requiredRating']||0) === 0) {
                <mat-icon class="overflow-visible"
                  color="var(--primary-color)" [ngStyle]="{'color':'gray'}">star_border
                </mat-icon>
              }
              @if ((skillItem && skillItem['requiredRating']||0) === maxRating) {
                <mat-icon class="overflow-visible"
                  [color]="color ? 'var(--primary-color)' : 'gray'">star
                </mat-icon>
              }
              @if ((skillItem && skillItem['requiredRating']||0) > 0 && (skillItem['requiredRating']||0) < maxRating) {
                <mat-icon
                  class="overflow-visible" [color]="color ? 'var(--primary-color)' : 'gray'">
                star_half</mat-icon>
              }
              <div>
                <span class="rating">{{ skillItem['requiredRating'] }}</span> {{
                proficiencyLevels[skillItem['requiredRating'] - 1] }}
              </div>
            </div>
          </div>
          <div class="d-flex w-100 h-1px bg-color-gray-light"></div>
        }
        <form class="d-flex flex-wrap gap-2 w-100 justify-content-between" [formGroup]="skillForm">
          <div class="d-flex w-100 flex-column gap-12">
            <div class="d-flex justify-content-between">
              <div>{{header}}</div>
              <div class="d-flex gap-2px align-items-center">
                @if ((skillItem && skillItem[key]||0) === 0) {
                  <mat-icon color="var(--primary-color)"  class="overflow-visible"
                    [ngStyle]="{'color':'gray'}">star_border
                  </mat-icon>
                }
                @if ((skillItem && skillItem[key]||0) === maxRating) {
                  <mat-icon  class="overflow-visible"
                    [color]="color ? 'var(--primary-color)' : 'gray'">star
                  </mat-icon>
                }
                @if ((skillItem && skillItem[key]||0) > 0 && (skillItem[key]||0) < maxRating) {
                  <mat-icon  class="overflow-visible"
                    [color]="color ? 'var(--primary-color)' : 'gray'">
                  star_half</mat-icon>
                }
                <div>
                  <span class="rating">{{ getFormControlValue(key) }}</span> {{
                  proficiencyLevels[getFormControlValue(key) - 1] }}
                </div>
              </div>
            </div>
            <div>
              <input type="range" min="1" max={{maxRating}} step="1"
                class="proficiency-slider-range_{{key}}_{{skillItem.skillItemId}}" formControlName="{{key}}"
                (change)="updateProficiency(key)">
              <div class="proficiency-slider-ticks">
                @for (level of proficiencyLevels; track level; let i = $index) {
<span class="tick" [ngClass]="{
                'active': i + 1 < getFormControlValue(key),
                'hidden': i + 1 == getFormControlValue(key)
              }"></span>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>