import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
    selector: 'app-staffing-role-settings',
    templateUrl: './staffing-role-settings.component.html',
    standalone: false
})
export class StaffingRoleSettingsComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  public staffingRoleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private ds: DataService,
    public utils: UtilitiesService,
    private weds: WaitErrorDialogsService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.setFormValue();
  }

  private buildForm() {
    this.staffingRoleForm = this.fb.group({
      isStaffingRoleMandatory: [SMS_OPTIONS.NO, Validators.required],
      maxStaffingRoleLimit: [1, [Validators.required, Validators.min(1)]],
      extractStaffingRoleWithAI: [SMS_OPTIONS.NO, Validators.required],
    });
  }

  private setFormValue() {
    const { smsSettings } = this.ds.client;
    if (smsSettings) {
      this.staffingRoleForm.setValue({
        isStaffingRoleMandatory: smsSettings?.isStaffingRoleMandatory || SMS_OPTIONS.NO,
        maxStaffingRoleLimit: smsSettings?.maximumStaffingRoles || 1,
        extractStaffingRoleWithAI: smsSettings?.extractStaffingRoleWithAI || SMS_OPTIONS.NO,
      });
    }
  }

  public saveData() {
    if (this.staffingRoleForm.invalid) return;
    const staffingRoleSettingsData = {
      isStaffingRoleMandatory: this.staffingRoleForm?.value?.isStaffingRoleMandatory,
      maximumStaffingRoles: this.staffingRoleForm?.value?.maxStaffingRoleLimit,
      extractStaffingRoleWithAI: this.staffingRoleForm?.value?.extractStaffingRoleWithAI,
      type: 'staffingRole',
    };
    this.onSave.emit(staffingRoleSettingsData);
  }

}
