<div class="proficiency-slider-wrapper">
  @if (mode !== proficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
  <div class="proficiency-slider-header">
    <div class="item-center gap-1">
      @if (isHighDemandSkill && isHighDemandSkillEnabled) {
      <mat-icon class="proficiency-slider-header-high-demand-skill-icon">trending_up</mat-icon>
      }
      <span>{{data.skillName}}</span>
    </div>
    @if (mode === proficiencySliderMode.GENERAL) {
    <div class="proficiency-slider-header-actions">
      <div (click)="showSkillOverlayDetails()" class="proficiency-slider-header-actions-info">
        <app-svg-icon src="icons/info.svg" class="mt-1" viewBox="0 0 14 14" fill="var(--color-info-blue)">
        </app-svg-icon>
        Details
      </div>
      <button (click)="showRemoveConfirmationModal()" class="mt-n1" mat-icon-button>
        <mat-icon class="proficiency-slider-header-actions-close">close</mat-icon>
      </button>
    </div>
    }
  </div>
  }
  <div [ngClass]="{'p-3' : mode === proficiencySliderMode.ENDORSER_AIDS_SIDEBAR}" class="proficiency-slider-body">
    <div class="proficiency-slider-container">
      <div class="proficiency-slider-container-data">
        <form class="d-flex flex-wrap gap-2 w-100 justify-content-between" [formGroup]="skillForm">
          @if (data.hasCaution) {
          <div
            class="d-flex w-100 align-items-center mb-1 gap-1 p-2 border-radius-4 border-1 border-color-color-yellow-40 bg-color-yellow-10 fs-12 black-text">
            <app-svg-icon fill="var(--color-yellow-dark)" src="icons/warning-open.svg" class="mt-1" height="16px"
              width="16px" viewBox="0 0 60 54"></app-svg-icon>
            Caution: {{ data.error }}
          </div>
          }
          @if (mode !== proficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
          }
          @for (item of skillQuestions; track item) {
          <div class="d-flex" [ngClass]="item.type === 'Rating' ? 'w-100 flex-column' : 'flex-row flex-grow-3'">
            @if (item.type === 'Rating') {
            <div class="d-flex justify-content-between">
              <div>{{item.title}}</div>
              <div class="d-flex gap-2">
                <app-svg-icon class="mt-2px" height="16px" width="16px" viewBox="0 0 25 24" src="icons/skill-star.svg">
                </app-svg-icon>
                {{ getFormControlValue(item.key) }} {{ proficiencyLevels[getFormControlValue(item.key) - 1] }}
              </div>
            </div>
            <div>
              <input class="proficiency-slider-range_{{item.key}}_{{data.skillItemId}}" type="range" min="1"
                max="{{maxRating}}" step="1" formControlName="{{item.key}}" (change)="updateProficiency(item.key)">
              <div class="proficiency-slider-ticks">
                @for (level of proficiencyLevels; track level; let i = $index) {
                <span class="tick" [ngClass]="{
                    'active': i + 1 < getFormControlValue(item.key),
                    'hidden': i + 1 == getFormControlValue(item.key)
                  }"></span>
                }
              </div>
            </div>
            }
            @if (mode !== proficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
            @if (item.type === 'Experience') {
            <div class="d-flex flex-column flex-grow-1 gap-1" matTooltip="Enter your skill experience in years">
              <label class="h5 fw-500 mb-1 d-block">{{item.title}}</label>
              <ng-select class="custom-select-input" formControlName="{{item.key}}" placeholder="{{item.placeholder}}"
                [items]="item.options" bindLabel="name" bindValue="value" [clearable]="false" [closeOnSelect]="true">
              </ng-select>
            </div>
            }
            @if (item.type === 'Year') {
            <div class="d-flex flex-column flex-grow-1 gap-1" matTooltip="Last time you used the skill">
              <label class="h5 fw-500 mb-1 d-block">{{item.title}}</label>
              <ng-select class="custom-select-input" formControlName="{{item.key}}" placeholder="{{item.placeholder}}"
                [items]="item.options" [clearable]="false" [closeOnSelect]="true">
              </ng-select>
            </div>
            }
            @if (item.type === 'Tagging') {
            <div class="d-flex flex-column flex-grow-1 gap-1"
              [matTooltip]="!isGlobalSkillTypeEnabled ? 'Choose your'+ ds.dictionary.skillType : tooltipForGlobalSkillType">
              <label class="h5 fw-500 mb-1 d-block">{{item.title}}</label>
              <ng-select class="custom-select-input" formControlName="{{item.key}}" placeholder="{{item.placeholder}}"
                [items]="item.options" bindLabel="name" bindValue="skillTypeId" [clearable]="false"
                [closeOnSelect]="true">
                <ng-template *ngIf="isGlobalSkillTypeEnabled" ng-label-tmp let-item="item">
                  <div class="item-center gap-1">
                    <mat-icon class="w-16px h-16px fs-16">{{item.icon}}</mat-icon> {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            }
            }
          </div>
          }
          @if (mode === proficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
          <div class="d-flex flex-column w-100">
            @if(data.lastUsed) {
            <div
              class="d-flex py-2 align-items-center justify-content-between border-top-1 border-color-color-gray-3">
              <span class="fs-12 fw-500 ls-6 text-uppercase color-color-gray-1000">Last Used</span>
              <span class="fs-12 fw-500 black-text text-right">{{data.lastUsed}}</span>
            </div>
            }
            @if(data.experience) {
            <div
              class="d-flex py-2 align-items-center justify-content-between border-top-1 border-color-color-gray-3">
              <span class="fs-12 fw-500 ls-6 text-uppercase color-color-gray-1000">Experience</span>
              <span class="fs-12 fw-500 black-text text-right">{{data.experience}} years</span>
            </div>
            }
            @if (data.skillType) {
            <div
              class="d-flex py-2 align-items-center justify-content-between border-top-1 border-color-color-gray-3">
              <span class="fs-12 fw-500 ls-6 text-uppercase color-color-gray-1000">Skill Type</span>
              <span class="fs-12 fw-500 black-text text-right">{{data.skillType}}</span>
            </div>
            }
            @if (data.updatedOn) {
            <div class="d-flex py-2 align-items-center justify-content-between border-top-1 border-color-color-gray-3">
              <span class="fs-12 fw-500 ls-6 text-uppercase color-color-gray-1000">Last Updated</span>
              <span class="fs-12 fw-500 black-text text-right">{{ data.updatedOn | date: 'd MMM y h:mm a' }}</span>
            </div>
            }
          </div>
          }
        </form>
      </div>
    </div>
    <!-- Footer action buttons -->
    @if(mode!=proficiencySliderMode.GENERAL && mode!=proficiencySliderMode.ENDORSER_AIDS_SIDEBAR) {
    <div class="proficiency-slider-footer pt-3">
      @if (mode === proficiencySliderMode.ADD) {
      <button (click)="onAddToMySkills()" mat-stroked-button class="primary-button w-100">
        <mat-icon class="ic-16 h-16px w-16px white mr-1">add</mat-icon>
        <span>ADD TO MY SKILLS</span>
      </button>
      }
      @if (mode === proficiencySliderMode.EDIT) {
      <div class="d-flex justify-content-between">
        <button (click)="onDelete()" class="secondary-button-warn delete-btn" mat-stroked-button>
          <mat-icon class="ic-16 mb-1px mr-1">close</mat-icon>
          <span>DELETE</span>
        </button>
        <button (click)="onUpdate()" class="primary-button" mat-stroked-button>
          SAVE
        </button>
      </div>
      }
    </div>
    }
  </div>
</div>
