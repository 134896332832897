@if ((myLearningTreatment === TREATMENT_MAP.V1 && config[0]?.cards?.length) || myLearningTreatment !== TREATMENT_MAP.V1) {
  <div class="d-flex flex-column align-items-start gap-12">
    @if (!headerConfig?.hideViewMoreBtn) {
      <div class="d-flex align-items-start flex-column">
        <span class="d-flex">
          @if (headerConfig?.headerIcon) {
            <mat-icon class="ic-20">{{headerConfig?.headerIcon}}</mat-icon>
          }
          <label class="h4">{{ headerConfig?.groupName }} @if (config.length > 1) {
            <span>({{totalCount}})</span>
          }</label>
        </span>
      </div>
    }
  <div class="d-flex flex-column align-items-start gap-3 custom-width" [ngClass]="{
      'card-group-box': headerConfig?.showGrayBG,
      'full-height': headerConfig?.hideViewMoreBtn
  }">
      <mat-tab-group class="w-100" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange()" [ngClass]="{'hide-tab-header': config.length === 1 || myLearningTreatment === TREATMENT_MAP.V1}">
        @for (tab of config; track tab) {
          <mat-tab>
            <ng-template mat-tab-label>
              <div>{{tab.groupName}} @if (tab.allowAdditionalFilters) {
                <span>({{tab.totalDataCount || 0}})</span>
              }</div>
            </ng-template>
            @if (tab?.subTitle && myLearningTreatment !== TREATMENT_MAP.V1) {
              <div class="pt-3 caption gray">{{tab.subTitle}}</div>
            }
            @if (tab.allowAdditionalFilters) {
              <div class="d-flex gap-2 align-items-baseline">
                <div class="caption gray">Filter by:</div>
                <div class="mt-3">
                  <form [formGroup]="tab.appliedFilterControl">
                    @for (filter of tab?.additionalFilters; track filter) {
                      @if (filter?.options?.length) {
                        <div matTooltip="Click anywhere to apply Filters">
                          <mat-form-field appearance="outline" class="filter">
                            <mat-select [placeholder]="filter?.title?.toLowerCase() | titlecase" multiple [formControlName]="filter?.key" (selectionChange)="onFilterApply()">
                              @for (option of filter?.options; track option) {
                                <mat-option [value]="option?.pk">{{option?.title}}</mat-option>
                              }
                            </mat-select>
                            <mat-icon matSuffix class="mat-custom-arrow">keyboard_arrow_down</mat-icon>
                          </mat-form-field>
                        </div>
                      }
                    }
                  </form>
                </div>
              </div>
            }
            <div class="d-flex gap-3 flex-wrap w-100 align-items-stretch mt-3" #cardHolder>
              @for (card of tab.cardsShown; track card) {
                <div>
                  <app-progress-card [data]=card></app-progress-card>
                </div>
              }
            </div>
            @if (tab.cards?.length > numberOfCardsToShow && !isLoading && !tab.hideViewMoreBtn) {
              <div class="mt-3">
                @if (tab.cards?.length <= 4 ) {
                  <button mat-button class="secondary-button h6 item-center"
                    (click)="toggleContainerExpansion()">
                    <label class="cursor-pointer"> {{ isExpanded ? 'Show Less' : 'Show More' }}</label>
                    <mat-icon>{{ isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
                  </button>
                } @else {
                  <button mat-button class="secondary-button h6 item-center"
                    (click)="redirectToViewAll()">
                    <label class="cursor-pointer">View All {{ tab.totalDataCount }} learnings</label>
                    <mat-icon class="ic-18 cursor-pointer">east</mat-icon>
                  </button>
                }
              </div>
            }
            @if (!isLoading && !tab.cardsShown.length) {
              <div class="no-data h4 gray my-3 w-100">
                <img src="assets/nodata.png" />
                <div>Sorry, there's no data available for you at this moment.</div>
              </div>
            }
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </div>
}