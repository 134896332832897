<div class="editable-tbl-con">
  @if (addButtonText&&addButtonPosition=='top'&&canEdit) {
    <div class="add-button-con">
      <button type="button" mat-stroked-button class="secondary-button" (click)="addRow()">{{addButtonText}}</button>
    </div>
  }
  @if (title) {
    <div class="title" class="title">
      <div class="regularfont">{{title}}</div>
    </div>
  }
  <form [formGroup]="editForm" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" class="form-con">
    @if (dataSource.length) {
      <table mat-table [dataSource]="dataSource" cdkDropList (cdkDropListDropped)="drop($event)"
        >
        @for (col of columns; track col; let i = $index) {
          <ng-container matColumnDef="{{col.key}}">
            <th mat-header-cell *matHeaderCellDef>{{col.title}}
            </th>
            <td mat-cell *matCellDef="let element">
              @if (element['disable'+col.key]||col.disabled) {
                <div class="{{'disabledCol disabled'+col.type}}">
                  {{element[col.key]}}
                </div>
              }
              @if ((col.type=='text'||col.type=='number')&&!element['disable'+col.key]&&!col.disabled) {
                <div>
                  <mat-form-field appearance="outline" class=" input-form-field">
                    @if (col.icon) {
                      <mat-icon matPrefix>{{col.icon}}</mat-icon>
                    }
                    <input type="{{col.type}}" matInput formControlName="{{col.key+'_'+element.tblId}}"
                      placeholder="{{col.placeholder}}" [required]="col.required"
                      [attr.min]="blockNegativeInputs ? 0 : null"
                      (keydown)="onKeyChange($event, col.type)"
                      (change)="selectionChanged(col.key+'_'+element.tblId, i)"
                      [autocomplete]="col.autocomplete||'off'"/>
                    @if (editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.required) {
                      <mat-error class="mt-2">{{col.title}} is required</mat-error>
                    }
                    @if (editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.max) {
                      <mat-error class="mt-2">
                        {{col.title}} should be <= {{editForm.get([col.key+'_'+element.tblId]).errors?.max?.max}}
                      </mat-error>
                    }
                    @if (editForm.get([col.key+'_'+element.tblId]).touched && editForm.get([col.key+'_'+element.tblId]).errors?.min) {
                      <mat-error class="mt-2">
                        {{col.title}} should be >= {{editForm.get([col.key+'_'+element.tblId]).errors?.min?.min}}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              }
              @if (col.type=='select'&&!element['disable'+col.key]&&!col.disabled) {
                <div>
                  <mat-form-field appearance="outline" class=" input-form-field">
                    @if (col.icon) {
                      <mat-icon matPrefix>{{col.icon}}</mat-icon>
                    }
                    <mat-select  (selectionChange)="selectionChangeEvent($event,col.key+'_'+element.tblId,i)" [multiple]='col.isMulti' formControlName="{{col.key+'_'+element.tblId}}"
                      [required]="col.required">
                      @for (option of col.options; track option) {
                        <mat-option [value]="option">
                          {{option}}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              }
              @if (col.type=='auto-complete'&&!element['disable'+col.key]&&!col.disabled) {
                <div>
                  <app-auto-complete #autoComplete (selectionChange)="selectionChangeEvent($event,col.key+'_'+element.tblId,i)" [input]="col" [data]="element"></app-auto-complete>
                </div>
              }
              @if (col.type=='icon'&&!element['disable'+col.key]&&!col.disabled) {
                <div>
                  <mat-icon class="delete-icon"
                  (click)="onIconClick(element.tblId,col.key)">{{element[col.key]}}</mat-icon>
                </div>
              }
              @if (col.type=='delete'&&!element['disable'+col.key]&&!col.disabled) {
                <div>
                  <mat-icon class="delete-icon" (click)="deleteRow(element.tblId)">clear</mat-icon>
                </div>
              }
            </td>
          </ng-container>
        }
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: tableColumns;let i=index" class="element-row" matRipple
          cdkDrag [cdkDragDisabled]="!enableDrag||!canEdit" cdkDragLockAxis="y">
        </tr>
      </table>
    }
    @if (footNotes) {
      <div class="caption gray">{{footNotes}}</div>
    }
    @if (saveButtonText && canEdit) {
      <div class="save-button-con">
        @if (addButtonText && addButtonPosition === 'bottom' && (maxRows < 1 || maxRows > dataSource.length)) {
          <button
            mat-stroked-button
            class="secondary-button"
            type="button"
            (click)="addRow()"
            >
            {{ addButtonText }}
          </button>
        }
        <button
          mat-stroked-button
          class="primary-button"
          [disabled]="editForm.invalid"
          >
          {{ saveButtonText }}
        </button>
      </div>
    }
  </form>
</div>
