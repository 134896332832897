import { Component, computed, inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IDialog } from '../shared.model';
import { DatatableComponent } from '../datatable/datatable.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [
    MatIconModule,
    DatatableComponent
  ]
})
export class DialogComponent {
  private data: WritableSignal<IDialog> = signal(inject(MAT_DIALOG_DATA));

  public icon = computed(() => this.data()?.icon);
  public title = computed(() => this.data().title);
  public datatable = computed(() => this.data()?.datatable);
}
