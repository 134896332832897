import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IActivityGroup, ISkillActivity, ISkillHistoryResponse, ISource, SortOrder } from '../skill-activity/skill-activity.component';
import { DataService } from 'src/app/services/data.service';
import { SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { take } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-recent-skill-activity',
  templateUrl: './recent-skill-activity.component.html',
  styleUrls: ['./recent-skill-activity.component.scss'],
  standalone: false,
  animations: [
    trigger('expandCollapse', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ height: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class RecentSkillActivityComponent implements OnInit {
  @Input() skill: any;
  @Input() isEndorsement: boolean = false;
  @Input() isSkillActivity: boolean = false;
  public activities: ISkillActivity[] = [];
  public filteredActivities: ISkillActivity[] = [];
  public selectedSource: any = null;
  public sources: ISource[] = [];
  public sortOrder: SortOrder = SortOrder.Ascending;
  public isLoading: boolean = false;

  public readonly iconMap: Record<string, string> = {
    Creation: 'add',
    Deletion: 'delete',
    Updation: 'sync',
    Endorsement: 'verified',
    Declined: 'cancel',
  };

  public readonly displayMap: Record<string, string> = {
    Creation: 'Created',
    Deletion: 'Deleted',
    Updation: 'Updated',
    Endorsement: 'Endorsed',
  };

  public ratingKeyMap: Record<string, string> = {
    projectRating: 'PSKL',
    selfAcquiredRating: 'SSKL',
    trainingRating: 'TSKL',
    incomingRating: 'ISKL',
    aspirationalRating: 'ASKL',
    managerialRating: 'Manager Rating',
  };

  public readonly endorsementIconsMap: { [key: string]: string } = {
    reject: 'icons/rejected-icon.svg',
    skip: 'icons/skip-button.svg'
  };

  public groupedActivities: IActivityGroup[] = [];
  public itemsPerPage: number;
  public currentPage = 1;

  private readonly SUPPORT_TEXT_BY = 'by';
  private readonly SUPPORT_TEXT_VIA = 'via';
  private readonly EVENT_UPDATION = 'Updation';
  private readonly ALL_SOURCES = 'All Sources';
  private readonly LABELS_FOR_VIA: string[] = ['Training', 'Project'];
  private _allGroupedActivities: IActivityGroup[] = [];
  private skillSourcesAlias: Array<{ sourceLabel: string; alias: string; label: string }> = [];

  constructor(private ds: DataService) {
    this.sources = [{ id: 'all', label: this.ALL_SOURCES }];
  }

  //------------------------------------------------------------------
  // Lifecycle Hooks
  //------------------------------------------------------------------

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.skill && this.skill) {
      this.getSkillActivities();
    }
  }

  ngOnInit(): void {
    this.skillSourcesAlias = this.ds.client.smsSettings.skillSourcesAlias;
    this.updateRatingKeyMap();
  }

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public getDisplayText(activity: ISkillActivity): string {
    let text = this.displayMap[activity.event] || activity.event;

    if (activity.event === 'Endorsement' && activity.endorsements) {
      const { endorsedLevel, requiresApproval, type } = activity.endorsements;

      if (type) {
        text = 'Endorsement';

        if (endorsedLevel && requiresApproval === 'Yes') {
          text += ` (L${endorsedLevel})`;
        }
        text += ` ${activity.label || type}`;
      }
    }
    return text;
  }

  public getDisplaySupportText(activity: ISkillActivity): string {
    if (activity.event === this.EVENT_UPDATION) {
      return this.LABELS_FOR_VIA.includes(activity.label) ? this.SUPPORT_TEXT_VIA : this.SUPPORT_TEXT_BY;
    }
    return this.SUPPORT_TEXT_BY;
  }

  public onSourcesChange(): void {
    if (this.selectedSource.includes('all')) {
      this.selectedSource = ['all'];
      this.filteredActivities = [...this.activities];
    } else if (!this.selectedSource.length) {
      this.filteredActivities = [...this.activities];
    } else {
      this.filteredActivities = this.activities.filter((activity) =>
        this.selectedSource.includes(activity.updateSource)
      );
    }
    this.sort();
    this.groupActivitiesByDate();
  }

  public toggleSortOrder(): void {
    this.sortOrder = this.sortOrder === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;
    this.sort();
    this.groupActivitiesByDate();
  }

  public toggleGroupVisibility(groupIndex: number): void {
    if (groupIndex >= 0 && groupIndex < this.groupedActivities.length) {
      this.groupedActivities[groupIndex].showAll = !this.groupedActivities[groupIndex].showAll;
    }
  }

  public loadMore(): void {
    this.currentPage++;
    this.updateVisibleItems();
  }

  public getAllGroupedActivities(): IActivityGroup[] {
    return this._allGroupedActivities;
  }

  public toggleDeltaVisibility(activity: ISkillActivity): void {
    activity['showDelta'] = !activity['showDelta'];
  }

  public checkIfL1Endorsement(activity: ISkillActivity): boolean {
    return activity.event === 'Endorsement' && activity.endorsements.requiresApproval === SMS_OPTIONS.YES;
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private updateRatingKeyMap(): void {
    const selectedSkillCategoryId = this.skill.skillCategoryId;

    const category = this.ds.client?.smsSettings?.categoryWiseSettings?.find(
      (cat) => cat.categoryId === selectedSkillCategoryId
    );

    this.ratingKeyMap = category?.skillRatings?.reduce((map, rating) => {
      if (rating.dataKey && rating.title) {
        map[rating.dataKey] = rating.title;
      }
      return map;
    }, {}) || {};
  }

  private sort(): void {
    this.filteredActivities.sort((a, b) => {
      const dateA = new Date(a.timestamp).getTime();
      const dateB = new Date(b.timestamp).getTime();
      return this.sortOrder === SortOrder.Ascending ? dateA - dateB : dateB - dateA;
    });
  }

  private getSkillActivities(): void {
    const payload = this.generatePayload();
    this.isLoading = true;

    if (this.isEndorsement) {
      payload.userId = this.skill.userId;
    }

    this.ds
      .postApi(API_ENDPOINT.SKILL_HISTORY, payload)
      .pipe(take(1))
      .subscribe({
        next: (data: ISkillHistoryResponse) => {
          this.handleResponse(data);
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  private handleResponse(data: ISkillHistoryResponse): void {
    this.activities = data.skillHistoryData || [];
    this.initializeSources();
    this.filteredActivities = [...this.activities];
    this.groupActivitiesByDate();
    this.selectedSource = this.sources.length ? [this.sources[0].id] : null;
  }

  private generatePayload(): any {
    return {
      clientId: this.ds.client.clientId,
      skillItemId: this.skill.skillItemId,
      userId: this.ds.user.userId,
      filters: {
        type: []
      }
    };
  }

  private initializeSources(): void {
    this.sources = [
      ...this.sources,
      ...this.skillSourcesAlias.map(source => ({
        id: source.sourceLabel,
        label: source.alias || source.label
      }))
    ];
  }

  private groupActivitiesByDate(): void {
    const groupedMap = new Map<string, ISkillActivity[]>();

    this.filteredActivities.forEach((activity) => {
      const date = new Date(activity.timestamp).toLocaleDateString();
      if (!groupedMap.has(date)) {
        groupedMap.set(date, []);
      }
      groupedMap.get(date)?.push(activity);
    });

    this._allGroupedActivities = Array.from(groupedMap.entries()).map(([date, activities]) => ({
      date,
      activities,
      showAll: false,
    }));

    this.currentPage = 1;
    this.groupedActivities = this._allGroupedActivities.slice(0, this.itemsPerPage);
  }

  private updateVisibleItems(): void {
    const endIndex = this.currentPage * this.itemsPerPage;
    this.groupedActivities = this._allGroupedActivities.slice(0, endIndex);
  }
}
