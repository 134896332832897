<button #starButton (menuOpened)="onMenuOpen()" class="proficiency-star-container"
  [ngClass]="editable?'editable':'not-editable'" [matMenuTriggerFor]="menu"
  [disabled]="!editable && !showRequiredProficiency"
  [matTooltip]="hideRatingValue ? 'Rating: '+(skillItem[key]||0)||0: (editable ? 'Click to edit' : '')"
  matTooltipPosition="above">
  @if ((skillItem && skillItem[key]||0) == 0) {
    <mat-icon color="var(--primary-color)" [ngStyle]="{'color':'gray'}"
      class="overflow-visible">star_border
    </mat-icon>
  }
  @if ((skillItem && skillItem[key]||0) == maxValue) {
    <mat-icon [color]="colored ? 'var(--primary-color)' : 'gray'"
      class="overflow-visible">star
    </mat-icon>
  }
  @if ((skillItem && skillItem[key]||0) > 0 && (skillItem[key]||0) < maxValue) {
    <mat-icon
      [color]="colored ? 'var(--primary-color)' : 'gray'" class="overflow-visible">
    star_half</mat-icon>
  }
  @if (skillItem && !hideRatingValue) {
    <div
      [ngClass]="skillItem[key] ? 'proficiency-star-value body black' : 'proficiency-star-value body gray' ">{{
    (skillItem[key] || 0) }}</div>
  }
  @if (showRequiredProficiency&&skillItem.requiredRating&&skillItem.requiredRating>skillItem[key]&&!skillItem.changes) {
    <app-svg-icon
    class="warning-icon" src="icons/warning.svg" fill="var(--color-hot-pink)"></app-svg-icon>
  }
</button>

<mat-menu #menu class="proficiency-slider-menu" yPosition="above" xPosition="before">
  <app-proficiency-slider-overlay [key]="key" [editable]="editable"
    [showRequiredProficiency]="showRequiredProficiency" [skillItem]="skillItem||{}" [client]="client"
    [header]="header" [noTooltip]="!editable" (onSetValue)="onRatingSelected($event)">
  </app-proficiency-slider-overlay>
</mat-menu>
