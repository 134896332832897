<div class="d-flex" [ngClass]="isGlobalSkillTypeEnabled ? 'gap-48 con' : 'flex-column' ">
  @if (data) {
    <div class="chart-body"
      [ngStyle]="{'flex-direction': (showChange === 'Below' || showChange === 'None-Below') ? 'column' : 'row'}">
      <div class="chart"
        [ngStyle]="{'height': (size === 'full' ? width : width / 2) + 'px', 'width': width + 'px', 'margin-right': showChange === 'Right' ? '14px' : 0}">
        <div class="chart h2 black">
          <div class="chart-inner"
            [ngClass]="{'chart-inner-full': size === 'full', 'chart-inner-half': size === 'half', 'chart-inner-adjusted': colorType?.includes('onboarding')}">
            {{data.value}}{{percentString}}
            @if (subtext) {
              <span class="subtext">{{subtext}}</span>
            }
          </div>
          <canvas #canvas [height]="(size === 'full' ? width : width / 2) + 'px'" [width]="width" baseChart
            [datasets]="datasets" [labels]="labels" [colors]="colors" type="doughnut" [options]="options">
          </canvas>
        </div>
      </div>
      @if (showChange !== 'None' && showChange !== 'None-Below') {
        <div class="change-con">
          <div class="change-value-con">
            @if (data.change) {
              <app-svg-icon class="change-direction mr-2"
              [src]="'icons/' + (data.change > 0 ? 'change-up.svg' : 'change-down.svg')"></app-svg-icon>
            }
            <div class="caption gray change-value" [ngStyle]="{'color': data.change >= 0 ? '#0ac5a1' : '#d90056'}">
              {{data.change}}%
            </div>
          </div>
          <div class="caption gray">vs last quarter</div>
        </div>
      }
    </div>
  }
  @if (showLegend) {
    <div class="legend-con">
      @for (label of labels; track label; let i = $index) {
        <div class="legend-con-item-wrapper">
          <div class="legend-con-item-label">
            <span [style.background]="colors[i]" class="legend-con-item-legend"></span>
            {{label}}
          </div>
          <div class="legend-con-item-number">
            {{data.values[i]}}
          </div>
        </div>
      }
    </div>
  }
</div>
