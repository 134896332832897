import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { IPreviewDataConfig, IResponse } from './preview-box-dialog.model';
import { DataService } from 'src/app/services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { DIALOG_TYPES } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-preview-box-dialog',
    templateUrl: './preview-box-dialog.component.html',
    styleUrls: ['./preview-box-dialog.component.scss'],
    standalone: false
})
export class PreviewBoxDialogComponent implements OnInit {

  public data: IPreviewDataConfig;

  constructor(
    private util: UtilitiesService,
    private ds: DataService,
    private sanitizer: DomSanitizer,
    private weds: WaitErrorDialogsService
  ) { }

  ngOnInit(): void {
    this.getData(); 
  }

  get isImage(): boolean {
    return this.data?.referenceFile && /\.(jpg|jpeg|png)$/i.test(this.data?.referenceFile);
  }

  get isPDF(): boolean {
    return this.data?.referenceFile && /\.pdf$/i.test(this.data?.referenceFile);
  }

  openLink() {
    this.util.openUrlInNewTab(this.data?.url);
  }

  private getData() {
    if (this.data?.presignedUrl) {
      this.data.presignedUrl = this.isPDF ? this.sanitizer.bypassSecurityTrustResourceUrl(this.data.presignedUrl+'#toolbar=0') : this.data.presignedUrl;
      return;
    };
  
    const waitDialog = this.weds.showDialog({type: DIALOG_TYPES.WAIT, code: -2, dontCloseAllDialogs: true });
    const url = API_ENDPOINT.GET_FILE_USING_REFERENCE_PATH(this.ds.currentAdminClientId);
    const contentType = this.util.getContentType(this.data.referenceFile);
    this.ds.careerPrismDataPostApi(url, {fileName: this.data.referenceFile}, contentType).pipe(take(1)).subscribe((res: IResponse) => {
      this.weds.closeDialog(waitDialog);
      this.data.presignedUrl = this.isPDF ? this.sanitizer.bypassSecurityTrustResourceUrl(res.downloadURI+'#toolbar=0') : res.downloadURI;
    })
  }

}
