<div class="reports-center-settings-con">
  <div class="h3 black">Learning Settings</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      <!-- <div class="h4 black ">Types of Learning Elements</div>
      <div *ngIf="client" class="skill-categories-con admin-form">
        <app-editable-table #skillCategories [canEdit]="canEdit" [enableDelete]="true"
          class="sms-editable-table" [columns]="skillCategoriesColumns"
          [dataSource]="client.smsSettings.skillCategories"
          [footNotes]="'You can add maximum 7 Learning Elements Types. Drag and drop categories to change order'"
          [saveButtonText]="'Save'" (onSave)="onLearningElementsTypesSave($event)" [addButtonText]="'Add'"
          [enableDrag]="true" [maxRows]="7">
        </app-editable-table>
      </div> -->
      @for (smsOption of smsOptions; track smsOption) {
        <div
          [ngClass]="smsOption.type=='textarea'||smsOption.type=='sub-title' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display':smsOption.showControl?'block':'none'}">
          @if (smsOption?.type === 'sub-title') {
            <div class="h4 black sub-title">
              {{smsOption.title}}
            </div>
            @if(smsOption?.dividerRequired){
              <mat-divider class="mb-3"></mat-divider>
            }
          }
          @if (smsOption.type=='select'&&smsOption.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field" [matTooltip]="smsOption?.hoverText" [matTooltipDisabled]="!smsOption?.hoverText">
                <mat-label>{{smsOption.title}}</mat-label>
                @if (smsOption.icon) {
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                }
                <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                  (selectionChange)="onSMSOptionSelected(smsOption, $event)" [multiple]="smsOption.isMulti"
                  [compareWith]="compareSelect">
                  @for (option of smsOption.options; track option) {
                    <mat-option
                      [value]="option.value">
                      {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                    </mat-option>
                  }
                </mat-select>
                @if (editForm.get(smsOption?.key)?.invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (smsOption.type === 'text'&& smsOption?.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{smsOption.title}}</mat-label>
                <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                  [required]="smsOption.required" autocomplete="off"
                  maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                @if (editForm.get(smsOption?.key)?.invalid) {
                  <mat-error>{{smsOption.errorText||'This input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if(smsOption.type === 'searchText') {
            <div>
              <ng-select [items]="searchResults" [bindLabel]="smsOption.bindLabel" class="default-mat-search" [loading]="searchLoading"
                [bindValue]="smsOption.bindValue" [formControl]="editForm.get(smsOption.key)" [typeahead]="search" required>
                <ng-template ng-placeholder-tmp>
                  <mat-icon class="mt-0">search</mat-icon>
                  <span class="gray">Search for user</span>
                </ng-template>
                
                <ng-template ng-option-tmp let-item="item">
                  {{ item.empId }}
                </ng-template>
              </ng-select>
              @if (editForm?.get(smsOption.key)?.invalid) {
                <mat-error>{{smsOption.errorText||'This input is mandatory'}}</mat-error>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (client && (isMyLearningTreatmentV3 || myLearningTreatment === TREATMENT_MAP.V2)) {
      <app-learning-type-data-table [canEdit]="canEdit" [client]="client" (changeEvent)="leTypeDataTableFormValueChanges()"></app-learning-type-data-table>
    }
    @if (client && (myLearningTreatment === TREATMENT_MAP.V2)) {
      <app-learning-element-certifications-settings [canEdit]="canEdit" [client]="client" (onSave)="saveData()"></app-learning-element-certifications-settings>
    }
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
    @if (client && isMyLearningTreatmentV3) {
      <app-learning-type-wise-settings [canEdit]="canEdit" [client]="client" [config]="config" (onSave)="saveLearningElementActionsData($event)"></app-learning-type-wise-settings>
    }
  </form>
</div>