@if (!emptyForm) {
  <div class="skill-taxonomy-con">
    <div class="h4 black ">Skill Tooltip Settings</div>
    <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)"
      class="admin-form">
      <div class="admin-form-con">
        @for (smsOption of smsOptions; track smsOption) {
          <div
            class="admin-input-con-half">
            @if (smsOption.type=='text'&&smsOption.showControl) {
              <div>
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                  <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                    [required]="smsOption.required" autocomplete="off"
                    maxlength="{{ smsOption.maxLength ? smsOption.maxLength : '70' }}" />
                  @if (editForm.get(smsOption.key).invalid) {
                    <mat-error>{{smsOption.errorText||'This input is
                    mandatory'}}</mat-error>
                  }
                </mat-form-field>
              </div>
            }
            @if (smsOption.type=='textarea'&&smsOption.showControl) {
              <mat-form-field appearance="outline"
                class="admin-input-form-field admin-text-area-field">
                <mat-label>{{smsOption.title}}</mat-label>
                <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                <textarea matInput [placeholder]="smsOption.title" formControlName="{{smsOption.key}}"
                  cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
              </mat-form-field>
            }
            @if (smsOption.type=='select'&&smsOption.showControl) {
              <div>
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  @if (smsOption.icon) {
                    <mat-icon matPrefix>{{smsOption.icon}}</mat-icon>
                  }
                  <mat-select formControlName="{{smsOption.key}}" [required]="smsOption.required"
                    (selectionChange)="onSMSOptionSelected(smsOption, $event)"
                    [multiple]="smsOption.isMulti" [compareWith]="compareSelect">
                    @for (option of smsOption.options; track option) {
                      <mat-option
                        [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                        {{smsOption.displayKey?option[smsOption.displayKey]:option}}
                      </mat-option>
                    }
                  </mat-select>
                  @if (editForm.get(smsOption.key).invalid) {
                    <mat-error>{{smsOption.errorText||'This input is
                    mandatory'}}</mat-error>
                  }
                </mat-form-field>
              </div>
            }
            @if (smsOption.type=='date'&&smsOption.showControl) {
              <div>
                <mat-form-field appearance="outline" class="admin-input-form-field">
                  <mat-label>{{smsOption.title}}</mat-label>
                  <mat-icon matPrefix>date_range</mat-icon>
                  <input matInput placeholder="{{smsOption.title}}" formControlName="{{smsOption.key}}"
                    [matDatepicker]="picker" [required]="smsOption.required" autocomplete="off"
                    (focus)="picker.open()" />
                  <mat-datepicker #picker></mat-datepicker>
                  @if (editForm.get(smsOption.key).invalid) {
                    <mat-error>{{smsOption.errorText||'This input is
                    mandatory'}}</mat-error>
                  }
                </mat-form-field>
              </div>
            }
          </div>
        }
      </div>
      @if (canEdit) {
        <div class="admin-save-button-con">
          <button type="submit" mat-stroked-button class="primary-button">Save</button>
        </div>
      }
    </form>
  </div>
}