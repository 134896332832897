<div class="bg-color-beige overflow-hidden h-100">
  @if (title()) {
    <div class="bg-color-primary w-100 d-flex justify-content-between overflow-hidden">
      <div class="body-large color-color-white d-flex align-items-center">
        @if (icon()) {
          <mat-icon class="ic-18 mr-2">{{ icon() }}</mat-icon>
        }
        <span>{{ title() }}</span>
      </div>
      <mat-icon class="cursor-pointer" mat-dialog-close>close</mat-icon>
    </div>
  }

  <div class="dialog-container d-flex justify-content-between">
    @if (datatable()) {
      <app-datatable [config]="datatable()"></app-datatable>
    }
  </div>
</div>
