@if (user) {
  <div class="profile-img-con">
    @if (!user.profileImg) {
      <div class="profile-placeholder caption black"  [ngStyle]="{'background-color':user.bg}">
        <div [ngStyle]="{ 'font-size': fontSize ? fontSize + 'px' : 'inherit', 'color': fontColor ? fontColor : 'var(--color-black)' }">{{user.initials}}</div>
      </div>
    }
    @if (user.profileImg) {
      <img src="{{user.profileImg}}" class="profileImg" (click)="onClick()" />
    }
    @if (canEdit) {
      <div class="img-edit-con" (click)="onFileClick()">
        <mat-icon>edit</mat-icon>
      </div>
    }
  </div>
}
