<div class="avatar-settings-con">
  <app-settings
    [canEdit]="canEdit"
    [settingsOptionTitle]="settingsOptionTitle"
    [settingsOptions]="avatarSettingsOptions"
    [client]="client"
    (onSave)="handleSave($event)"
    >
    @if (hasAccessAvatar) {
      <div class="data-con">
        <mat-tab-group class="avatar-tab" mat-align-tabs="start">
          <mat-tab label="User Avatars">
            <app-avatar-list
              [enabledAvatars]="avatarsEnabledResponse"
              [disabledAvatars]="avatarsDisabledResponse"
              (deleteEvent)="onDeleteEvent($event)"
              (updateEvent)="onUpdateEvent($event)"
              (addAvatarEvent)="onAddAvatarEvent($event)"
              (paginationEvent)="onPaginationEvent($event)"
              (paginationDisbaledEvent)="onPaginationDisbaledEvent($event)"
              (enableDisableEvent)="onEnableDisableEvent($event)"
            ></app-avatar-list>
          </mat-tab>
        </mat-tab-group>
      </div>
    }
  </app-settings>
</div>
