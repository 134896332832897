<div class="no-data-con">
  <img class="no-data-icon" src="../../../../assets/nodata.png" alt="No skills">
  <div class="no-data-con2">
    @if (!!data()?.title) {
      <div class="h5 gray">{{data?.title}}</div>
    }
    @if (!!data()?.message) {
      <div class="caption gray">{{data.message}}</div>
    }
  </div>
</div>
