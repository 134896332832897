<div class="group-card rev-level-{{maxNestingLevel}}" [formGroup]="groupForm">
  <div class="group-buttons d-flex mb-3 gap-2">
    @if (maxNestingLevel > 1) {
      <button mat-stroked-button class="btn teritiary-button fs-12"
        (click)="addCondition()"><mat-icon class="mr-1 ic-16">add</mat-icon>ADD CONDITION</button>
      }
      <!-- <button mat-stroked-button *ngIf="maxNestingLevel > 1" class="btn teritiary-button fs-12"
      (click)="addSubGroup()"><mat-icon class="mr-1 ic-16 mt-2">add</mat-icon>ADD SUB-GROUP</button> -->
      @if (conditions && conditions.length > 1) {
        <button mat-icon-button class="btn ml-auto"
          (click)="removeGroup(currentParentIndex)">
          <mat-icon class="ic-16">close</mat-icon>
        </button>
      }
    </div>
    <!-- Conditions and nested groups -->
    <div formArrayName="conditions">
      @for (subgroup of conditions.controls; track subgroup; let j = $index) {
        @if (subgroup.get('type').value === 'condition') {
          <ng-container
          *ngTemplateOutlet="conditionTemplate; context: { group: subgroup, index: j }"></ng-container>
        }
        @if (subgroup.get('type').value === 'group') {
          <app-search-user-group [groupForm]="subgroup" [maxNestingLevel]="maxNestingLevel - 1" [tagsList]="tagsList"
            [parentArray]="conditions" [currentParentIndex]="j" [filterDropDownData]="filterDropDownData">
          </app-search-user-group>
        }
        <!-- Logic Operator Toggle Button between conditions/groups -->
        @if (j < conditions.length - 1) {
          <div class="logic-operator my-3">
            <button mat-stroked-button class="btn primary-button" mat-icon-button (click)="toggleAndOr(groupForm)">
              <mat-icon>repeat</mat-icon>
              {{ groupForm.get('logicOperator')?.value || 'AND' }}
            </button>
          </div>
        }
      }
    </div>
  </div>
  <!-- Condition Template -->
  <ng-template #conditionTemplate let-group="group" let-index="index">
    <div class="condition-card data-con flex-wrap">
      <ng-select class="flex-fill" style="max-width: 180px;width: 150px;" (change)="onTagSelectChange($event, group)"
        [clearable]="false" [formControl]="group.get('tag')" bindLabel="label" bindValue="type"
      [items]="accomplishments" placeholder="Select Filter Type" [multiple]="false"></ng-select>
      <ng-select class="flex-fill" style="max-width: 280px;width: 255px;" [formControl]="group.get('searchValue')"
        [items]="getItemsForTag(group.get('tag')?.value)" bindLabel="title" bindValue="id" [multiple]="true"
        [clearable]="false" [typeahead]="search$" [trackByFn]="trackByFn" placeholder="Choose from below options"
        [virtualScroll]="true" [loading]="isLoading(group.get('tag')?.value)"
        [closeOnSelect]="true"
        (scrollToEnd)="onScrollToEndAccomplishments(group.get('tag')?.value)" (search)="onSearch($event, group)">
      </ng-select>
      <button mat-icon-button class="btn teritiary-button ml-auto" (click)="removeConditionOrGroup(index)">
        <mat-icon class="ic-16">close</mat-icon>
      </button>
    </div>
  </ng-template>