@if (goal?.type) {
  <div class="mt-3 data-con goal-entity" [ngClass]="{'min-h-fit': ['SPECIALIZATION', 'ROLE'].includes(goal?.type)}" >
    <div class="header-sec d-flex justify-content-between item-center">
      <div class="name-icon d-flex item-center gap-2">
        <div class="icon-sec item">
          <button class="btn item-center" [ngStyle]="{'background-color': getBGColorForGoalStatus(goal.goalStatus), 'color': getColorForGoalStatus(goal.goalStatus)}">
            <mat-icon>{{goal.icon}}</mat-icon>
          </button>
        </div>
        <div class="title-text h3">{{ goal.title }}</div>
      </div>
      @if ( masterGoalData?.skillCategory?.name) {
        <div class="btn skill-hierarchy item-center">{{ masterGoalData.skillCategory.name }}</div>
      }
    </div>
    @if (!['SPECIALIZATION', 'ROLE'].includes(goal?.type)) {
      <div class="divider mt-3 mb-3"></div>
    }
    @if (goal.type.toUpperCase() === 'SKILL') {
      <div class="skill-group mb-3">
        <div class="name mb-1">SKILL GROUP</div>
        <div class="value">{{ masterGoalData?.skillHierarchy?.[0]?.name }}</div>
      </div>
      <div class="skill-sub-group mb-3">
        <div class="name mb-1">SKILL SUB-GROUP</div>
        <div class="value">{{ masterGoalData?.skillHierarchy?.[1]?.name }}</div>
      </div>
      <div class="rating">
        <div class="body p-3 d-flex justify-content-between">
          <div class="current d-flex flex-column">
            <div class="name mb-1">CURRENT RATING </div>
            <div class="value gap-1"><mat-icon>star</mat-icon>{{ goal.currentValue }}</div>
          </div>
          <div class="arrow item-center"><mat-icon>east</mat-icon></div>
          <div class="target d-flex flex-column">
            <div class="name mb-1">TARGET RATING</div>
            <div class="value gap-1"><mat-icon>star</mat-icon>{{ goal.requiredValue }}</div>
          </div>
        </div>
      </div>
    }
    @if (goal.type.toUpperCase() === 'COURSE') {
      <div class="cours-body">
        <app-progress-card [data]="courseData" [isHoverPopupEnabled]="false"></app-progress-card>
        <div class="skills-sec mt-3 d-flex gap-2">
          @for (skill of skills; track skill) {
            <div class="btn item-center">{{skill}}</div>
          }
        </div>
      </div>
    }
    @if (goal.type.toUpperCase() === 'CERTIFICATION') {
      <div class="cours-body">
        <app-progress-card [data]="certificationData" [isHoverPopupEnabled]="false"></app-progress-card>
        <div class="skills-sec mt-3 d-flex gap-2">
          @for (skill of skills; track skill) {
            <div class="btn item-center">{{skill}}</div>
          }
        </div>
      </div>
    }
    @if (['SPECIALIZATION', 'ROLE'].includes(goal.type) && false) {
      <!-- hiding table for spec & role for now-->
      @if (dataSourceMandatory?.data?.length) {
        <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Mandatory', dataSource: dataSourceMandatory, displayedColumns: displayedColumns}"></ng-container>
      }
      @if (dataSourceCondtionalMandatory?.data?.length) {
        <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Conditional Mandatory', hasBadge: true, dataSource: dataSourceCondtionalMandatory, displayedColumns: displayedColumns}"></ng-container>
      }
      @if (dataSourceOptional?.data?.length) {
        <ng-container *ngTemplateOutlet="tableTemplate; context: {title: 'Optional', dataSource: dataSourceOptional, displayedColumns: displayedColumns}"></ng-container>
      }
    }
    @if (goal.type.toUpperCase() === 'CUSTOM') {
    }
  </div>
}

<ng-template #tableTemplate let-dataSource="dataSource" let-title="title" let-hasBadge="hasBadge" let-displayedColumns="displayedColumns">
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="demo-table w-100 mt-4 mb-20">
      <ng-container matColumnDef="skill">
        <th mat-header-cell *matHeaderCellDef>{{ title | uppercase }} SKILLS (2/4)</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon>electric_bolt</mat-icon>
          {{ element.skillName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="current">
        <th mat-header-cell *matHeaderCellDef>CURRENT</th>
        <td mat-cell *matCellDef="let element">{{ element.currentRating }}</td>
      </ng-container>
      <ng-container matColumnDef="expected">
        <th mat-header-cell *matHeaderCellDef>EXPECTED</th>
        <td mat-cell *matCellDef="let element">{{ element.expectedRating }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      @if (!hasBadge) {
        <tr
          mat-row
          (click)="clickedRows.add(row)"
          [class.demo-row-is-clicked]="clickedRows.has(row)"
          *matRowDef="let row; columns: displayedColumns;"
        ></tr>
      }
    </table>
  </div>
  @if (hasBadge) {
    <div class="table-container">
      <div class="badge-container">
        <div class="badge item-center">{{dynamicBadgeText}}</div>
        <table mat-table [dataSource]="dataSource" class="demo-table w-100 mb-1">
          <ng-container matColumnDef="skill">
            <td mat-cell *matCellDef="let element">
              <mat-icon>electric_bolt</mat-icon>
              {{ element.skillName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="current">
            <td mat-cell *matCellDef="let element">{{ element.currentRating }}</td>
          </ng-container>
          <ng-container matColumnDef="expected">
            <td mat-cell *matCellDef="let element">{{ element.expectedRating }}</td>
          </ng-container>
          <tr
            mat-row
            (click)="clickedRows.add(row)"
            [class.demo-row-is-clicked]="clickedRows.has(row)"
            *matRowDef="let row; columns: displayedColumns;"
          ></tr>
        </table>
      </div>
    </div>
  }
</ng-template>
