import { Component, ViewChild } from '@angular/core';
import { AdvancedSearchFormComponent } from './advanced-search-form/advanced-search-form.component';
import { PrismSearchService } from '../prism-search.service';
import { STORAGE_CONSTANTS } from 'src/app/constants/storage.constants';
import { ITagsConfigMap } from '../prism-search.model';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-advanced-search-modal',
  templateUrl: './advanced-search-modal.component.html',
  styleUrls: ['./advanced-search-modal.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    AdvancedSearchFormComponent
  ]
})
export class AdvancedSearchModalComponent {
  @ViewChild(AdvancedSearchFormComponent) advancedSearchFormComponent: AdvancedSearchFormComponent;

  public tagsConfigMap: ITagsConfigMap = this.prismSearchService.tagsConfigMap;

  private readonly IN_BETWEEN_OPERATORS = 'range';

  //------------------------------------------------------------------
  // Constructor
  //------------------------------------------------------------------

  constructor(private prismSearchService:  PrismSearchService) {}

  //------------------------------------------------------------------
  // Public Methods
  //------------------------------------------------------------------

  public generateQueryAndSearch() {
    const formVal = this.advancedSearchFormComponent.searchForm.value;
    localStorage.setItem(STORAGE_CONSTANTS.ADVANCED_SEARCH_FORMATTED_QUERY, JSON.stringify(formVal));
    const result = this.mapGroup(formVal);
    this.prismSearchService.navigateToPrismSearch(result, true);
  }

  //------------------------------------------------------------------
  // Private Methods
  //------------------------------------------------------------------

  private mapGroup(group: any): any {
    const groupOperator = group.logicOperator === 'AND' ? 'and' : 'or';

    const groupOutput: any = {
      groupOperator: groupOperator,
      value: group.conditions.map((condition: any) => {
        if (condition.type === 'condition') {
          return this.mapCondition(condition);
        } else if (condition.type === 'group') {
          return this.mapGroup(condition);
        }
      }).flat()
    };
    return groupOutput;
  }
  private mapCondition(condition: any): any[] {
    const tag = condition.tag.toLowerCase();
    const operator = this.mapOperator(condition);

    if (tag === 'skill') {
      return this.handleSkillCondition(condition);
    }
    if(tag === 'allocation') {
      return this.handleAllocationCondition(condition);
    }

    return [
      {
        [tag]: {
          operator: operator,
          value: Array.isArray(condition.searchValue) ? condition.searchValue : [condition.searchValue],
        },
      },
    ];
  }

  private handleAllocationCondition(condition: any): any {
    const allocationFields = ['pool-code', 'allocation-percentage', 'allocation-date'];
    const allocationConditionsOutput: any[] = [];

    allocationFields.forEach((field) => {      
      if (field === 'pool-code' && condition.searchValue) {
        condition.tag = 'pool-code';
        const value = Array.isArray(condition.searchValue) ? condition.searchValue : [condition.searchValue];
        if (value.length > 0 && value.some((v: any) => v != null && v !== '')) {
          allocationConditionsOutput.push({
            [field]: {
              operator: this.mapOperator(condition),
              value: value,
            },
          });
        }
      }
      else if (field === 'allocation-percentage' && condition.allocationPercentage != null && condition.allocationPercentage !== '') {
        condition.tag = 'allocation-percentage';
        const output: any = {
          [field]: {
            operator: this.mapOperator(condition),
            value: condition.allocationPercentage,
          },
        };
        if (condition.conditionType === 'in between' && condition.allocationPercentage2 != null) {
          output[field].secondaryValue = condition.allocationPercentage2;
          allocationConditionsOutput.push(output);
        } else if (condition.conditionType !== 'in between') {
          allocationConditionsOutput.push(output);
        }
      }
      else if (field === 'allocation-date' && (condition.startDate || condition.endDate)) {
        condition.tag = 'allocation-date';
        const dateConditions = this.handleDateCondition(condition);
        if (dateConditions.length > 0) {
          allocationConditionsOutput.push(...dateConditions);
        }
      }
    });
    
    return allocationConditionsOutput;
  }
  
  private handleSkillCondition(condition: any): any[] {
    const tag = condition.tag.toLowerCase();
    const operator = this.mapOperator(condition);

    const conditionOutput = {
      [tag]: {
        operator: operator,
        value: Array.isArray(condition.searchValue) ? condition.searchValue : [condition.searchValue],
      },
    };

    const conditionResults = [conditionOutput];

    if (condition.conditionType === 'in between') {
      this.addSkillRating(condition, conditionResults, this.IN_BETWEEN_OPERATORS);
    } else if (condition.proficiencyLevel !== 'ALL') {
      const tagOperatorMapping =
        this.advancedSearchFormComponent?.tagValuesAPIResposne?.tagOperatorMapping?.['skillRating'] || [];
      const operatorMapping = tagOperatorMapping.find((op: any) => op.text === condition.conditionType);
      this.addSkillRating(condition, conditionResults, operatorMapping?.operator);
    }

    return conditionResults;
  }

  private handleDateCondition(condition: any): any[] {
    const startDate = !!condition.startDate && typeof condition.startDate === 'string' ? condition.startDate : (condition.startDate ? this.getISOString(condition.startDate) : null);
    const endDate = !!condition.endDate && typeof condition.endDate === 'string' ? condition.endDate : (condition.endDate ? this.getISOString(condition.endDate) : null);

    const dateConditions: any[] = [];

    let tag = condition.tag.toLowerCase();
    if(tag === 'allocation') {
      tag = 'allocation-date';
    }
    const tagOperatorMapping = this.advancedSearchFormComponent?.tagValuesAPIResposne?.tagOperatorMapping?.[tag]?.[0];

    if (startDate) {
      dateConditions.push({
        [tagOperatorMapping?.datekeys?.start]: {
          operator: '>=',
          value: startDate,
        },
      });
    }

    if (endDate) {
      dateConditions.push({
        [tagOperatorMapping?.datekeys?.end]: {
          operator: '<=',
          value: endDate,
        },
      });
    }

    return dateConditions;
  }

  private addSkillRating(condition: any, conditionResults: any[], operator: string) {
    const skillRating: any = {
      operator: operator,
      value: condition.proficiencyLevel
    };
    if(operator === this.IN_BETWEEN_OPERATORS) {
     skillRating.secondaryValue = condition.proficiencyLevel2;
    }
    conditionResults[0].skillRating = skillRating;
  }

  private mapOperator(condition: any): string {
    const tag = condition.tag.toLowerCase();
    const operatorText = condition.conditionType;

    const tagOperatorMapping = this.advancedSearchFormComponent?.tagValuesAPIResposne?.tagOperatorMapping?.[tag] || [];
    const operatorMapping = tagOperatorMapping.find((op: any) => op.text === operatorText);

    return operatorMapping ? operatorMapping.operator : 'in';
  }

  private getISOString(date: Date): string {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000)).toISOString();
  }
}
