<div class="avatar-container">
  <div class="enabled mt-4">
    <div class="header-sec d-flex justify-content-between">
      <div class="title h3 fs-16 mb-4">Enabled Avatars</div>
      <input type="file" (change)="onFileSelectedFromUser($event, fileInput)" accept="image/png, image/jpeg" id="fileInput"  style="visibility: hidden;" #fileInput>
      <div class="action-btn">
        <button mat-stroked-button class="primary-button" (click)="fileInput.click()">
          <mat-icon class="white">add</mat-icon>
          <span>ADD AVATAR</span>
        </button>
      </div>
    </div>
    <div class="body-sec d-flex flex-wrap gap-32">
      @for (avatar of enabledAvatars?.data?.avatars; track avatar) {
        <div (click)="onAvatarClick(avatar)">
          <img [src]="getAvatarUrl(avatar)" alt="Avatar Image">
        </div>
      }
    </div>
    <app-paginator-ui #paginator (onPaginationEvent)="onPaginationEvent($event)" [data]="paginatorDataEnabled" itemTitle="enabled avatars">
    </app-paginator-ui>
    @if (!enabledAvatars?.data?.avatars?.length) {
      <div class="no-disabled mt-32">
        There are no enabled avatars.
      </div>
    }
  </div>
  <hr class="mb-40 mt-40">
  <div class="disabled">
    <div class="header-sec d-flex justify-content-between">
      <div class="title h3 fs-16 mb-4">Disabled Avatars</div>
    </div>
    <div class="body-sec d-flex flex-wrap gap-32">
      @for (avatar of disabledAvatars?.data?.avatars; track avatar) {
        <div (click)="onAvatarClick(avatar)">
          <img [src]="getAvatarUrl(avatar)" alt="Avatar Image">
        </div>
      }
      <app-paginator-ui #paginatorDisabled class="w-100" (onPaginationEvent)="onPaginationEventDisabled($event)" [data]="paginatorDataDisabled" itemTitle="disabled avatars">
      </app-paginator-ui>
      @if (!disabledAvatars?.data?.avatars?.length) {
        <div class="no-disabled">
          There are no disabled avatars.
        </div>
      }
    </div>
  </div>
</div>
