import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, Signal, signal, WritableSignal } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  imports: [
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    CommonModule
  ]
})
export class PaginatorComponent {
  public totalCount = input<number>(0);
  public disableLimit = input<boolean>(false);
  public title = input<string>('');
  public refresh = input<boolean>(false);
  public defaultLimit = input<number>(10);

  public limit: WritableSignal<number> = signal(this.defaultLimit());
  public pageNumber: WritableSignal<number> = signal(1);
  public pageOptions: WritableSignal<number[]> = signal([5, 10, 20, 50, 100]);

  public endOfRange: Signal<number> = computed(() => Math.min(this.pageNumber() * this.limit(), this.totalCount()));
  public startOfRange : Signal<number>= computed(() => Math.max((this.pageNumber() - 1) * this.limit() + 1, 1));
  public pages: Signal<number[]> = computed(() => {
    const pageNumber = this.pageNumber();
    const totalCount = this.totalCount();
    const limit = this.limit();

    let pages = [pageNumber];
    if (pageNumber > 1) pages.splice(0, 0, pageNumber - 1);
    if (pageNumber + 1 <= Math.ceil(totalCount / limit)) pages.push(pageNumber + 1);
    if (pages.length < 3 && pageNumber + 2 <= Math.ceil(totalCount / limit)) pages.push(pageNumber + 2);
    else if (pages.length < 3 && pageNumber > 2) pages.splice(0, 0, pageNumber - 2);

    return pages;
  });

  constructor() {
    effect(() => {
      if (this.refresh()) {
        this.limit.set(this.defaultLimit());
        this.pageNumber.set(1);
      }
    });
  }
  
  public onLimitChange(event: MatSelectChange): void {
    this.pageNumber.set(1);
    this.limit.set(event.value);
  }

  public onPageNumberClick(page: number): void {
    if (this.pageNumber() == page) return;
    this.pageNumber.set(page);
  }

  public onNextPrev(type: string): void {
    if (type == 'first') this.pageNumber.set(1);
    if (type == 'next') this.pageNumber.set(this.pageNumber() + 1);
    if (type == 'prev') this.pageNumber.set(this.pageNumber() - 1);
    if (type == 'last') this.pageNumber.set(Math.ceil(this.totalCount() / this.limit()));
  }
}
