import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-generic-multilevel-select',
    templateUrl: './generic-multilevel-select.component.html',
    styleUrls: ['./generic-multilevel-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GenericMultilevelSelectComponent),
            multi: true
        }
    ],
    standalone: false
})
export class GenericMultilevelSelectComponent implements ControlValueAccessor {
  @Input() options: any[] = [];
  @Input() minWidth: number = 150;
  @Input() dropdownPosition: string = 'bottom';
  @Output() optionSelected = new EventEmitter<any>();

  public dropdownVisible: boolean = false;
  public selectedOption: any = null;
  public hoveredOption: any = null;
  public subOptionsVisible: boolean = false;
  public subMenuLeftPosition: number = 0;

  // ControlValueAccessor methods
  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private elRef: ElementRef, private renderer: Renderer2) {
    this.renderer.listen('document', 'click', (event) => {
      if (!this.elRef.nativeElement.contains(event.target)) {
        this.dropdownVisible = false;
      }
    });
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
  }

  showSubMenu(option: any): void {
    this.hoveredOption = option;
    this.subOptionsVisible = true;
    const dropdownElement = this.elRef.nativeElement.querySelector('.dropdown-item:hover');
    if (dropdownElement) {
      const rect = dropdownElement.getBoundingClientRect();
      this.subMenuLeftPosition = rect.width;
    }
  }

  hideSubMenu(): void {
    this.subOptionsVisible = false;
    this.hoveredOption = null;
  }

  keepDropdownOpen(): void {
    this.dropdownVisible = true;
  }

  keepSubMenuOpen(): void {
    this.subOptionsVisible = true;
  }

  onOptionSelect(option: any): void {
    this.selectedOption = option;
    this.dropdownVisible = false;
    this.subOptionsVisible = false;
    this.onChange(option.value);
    this.optionSelected.emit(option);
  }

  onSubOptionSelect(subOption: any): void {
    this.selectedOption = subOption;
    this.dropdownVisible = false;
    this.subOptionsVisible = false;
    this.onChange(subOption.value);
    this.optionSelected.emit(subOption);
  }

  writeValue(value: any): void {
    this.selectedOption = this.options.find(opt => opt.value === value.toLowerCase() );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Handle disabled state if necessary
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(event: MouseEvent): void {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.dropdownVisible = false;
    }
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
    this.subOptionsVisible = false;
  }
}
