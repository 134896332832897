import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { SMS_OPTIONS, ACCESS_LEVELS, TAG_FILTER_ENABLED_MODALS } from "../../../../constants";
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ValidateString } from 'src/app/services/validation.service';
import { EditableTableComponent } from 'src/app/modules/common-components/editable-table/editable-table.component';
import { IUserSpecializationTags } from './specializations-settings.model';

@Component({
    selector: 'app-specializations-settings',
    templateUrl: './specializations-settings.component.html',
    styleUrls: ['./specializations-settings.component.scss'],
    standalone: false
})
export class SpecializationsSettingsComponent implements OnInit {
  private _client: Client;
  @ViewChild('userSpecTags') userSpecTagsDataTable: EditableTableComponent;
  @Input() canEdit: boolean = false;
  @Output() onSave = new EventEmitter();
  editForm: FormGroup;
  arr30 = Array.from({ length: 30 }, (_, i) => i + 1);
  public userSpecializationTagsConfig = {
    canEdit: this.canEdit,
    enableDelete: true,
    footNotes: 'Add at least one specialization tag to enable the feature, max 10',
    addButtonText: 'Add',
    enableDrag: false,
    maxRows: 10,
    columns: [
      { title: 'Specialization Tags', key: 'name', type: 'text',
        disabled: false, required: true,
        validators: [Validators.required, ValidateString(2)],
        placeholder: 'E.g.: Best Match on Demand, Best Match on Pricing, etc.'
      }
    ],
  }
  public userSpecializationTags: IUserSpecializationTags[] = [];
  private userSpecTagsFormValid: boolean = true;  
  smsOptions: any = [
    { title: 'Master Controls', type: 'sub-title' },
    { title: 'Specialization Mode', key: 'specializationMode', type: 'select', options: [SMS_OPTIONS.STANDARD, SMS_OPTIONS.SKILL_CLUSTER_BASED], validators: [Validators.required], icon: "settings_suggest", defaultValue: 'Standard' },
    { title: 'Tier Type', key: 'specializationTierType', type: 'select', options: [SMS_OPTIONS.DESIGNATION, SMS_OPTIONS.DESIGNATION_LEVEL], isMulti: false, validators: [], icon: 'settings_suggest', checkValue: [{ key: 'specializationMode', values: ['Skill Cluster Based'] }] },
    { title: 'Tier Matching Logic', key: 'tierMatchingLogic', type: 'select', options: Object.values(SMS_OPTIONS.TIER_MATCHING_LOGIC_OPTIONS), isMulti: false, validators: [], icon: 'settings_suggest', checkValue: [{ key: 'specializationMode', values: ['Skill Cluster Based'] }, { key: 'skillClusterForManageLearnings', values: [SMS_OPTIONS.YES] }, { key: 'specializationTierType', values: ['Designation'] }] },
    { title: 'Use additional master fields?', key: 'useAdditionalMasterFields', type: 'select', options: [SMS_OPTIONS.PRE_REQUISITE_SPECIALIZATIONS, SMS_OPTIONS.SPECIALIZATION_PROGRAM, SMS_OPTIONS.LEVEL_OF_SPECIALIZATION], isMulti: true, validators: [], icon: "settings_suggest" },
    { title: 'Level Of Specialization Count', key: 'levelOfSpecializationCount', type: 'select', options: Array.from({ length: 10 }, (_, i) => (i + 1).toString()), isMulti: false, validators: [], icon: "settings_suggest", checkValue: [{ key: 'useAdditionalMasterFields', values: ['Level Of Specialization'] }] },
    { title: 'Use additional skill fields', key: 'useAdditionalSkillFields', type: 'select', options: [SMS_OPTIONS.QUALIFICATION_RATING_TYPES, SMS_OPTIONS.QUALIFICATION_RATING, SMS_OPTIONS.EXIT_RATING_TYPES], isMulti: true, validators: [], icon: "settings_suggest" },
    { title: 'Specialization Type Tagging Enabled', key: 'specializationTypeTagging', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Use versioning?', key: 'useVersioning', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Use Skill Cluster Based Insights?', key: 'useSkillClusterBasedInsights', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Use Tag Filtering on the basis of', key: 'useTagFilteringOnTheBasisOf', type: 'select', options: [SMS_OPTIONS.ASSIGNEE, SMS_OPTIONS.ASSIGNER], validators: [Validators.required], icon: "settings_suggest", defaultValue: 'Assignee', checkValue: [{ key: 'specializationMode', values: [SMS_OPTIONS.SKILL_CLUSTER_BASED] }, { key: 'skillClusterForManageLearnings', values: [SMS_OPTIONS.YES] }, { key: 'tagFiltersEnabledFor', values: [TAG_FILTER_ENABLED_MODALS.MANAGE_LEARNINGS] }] },

    { title: 'Assignment Controls', type: 'sub-title' },
    { title: 'Number of active specializations per employee', key: 'numActiveSpecializations', type: 'select', options: this.arr30, validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Specialization Category Tagging Enabled', key: 'specializationCategoryTagging', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Auto Populate Skills of Requested Specialization', key: 'autoPopulateRequestedSpecializationSkills', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationCategoryTagging', values: [SMS_OPTIONS.YES] }] },
    { title: 'Number of active primary specializations per employee', key: 'numActivePrimarySpecializations', type: 'select', options: [0, ...this.arr30], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationCategoryTagging', values: [SMS_OPTIONS.YES] }] },
    { title: 'Number of active secondary specializations per employee', key: 'numActiveSecondarySpecializations', type: 'select', options: [0, ...this.arr30], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationCategoryTagging', values: [SMS_OPTIONS.YES] }] },
    { title: 'Use Skill Cluster for Manage Learnings', key: 'skillClusterForManageLearnings', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationMode', values: ['Skill Cluster Based'] }] },

    { title: 'User Specialization Tags', type: 'sub-title' },
    { title: 'Enable User Specialization Tags', key: 'isUserSpecializationTagsEnabled', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest", defaultValue: SMS_OPTIONS.NO},

    { title: 'User Controls', type: 'sub-title' },
    { title: 'Show All Specializations', key: 'showAllSpecialisations', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Specialization Match Score Enabled', key: 'specializationMatchScoreEnabled', type: 'select', options: [SMS_OPTIONS.MATCH_SCORE, SMS_OPTIONS.BINARY, SMS_OPTIONS.NO], isMulti: true, icon: "settings_suggest" },
    { title: 'Show Trending Specializations', key: 'showTrendingSpecialiations', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Auto Start Pre-Selected Specialization', key: 'autoStartPreSelectedSpec', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Enable Top Pick Suggestions', key: 'topPicksEnabled', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Enable Suggestions on Home Page', key: 'specializationSuggestionsOnHomePage', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Allow Specialization Requests', key: 'specializationRequestsEnabled', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'No. of days after which user can request same specialization again', key: 'coolingOffPeriodForRejectedSpecializations', type: 'number', validators: [Validators.required, Validators.min(0), Validators.max(9999)], icon: "settings_suggest", checkValue: [{ key: 'specializationRequestsEnabled', values: [SMS_OPTIONS.YES] }] },
    { title: 'Allow Direct Specialization Assignments', key: 'allowDirectSpecializationAssignments', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationRequestsEnabled', values: [SMS_OPTIONS.YES] }], defaultValue: SMS_OPTIONS.NO },
    { title: 'Prevent assignment when no skill gaps', key: 'checkSkillGapsForSpecializationAssignment', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Allow user to drop specializations', key: 'dropSpecializationsEnabled', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest" },
    { title: 'Allow user to drop primary specializations', key: 'dropPrimarySpecializationsEnabled', type: 'select', options: [SMS_OPTIONS.YES, SMS_OPTIONS.NO], validators: [Validators.required], icon: "settings_suggest", checkValue: [{ key: 'specializationCategoryTagging', values: [SMS_OPTIONS.YES] }, { key: 'dropSpecializationsEnabled', values: [SMS_OPTIONS.YES] }] },
    { title: 'No. of days the drop option should be available after assigning ', key: 'numDaysDropSpecialization', type: 'number', validators: [Validators.required, Validators.min(0), Validators.max(9999)], icon: "settings_suggest", checkValue: [{ key: 'dropSpecializationsEnabled', values: [SMS_OPTIONS.YES] }] },
    { title: 'Allow categories for Specialization Requests', key: 'categoriesEnabledForSpecializationRequests', type: 'select', options: [SMS_OPTIONS.PRIMARY, SMS_OPTIONS.SECONDARY], validators: [Validators.required], icon: "settings_suggest", isMulti: true, checkValue: [{ key: 'specializationCategoryTagging', values: [SMS_OPTIONS.YES] }, { key: 'specializationRequestsEnabled', values: [SMS_OPTIONS.YES] }] },
    { title: 'Use Tag Based Filtering On', key: 'tagFiltersEnabledFor', type: 'select', options: Object.values(TAG_FILTER_ENABLED_MODALS), icon: "settings_suggest", isMulti: true, checkValue: [{ key: 'specializationMode', values: [SMS_OPTIONS.SKILL_CLUSTER_BASED] }, { key: 'skillClusterForManageLearnings', values: [SMS_OPTIONS.YES] }] },
    
    { title: 'Learning Controls', type: 'sub-title' },
    { title: 'Text to be shown on the button', key: 'textToBeShownOnButton', type: 'text', validators: [], icon: "settings_suggest" },
    { title: 'Text to be shown on the information icon', key: 'textToBeShownOnInfoIcon', type: 'text', validators: [], icon: "settings_suggest" },
    { title: 'URL to be hyperlinked to the button', key: 'urlToBeHyperlinkedToButton', type: 'text', validators: [], icon: "settings_suggest" },  
  ];
  constructor(private ds: DataService, private weds: WaitErrorDialogsService, private fb: FormBuilder, public util: UtilitiesService) {
    this.editForm = fb.group({});
  }

  ngOnInit(): void {
    this.updateUserSpecializationTagsConfig();
  }

  @Input()
  set client(client: Client) {
    this._client = client;
    this.buildForms();
  }
  get client() {
    return this._client;
  }

  buildForms() {
    this.smsOptions.map((s) => {
      let currentValue = this.editForm.value;
      let value = currentValue[s.key] || this.client.smsSettings[s.key] || s.defaultValue;
      s.showControl = true;
      (s.allowIf || []).map((key) => { if ((this.client.features.admin[key] || ACCESS_LEVELS.NO_ACCESS) == ACCESS_LEVELS.NO_ACCESS) s.showControl = false; });
      (s.checkValue || []).map((item) => {
        const itemValue = this.editForm.contains(item.key) ? this.editForm.get(item.key).value : this.client.smsSettings[item.key]
        if (Array.isArray(itemValue) ? !item.values.some((val) => itemValue.includes(val)) : item.values.indexOf(itemValue) < 0) s.showControl = false;
      });
      if (this.editForm.contains(s.key)) this.editForm.removeControl(s.key);
      if (s.showControl) this.editForm.addControl(s.key, new FormControl({ value: value, disabled: !this.canEdit }, s.validators));
    });
    this.userSpecializationTags = this.client.smsSettings?.userSpecializationTags || [];
  }

  public onUserSpecTagsValidityChange(isValid: boolean) {
    this.userSpecTagsFormValid = isValid;
  }

  public addUserSpecTagsDataTableRow() {
    this.userSpecTagsDataTable.addRow();
  }

  public get maxUserSpecTagsReached(): boolean {
    return this.userSpecTagsDataTable?.dataSource?.length >= this.userSpecializationTagsConfig.maxRows;
  }

  public getErrorMessage(controlKey: string): string {
    const control = this.editForm?.get(controlKey);
    if (control?.hasError('required')) {
      return 'This field is required.';
    }
    if (control?.hasError('min')) {
      return `The value must be at least 0`;
    }
    if (control?.hasError('max')) {
      return `The value must not exceed 9999`;
    }
    return '';
  }

  saveData() {
    if (this.editForm.invalid || !this.userSpecTagsFormValid) return;
    const d = this.editForm.value;
    if (!this.handleUserSpecializationTags(d)) return;
    const {
      numActivePrimarySpecializations = 0,
      numActiveSecondarySpecializations = 0,
      numActiveSpecializations = 0,
      urlToBeHyperlinkedToButton
    } = d;

    const isNumbActiveSpecIsSynced = (numActiveSpecializations >= Math.max(numActivePrimarySpecializations, numActiveSecondarySpecializations)) && (numActiveSpecializations <= (numActivePrimarySpecializations + numActiveSecondarySpecializations));

    if (
      d.useAdditionalSkillFields?.indexOf(SMS_OPTIONS.QUALIFICATION_RATING_TYPES) >= 0 &&
      d.useAdditionalSkillFields?.indexOf(SMS_OPTIONS.QUALIFICATION_RATING) < 0
    ) {
      this.weds.showDialog({
        type: 'generic',
        title: 'Error',
        message:
          'Qualification Rating Types cannot be enabled without enabling Qualification Rating',
        btnTitle: 'Ok',
      });
    } else if (!isNumbActiveSpecIsSynced && numActivePrimarySpecializations && numActiveSecondarySpecializations) {
      this.weds.showDialog({
        type: 'generic',
        title: 'Error',
        message:
          'Number of active specializations per employee should be 1. Less than or equal to the Sum of Number of active primary and secondary specializations per employee and 2. Greater than or equal to Max of Number of active primary and secondary specializations per employee',
        btnTitle: 'Ok',
      });
    } else if (urlToBeHyperlinkedToButton && !this.util.isValidUrl(urlToBeHyperlinkedToButton)) {
      this.weds.showDialog({
        type: 'generic',
        title: 'Invalid URL',
        message: 'Please enter a valid URL for the hyperlink',
        btnTitle: 'Ok',
      });
    } else {
      this.onSave.emit({ ...d, type: 'specializations' });
    }
  }

  compareSelect(o1: any, o2: any) {
    let keys = ['id', 'categoryId'];
    for (let i = 0; i < keys.length; i++) {
      if ((o1 && o1[keys[i]]) || (o2 && o2[keys[i]])) {
        return (o1 || {})[keys[i]] == (o2 || {})[keys[i]];
      }
    }
    return o1 == o2;
  }

  onSMSOptionSelected(smsOption, event) {
    const fields = [
      'specializationMode',
      'specializationCategoryTagging',
      'dropSpecializationsEnabled',
      'specializationRequestsEnabled',
      'useAdditionalMasterFields',
      'specializationTierType',
      'skillClusterForManageLearnings',
      'tagFiltersEnabledFor'
    ];
    if (fields.indexOf(smsOption.key) >= 0) this.buildForms();
  }

  evaluateEnterKey(event) {
    if ((event.srcElement.className || '').search('textarea') >= 0) return;
    event.preventDefault()
  }

  private updateUserSpecializationTagsConfig() {
    if (this.userSpecializationTagsConfig?.columns?.length) {
      this.userSpecializationTagsConfig.columns[0].disabled = !this.canEdit;
    }
    this.userSpecializationTagsConfig.canEdit = this.canEdit;
  }

  private handleUserSpecializationTags(formData: {[key: string]: any}): boolean {
    if (formData.isUserSpecializationTagsEnabled === SMS_OPTIONS.YES) {
      const userSpecializationTags = this.userSpecTagsDataTable?.getDataFromForm();
      if (!userSpecializationTags?.length) {
        this.weds.showDialog({ type: 'generic', title: 'Error', message: 'You need to add at least one specialization tag', btnTitle: 'Ok' });
        return false;
      }
      formData.userSpecializationTags = userSpecializationTags;
    }
    return true;
  }
}
