<div class="p2p-settings-con">
  <div class="h3 black">{{ settingsOptionTitle }}</div>
  <form [formGroup]="editForm" (ngSubmit)="saveData()" (keydown.enter)="evaluateEnterKey($event)" class="admin-form">
    <div class="admin-form-con">
      @for (option of settingsOptions; track option) {
        <div [ngClass]="option.type === 'textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display': option.showControl ? 'block' : 'none'}">
          @if (option.showControl) {
            <div>
              @if (option.type === 'select') {
                <div>
                  <mat-form-field appearance="outline" class="admin-input-form-field">
                    <mat-label>{{ option.title }}</mat-label>
                    @if (option.icon) {
                      <mat-icon matPrefix>{{ option.icon }}</mat-icon>
                    }
                    <mat-select formControlName="{{ option.key }}">
                      @for (item of option.options; track item) {
                        <mat-option [value]="item">
                          {{ option.displayKey ? item[option.displayKey] : item }}
                        </mat-option>
                      }
                    </mat-select>
                    @if (editForm.get(option.key).touched && editForm.get(option.key).invalid) {
                      <mat-error>
                        {{ option.errorText || 'This input is mandatory' }}
                      </mat-error>
                    }
                  </mat-form-field>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
  <div class="dynamic-content">
    <ng-content></ng-content>
  </div>
</div>
