import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { DEFAULT_LEARNING_TYPES, ILearningTypes } from '../learning-settings/learning-settings.model';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
    selector: 'app-learning-type-data-table',
    templateUrl: './learning-type-data-table.component.html',
    styleUrls: ['./learning-type-data-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: false
})
export class LearningTypeDataTableComponent implements OnInit {

  @Input() canEdit: boolean = false;
  @Input() client: Client;
  @Output() changeEvent = new EventEmitter();

  public tableColumns = ['learningType', 'action'];
  public expandedElem: any = null;
  public formGroup: FormGroup;
  public data: ILearningTypes[] = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private util: UtilitiesService
  ) { }

  ngOnInit(): void {
    this.data = this.client.smsSettings?.learningElementTypes?.length ? this.client.smsSettings.learningElementTypes : [...DEFAULT_LEARNING_TYPES];
    this.formGroup = this.fb.group({
      name: new FormControl({ value: '', disabled: !this.canEdit }, [Validators.required, this.uniqueNameValidator.bind(this), Validators.pattern(/^[a-zA-Z0-9\s\-]{5,30}$/)]),
      enable: new FormControl({ value: true, disabled: false }),
      isCustom: new FormControl({ value: true, disabled: false }),
      id: new FormControl({ value: true, disabled: false })
    });
  }

  public addNewItem() {
    this.data = this.data.filter(item => item.name)
    const newItem = { id: this.util.generateUniqueRandomString(12), name: '', enable: true, isCustom: true };
    this.data = [newItem, ...this.data]
    this.onRowClick(newItem);
  }
  public onRowClick(element) {
    this.expandedElem = this.expandedElem === element ? null : element;
    this.setFormValues(element)
  }

  public onToggle(element): void {
    element.enable = !element.enable;
    this.emitChangeEvent();
  }

  public deleteType(element) {
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm Change', message: 'This is a dangerous setting, are you absolutely sure you want to change these settings?', btnTitle: 'Yes', btn2Title: 'Cancel' };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event === 'BTN_CLICK') {
        this.data = this.data.filter(d => d.id !== element.id);
        this.emitChangeEvent();
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  public addLearningType() {
    if (this.formGroup.valid) {
      const newLearningType = this.formGroup.value;
      let newItem = this.data.find(item => item.id === newLearningType.id)
      newItem.name = newLearningType.name;

      this.expandedElem = null;
      this.emitChangeEvent();
    } else {
      this.formGroup.get('name').markAsTouched();
    }
  }

  private uniqueNameValidator (control: AbstractControl) {
    const value = control?.value?.toLowerCase();
    const isDuplicates = this.data.some(val => val?.name?.toLowerCase() === value && val.id !== this.expandedElem?.id)

    return isDuplicates ? { duplicateNames: true } : null;
  }

  private setFormValues(values) {
    this.formGroup.patchValue(values);
    if (!values.isCustom || !this.canEdit) {
      this.formGroup.get('name').disable();
    } else {
      this.formGroup.get('name').enable();
    }
    this.formGroup.markAsUntouched();
  }

  private emitChangeEvent() {
    this.changeEvent.emit();
  }
}
