<div class="alias-con">
  <div class="h3 black ">Aliases for key SMS terms</div>
  <form [formGroup]="aliasForm" (ngSubmit)="saveAliases()" (keydown.enter)="$event.preventDefault()"
    class="admin-form">
    <div class="d-flex flex-column">
      @for (feature of aliasTotalList; track feature) {
        <div>
          @if (feature?.aliasesCategoryList?.length) {
            <div class="h4 black mb-3">{{feature.category}}</div>
            <div class="admin-form-con">
              @for (alias of feature.aliasesCategoryList; track alias) {
                <div
                  [ngClass]="alias.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
                  [ngStyle]="{'display':alias.showControl?'block':'none'}">
                  @if (alias.showControl) {
                    <mat-form-field appearance="outline" class="admin-input-form-field">
                      <mat-label>{{alias.title}}</mat-label>
                      <mat-icon matPrefix>{{alias.icon}}</mat-icon>
                      <input matInput placeholder="{{alias.title}}" formControlName="{{alias.key}}"
                        [required]="alias.required" autocomplete="off"
                        maxlength="{{ alias.maxLength ? alias.maxLength : '70' }}" />
                      @if (aliasForm.get(alias.key).invalid) {
                        <mat-error>
                          {{alias.errorText||'This input is mandatory'}}
                        </mat-error>
                      }
                    </mat-form-field>
                  }
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
</div>
