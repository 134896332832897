<div [ngClass]="{'no-high-demand-skill' : isGlobalSkillTypeEnabled && !isHighDemandSkillEnabled}">
  <div class="chart-body">
    @for (skillGroup of skillGroups; track skillGroup; let i = $index) {
      <div class="skill-category"
        [ngStyle]="{'--i': i, '--j': skillGroups.length}">
        <div class="skill-category-text-con">
          <div class="label primary compentency-title">{{ skillGroup }}</div>
        </div>
      </div>
    }
    <canvas #canvas [height]="(size=='full'?width:width/2)+'px'" [width]="width" baseChart [datasets]="radarChartData.datasets"
      [labels]="radarChartData.labels" type="radar" [options]="radarChartOptions">
    </canvas>
  </div>
</div>
