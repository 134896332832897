<div class="bottom-con caption gray">
  <div class="limit-con">
    @if (!disableLimit()) {
      <mat-select [value]="limit()" (selectionChange)="onLimitChange($event)">
        @for (option of pageOptions(); track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
      <div>rows per page</div>
    }
  </div>
  @if (limit() < totalCount()) {
    <div class="offset-con">
      <span class="mr-2">Showing {{ startOfRange() }} to
      {{ endOfRange() }} of {{ totalCount() }} {{ title() }}</span>
      @if (pageNumber() > 1) {
        <mat-icon (click)="onNextPrev('first')">arrow_left</mat-icon>
        <mat-icon (click)="onNextPrev('prev')">keyboard_arrow_left</mat-icon>
      }
      @for (page of pages(); track page) {
        <div [ngClass]="{'page-number-button-selected': page == pageNumber()}" (click)="onPageNumberClick(page)"
          class="page-number-button">
          {{ page }}
        </div>
      }
      @if (pageNumber() * limit() < totalCount()) {
        <mat-icon (click)="onNextPrev('next')">keyboard_arrow_right</mat-icon>
        <mat-icon (click)="onNextPrev('last')">arrow_right</mat-icon>
      }
    </div>
  }
</div>
