<div class="recommendations-con">
  <div class="h3 black ">Skill Recommendations Settings</div>
  <form [formGroup]="recommendationsForm" (ngSubmit)="saveRecommendations()" (keydown.enter)="$event.preventDefault()"
    class="admin-form">
    <div class="admin-form-con">
      @for (recommendation of recommendationOptions; track recommendation) {
        <div
          [ngClass]="recommendation.type=='textarea' ? 'admin-input-con-full' : 'admin-input-con-half'"
          [ngStyle]="{'display':recommendation.showControl?'block':'none'}">
          @if (recommendation.type=='select'&&recommendation.showControl) {
            <div>
              <mat-form-field appearance="outline" class="admin-input-form-field">
                <mat-label>{{recommendation.title}}</mat-label>
                @if (recommendation.icon) {
                  <mat-icon matPrefix>{{recommendation.icon}}</mat-icon>
                }
                <mat-select formControlName="{{recommendation.key}}" [required]="recommendation.required"
                  (selectionChange)="onRecommendationOptionSelected(recommendation, $event)"
                  [multiple]="recommendation.isMulti" [compareWith]="compareSelect">
                  @for (option of recommendation.options; track option) {
                    <mat-option
                      [value]="option.skillTagId || option.skillTypeId ? option.skillTagId || option.skillTypeId : option">
                      {{recommendation.displayKey?option[recommendation.displayKey]:option}}
                    </mat-option>
                  }
                </mat-select>
                @if (recommendationsForm.get(recommendation.key).invalid) {
                  <mat-error
                    >{{recommendation.errorText||'This
                    input is
                  mandatory'}}</mat-error>
                }
              </mat-form-field>
            </div>
          }
          @if (recommendation.type=='textarea') {
            <div>
              @if (recommendation.type=='textarea'&&recommendation.showControl) {
                <mat-form-field appearance="outline"
                  class="admin-input-form-field admin-text-area-field">
                  <mat-label>{{recommendation.title}}</mat-label>
                  <mat-icon matPrefix>{{recommendation.icon}}</mat-icon>
                  <textarea matInput [placeholder]="recommendation.title" formControlName="{{recommendation.key}}"
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="20" class="config-tooltip h5 black"></textarea>
                </mat-form-field>
              }
            </div>
          }
        </div>
      }
    </div>
    @if (canEdit) {
      <div class="admin-save-button-con">
        <button type="submit" mat-stroked-button class="primary-button">Save</button>
      </div>
    }
  </form>
</div>