export const ALIASES_MASTER_LIST: any[] = [
  {
    category: "My Skills",
    aliasesCategoryList: [
      { title: 'Skills & Competencies', key: 'skillsAndCompetenciesAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Upload Resume', key: 'uploadResumeAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'High Demand Skill', key: 'highDemandSkillAlias', type: 'text', validators: [], icon: 'trending_up' },
      { title: 'Skill Type', key: 'skillTypeAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Skill Group', key: 'skillGroupAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
  {
    category: "My Specialization",
    aliasesCategoryList: [
      { title: 'Specialization', key: 'specializationsAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'] },
      { title: 'Primary Specialization', key: 'primarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
      { title: 'Secondary Specialization', key: 'secondarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
      { title: 'Tertiary Specialization', key: 'tertiarySpecializationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'], checkValue: [{ key: 'specializationCategoryTagging', values: ['Yes'] }] },
      { title: 'Exit Rating', key: 'exitRatingAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['specializations'] }
    ]
  },
  {
    category: "Admin",
    aliasesCategoryList: [
      { title: 'Core Mandatory', key: 'coreMandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Mandatory', key: 'mandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Cond. Mandatory', key: 'conditionalMandatoryAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Optional', key: 'optionalAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Designation', key: 'designationAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designations'] },
      { title: 'Designation Level', key: 'designationLevelAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-levels'] },
      { title: 'Designation ID', key: 'extDesignationIdAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designations'], checkValue: [{ key: 'mapExternalIdToDesignations', values: ['Yes'] }] },
      { title: 'Role', key: 'roleAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Rating', key: 'ratingAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Skill Cluster', key: 'skillClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['skill-clusters'] },
      { title: 'Service', key: 'servicesAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['services'] },
      { title: 'Location Cluster', key: 'locationClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['location-clusters'] },
      { title: 'Demand Units', key: 'demandUnitAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['demand-units'] },
      // { title: 'All Demand Unit', key: 'allDemandUnitAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['all-demand-units'] },
      { title: 'SKU', key: 'skuAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['sku'] }
    ]
  },
  {
    category: "Career Path",
    aliasesCategoryList: [
      { title: 'Designation Path', key: 'designationPathAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-paths'] },
      { title: 'Designation Path Bookmark', key: 'bookmarkAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Designation Cluster', key: 'designationClusterAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['designation-clusters'] }
    ]
  },
  {
    category: "My Team",
    aliasesCategoryList: [
      { title: 'My Team', key: 'myTeamAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Team Details', key: 'teamDetailsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Manage Learnings', key: 'manageLearningsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Endorsements', key: 'endorsementsAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
  {
    category: "Resume Builder",
    aliasesCategoryList: [
      { title: 'Open Request', key: 'openRequestAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Resume Approved', key: 'resumeApprovedAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Resume Submitted', key: 'resumeSubmittedAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Resume Rejected', key: 'resumeRejectedAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Request Cancelled', key: 'resumeCancelledAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Request Delayed', key: 'resumeUpdateDelayedAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Request Closed', key: 'requestClosedAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
  {
    category: "My Learnings",
    aliasesCategoryList: [
      { title: 'My Learnings', key: 'myLearningsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'My Learning Initial Status', key: 'myLearningInitialStatusAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Must Complete', key: 'mustCompleteLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Completed', key: 'completedLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Optional', key: 'optionalLearningElementAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
  {
    category: "My Goals",
    aliasesCategoryList: [
      { title: 'My Goals', key: 'myGoalsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Team Goals', key: 'teamGoalsAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Growth Plan', key: 'developmentPlanAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
  {
    category: "Gamification",
    aliasesCategoryList: [
      { title: 'Badges', key: 'badgesAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['gamification'] },
      { title: 'Karma Points', key: 'pointsAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['gamification'] },
      { title: 'Leaderboard', key: 'leaderboardAlias', type: 'text', validators: [], icon: 'settings_suggest', allowIf: ['gamification'] }
    ]
  },
  {
    category: "Others",
    aliasesCategoryList: [
      { title: 'Skill Recommendation', key: 'skillRecommendationAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Staffing Role', key: 'staffingRoleAlias', type: 'text', validators: [], icon: 'settings_suggest' },
      { title: 'Skill Taxonomy Visualization', key: 'skillTaxonomyVisualizationAlias', type: 'text', validators: [], icon: 'settings_suggest' }
    ]
  },
];
