<!-- progress card -->
<div class="w-fit-content h-100" (mouseenter)="showTooltip = isHoverPopupEnabled" (mouseleave)="showTooltip = false"
  #progressCard>
  <div class="card-wrapper d-grid gap-2 h-100">
    @if (data?.assignedBy && data?.assignedBy !== EVENT && data?.assignedBy !== userId) {
      <div class="top-info-con align-items-center d-flex gap-1">
        <div class="profile-placeholder item-center rounded-circle" skeletonNew [isLoading]="isLoading">
          <span class="h6 white">{{data.assignedBy === ASSIGNED_TYPE.LMS ? ASSIGNED_TYPE.AUTO_ASSIGNED : data?.assigneeInitials}}</span>
        </div>
        @if (data?.assignedBy !== ASSIGNED_TYPE.LMS) {
          <div class="label gray" skeletonNew [isLoading]="isLoading">
            <span><strong>{{data?.assigneeName}}</strong></span> assigned you this as part of
            <span><strong>upcoming project requirements</strong></span>
          </div>
        } @else {
          <span class="label gray"><strong>Auto assigned to you from LMS</strong></span>
        }
      </div>
    }

    <div class="card-con d-flex flex-column align-items-start position-relative">
      <div class="top-con item-center"
                [ngClass]="{
                    'course-card': data?.type === TYPE.COURSE,
                    'assessment-card': data?.type === TYPE.ASSIGNMENT,
                    'certification-card': data?.type === TYPE.CERTIFICATION
                }" skeletonNew [isLoading]="isLoading">
        <label class="h5 nowrap m-2">{{data?.title}}</label>
      </div>

      @if (data?.type && cardConfig.labelRequired) {
        <div
          class="tag-con d-inline-flex justify-content-center align-items-center position-absolute m-1 border-radius-4 label black">
          {{data?.type}}
        </div>
      }

      @if (data.assignedBy === userId && !cardConfig.labelRequired) {
        <div
          class="tag-con d-inline-flex justify-content-center align-items-center position-absolute m-1 border-radius-4 label black">
          Added by you
        </div>
      }

      @if (data?.userStatus !== STATUS.NOT_STARTED && data?.progress) {
        <div
          class="progress-con d-inline-flex align-items-center mt-n1">
                <div class="progress-bar" [ngStyle]="{
                        'background': calculateBackgroundColor(data),
                        'width': data?.progress < 100 ? data?.progress+'%' : '100%'
                    }">
          </div>
        </div>
      }

      <div class="buttom-con d-flex flex-column align-items-start gap-2 align-self-stretch">
        <div class="d-flex gap-2">
          @if (data?.userStatus) {
            <div class="status-con item-center p-1" skeletonNew [isLoading]="isLoading" [skeletonWidth]="'30%'"
                  [ngClass]="{
                      'not-started': data?.userStatus === STATUS.NOT_STARTED && !cardConfig.showCurrentState,
                      'in-Progress': data?.userStatus === STATUS.IN_PROGRESS && !cardConfig.showCurrentState,
                      'completed': data?.userStatus === STATUS.COMPLETED && !cardConfig.showCurrentState,
                      'active': data.currentState === STATUS.ACTIVE && cardConfig.showCurrentState,
                      'expired': data.currentState === STATUS.EXPIRED && cardConfig.showCurrentState
                      }">
              <label class="h6">{{cardConfig.showCurrentState ? data.currentState : userStatusMap[data?.userStatus]}}</label>
            </div>
          }
          <div class="status-con item-center p-1"
                    [ngClass]="{
                        'result-status-passed': data?.resultStatus === STATUS.PASSED,
                        'result-status-failed': data?.resultStatus === STATUS.FAILED
                        }">
            <label class="h6">{{userStatusMap[data?.resultStatus]}}</label>
          </div>
        </div>
        <div class="caption h-16px gray gap-1" skeletonNew [isLoading]="isLoading" [skeletonWidth]="'60%'">
          @if (data?.source) {
            <span>on {{data?.source}}</span>
          }
          @if (data?.originCreator) {
            <span>
              <div class="gray-dot"></div>
              <span>by {{data?.originCreator}}</span>
            </span>
          }
          @if (data?.duration) {
            <span>
              <div class="gray-dot"></div>
              <span>{{data?.durationInTime}}</span>
            </span>
          }
        </div>
      </div>

      @if (data?.isFinished && useFinishingLearningElement) {
        <div
          class="accelerator-con d-flex align-items-center justify-content-center gap-1">
          <mat-icon class="white ic-20">run_circle</mat-icon>
          <label class="label white">Accelerator</label>
        </div>
      }

      <!-- on progress card hover-->
      @if (showTooltip && !isLoading) {
        <div class="hover-card-wrapper d-flex align-items-center"
          [ngClass]="getBestTooltipPosition()" @delayTooltip>
          <div class="item-center">
            <div class="polygon-icon-con">
              <mat-icon class="color-color-white" svgIcon="polygon"></mat-icon>
            </div>
            <div>
              @if (data?.isFinished) {
                <div
                  class="accelerator-hover-con d-flex align-items-center justify-content-center">
                  <mat-icon class="white ic-20 pr-1">run_circle</mat-icon>
                  <label class="label white">
                    Accelerator: Finish this for swift progression through the learning path—skip the rest!
                  </label>
                </div>
              }
              <div class="hover-card d-flex flex-column align-items-start px-20 pb-20 pt-3"
                [ngClass]="{'hover-card-top-radius':data?.isFinished}">
                <label class="h4 black">{{data?.title}}</label>
                <div class="d-flex flex-column align-items-start align-self-stretch gap-2">
                  <div class="d-flex justify-content-between align-self-stretch align-items-end">
                    <div
                      class="tag-con d-inline-flex justify-content-center align-items-center border-radius-4 label black">
                      {{data?.type}}
                    </div>
                    @if (data?.progress) {
                      <div class="status-con item-center p-1"
                                      [ngClass]="{
                                          'not-started': data?.userStatus === STATUS.NOT_STARTED,
                                          'in-Progress': data?.userStatus === STATUS.IN_PROGRESS,
                                          'completed': data?.userStatus === STATUS.COMPLETED,
                                          'result-status-passed': data.userStatus === STATUS.PASSED,
                                          'result-status-failed': data.userStatus === STATUS.FAILED
                                      }">
                        <label class="h6">{{userStatusMap[data?.userStatus]}}</label>
                      </div>
                    } @else {
                      <label class="label"
                                      [ngClass]="{
                                          'not-started-txt': data?.userStatus === STATUS.NOT_STARTED,
                                          'in-Progress-txt': data?.userStatus === STATUS.IN_PROGRESS || statusFailedAndCompleted,
                                          'completed-txt': data?.userStatus == STATUS.COMPLETED
                                      }">
                        {{ (data?.progress) | number: '1.0-0'}}% complete
                      </label>
                    }
                  </div>
                  @if (data?.userStatus !== STATUS.NOT_STARTED && data?.progress) {
                    <div
                      class="progress-con d-inline-flex align-items-center mt-n1">
                              <div class="progress-bar" [ngStyle]="{
                                  'background': calculateBackgroundColor(data),
                                  'width':data?.progress < 100 ? data?.progress+'%' : '100%'
                              }">
                      </div>
                    </div>
                  }
                </div>
                <div class="caption gray gap-1">
                  @if (data?.source) {
                    <span>on {{data?.source}}</span>
                  }
                  @if (data?.originCreator) {
                    <div class="gray-dot"></div>
                  }
                  @if (data?.originCreator) {
                    <span>by {{data?.originCreator}}</span>
                  }
                  @if (data?.duration) {
                    <div class="gray-dot"></div>
                  }
                  @if (data?.duration) {
                    <span>{{data?.duration}}h</span>
                  }
                </div>
                <label class="align-self-stretch caption description">{{data?.description}}</label>
                <div class="w-100">
                  @if (data.startDate || data.endDate) {
                    <span>
                      <mat-divider></mat-divider>
                      <div class="d-flex w-100 py-3">
                        @if (data.startDate) {
                          <span>Issued {{data.startDate | date: 'd MMM yyyy'}} </span>
                        }
                        @if (data.endDate) {
                          <span class="ml-auto">Expires {{data.endDate | date: 'd MMM yyyy'}}</span>
                        }
                      </div>
                    </span>
                  }
                  @if (cardConfig.showSkills && data.skillItemIds?.length) {
                    <span>
                      <mat-divider></mat-divider>
                      <div class="d-flex w-100 py-3 gap-2 flex-wrap">
                        @for (skillItemId of data.skillItemIds; track skillItemId) {
                          <span class="skill-tag px-2 py-1"> {{data?.skillItemIdVsName?.[skillItemId]}} </span>
                        }
                      </div>
                      <mat-divider *ngIf="data.specialization || (cardConfig.showEditButton && data.assignedBy === userId) || data?.contentUrl || data?.referenceFile || data.referenceUrl"></mat-divider>
                    </span>
                  }
                </div>
                @if (data?.specialization) {
                  <div class="buttom-info-con d-flex align-items-center align-self-stretch gap-2 p-2 border-radius-4">
                    <div class="route-icon-con item-center rounded-circle">
                      <mat-icon class="route-icon ic-20 white">route</mat-icon>
                    </div>
                    <label class="caption gray">Assigned to you as part of {{data?.specialization}} skill’s learning
                    path</label>
                  </div>
                }
                <div class="d-flex gap-3 w-100">
                  <!-- <button mat-button *ngIf="canDrop" class="secondary-button-warn h6"
                (click)="onDropClick()">DROP</button> -->
                @if (cardConfig.showEditButton && data.assignedBy === userId) {
                  <button mat-button (click)="onEditClick()" class="secondary-button h6">
                    EDIT
                  </button>
                }
                @if (data?.contentUrl || data?.referenceFile || data.referenceUrl) {
                  <button mat-button (click)="viewDetails(data)"
                    class="item-center primary-button border-0 gap-1 w-100 flex-1 cursor-pointer">
                    <label class="h6 white cursor-pointer">View details</label>
                    <mat-icon iconPositionEnd class="new-tab-icon ic-18 white cursor-pointer">open_in_new</mat-icon>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>


</div>
